import { render, staticRenderFns } from "./SetsLibrary.vue?vue&type=template&id=ac7ce5e8&scoped=true"
import script from "./SetsLibrary.vue?vue&type=script&lang=ts"
export * from "./SetsLibrary.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac7ce5e8",
  null
  
)

export default component.exports