<template>
  <div>
    <v-container fluid>
      <v-alert type="error" v-if="!is_authenticated"> This {{ typeName }} is read-only </v-alert>
      <v-alert type="error" v-if="!user.is_editor">
        This {{ typeName }} is read-only. Only users with Editor role can do modifications
      </v-alert>
      <v-alert type="error" v-else-if="!isEditable">
        This {{ typeName }} is read-only. To do any modifications - click on the "Copy" button below
      </v-alert>

      <v-toolbar style="z-index: 1" class="mb-8" short elevation="1" rounded>
        <v-toolbar-items style="width: 100%">
          <v-form
            ref="builderform"
            v-model="validForm"
            lazy-validation
            class="mt-2"
            style="width: 400px">
            <v-text-field
              label="Name"
              v-if="currentHold"
              dense
              outlined
              v-model="currentHold.name"
              :rules="nameRules"
              required
              :disabled="!isEditable"></v-text-field>
          </v-form>
          <v-spacer />
          <v-btn
            elevation
            text
            color="green"
            v-if="isEditable"
            :loading="isLoading"
            :disabled="isLoading"
            @click="onSubmit"
            ><v-icon>mdi-content-save</v-icon>Save</v-btn
          >

          <v-btn
            elevation
            text
            color="primary"
            v-if="currentHold && currentHold.id"
            :disabled="isLoading"
            :loading="isLoading"
            @click="
              copyName = `${currentHold.name} (copy)`;
              showCopyModal = true;
            "
            ><v-icon>mdi-content-copy</v-icon>Copy</v-btn
          >

          <v-btn
            elevation
            text
            color="error"
            v-if="isEditable && currentHold && currentHold.id"
            :disabled="isLoading"
            :loading="isLoading"
            @click="showDeleteModal = true"
            ><v-icon>mdi-delete</v-icon>Delete</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <container-editor :base_type="base_type" v-model="currentHold.data" />
    </v-container>

    <v-dialog v-model="showCopyModal" width="500">
      <v-card>
        <v-card-title class="text-h5">Copy</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              label="New name:"
              v-model="copyName"
              required
              @keydown.enter.prevent="copyHold()"
              :rules="nameRules"
              :counter="100"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showCopyModal = false">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click.stop="
              copyHold();
              showCopyModal = false;
            "
            >Make copy</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteModal" width="500">
      <v-card>
        <v-card-title class="text-h5">Delete this?</v-card-title>
        <v-card-actions>
          <v-btn text @click.stop="showDeleteModal = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click.stop="
              deleteHold();
              showDeleteModal = false;
            "
            >Yes, I am sure</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSupportModal">
      <v-card>
        <v-card-title class="text-h5">Help</v-card-title>
        <v-card-text>
          <p>
            Creating or editing a {{ typeName }} can be a bit tricky. If you would like to add one
            not found in the Library and find it hard to create one - just drop an email to:
            <a :href="`mailto:support@cargo-planner.com?subject=Please add this ${typeName} for us`"
              >support@cargo-planner.com</a
            >
            with the dimensions and specifications and we will try to create it for you.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showSupportModal = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      {{ error_message }}
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import Vue from 'vue';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import { SceneManager } from '@/graphics/sceneManager';
import API from '@/API';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { Hold, HoldData, ListType } from '@/models/LoadlistModel';
import { User } from '@/models/UserCompanyModel';
import ContainerEditor from './Custom/ContainerEditor/index.vue';
import { getSerializerError } from '@/misc/errorUtils';

export default Vue.extend({
  name: 'modify_hold',
  components: {
    sceneComponent,
    ContainerEditor,
  },
  data() {
    return {
      isLoading: false,
      nameRules: [
        (v: string) => !!v || 'A name is required',
        (v: string) => (v && v.length <= 100) || 'Name must be less than 100 characters',
      ],
      validForm: false,
      showDeleteModal: false,
      showCopyModal: false,
      showSupportModal: false,
      copyName: '',
      nameError: false,
      renderKey: 1,
      currentHold: {} as Hold,
      active_tab: 0,
      showSnackbar: false,
      error_message: undefined as string,
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    base_type(): ListType {
      return (this.$route.params.type || 'SEA') as ListType;
    },
    hold(): HoldData {
      return [...this.miscStore.systemHolds, ...this.miscStore.userHolds].find(
        (h) => h.id === parseInt(this.$route.params.hold_id)
      );
    },

    isEditable(): boolean {
      return (
        (this.user.is_editor && !this.currentHold?.default) || this.$route.query['edit'] == 'true'
      );
    },
    user(): User {
      return this.miscStore.user;
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    length_dim(): string {
      return this.miscStore.length_dim;
    },
    typeName(): string {
      return this.$typeNames(this.base_type);
    },
  },
  watch: {
    hold: {
      handler: function (hold: Hold): void {
        if (hold) {
          this.currentHold = JSON.parse(JSON.stringify(hold));
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    SceneManager.clearScene();
  },
  mounted() {},
  methods: {
    onSubmit(): void {
      if ((this.$refs.builderform as any).validate()) {
        this.isLoading = true;

        let finalAssembly = {
          base_type: this.base_type,
          ...this.currentHold,
        } as Hold;

        const crud_operation = !finalAssembly.id ? API.createHold : API.updateHold;
        crud_operation(finalAssembly)
          .then((success) => {
            return this.miscStore.getUserHolds();
          })
          .then((_) => {
            this.isLoading = false;
            this.$router.go(-1);
          })
          .catch((error) => {
            if (error?.response?.data) {
              this.error_message = getSerializerError(error.response.data);
            } else {
              this.error_message = 'Unknown error when saving';
              console.log(error);
            }
            this.showSnackbar = true;
            this.isLoading = false;
          });
      }
    },
    deleteHold(): void {
      this.isLoading = true;

      API.deleteHold(this.currentHold.id)
        .then(() => {
          return this.miscStore.getUserHolds();
        })
        .then((_) => {
          this.isLoading = false;
          this.$router.go(-1);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    copyHold(): void {
      var newHold = JSON.parse(JSON.stringify(this.currentHold));

      newHold.id = null;
      newHold.priority = 10000;
      newHold.default = false;
      newHold.name = this.copyName;

      this.isLoading = true;

      API.createHold(newHold)
        .then((data: any) => {
          this.isLoading = false;

          this.currentHold = { ...this.currentHold, ...data.data };

          this.$router.replace({
            name: 'modify_hold',
            params: { hold_id: data.data.id, type: data.data.base_type },
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error?.response?.data) {
            this.error_message = getSerializerError(error.response.data);
          } else {
            this.error_message = 'Unknown error when saving';
            console.log(error);
          }
          this.showSnackbar = true;
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-subtitle-1 {
  margin-bottom: 6px;
  margin-top: 24px;
}
</style>
