<template>
  <v-dialog v-model="show" width="800" class="dialog-test">
    <v-card>
      <v-card-title class="text-h5">Select container type</v-card-title>
      <v-card-title
        >Temporary containers
        <v-spacer></v-spacer>
        <v-btn icon @click="showContainerBuilder()"
          ><v-icon color="success">mdi-plus-circle</v-icon></v-btn
        >
      </v-card-title>
      <select-container-type-table-component
        v-if="temporaryContainers.length > 0"
        :holds="temporaryContainers"
        @containerSelected="
          $emit('containerSelected', $event)
        "></select-container-type-table-component>
      <v-card-title>Library containers</v-card-title>
      <v-card-text>
        <container-library-component ref="containerLibrary" :holdType="$route.params.type">
          <template v-slot:items="{ paginatedItems: containers }">
            <select-container-type-table-component
              :holds="containers"
              @containerSelected="
                $emit('containerSelected', $event)
              "></select-container-type-table-component>
          </template>
        </container-library-component>
      </v-card-text>
      <v-card-actions>
        <v-btn @click.stop="$emit('close')">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog width="800" v-if="showAddInlineContainerModal" v-model="showAddInlineContainerModal">
      <v-card>
        <v-card-title class="text-h5">Add space</v-card-title>
        <v-card-text>
          <inline-hold-edit-component
            v-model="newTempContainer"
            :basetype="currentBaseType"
            hideQtyCost
            hideBottomSpacer
            editableName>
          </inline-hold-edit-component>
          <v-alert type="info" outlined
            >You can customize this space more later on in the Overview tab</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showAddInlineContainerModal = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="success"
            @click="
              $emit('containerSelected', newTempContainer);
              showAddInlineContainerModal = false;
            "
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showContainerBuilderModal"
      v-model="showContainerBuilderModal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="closeContainerBuilder()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Container builder</v-toolbar-title>
        </v-toolbar>
        <v-text-field
          class="pa-4 pb-0"
          label="Name"
          dense
          outlined
          :value="newTempContainer.name"
          @input="updateContainerName($event)"
          required></v-text-field>
        <container-edit-component :base_type="currentBaseType" v-model="newTempContainer" />
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import containerLibraryComponent from '@/components/Custom/ContainerLibrary.vue';
import inlineHoldEditComponent from '@/components/Custom/InlineHoldEdit.vue';
import selectContainerTypeTableComponent from '@/components/SetBuilder/SelectContainerTypeTable.vue';
import containerEditComponent from '../Custom/ContainerEditor/index.vue';
import { Hold, HoldData, ListType } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { SetType } from '@/models/SetsModel';
import { SceneManager } from '@/graphics/sceneManager';
import { ViewSettings } from '@/models/GraphicsModel';

export default Vue.extend({
  name: 'select_slot_container_type',
  props: {
    set: Object as PropType<SetType>,
    positionName: String,
    visible: Boolean,
  },
  components: {
    containerLibraryComponent,
    inlineHoldEditComponent,
    selectContainerTypeTableComponent,
    containerEditComponent,
  },
  data() {
    return {
      currentBaseType: this.$route.params.type as ListType,
      showContainerBuilderModal: false,
      showAddInlineContainerModal: false,
      newTempContainer: {
        id: null,
        name: 'Custom',
        L: 2,
        W: 2,
        H: 2,
        items: [],
        payload: 1000,
        rotation: [0, 0, 0, 'XYZ'],
        base_type: this.$route.params.type as ListType,
      } as HoldData,
      prevViewSettings: undefined as ViewSettings,
    };
  },
  mounted() {
    (this.$refs.containerLibrary as any)?.$refs?.search?.focus();
  },
  computed: {
    ...mapStores(useMiscStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    allLibraryContainers(): Hold[] {
      return this.miscStore.holds;
    },
    temporaryContainers(): Hold[] {
      return this.set.data.container_types
        .filter((i) => !this.allLibraryContainers.some((x) => x.id === i.id))
        .map((holdData) => ({ data: holdData }) as Hold);
    },
  },
  methods: {
    generateID(): number {
      // also used in Builder.vue
      return parseInt(String(new Date().getTime()).substr(-8));
    },
    showContainerBuilder() {
      this.newTempContainer.id = this.generateID();
      this.prevViewSettings = SceneManager.getViewSettings();
      this.showContainerBuilderModal = true;
    },
    closeContainerBuilder() {
      this.showContainerBuilderModal = false;
      // reset to previous view settings for set
      SceneManager.setViewSettings(this.prevViewSettings);
      this.$emit('containerSelected', this.newTempContainer);
    },
    updateContainerName(updatedName: string) {
      this.newTempContainer = {
        ...this.newTempContainer,
        name: updatedName,
      };
    },
  },
});
</script>

<style scoped></style>
