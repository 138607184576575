import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{ref:"plannerDiv",staticStyle:{"outline":"none","overflow":"hidden"},attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.unloadLoadedCargoes()}}},[_c('div',{on:{"dragover":function($event){$event.preventDefault();_vm.draggedOverHold = true},"dragend":function($event){_vm.draggedOverHold = null},"dragleave":function($event){_vm.draggedOverHold = null},"drop":function($event){return _vm.cargoDropOnContainer($event, _vm.hold.__indices.start, _vm.augmentedHold.bundledItems.length)}}},[_vm._t("top",null,{"container":_vm.augmentedHold})],2),(!_vm.hideItems)?_c('div',{staticClass:"item-list",class:{ errorDrop: _vm.showMoveError === _vm.hold.__indices.start },style:({ height: _vm.listHeight || '50vh' })},[_vm._l((_vm.augmentedHold.bundledItems),function(item,index){return _c('div',{key:index,staticClass:"item-container px-1 pt-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g({staticClass:"item",class:{
                draggedItem: _vm.draggedItemIndex === index,
              },attrs:{"draggable":true},on:{"dragover":function($event){$event.preventDefault();_vm.draggedOverRow = index},"dragend":function($event){_vm.draggedOverRow = null;
                _vm.draggedItemIndex = null;},"dragleave":function($event){_vm.draggedOverRow = null},"dragstart":function($event){return _vm.dragStart(item, index, _vm.hold, _vm.hold.__indices.start, $event)},"drag":_vm.onDrag,"drop":function($event){return _vm.cargoDropOnItem($event, _vm.hold.__indices.start, index)},"click":function($event){return _vm.toggleSelectedIndices(index)},"contextmenu":function($event){return _vm.showContextMenu($event, index)}}},on),[_c('div',{staticClass:"item-container new-slot",class:{
                  draggedOver: index === _vm.draggedOverRow,
                }}),_c(VCard,{class:{
                  selectedCargo: _vm.selectedCargoIndices.includes(index),
                },attrs:{"outlined":""}},[_c(VCardText,[_c(VRow,{attrs:{"justify":"center","align":"center"}},[(!item.from_container)?_c(VIcon,{attrs:{"color":item.color}},[_vm._v("fa-cube")]):_vm._e(),_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v(_vm._s(item.label))]),_c(VSpacer),_c('span',[_vm._v(_vm._s(item.qty)+" pcs")])],1),_c(VRow,[_c('span',[_vm._v(_vm._s(_vm._f("toLength")(item.L,false))+" x "+_vm._s(_vm._f("toLength")(item.W,false))+" x "+_vm._s(_vm._f("toLength")(item.H,true))+", "+_vm._s(_vm._f("toWeight")(item.WT,true)))]),_c(VSpacer),_c('span',{staticClass:"item-indicator"},[_vm._v(_vm._s(item.not_stackable ? ' ≠ ' : '')+" "+_vm._s(item.orientations <= 3 ? ' ⇈ ' : ''))])],1),_c(VRow,[(item.shipment_id)?_c(VChip,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.shipment_id)+" ")]):_vm._e(),(item.priority)?_c(VChip,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" Priority "+_vm._s(item.priority)+" ")]):_vm._e()],1)],1),_c('sub-item-component',{attrs:{"item":item}})],1)],1)]}}],null,true)},[_c('span',[_vm._v("Drag to move")]),(isNaN(_vm.itemIndex))?_c('span',[_vm._v(", Press "),_c('kbd',[_vm._v("Delete")]),_vm._v(" to unload")]):_vm._e(),_c('br'),(item.itemIndices.length > 1)?_c('span',[_vm._v("Hold "),_c('kbd',[_vm._v("Alt")]),_vm._v(" to move just one of the cargoes")]):_vm._e()])],1)}),_c('div',{staticClass:"item-container new-slot",class:{
          draggedOver: _vm.draggedOverHold && _vm.draggedOverRow === null,
        },on:{"dragover":function($event){$event.preventDefault();_vm.draggedOverHold = true},"dragend":function($event){_vm.draggedOverHold = null},"dragleave":function($event){_vm.draggedOverHold = null},"drop":function($event){return _vm.cargoDropOnContainer($event, _vm.hold.__indices.start, _vm.augmentedHold.bundledItems.length)}}})],2):_vm._e()]),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showSnackBar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c(VIcon,[_vm._v("mdi-exclamation")]),_vm._v(" The move results in some unloaded items. Hold "),_c('kbd',[_vm._v("Shift")]),_vm._v(" to do the move anyway ")],1),_c(VMenu,{attrs:{"position-x":_vm.contextMenu.x,"position-y":_vm.contextMenu.y,"absolute":"","offset-y":""},model:{value:(_vm.contextMenu.show),callback:function ($$v) {_vm.$set(_vm.contextMenu, "show", $$v)},expression:"contextMenu.show"}},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.goToRow()}}},[_c(VListItemTitle,[_vm._v(" Show in Data ")])],1),_c(VListItem,{attrs:{"disabled":!_vm.selectedCargoIndices.length},on:{"click":_vm.unloadLoadedCargoes}},[_c(VListItemTitle,[_vm._v(" Unload selected ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }