import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"persistent":"","width":"800"},model:{value:(_vm.is_calculating),callback:function ($$v) {_vm.is_calculating=$$v},expression:"is_calculating"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Calculating...")]),_c(VCardText,[_c(VProgressLinear,{staticClass:"my-3",attrs:{"value":_vm.progress,"color":"primary","height":"25"}}),_c(VAlert,{attrs:{"color":"info","icon":"fa-lightbulb","outlined":""}},[_vm._v("You can change the timeout in \"Load settings\". A longer timeout generates better solutions.")])],1)],1)],1),_c(VSnackbar,{attrs:{"timeout":5000,"top":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.calculation_info_message)+" "),_c(VBtn,{attrs:{"text":"","color":"primary"},nativeOn:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("Ok")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }