<template>
  <div class="height100">
    <v-container fluid style="margin-bottom: -64px; position: relative" class="height100">
      <v-alert type="error" v-if="!is_authenticated"> This set is read-only </v-alert>
      <v-alert type="error" v-if="!user.is_editor">
        This set is read-only. Only users with Editor an editor role can do modifications
      </v-alert>
      <v-alert type="error" v-else-if="!isEditable">
        This set is read-only. To do any modifications - click on the "Copy" button below
      </v-alert>
      <v-row class="height100">
        <v-tabs v-model="active_tab">
          <v-tab v-for="tab in tabs" :key="tab.title">{{ tab.title }}</v-tab>
          <v-tabs-items v-model="active_tab" id="set-builder" class="tool-bar height100">
            <v-tab-item v-for="tab in tabs" :key="tab.key" :transition="false">
              <v-card flat v-if="tab.key === 'overview'">
                <div class="my-4">
                  <set-overview-component
                    ref="overviewcomponent"
                    :isActive="active_tab === 0"
                    :set="set"
                    :displayConfiguration="displayConfiguration"
                    @goToBuilder="active_tab = 1"
                    @validForm="validForm = $event">
                  </set-overview-component>
                </div>
              </v-card>
              <v-card flat v-if="tab.key === 'builder'">
                <div class="my-4">
                  <set-builder-component
                    :isActive="active_tab === 1"
                    :set="set"
                    :displayConfiguration="displayConfiguration">
                  </set-builder-component>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <div style="position: absolute; right: 0">
          <v-btn
            v-if="isEditable"
            class="ma-2"
            :loading="isLoading"
            :disabled="!enabledSave"
            @click="onSubmit">
            <v-icon>mdi-content-save</v-icon>
            Save
          </v-btn>

          <v-btn
            class="ma-2"
            v-if="set.id && user.is_editor"
            :disabled="isLoading"
            :loading="isLoading"
            @click="showCopyModal = true"
            ><v-icon>mdi-content-copy</v-icon>Copy</v-btn
          >

          <v-btn
            class="ma-2"
            color="error"
            v-if="isEditable && set.id"
            :disabled="isLoading"
            :loading="isLoading"
            @click="showDeleteModal = true"
            ><v-icon>mdi-delete</v-icon>Delete</v-btn
          >
        </div>
      </v-row>
    </v-container>

    <v-dialog v-model="showDeleteModal" width="800">
      <v-card>
        <v-card-title class="text-h5">Delete this?</v-card-title>
        <v-card-actions>
          <v-btn
            color="error"
            @click.stop="
              deleteSet();
              showDeleteModal = false;
            "
            >Yes, I am sure</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="showDeleteModal = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCopyModal" width="800">
      <v-card>
        <v-card-title class="text-h5">Copy</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              label="New name:"
              v-model="copyName"
              required
              :rules="nameRules"
              :counter="30"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click.stop="
              copySet();
              showCopyModal = false;
            "
            >Make copy</v-btn
          >
          <v-btn text @click.stop="showCopyModal = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSupportModal" width="800">
      <v-card>
        <v-card-title class="text-h5">Help</v-card-title>
        <v-card-text>
          <p>
            Creating a set is currently quite difficult. If you would like to add one not found in
            the Library and find it hard to create one - just drop an email to:
            <a :href="'mailto:support@cargo-planner.com?subject=Please create this set for us'"
              >support@cargo-planner.com</a
            >
            with the dimensions and specifications and we will try to create it for you. Or write us
            a line through the chat, that can be found in the bottom right corner.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showSupportModal = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      {{ error_message }}
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import setOverviewComponent from '@/components/SetBuilder/SetOverview.vue';
import setBuilderComponent from '@/components/SetBuilder/Builder.vue';
import API from '@/API';
import { SetSlot, SetType, SetTypeData } from '@/models/SetsModel';
import { User } from '@/models/UserCompanyModel';
import { getSerializerError } from '@/misc/errorUtils';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
export default Vue.extend({
  name: 'set_entry',
  components: {
    setOverviewComponent,
    setBuilderComponent,
  },
  data() {
    return {
      validForm: false,
      isLoading: false,
      showDeleteModal: false,
      showCopyModal: false,
      showSupportModal: false,
      enabledSave: false,
      copyName: '',
      nameError: false,
      nameRules: [
        (v: string) => !!v || 'A name is required',
        (v: string) => (v && v.length <= 100) || 'Name must be less than 100 characters',
      ],
      set: {
        data: {
          name: null,
          id: null,
          container_types: [],
          slots: [{ configurations: [{ group: [] }], name: 'Group 1' }],
          quantity: null,
          payload: null,
          base_type: this.$route.params.type,
        },
        base_type: this.$route.params.type,
        id: null,
        editable: true,
        priority: 20000,
        name: null,
        default: false,
      } as SetType,
      displayConfiguration: [0],
      active_tab: 0,
      showSnackbar: false,
      error_message: undefined as string,
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    tabs(): { key: string; title: string }[] {
      return [
        {
          key: 'overview',
          title: 'Overview',
        },
        {
          key: 'builder',
          title: 'Builder',
        },
      ];
    },
    isEditable(): boolean {
      return (!this.set.default && this.user.is_editor) || this.$route.query['edit'] == 'true';
    },
    user(): User {
      return this.miscStore.user;
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
  },
  watch: {
    set: {
      handler: function (val: SetType): void {
        this.enabledSave = true;
      },
      deep: true,
    },
    'set.data.slots': {
      handler: function (slots: SetSlot[]): void {
        this.set.data.container_types = this.set.data.container_types.filter((c) => {
          const foundContainerType = slots.find((slot) =>
            slot.configurations.find((alt) => alt.group.find((g) => g.container_type_id === c.id))
          );
          return foundContainerType;
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (!localStorage.getItem('cplHasSeenSetBuilderSupport')) {
      this.showSupportModal = true;
      localStorage.setItem('cplHasSeenSetBuilderSupport', 'true');
    }
    if (this.$route.params.set_id) {
      this.isLoading = true;

      API.getSet(this.$route.params.set_id)
        .then((response) => {
          this.isLoading = false;
          this.set = { ...this.set, ...response.data };
          this.displayConfiguration = this.set.data.slots.map(() => 0);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    } else {
      this.set.data = {
        ...this.set.data,
      };
    }
  },
  methods: {
    onSubmit(event: any) {
      if (this.validForm) {
        this.isLoading = true;
        this.enabledSave = false;
        const finalAssembly = {
          ...this.set,
          name: this.set.data.name,
        };
        // Remove unused keys
        Object.keys(finalAssembly.data)
          .filter(
            (key) =>
              finalAssembly.data[key as keyof SetTypeData] &&
              finalAssembly.data[key as keyof SetTypeData].constructor === Object &&
              !Object.values(finalAssembly.data[key as keyof SetTypeData]).some((v) => {
                if (Array.isArray(v)) {
                  return v.length;
                } else {
                  return !!v;
                }
              })
          )
          .forEach((key) => {
            (finalAssembly.data[key as keyof SetTypeData] as any) = undefined;
          });
        finalAssembly.data.slots.forEach((slot) =>
          slot.configurations.forEach((alt) =>
            alt.group.forEach((ht) => {
              ht.position.x = ht.position.x || 0;
              ht.position.y = ht.position.y || 0;
              ht.position.z = ht.position.z || 0;
            })
          )
        );

        const crud_operation = !finalAssembly.id ? API.createSet : API.updateSet;
        crud_operation(finalAssembly)
          .then((data) => {
            if (!finalAssembly.id) {
              this.set.id = data.data.id;
              this.$router.replace({
                name: 'modify_set',
                params: { type: this.set.base_type, set_id: data.data.id },
              });
            }
            return this.miscStore.getUserSets();
          })
          .then((_) => {
            this.isLoading = false;
            this.enabledSave = false;
          })
          .catch((error) => {
            if (error?.reponse?.data) {
              this.error_message = getSerializerError(error.response.data.data);
            } else {
              this.error_message = 'Unknown error when saving';
              console.log(error);
            }
            this.showSnackbar = true;
            this.isLoading = false;
            this.enabledSave = true;
          });
      }
    },
    copySet() {
      const newSet = JSON.parse(JSON.stringify(this.set));

      newSet.id = null;
      newSet.data.id = null;
      newSet.priority = 10000;
      newSet.data.name = this.copyName;
      newSet.name = this.copyName;

      this.isLoading = true;

      API.createSet(newSet)
        .then((data) => {
          this.isLoading = false;

          this.set = { ...this.set, ...data.data };

          this.$router.replace({
            name: 'modify_set',
            params: { type: this.set.base_type, set_id: data.data.id },
          });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    deleteSet() {
      this.isLoading = true;

      API.deleteSet(this.set.id)
        .then((_) => {
          return this.miscStore.getUserSets();
        })
        .then((_) => {
          this.isLoading = false;
          this.$router.go(-1);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-slide-group__prev {
  display: none !important;
}
</style>
