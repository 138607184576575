import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mt-3"},[_c(VCardTitle,[_c('p',{staticClass:"text-h6"},[_vm._v("Logo")])]),_c(VCardText,[(_vm.logo_url)?_c('img',{staticStyle:{"max-height":"120px","max-width":"400px"},attrs:{"id":"logoPreview","src":_vm.logo_url,"alt":"No logo added"}}):_vm._e(),_c(VFileInput,{attrs:{"accept":"image/*","label":"File input"},on:{"change":_vm.logoChange}})],1),_c(VCardActions,[(_vm.logo_file)?_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":_vm.uploadLogo}},[_vm._v("Save")]):_vm._e()],1)],1)],1)],1),_c('settings-component',{staticClass:"mt-2",attrs:{"isCompanySettings":""}}),_c(VSnackbar,{attrs:{"timeout":5000,"top":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" Information updated "),_c(VBtn,{attrs:{"text":"","color":"primary"},nativeOn:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("Ok")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }