import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,[_c(VCol,[_c(VLabel,{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticStyle:{"max-width":"140px"},attrs:{"items":_vm.options},model:{value:(_vm.isPercentage),callback:function ($$v) {_vm.isPercentage=$$v},expression:"isPercentage"}}),(_vm.isPercentage)?_c(VSlider,{staticClass:"pt-8",attrs:{"thumb-label":"always","thumb-size":24,"thumb-color":_vm.percentageValue ? 'primary' : 'grey',"track-color":"light-grey","track-fill-color":_vm.percentageValue ? 'primary' : 'grey',"max":35,"min":0,"step":"1","ticks":"always","tick-size":"1"},model:{value:(_vm.percentageValue),callback:function ($$v) {_vm.percentageValue=$$v},expression:"percentageValue"}}):_c('length-input-field',{staticStyle:{"margin-left":"2px"},attrs:{"min":0},model:{value:(_vm.absoluteValue),callback:function ($$v) {_vm.absoluteValue=$$v},expression:"absoluteValue"}})],1)],1),_c(VCol,{staticClass:"my-auto"},[_c(VImg,{staticClass:"mx-auto",attrs:{"width":"200","cover":false,"src":require("@/assets/svg/explainers/allowed_overhang.svg")}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }