import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.items),function(subItem,index){return _c(VCard,{key:index,staticClass:"py-1 px-1 mx-1 my-1",attrs:{"outlined":"","disabled":_vm.depth == 0}},[_c(VCardText,[_c(VRow,[(!subItem.from_container)?_c(VIcon,{style:({ color: subItem.color })},[_vm._v("fa-cube")]):_vm._e(),_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v(_vm._s(subItem.label))]),_c(VSpacer),_c('span',[_vm._v(_vm._s(subItem.qty)+" pcs")])],1),_c(VRow,[_c('span',[_vm._v(_vm._s(_vm._f("toLength")(subItem.L,false))+" x "+_vm._s(_vm._f("toLength")(subItem.W,false))+" x "+_vm._s(_vm._f("toLength")(subItem.H,true))+", "+_vm._s(_vm._f("toWeight")(subItem.WT,true)))]),_c(VSpacer),_c('span',{staticClass:"item-indicator"},[_vm._v(_vm._s(subItem.not_stackable ? ' ≠ ' : '')+" "+_vm._s(subItem.orientations <= 3 ? ' ⇈ ' : ''))])],1)],1),_c('sub-items',{attrs:{"item":subItem,"depth":_vm.depth + 1}})],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }