import {
  HoldData,
  HoldDataWithIndices,
  HoldDataWithPosition,
  HoldItem,
  Indices,
} from '@/models/LoadlistModel';
import { GroupedSet, PlacedContainer, Set, SetTypeData } from '@/models/SetsModel';
import ItemUtils from '@/misc/itemUtils';
import { AugmentedHold } from './hold';
import containerUtils from '@/misc/containerUtils';
import { v4 as uuidv4 } from 'uuid';

export class AugmentedSet {
  set: Set | GroupedSet;
  rendering: HoldDataWithPosition[];
  constructor(s: Set | GroupedSet, holds: HoldData[]) {
    this.set = s;
    this.rendering = this.set.containers
      .map((pc) => {
        const holdIndex = holds.findIndex((h) => h.uuid == pc.uuid);
        if (holdIndex < 0) {
          return null;
        }
        const hold = holds[holdIndex];
        return {
          position: pc.position,
          rotation: pc.rotation,
          position_name: pc.position_name,
          __indices: { start: holdIndex, end: holdIndex },
          ...hold,
        } as HoldDataWithPosition;
      })
      .filter((h) => h);
  }

  static fromSetType(setType: SetTypeData, displayConfigurations: number[] = []): AugmentedSet {
    const renderedSet: HoldDataWithPosition[] = [];
    setType.slots.forEach((s, index) => {
      s.configurations[displayConfigurations[index] || 0]?.group.forEach((ht) => {
        const hold = JSON.parse(
          JSON.stringify({
            ...setType.container_types.find((t) => t.id == ht?.container_type_id),
            ...(ht.props
              ? Object.fromEntries(Object.entries(ht.props).filter(([, v]) => v != null))
              : undefined),
          })
        );
        hold.position = ht?.position || { x: 0, y: 0, z: 0 };
        hold.rotation = ht?.rotation || [0, 0, 0, 'XYZ'];
        hold.position_name = ht?.position_name;
        hold.uuid = uuidv4();
        renderedSet.push(hold);
      });
    });
    const set: Set = {
      name: '',
      type_id: setType.id,
      containers: renderedSet.map((h) => {
        return {
          uuid: uuidv4(),
          position: h.position,
          rotation: h.rotation,
          position_name: h.position_name,
        } as PlacedContainer;
      }),
      uuid: uuidv4(),
    };
    const aug = new AugmentedSet(set, []);
    aug.rendering = renderedSet;
    return aug;
  }
  get holdIndices(): Indices {
    return this.rendering
      .map((a) => a.__indices)
      .reduce((a, b) => {
        return {
          start: a.start < b.start ? a.start : b.start,
          end: a.end > b.end ? a.end : b.end,
        };
      });
  }
  get __indices(): Indices {
    return (this.set as GroupedSet).__indices;
  }
  get groupedHolds(): HoldDataWithIndices[] {
    return (this.set as GroupedSet).groupedHolds;
  }

  get volume(): number {
    return this.rendering?.reduce((sum, hold) => sum + hold.volume, 0);
  }

  get volumeUtilization(): number {
    // Use max_height instead of height if it exists and height is zero
    return this.volume / this.maxVolume;
  }
  get maxVolume(): number {
    return this.rendering.reduce((sum, hold) => {
      return sum + new AugmentedHold(hold).maxVolume;
    }, 0);
  }
  get weight(): number {
    return this.rendering?.reduce((sum, hold) => sum + hold.WT, 0);
  }
  get weightUtilization(): number {
    return this.weight / this.payload;
  }
  get payload(): number {
    const holds_payload = this.rendering.reduce((sum, hold) => sum + hold.payload, 0);
    return Math.min(this.set.payload || 0, holds_payload) || holds_payload;
  }
  get tare(): number {
    return this.rendering?.reduce((sum, hold) => sum + (hold.tare || 0), 0);
  }
  get grossWeight(): number {
    return this.weight + this.tare;
  }
  get freightTonnes(): number {
    return Math.max(this.volume, this.weight * 0.001) || 0.0;
  }
  get cargoes(): HoldItem[] {
    return this.rendering?.flatMap((hold) => hold.items.filter((i) => i.qty > 0));
  }
  get items_count(): number {
    return this.rendering?.reduce((sum, hold) => sum + hold.items_count, 0);
  }
  get bundledItems(): HoldItem[] {
    return ItemUtils.bundledItems(this.cargoes);
  }
  get oogValues(): { value: number; title: string }[][] {
    return this.rendering.map((h) => containerUtils.oogValues(h));
  }
  equipmentSummary(): { name: string; count: number }[] {
    const eq: Array<{ name: string; count: number }> = [];
    this.rendering
      .map((h) => h.name)
      .forEach((name) => {
        const index = eq.findIndex((v) => v.name === name);
        if (index >= 0) {
          eq[index].count += 1;
        } else {
          eq.push({ name, count: 1 });
        }
      });
    return eq;
  }
  chargableWeight(shipping_factor: number | null): number | null {
    if (shipping_factor) {
      return this.rendering
        .flatMap((h) => h.items)
        .filter((i) => i.qty > 0)
        .map((i) => {
          return Math.max(shipping_factor * i.H * i.L * i.W, i.WT);
        })
        .reduce((a, b) => {
          return a + b;
        }, 0);
    }
    return null;
  }
}
