<template>
  <div>
    <v-toolbar dense class="no-print mt-2" elevation="1">
      <v-btn
        outlined
        @click="showLoadConfigurationsModal = true"
        id="equipmentLoadConfigurationButton"
        :icon="$vuetify.breakpoint.xsOnly"
        :text="$vuetify.breakpoint.smAndUp">
        <v-icon>mdi-database</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">Presets</template>
      </v-btn>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="!presetDiff"
              @click="reapplyPreset"
              outlined
              v-on="on"
              color="warning"
              dense
              class="mx-2 my-2"
              style="cursor: pointer"
              text>
              <v-icon>mdi-database-arrow-down-outline</v-icon>
            </v-btn>
          </template>
          <div>The loadplan and the preset are out of sync. Click to re-apply the preset</div>
        </v-tooltip>
      </div>

      <!-- <v-btn
        :ripple="false"
        @click="showSettingsDialog = true"
        :icon="$vuetify.breakpoint.xsOnly"
        :text="$vuetify.breakpoint.smAndUp"
        id="equipmentLoadSettingsButton"
      >
        <v-icon left>mdi-tune</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">Settings</template>
      </v-btn> -->

      <!-- <v-chip
        class=" mr-2"
        @click="showLoadplanVersions = !showLoadplanVersions"
      >
        Version {{ loadplan_version + 1 }}
      </v-chip> -->
      <v-spacer></v-spacer>
      <v-btn
        outlined
        id="equipmentLoadSettingsButton"
        class="ma-2"
        @click="showSettingsDialog = true"
        ><v-icon>mdi-tune</v-icon> Settings</v-btn
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mr-2"
            v-on="on"
            color="primary"
            v-if="unloadedWithoutReason.length && has_result && !isLoading"
            @click="addItemsCalculate"
            id="equipmentAddItemsBtn"
            :ripple="false">
            Load {{ unloadedWithoutReason.length }} new items<v-icon right>fa-plus-circle</v-icon>
          </v-btn>
        </template>
        <div>
          Load new items into current loadplan, without creating or rearranging
          {{ typeName }}s.
        </div>
      </v-tooltip>
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        @click="calculate"
        :disabled="!selectedHolds.length"
        id="equipmentCalculateBtn"
        :ripple="false"
        icon>
        <v-icon color="success">fa-arrow-circle-right</v-icon>
      </v-btn>
      <v-btn
        v-else
        class="success"
        @click="calculate"
        :disabled="!selectedHolds.length && !selectedSets.length"
        id="equipmentCalculateBtn"
        :ripple="false">
        Create new Load Plan
        <v-icon right>fa-arrow-circle-right</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card v-if="showLoadplanVersions" class="my-1" id="equipmentLoadplanVersions">
      <v-card-text>
        <loadplan-version-component> </loadplan-version-component>
      </v-card-text>
    </v-card>
    <v-row justify="center" class="mb-10 my-1">
      <v-col cols="12" sm="5">
        <v-card id="equipmentLibrary">
          <v-card-title>
            <span class="text-h6 font-weight-light">Equipment library</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              :disabled="!isAuthenticated"
              @click="
                $router.push({
                  name: active_tab.is_set ? 'modify_set' : 'modify_hold',
                  params: { type: active_tab.mot },
                })
              "
              :ripple="false">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-tabs v-model="active_tab_index">
              <v-tab v-for="tab in tabs.filter((i) => i.primary)" :key="tab.title"
                ><span>{{ tab.typename }}s</span></v-tab
              >
              <v-divider vertical></v-divider>

              <v-tooltip top v-for="tab in tabs.filter((i) => !i.primary)" :key="tab.title">
                <template v-slot:activator="{ on }">
                  <v-tab v-on="on"
                    ><span>{{ tab.typename }}</span></v-tab
                  >
                </template>
                <span
                  >Equipment to use if your cargoes should be pre loaded onto a pallet or crate
                  before being loaded into your primary equipment</span
                >
              </v-tooltip>

              <v-tabs-items v-model="active_tab_index" class="px-1 py-1">
                <v-tab-item v-for="tab in tabs" :key="tab.key">
                  <component :is="tab.component" :holdType="tab.mot" :setType="tab.mot">
                    <template v-slot:items="{ paginatedItems: items }">
                      <v-expansion-panels focusable multiple class="unselectable">
                        <v-expansion-panel v-for="item in items" :key="item.id">
                          <v-expansion-panel-header class="py-1">
                            <span>
                              <v-btn
                                @click.native.stop="tab.addMethod(item.data)"
                                large
                                icon
                                :ripple="false"
                                :disabled="
                                  tab.disabledItems.some((i) => i === item.id) || tab.limit_exceeded
                                ">
                                <v-icon color="green">mdi-plus-circle</v-icon>
                              </v-btn>
                              <v-icon v-if="!item.default">mdi-account</v-icon>
                              {{ item.name }}
                            </span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <scene-component
                              v-if="tab.is_set"
                              class="my-2 d-flex justify-center"
                              :setType="item.data"
                              :canvas-width="300"
                              :canvas-height="150"></scene-component>
                            <scene-component
                              v-else
                              class="my-2 d-flex justify-center"
                              :hold-object="item.data"
                              :canvas-width="300"
                              :canvas-height="150"></scene-component>
                            <div v-if="item.data.description" class="pt-2">
                              <v-divider />
                              <p class="text-md mt-2">
                                {{ item.data.description }}
                              </p>
                            </div>
                            <v-card-actions>
                              <v-tooltip
                                bottom
                                :disabled="!tab.disabledItems.some((i) => i === item.id)">
                                <template v-slot:activator="{ on }">
                                  <div v-on="on">
                                    <v-btn
                                      @click="
                                        $router.push(
                                          tab.is_set
                                            ? {
                                                name: 'modify_set',
                                                params: {
                                                  set_id: item.data.id,
                                                  type: item.base_type,
                                                },
                                              }
                                            : {
                                                name: 'modify_hold',
                                                params: {
                                                  hold_id: item.id,
                                                  type: item.base_type,
                                                },
                                              }
                                        )
                                      "
                                      :disabled="tab.disabledItems.some((i) => i === item.id)"
                                      block>
                                      <div v-if="!item.default && user.is_editor">
                                        <v-icon>mdi-pencil</v-icon>Edit
                                      </div>
                                      <div v-else>
                                        <v-icon>mdi-magnify-plus-outline</v-icon>Details
                                      </div>
                                    </v-btn>
                                  </div>
                                </template>
                                <span
                                  >You need to remove this from the panel to right in order to
                                  continue</span
                                >
                              </v-tooltip>
                              <v-spacer />
                              <v-btn
                                @click.native.stop="tab.addMethod(item.data)"
                                :disabled="
                                  tab.disabledItems.some((i) => i === item.id) || tab.limit_exceeded
                                "
                                ><v-icon color="green">mdi-plus-circle</v-icon> Select
                                {{ tab.is_set ? setName : typeName }}</v-btn
                              >
                            </v-card-actions>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </component>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col text-center>
        <v-row justify="center">
          <v-icon style="margin-top: 140px" x-large>fa-exchange-alt</v-icon>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card id="equipmentSelectedHolds">
          <v-card-title>
            <span class="text-h6 font-weight-light"> Selected equipment</span>
          </v-card-title>
          <v-card-title>
            <v-chip class="success" small>Primary</v-chip>
            <v-spacer></v-spacer>
            <!-- <v-btn
              outlined
              color="success"
              id="equipmentLoadSettingsButton"
              @click="showSettingsDialog = true"
              ><v-icon>mdi-tune</v-icon> Settings</v-btn
            > -->
          </v-card-title>

          <v-card-text v-if="selectedHolds.length || selectedSets.length" class="pb-0">
            <v-expansion-panels focusable class="unselectable">
              <v-expansion-panel
                v-for="(hold, index) in selectedHolds"
                :key="hold.id"
                @click="$vuetify.goTo($event.target)">
                <v-expansion-panel-header multiple class="py-1"
                  ><span
                    ><v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="removeContainer(index)" large icon v-on="on" :ripple="false">
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove this equipment</span> </v-tooltip
                    >{{ hold.name }}</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <inline-hold-edit-component
                    v-model="selectedHolds[index]"
                    :basetype="loadlist.list_type"
                    class="mt-3">
                    <template v-slot:view="{ renderKey }">
                      <scene-component
                        :hold-object="hold"
                        :canvas-width="400"
                        :canvas-height="200"
                        :key="renderKey"></scene-component>
                    </template>
                  </inline-hold-edit-component>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-for="(set, setIndex) in selectedSets"
                :key="set.id"
                @click="$vuetify.goTo($event.target)">
                <v-expansion-panel-header class="py-1"
                  ><span
                    ><v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="removeSet(setIndex)" large icon v-on="on" :ripple="false">
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove this equipment</span> </v-tooltip
                    >{{ set.name }}</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-card class="mt-3">
                    <v-card-text>
                      <modify-set-component
                        v-model="selectedSets[setIndex]"
                        :basetype="selectedSets[setIndex].base_type">
                      </modify-set-component>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-text>
            <toggle-alert
              v-for="a in primaryAlerts"
              :key="a.title"
              class="my-2"
              :type="a.level"
              outlined
              :body="a.body">
              {{ a.title }}
            </toggle-alert>
          </v-card-text>

          <template v-if="tabs.find((t) => t.key == 'pallets')">
            <v-card-title>
              <v-tooltip top max-width="500">
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small>Secondary</v-chip>
                </template>
                <span
                  >Secondary equipment will be loaded with cargoes having the "Preload" field
                  enabled. The secondary equipment will then be loaded into the primary equipment.
                  Think of if like boxes on pallets in containers</span
                >
              </v-tooltip>
            </v-card-title>

            <v-card-text v-if="selectedPallets.length" class="pb-0">
              <v-expansion-panels focusable multiple class="unselectable">
                <v-expansion-panel
                  v-for="(pallet, palletIndex) in selectedPallets"
                  :key="pallet.id"
                  @click="$vuetify.goTo($event.target)">
                  <v-expansion-panel-header class="py-1"
                    ><span
                      ><v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="removePallet(palletIndex)"
                            large
                            icon
                            v-on="on"
                            :ripple="false">
                            <v-icon color="red">mdi-minus-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove this equipment</span> </v-tooltip
                      >{{ pallet.name }}</span
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card class="mt-3">
                      <v-card-text>
                        <inline-hold-edit-component
                          v-model="selectedPallets[palletIndex]"
                          basetype="PALL"
                          showSubHoldProps>
                          <template v-slot:view="{ renderKey }">
                            <scene-component
                              :hold-object="pallet"
                              :canvas-width="400"
                              :canvas-height="200"
                              :key="renderKey"></scene-component>
                          </template>
                        </inline-hold-edit-component>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <v-card-text
              ><toggle-alert
                v-for="a in secondaryAlerts"
                :key="a.title"
                class="my-2"
                :type="a.level"
                outlined
                :body="a.body">
                {{ a.title }}
              </toggle-alert>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <load-settings-modal
      :visible.sync="showSettingsDialog"
      :items="loadlist.data"
      :base_type="loadlist.list_type"
      :holds="allHolds"
      :settings="loadplan.settings"
      @update="
        setLoadplanProperty(
          {
            key: 'settings',
            value: $event,
          },
          true
        )
      "></load-settings-modal>

    <load-configurations-modal
      :visible="showLoadConfigurationsModal"
      @close="showLoadConfigurationsModal = false"></load-configurations-modal>

    <v-dialog v-model="showCostDialog" width="800">
      <v-card>
        <v-card-title class="text-h5">Costs - an example</v-card-title>
        <v-card-text class="text-body-1">
          One of your shipments requires either 2 x 20ft containers or 1 x 40ft container.<br /><br />
          A 40ft container is roughly twice as large as a 20ft container, however it is usually
          <strong>not twice as expensive</strong>. A not too uncommon figure is that a 40ft
          container is 30% more expensive than the 20ft equivalent. We can then set a cost for the
          20ft container to, let's say "10" and "13" for a 40ft container - to take this into
          consideration. <br /><br />
          Having no costs associated can lead "wrong" results were the tool picks multiple 20ft
          containers instead of a fewer amount of 40ft containers
        </v-card-text>
        <v-card-actions> </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click.stop="showCostDialog = false">Ok, close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import loadSettingsModal, {
  DEFAULT_SETTINGS,
} from '@/components/Modals/LoadSettings/LoadSettings.vue';
import loadConfigurationsModal from '@/components/Modals/LoadConfigurations.vue';
import containerLibraryComponent from '@/components/Custom/ContainerLibrary.vue';
import setsLibraryComponent from '@/components/Custom/SetsLibrary.vue';
import loadplanVersionComponent from '@/components/Custom/LoadplanVersion.vue';
import inlineHoldEditComponent from '../../Custom/InlineHoldEdit.vue';
import modifySetComponent from '@/components/Custom/InlineSetEdit.vue';
import toggleAlert from '@/components/Custom/ToggleAlert.vue';
import {
  HoldData,
  ListType,
  UnloadedItem,
  Loadlist,
  Loadplan,
  LoadConfiguration,
} from '@/models/LoadlistModel';
import { User } from '@/models/UserCompanyModel';
import { CalcData } from '@/models/CalculationModel';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { SetType, SetTypeData, Set } from '@/models/SetsModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import API from '@/API';
import objectUtils from '@/misc/objectUtils';

export default Vue.extend({
  name: 'options',
  data() {
    return {
      selectedHolds: [] as HoldData[],
      selectedPallets: [] as HoldData[],
      selectedSets: [] as SetTypeData[],
      maxSelectedContainers: 15,
      showSettingsDialog: false,
      showPalletModal: false,
      showLoadConfigurationsModal: false,
      showCostDialog: false,
      showLoadplanVersions: false,
      active_tab_index: 0,
      isLoading: false,
      preset: undefined as LoadConfiguration,
    };
  },
  components: {
    sceneComponent,
    loadSettingsModal,
    containerLibraryComponent,
    setsLibraryComponent,
    loadplanVersionComponent,
    loadConfigurationsModal,
    inlineHoldEditComponent,
    modifySetComponent,
    toggleAlert,
  },
  watch: {
    'loadplan.selected_holds': {
      handler: function (val: HoldData[]): void {
        this.selectedHolds = JSON.parse(JSON.stringify(val || []));
      },
      immediate: true,
    },
    'loadplan.pallet_types': {
      handler: function (val: HoldData[]): void {
        this.selectedPallets = JSON.parse(JSON.stringify(val || []));
      },
      immediate: true,
    },
    'loadplan.set_types': {
      handler: function (val) {
        this.selectedSets = JSON.parse(JSON.stringify(val || []));
      },
      immediate: true,
    },
    'loadplan.preset': {
      handler: function (val) {
        if (val) this.getConfiguration(val).then((p) => (this.preset = p));
      },
    },
  },
  mounted() {
    if (this.$route.params.settings == 'settings') {
      this.showSettingsDialog = true;
    }
    if (this.loadplan.preset)
      this.getConfiguration(this.loadplan.preset).then((p) => (this.preset = p));
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    typeName(): ListType {
      return this.$typeNames(this.loadlist.list_type);
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    showPalletCard(): boolean {
      return ['SEA', 'ROAD', 'AIR'].includes(this.loadlist.list_type);
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    has_result(): boolean {
      return this.loadplan?.holds?.length > 0;
    },
    loadplan_version(): number {
      return this.loadlistStore.loadplan_version;
    },
    unloaded_items(): UnloadedItem[] {
      return this.loadlistStore.unloaded_items;
    },
    user(): User {
      return this.miscStore.user;
    },
    isAuthenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    displaySets(): boolean {
      return this.miscStore.company.sets_enabled;
    },

    secondaryAlerts(): {
      title: string;
      level: string; //"info" | "warning" | "error";
      body?: string;
    }[] {
      return [
        {
          title: 'Secondary equipment is too tall',
          level: 'error',
          body: `Your secondary equipment might be too tall for your primary
                equipment. Please select some primary equipment that has a
                larger max height or reduce the max height of the secondary
                equipment`,
          visible: this.hasToTallSecondaryEquipment,
        },
        {
          title: 'No secondary equipment',
          level: 'error',
          body: `Some cargoes have the "Preload" option enabled but no secondary equipment is
                selected`,
          visible: !this.selectedPallets.length && this.hasPalletizedCargoes,
        },
        {
          title: 'No cargoes to preload',
          level: 'info',
          body: `Select which items should be preloaded using the
                "Preload" column in 1. Data`,
          visible: this.selectedPallets.length && !this.hasPalletizedCargoes,
        },
        {
          title: 'Single layer loading',
          level: 'info',
          body: `Some of the secondary equipment does not have a fixed height or
                a max height set which means that only one layer of cargoes will
                be loaded on these`,
          visible: this.anyOpenPallets,
        },
        {
          title: 'You can pre-load cargoes into secondary equipment (like pallets)',
          level: 'info',
          visible: !this.selectedPallets.length && !this.hasPalletizedCargoes,
        },
      ].filter((alert) => alert.visible);
    },
    primaryAlerts(): {
      title: string;
      level: string; //"info" | "warning" | "error";
      body?: string;
    }[] {
      return [
        {
          title: 'Single layer loading',
          level: 'info',
          body: `Some of the primary equipment does not have a fixed height or a
              max height set which means that only one layer of cargoes will be
              loaded on these`,
          visible: this.anyOpenContainers,
        },
        {
          title: 'No equipment selected',
          level: 'error',
          body: `You need to select some form of primary equipment in order to continue`,
          visible: !this.selectedHolds.length && !this.selectedSets.length,
        },
      ].filter((alert) => alert.visible);
    },
    hasPalletizedCargoes(): boolean {
      return this.loadlist.data?.some((i) => i.palletize);
    },
    containerHeights(): number[] {
      return [...this.selectedHolds, ...this.selectedSets?.flatMap((i) => i.container_types)]?.map(
        (i) => Math.max(i?.H > 0 ? i.H : 0, i?.max_height > 0 ? i.max_height : 0)
      );
    },
    palletHeights(): number[] {
      return this.selectedPallets?.map((i) =>
        Math.max(i?.H > 0 ? i.H : 0, i?.max_height > 0 ? i.max_height : 0)
      );
    },
    hasToTallSecondaryEquipment(): boolean {
      const palletHeightsWithFloor = this.selectedPallets?.map(
        (i) =>
          Math.max(i?.H > 0 ? i.H : 0, i?.max_height > 0 ? i.max_height : 0) +
          (i.floor_height ? i.floor_height : 0)
      );
      if (this.containerHeights.length && palletHeightsWithFloor.length) {
        const tallestSecondary = Math.max(...palletHeightsWithFloor);
        const tallestPrimary = Math.max(...this.containerHeights);
        if (tallestPrimary && tallestSecondary) return tallestSecondary > tallestPrimary;
      }

      return false;
    },
    anyOpenContainers(): boolean {
      return this.containerHeights.some((i) => !i);
    },
    anyOpenPallets(): boolean {
      return this.palletHeights.some((i) => !i);
    },
    active_tab(): {
      key: string;
      title: string;
      typename: string;
      visible_for: string[];
      mot: ListType;
      hide: boolean;
      is_set?: boolean;
    } {
      return this.tabs[this.active_tab_index];
    },
    tabs(): {
      key: string;
      title: string;
      typename: string;
      visible_for: string[];
      mot: ListType;
      hide: boolean;
      is_set?: boolean;
      disabledItems: number[];
      addMethod: Function;
      limit_exceeded: boolean;
      component: string;
      primary?: boolean;
    }[] {
      return [
        {
          key: 'equipment',
          title: 'Equipment library',
          typename: this.typeName,
          visible_for: ['SEA', 'ROAD', 'AIR', 'PALL'],
          mot: this.loadlist.list_type,
          hide: false,
          disabledItems: this.selectedHolds.map((i) => i.id),
          addMethod: this.addContainer,
          limit_exceeded: this.selectedHolds.length >= this.maxSelectedContainers,
          component: 'containerLibraryComponent',
          primary: true,
        },

        {
          key: 'sets',
          title: `${this.setName} library`,
          typename: this.setName,
          visible_for: ['ROAD', 'AIR', 'SEA', 'PALL'],
          mot: this.loadlist.list_type,
          hide: !this.displaySets,
          is_set: true,
          disabledItems: this.selectedSets.map((i) => i.id),
          addMethod: this.addSet,
          limit_exceeded: this.selectedSets.length >= this.maxSelectedContainers,
          component: 'setsLibraryComponent',
          primary: true,
        },
        {
          key: 'pallets',
          title: 'Pallet library',
          typename: 'secondary',
          visible_for: ['SEA', 'ROAD', 'AIR', 'PALL'],
          mot: 'PALL' as ListType,
          hide: false,
          disabledItems: this.selectedPallets.map((i) => i.id),
          addMethod: this.addPallet,
          limit_exceeded: this.selectedPallets.length >= this.maxSelectedContainers,
          component: 'containerLibraryComponent',
        },
      ].filter((t) => !t.hide && t.visible_for.includes(this.loadlist.list_type));
    },
    unloadedWithoutReason(): UnloadedItem[] {
      return this.unloaded_items.filter((i) => !i.reason);
    },
    allHolds(): HoldData[] {
      return (this.selectedHolds || [])
        .concat(this.selectedPallets || [])
        .concat((this.selectedSets || []).flatMap((st) => st.container_types));
    },
    presetDiff(): boolean {
      if (this.loadplan?.preset === undefined || this.preset === undefined) {
        return false;
      }
      if (!objectUtils.equalObjects(this.preset.data.holds, this.selectedHolds)) {
        // console.log('there is a difference in holds');
        return true;
      }
      if (!objectUtils.equalObjects(this.preset.data.pallet_types, this.selectedPallets)) {
        // console.log('there is a difference in pallets');
        return true;
      }
      if (!objectUtils.equalObjects(this.preset.data.set_types, this.selectedSets)) {
        // console.log('there is a difference in sets');
        return true;
      }
      if (
        !objectUtils.equalObjects(
          this.preset.data.settings || DEFAULT_SETTINGS,
          this.loadplan.settings,
          [],
          true
        )
      ) {
        // console.log('there is a difference in settings');
        return true;
      }
      return false;
    },
  },
  methods: {
    getConfiguration(id: number): Promise<LoadConfiguration> {
      return API.getLoadConfiguration(id)
        .then((response) => response.data)
        .catch(() => undefined);
    },
    reapplyPreset(): void {
      this.selectedPallets = JSON.parse(JSON.stringify(this.preset.data.pallet_types || []));
      this.selectedHolds = JSON.parse(JSON.stringify(this.preset.data.holds || []));
      this.selectedSets = JSON.parse(JSON.stringify(this.preset.data.set_types || []));

      this.loadlistStore.setLoadplanProperty({
        key: 'settings',
        value: this.preset.data.settings || DEFAULT_SETTINGS,
      });
    },
    addContainer(data: HoldData): void {
      this.selectedHolds.push(JSON.parse(JSON.stringify(data)));
      this.setLoadplanProperty({
        key: 'selected_holds',
        value: JSON.parse(JSON.stringify(this.selectedHolds)),
      });
    },
    removeContainer(index: number): void {
      this.selectedHolds.splice(index, 1);
      this.setLoadplanProperty({
        key: 'selected_holds',
        value: JSON.parse(JSON.stringify(this.selectedHolds)),
      });
    },
    addPallet(pallet: HoldData): void {
      if (this.selectedPallets.findIndex((s) => s.id === pallet.id) === -1) {
        this.selectedPallets.push(JSON.parse(JSON.stringify(pallet)));
        this.setLoadplanProperty({
          key: 'pallet_types',
          value: JSON.parse(JSON.stringify(this.selectedPallets)),
        });
      }
    },
    removePallet(index: number): void {
      this.selectedPallets.splice(index, 1);
      this.setLoadplanProperty({
        key: 'pallet_types',
        value: JSON.parse(JSON.stringify(this.selectedPallets)),
      });
    },
    addSet(set: SetType): void {
      if (this.selectedSets.findIndex((s) => s.id === set.id) === -1) {
        this.selectedSets.push(JSON.parse(JSON.stringify(set)));
        this.setLoadplanProperty({
          key: 'set_types',
          value: JSON.parse(JSON.stringify(this.selectedSets)),
        });
      }
    },
    removeSet(index: number): void {
      this.selectedSets.splice(index, 1);
      this.setLoadplanProperty({
        key: 'set_types',
        value: JSON.parse(JSON.stringify(this.selectedSets)),
      });
    },
    calculate(): void {
      this.setLoadplanProperty({
        key: 'selected_holds',
        value: JSON.parse(JSON.stringify(this.selectedHolds)),
      });
      this.setLoadplanProperty({
        key: 'pallet_types',
        value: JSON.parse(JSON.stringify(this.selectedPallets)),
      });
      this.setLoadplanProperty({
        key: 'set_types',
        value: JSON.parse(JSON.stringify(this.selectedSets)),
      });

      this.calculateLoadplan({
        settings: this.loadplan.settings,
        items: this.loadlist.data,
        container_types: this.selectedHolds,
        pallet_types: this.loadplan.pallet_types,
        set_types: this.selectedSets,
        pol: this.loadlist.pol,
        pod: this.loadlist.pod,
      });
    },
    addItemsCalculate() {
      // Shallow copy
      const holds = Array.from(this.loadplan.holds);
      const sets = this.loadplan.sets
        .filter((i) => i.containers.length > 1)
        .map((set) => {
          for (const placedContainer of set.containers) {
            const holdIndex = holds.findIndex((c) => c.uuid === placedContainer.uuid);

            if (holdIndex > -1) {
              placedContainer.container = holds[holdIndex];
              holds.splice(holdIndex, 1);
            }
          }
          return set;
        });

      this.calculateLoadplan({
        settings: this.loadplan.settings,
        items: this.unloadedWithoutReason,
        containers: holds,
        sets: sets,
      });
    },
    calculateLoadplan(calcData: CalcData): void {
      this.isLoading = true;
      this.loadlistStore
        .calculateLoadplan(calcData)
        .then(
          (solution: { containers: HoldData[]; unloaded_items: UnloadedItem[]; sets: any[] }) => {
            this.loadlistStore.updateLoadplanHolds({
              replace: this.loadplan.holds.length,
              holds: solution.containers,
            });
            this.loadlistStore.updateLoadplanSets({
              replace: this.loadplan.sets?.length || 0,
              sets: solution.sets,
            });
            this.loadlistStore.saveLoadlistResult();
            this.$router.push({
              name: 'workspace',
              params: { version: this.loadplan_version.toString() },
            });
          }
        )
        .catch((_) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    setLoadplanProperty(propertyInfo: { key: string; value: any }, save = false) {
      this.loadlistStore.setLoadplanProperty(propertyInfo);
      this.loadlistStore.saveLoadlistResult(save);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
</style>
