import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"loading":_vm.isLoading}},[_c(VCardTitle,{staticClass:"text-h5",attrs:{"primary-title":""}},[_vm._v("Quotation")]),_c(VCardText,[_c(VSelect,{attrs:{"items":_vm.currencies,"item-text":"name","return-object":"","required":"","label":"Select currency"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('div',{staticClass:"mb-4"},[_vm._v("Set default quotation per type")]),_vm._l((_vm.holdTypes),function(holdType){return _c('div',{key:holdType.id},[_c(VTextField,{attrs:{"type":"number","label":holdType.name,"suffix":_vm.currency.code},model:{value:(_vm.quotations[holdType.id]),callback:function ($$v) {_vm.$set(_vm.quotations, holdType.id, $$v)},expression:"quotations[holdType.id]"}})],1)})],2),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close', false)}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{staticClass:"primary",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateQuotation.apply(null, arguments)}}},[_vm._v("Apply")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }