<template>
  <div>
    <v-toolbar dense class="my-2 no-print">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Go back to load list</span>
      </v-tooltip>

      <v-btn icon @click="print">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn icon :loading="isLoading" :disabled="isLoading" @click="getXlsx">
        <v-icon>fa-file-excel</v-icon>
      </v-btn>
    </v-toolbar>

    <v-alert class="text-subtitle-1 mt-2" color="info" outlined>
      <strong>Summary:</strong>
      <p>
        Total weight: {{ summary.weight | toWeight }}
        <br />
        Total volume: {{ summary.volume | toVolume }}
        <br />
        Total items: {{ summary.no }} items
      </p>
    </v-alert>

    <summary-table></summary-table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import SummaryTable from './SummaryTable.vue';
import FileSaver from 'file-saver';
import ExcelService from '@/services/excelService';
import TableUtils from '@/misc/tableUtils';
import { Loadlist } from '@/models/LoadlistModel';
import { Workbook } from 'exceljs';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';

interface SummaryResult {
  no: number;
  volume: number;
  weight: number;
}

export default Vue.extend({
  name: 'loadlist_summary_view',
  data() {
    return {
      isLoading: false,
      printing: false,
    };
  },
  components: {
    SummaryTable,
  },
  computed: {
    ...mapStores(useLoadlistStore),
    summary(): SummaryResult {
      const result: SummaryResult = {
        no: 0,
        volume: 0,
        weight: 0,
      };

      this.loadlist.data.forEach((item) => {
        if (item.qty > 0) {
          result.no += item.qty;
          result.weight += item.wt * this.$toSI(this.loadlist.weight_dim) * item.qty;
          result.volume +=
            item.l * item.w * item.h * Math.pow(this.$toSI(this.loadlist.length_dim), 3) * item.qty;
        }
      });

      return result;
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
  },
  methods: {
    print(): void {
      this.printing = true;
      this.$hideChatWidget();
      this.$nextTick(() => {
        setTimeout(() => {
          window.print();
          this.printing = false;
        }, 200);
      });
    },
    getXlsx(): void {
      this.isLoading = true;
      const loadExcelJS = () => import('exceljs');
      loadExcelJS()
        .then((exceljs) => {
          const excelService = new ExcelService();
          const tables = document.getElementsByTagName('table');
          const tablesJson = [...tables].map((table) => {
            return TableUtils.loadTableToJson(table);
          });
          const workbook = excelService.loadlistToXlsx(new exceljs.Workbook(), {
            loadlist: this.loadlist,
            tablesJson,
          });
          this.saveLoadlistFile(['Loadlist - ', this.loadlist.name, '.xlsx'].join(''), workbook);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    async saveLoadlistFile(fileName: string, workbook: Workbook): Promise<void> {
      const xls64 = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(
        new Blob([xls64], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        fileName
      );
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
