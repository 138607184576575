<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-card-title class="text-h5" primary-title>{{ title }}</v-card-title>
      <v-card-text class="whitespace-pre" v-if="description">{{ description }}</v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click.stop="$emit('close')">{{ denyButton }}</v-btn>
        <v-spacer />
        <v-btn :color="confirmColor" @click.stop="$emit('confirm')">{{ confirmButton }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
    confirmButton: {
      default: 'Yes',
      type: String,
    },
    denyButton: {
      default: 'No',
      type: String,
    },
    confirmColor: {
      default: 'primary',
      type: String,
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
});
</script>
<style scoped>
.whitespace-pre {
  white-space: pre;
}
</style>
