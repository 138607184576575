import { Header, InputDataTable, ValueRow } from '@/models/InputDataModel';
import { HoldData, HoldItem } from '@/models/LoadlistModel';
import { Parser } from 'expr-eval';

function evaluateExpressionWithDefaults(
  expressionStr: string,
  variables = {} as { [key: string]: any },
  defaultValue = 0
) {
  const parser = new Parser();
  const expr = parser.parse(expressionStr);

  // Intercept the evaluation to supply default values
  return expr.evaluate(
    new Proxy(variables, {
      get: (target, name) => (name in target ? target[name as string] : defaultValue),
    })
  );
}

export default {
  defaultLoadlanColumns: ['sku', 'label', 'qty', 'l', 'w', 'h', 'wt', 'not_stackable'],
  loadTableToJson(tableElement: HTMLTableElement, unloaded = false): InputDataTable {
    const headers = [] as Header[][];
    const dataRows = [] as ValueRow[];
    const rows = Array.from(tableElement.rows);
    let totalRow = {} as ValueRow;

    rows
      .filter((row) => row.cells.length > 0 && row.cells[0].tagName == 'TH')
      .forEach((row) => {
        const header = Array.from(row.cells).map((cell) => {
          const colspan = Number(cell.getAttribute('colspan'));
          return {
            value: cell.innerText.trim(),
            colspan: isNaN(colspan) ? 1 : colspan || 1,
            onlySummary: true,
          };
        });
        headers.push(header);
      });

    if (headers.length > 0) {
      rows
        .filter((row) => row.cells.length > 0 && row.cells[0].tagName == 'TD')
        .forEach((row) => {
          const values = Array.from(row.cells).map((cell) => {
            const value = cell.innerText.trim();
            if (value === '' || value === '<!---->') {
              return null;
            } else {
              return Number.isNaN(Number(value)) ? value : Number(value);
            }
          });
          const rowData = { isFirstChild: false, values };
          if (row.parentElement.firstChild === row) {
            rowData.isFirstChild = true;
          }
          if (rowData.values[1] == null) {
            totalRow = rowData;
          } else {
            rowData.values.forEach((v, rowIndex) => {
              const h = headers[headers.length - 1][rowIndex];
              h.onlySummary = h.onlySummary && v === null;
            });

            dataRows.push(rowData);
          }
        });
    }
    return {
      headerRows: headers,
      valuesRows: dataRows,
      totalRow: totalRow,
      unloaded,
    };
  },
  calculateFormula(formula: any, item: HoldItem, hold: HoldData): number {
    try {
      let translations: { [key: string]: any } = {
        ItemLength: item.L,
        ItemWidth: item.W,
        ItemHeight: item.H,
        ItemWeight: item.WT,
        ItemArea: (item.bb.max.x - item.bb.min.x) * (item.bb.max.y - item.bb.min.y),
        ItemCenterX: item.bb.min.x + (item.bb.max.x - item.bb.min.x) / 2.0,
        ItemCenterY: item.bb.min.y + (item.bb.max.y - item.bb.min.y) / 2.0,
        ItemMinZ: item.bb.min.z,
        ItemQty: item.qty,
        ContainerL: hold.L,
        ContainerW: hold.W,
      };
      for (let key in item?.metadata || {}) {
        translations[`Metadata${key.replace(/[^a-zA-Z]/g, '')}`] = Number(item.metadata[key]);
      }
      return evaluateExpressionWithDefaults(formula, translations);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
};
