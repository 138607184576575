<template>
  <div>
    <v-data-table
      v-bind:items="loadlist.data"
      hide-default-footer
      :items-per-page="loadlist.data.length">
      <template v-slot:header>
        <tr>
          <th
            class="text-left"
            style="white-space: nowrap"
            v-for="column in filteredColumns"
            :key="column.key">
            {{ column.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-left" v-for="column in filteredColumns" :key="column.key">
            <v-icon v-if="column.key === 'color'" :color="item.color"
              >mdi-checkbox-blank-circle</v-icon
            >
            <span v-else-if="column.key === 'allowed_containers'">
              {{
                item.allowed_containers
                  ? item.allowed_containers
                      .map(
                        (containerId) =>
                          (
                            holdsLibrary.find((hl) => hl.id === containerId) || {
                              name: 'Unknown',
                            }
                          ).name
                      )
                      .join(',')
                  : ''
              }}
            </span>
            <span v-else-if="column.type === 'bool'">
              {{ item[column.key] ? 'Yes' : 'No' }}
            </span>
            <span v-else>
              {{ item[column.key] }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Loadlist, Loadplan, HoldInputItem, Hold } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import ItemProperties, { CustomItemProperty, ItemProperty } from '@/misc/itemProperties';
import { CompanySettings, UserPreferences } from '@/models/UserCompanyModel';

export default Vue.extend({
  name: 'loadlist_summary',
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    length_dim(): string {
      return this.loadlist.length_dim.toLowerCase();
    },
    weight_dim(): string {
      return this.loadlist.weight_dim.toLowerCase();
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
    companySettings(): CompanySettings {
      return this.miscStore.company_settings;
    },
    lengthDimHeaderText(): string {
      return '[' + (this.length_dim ? this.length_dim.toLowerCase() : '-') + ']';
    },
    weightDimHeaderText(): string {
      return '[' + (this.weight_dim ? this.weight_dim.toLowerCase() : '-') + ']';
    },
    extraColumns(): ItemProperty[] {
      if (this.companySettings?.extra_columns) {
        return this.companySettings?.extra_columns.map((i: CustomItemProperty) => {
          return {
            key: i.name,
            input: 'text',
            text: i.name,
            desc: i.desc,
            width: 150,
          };
        });
      }
      return [];
    },
    hiddenColumns(): string[] {
      return Array.isArray(this.preferences?.hidden_input_columns)
        ? this.preferences.hidden_input_columns
        : [];
    },
    getColumns(): ItemProperty[] {
      return [
        ...ItemProperties.props()
          .filter((i) => !this.hiddenColumns.includes(i.key))
          .filter((i) => !i.additional && !i.readOnly)
          .map((i) => {
            switch (i.dimension) {
              case 'length':
                i.text = `${i.text} ${this.lengthDimHeaderText}`;
                break;
              case 'weight':
                i.text = `${i.text} ${this.weightDimHeaderText}`;
                break;
            }
            return i;
          }),
        ...this.extraColumns,
      ];
    },
    filteredColumns(): ItemProperty[] {
      return this.getColumns.filter((i) => {
        return this.loadlist.data.some((j) => !!j[i.key as keyof HoldInputItem]);
      });
    },
    holdsLibrary(): Hold[] {
      return this.miscStore.holds;
    },
  },
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media print {
  /* Hide all columns by default */
  table th,
  table td {
    display: none;
  }

  /* Show only the first two columns */
  table th:nth-child(-n + 6),
  table td:nth-child(-n + 6) {
    display: table-cell;
  }
}
</style>
