<template>
  <div>
    <cargo-library editable></cargo-library>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import CargoLibraryComponent from '@/components/CargoLibrary/CargoLibrary.vue';
export default Vue.extend({
  name: 'cargo-library-view',
  components: { 'cargo-library': CargoLibraryComponent },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
  filters: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
