<template>
  <div>
    <div v-if="loadlist">
      <v-carousel
        v-if="augmentedSets.length"
        height="250"
        hide-delimiters
        :showArrows="augmentedSets.length > 1"
        show-arrows-on-hover
        class="text-center">
        <v-carousel-item v-for="(auSet, i) in augmentedSets" :key="i">
          <span class="text-h6">{{ i + 1 }}: {{ auSet.set.name }}</span>
          <scene-component
            :key="auSet.set.uuid"
            :set="auSet"
            :canvas-width="400"
            :canvas-height="200"
            :customViewSettings="{
              view: '3d',
            }"
            centered />
        </v-carousel-item>
      </v-carousel>
      <div>
        <v-alert type="info" outlined v-if="loadlist.notes || loadplan.notes">
          <p v-if="loadlist.notes">{{ loadlist.notes }}</p>
          <p v-if="loadplan.notes">
            {{ loadplan.notes }}
          </p>
        </v-alert>
      </div>
      <loadlist-summary :loadlist="loadlist" />
    </div>
    <div v-else class="text-center">
      <v-progress-circular indeterminate v-bind:size="50" color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { AugmentedSet } from '@/models/augmented/set';
import Vue, { PropType } from 'vue';
import { Loadplan, Loadlist } from '../../models/LoadlistModel';
import LoadlistSummary from '../Custom/LoadlistSummary.vue';
import SceneComponent from '../Custom/SceneComponent.vue';

export default Vue.extend({
  name: 'loadlist-preview',
  components: {
    LoadlistSummary,
    SceneComponent,
  },
  props: {
    loadlist: Object as PropType<Loadlist>,
  },
  computed: {
    augmentedSets(): AugmentedSet[] {
      return this.loadplan?.sets?.map((s) => new AugmentedSet(s, this.loadplan.holds)) || [];
    },
    loadplan(): Loadplan {
      return this.loadlist?.result?.versions[0];
    },
  },
});
</script>
<style scoped></style>
