<template>
  <div>
    <v-btn-toggle v-if="!always_open" v-model="open" borderless style="float: right" class="mr-4">
      <v-btn text elevation="0" small>
        <span v-if="open !== 0"> <v-icon small>mdi-chevron-down</v-icon> Show more </span>
        <span v-else><v-icon small>mdi-chevron-up</v-icon> Show less </span>
      </v-btn>
    </v-btn-toggle>
    <v-card-text v-if="always_open" class="pt-0"><slot></slot></v-card-text>
    <v-expansion-panels v-else flat class="mb-4" v-model="open" accordion style="margin-top: -20px">
      <v-expansion-panel>
        <v-expansion-panel-content>
          <v-divider class="mb-8 mt-2" />
          <slot></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'advanced-section',
  data: function () {
    return {
      open: undefined,
    };
  },
  props: {
    always_open: Boolean,
  },
  computed: {},
  mounted(): void {},
  methods: {},
});
</script>
