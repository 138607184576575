export enum Warning {
  NO_LIMIT = 'NO_LIMIT',
  NO_WEIGHT = 'NO_WEIGHT',
  FILLER = 'FILLER',
  LOW_DENSITY = 'LOW_DENSITY',
  HIGH_DENSITY = 'HIGH_DENSITY',
  LENGTH_LT_WIDTH = 'LENGTH_LT_WIDTH',
}

export const shortDesc = (w: Warning): string => {
  switch (w) {
    case Warning.NO_WEIGHT:
      return 'This item has no initial weight provided';
    case Warning.NO_LIMIT:
      return 'This item will fill an entire unit';
    case Warning.FILLER:
      return 'This item will be used as a "filler"';
    case Warning.LOW_DENSITY:
      return 'Low density';
    case Warning.HIGH_DENSITY:
      return 'High density';
    case Warning.LENGTH_LT_WIDTH:
      return 'Length is less than width';
    default:
      return 'unknown error';
  }
};
