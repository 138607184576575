<template>
  <div>
    <div
      tabindex="0"
      v-on:keyup.delete="unloadLoadedCargoes()"
      style="outline: none; overflow: hidden"
      ref="plannerDiv">
      <div
        @dragover.prevent="draggedOverHold = true"
        @dragend="draggedOverHold = null"
        @dragleave="draggedOverHold = null"
        @drop="
          cargoDropOnContainer($event, hold.__indices.start, augmentedHold.bundledItems.length)
        ">
        <slot name="top" v-bind:container="augmentedHold"> </slot>
      </div>
      <div
        v-if="!hideItems"
        class="item-list"
        v-bind:style="{ height: listHeight || '50vh' }"
        v-bind:class="{ errorDrop: showMoveError === hold.__indices.start }">
        <div
          class="item-container px-1 pt-1"
          v-for="(item, index) in augmentedHold.bundledItems"
          :key="index">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                class="item"
                v-bind:class="{
                  draggedItem: draggedItemIndex === index,
                }"
                :draggable="true"
                @dragover.prevent="draggedOverRow = index"
                @dragend="
                  draggedOverRow = null;
                  draggedItemIndex = null;
                "
                @dragleave="draggedOverRow = null"
                @dragstart="dragStart(item, index, hold, hold.__indices.start, $event)"
                @drag="onDrag"
                @drop="cargoDropOnItem($event, hold.__indices.start, index)"
                @click="toggleSelectedIndices(index)"
                @contextmenu="showContextMenu($event, index)">
                <div
                  class="item-container new-slot"
                  v-bind:class="{
                    draggedOver: index === draggedOverRow,
                  }"></div>
                <v-card
                  outlined
                  v-bind:class="{
                    selectedCargo: selectedCargoIndices.includes(index),
                  }">
                  <v-card-text>
                    <v-row justify="center" align="center">
                      <v-icon :color="item.color" v-if="!item.from_container">fa-cube</v-icon>
                      <span class="ml-2 font-weight-bold">{{ item.label }}</span>
                      <v-spacer></v-spacer>
                      <span>{{ item.qty }} pcs</span>
                    </v-row>
                    <v-row>
                      <span
                        >{{ item.L | toLength(false) }} x {{ item.W | toLength(false) }} x
                        {{ item.H | toLength(true) }}, {{ item.WT | toWeight(true) }}</span
                      >
                      <v-spacer></v-spacer>
                      <span class="item-indicator"
                        >{{ item.not_stackable ? ' ≠ ' : '' }}
                        {{ item.orientations <= 3 ? ' ⇈ ' : '' }}</span
                      >
                    </v-row>
                    <v-row>
                      <v-chip class="mr-1" small v-if="item.shipment_id">
                        {{ item.shipment_id }}
                      </v-chip>
                      <v-chip class="mr-1" small v-if="item.priority">
                        Priority {{ item.priority }}
                      </v-chip>
                    </v-row>
                  </v-card-text>
                  <sub-item-component :item="item"></sub-item-component>
                </v-card>
              </div>
            </template>
            <span>Drag to move</span>
            <span v-if="isNaN(itemIndex)">, Press <kbd>Delete</kbd> to unload</span>
            <br />
            <span v-if="item.itemIndices.length > 1"
              >Hold <kbd>Alt</kbd> to move just one of the cargoes</span
            >
          </v-tooltip>
        </div>

        <div
          class="item-container new-slot"
          v-bind:class="{
            draggedOver: draggedOverHold && draggedOverRow === null,
          }"
          @dragover.prevent="draggedOverHold = true"
          @dragend="draggedOverHold = null"
          @dragleave="draggedOverHold = null"
          @drop="
            cargoDropOnContainer($event, hold.__indices.start, augmentedHold.bundledItems.length)
          "></div>
      </div>
    </div>

    <v-snackbar v-model="showSnackBar">
      <v-icon>mdi-exclamation</v-icon> The move results in some unloaded items. Hold
      <kbd>Shift</kbd> to do the move anyway

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackBar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-menu
      v-model="contextMenu.show"
      :position-x="contextMenu.x"
      :position-y="contextMenu.y"
      absolute
      offset-y>
      <v-list>
        <v-list-item @click="goToRow()">
          <v-list-item-title> Show in Data </v-list-item-title>
        </v-list-item>
        <v-list-item @click="unloadLoadedCargoes" :disabled="!selectedCargoIndices.length">
          <v-list-item-title> Unload selected </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import MoveItemsUtils from '@/misc/moveItemsUtils';

import { HoldData, HoldDataWithIndices, HoldItem, UnloadedItem } from '@/models/LoadlistModel';
import { useLoadlistStore } from '@/stores/loadlistStore';

import { AugmentedHold } from '@/models/augmented/hold';
import itemUtils from '@/misc/itemUtils';
import subItemComponent from './SubItem.vue';
import { mapStores } from 'pinia';

export default Vue.extend({
  name: 'planner-items',
  components: {
    subItemComponent,
  },
  props: {
    hold: {
      default: null,
      type: Object as PropType<HoldDataWithIndices>,
    },
    listHeight: {
      default: null,
      type: Number,
    },
    hideItems: {
      default: null,
      type: Boolean,
    },
    externalItemsSelection: {
      default: null,
      type: Array as PropType<HoldItem[]>,
      required: false,
    },
  },

  data() {
    return {
      draggedItemIndex: null as number,
      draggedOverRow: null as number,
      draggedOverHold: null as boolean,
      selectedCargoIndices: [] as number[],
      showMoveError: null as number,
      showSnackBar: false,
      contextMenu: {
        show: false,
        x: 0,
        y: 0,
        itemIndex: undefined,
      },
    };
  },
  computed: {
    ...mapStores(useLoadlistStore),

    augmentedHold(): AugmentedHold {
      return new AugmentedHold(this.hold);
    },
    itemIndex(): number {
      return Number(this.$route.params.item);
    },
  },
  watch: {
    externalItemsSelection: {
      handler: function (a: HoldData[]): void {
        const selected: number[] = [];
        if (a) {
          for (let i = 0; i < a.length; i++) {
            if (i === 0 || !itemUtils.compareItems(a[i], a[i - 1])) {
              const index = this.augmentedHold.bundledItems.findIndex((b) =>
                itemUtils.compareItems(a[i], b)
              );
              if (index >= 0) {
                if (!selected.includes(index)) {
                  selected.push(index);
                }
              }
            }
          }
        }
        this.selectedCargoIndices = selected;
      },
      immediate: true,
    },
  },
  methods: {
    bundledChildren(item: HoldItem): HoldItem[] {
      return itemUtils.bundledItems(item.from_container?.items || []);
    },
    dragStart(item: HoldItem, itemIndex: number, hold: HoldData, holdIndex: number, e: any): void {
      this.selectedCargoIndices = [];
      this.draggedItemIndex = itemIndex;
      e.dataTransfer.setData('text', JSON.stringify({ item, itemIndex, hold, holdIndex }));
    },
    onDrag(e: MouseEvent): void {
      const rect = (this.$refs.plannerDiv as HTMLElement).getBoundingClientRect();
      if (e.clientY > window.innerHeight - 100) {
        window.scrollBy(0, 2);
      } else if (e.clientY < rect.top) window.scrollBy(0, -2);
    },
    toggleSelectedIndices(i: number): void {
      const index = this.selectedCargoIndices.indexOf(i);

      if (index >= 0) {
        this.selectedCargoIndices.splice(index, 1);
      } else this.selectedCargoIndices.push(i);
    },
    moveUnloadedItems(
      toHoldIndex: number,
      toPositionIndex: number,
      unloaded_items: UnloadedItem[]
    ): void {
      this.updateLoadingContainers([toHoldIndex]);
      MoveItemsUtils.loadFromUnloaded(toHoldIndex, toPositionIndex, unloaded_items)
        .then((status) => {
          if (status === -1) {
            this.showMoveError = toHoldIndex;
            setTimeout(() => {
              this.showMoveError = null;
            }, 2000);
          }
        })
        .finally(() => this.resetDragged());
    },

    moveLoadedItems(
      fromHoldIndex: number,
      fromItemIndices: number[],
      toHoldIndex: number,
      toPosition: number,
      force: boolean
    ): void {
      this.showSnackBar = false;

      this.updateLoadingContainers([fromHoldIndex, toHoldIndex]);
      MoveItemsUtils.moveLoadedItems(fromHoldIndex, fromItemIndices, toHoldIndex, toPosition, force)
        .then((status) => {
          if (status === -1) {
            this.showSnackBar = true;
            this.showMoveError = toHoldIndex;
            setTimeout(() => {
              this.showMoveError = null;
            }, 2000);
          }
        })
        .finally(() => this.resetDragged());
    },
    unloadLoadedCargoes(): void {
      console.log(this.itemIndex);
      if (!isNaN(this.itemIndex)) return;
      if (!this.selectedCargoIndices.length) return;
      const fromIndices = this.selectedCargoIndices
        .reduce((prev, cur) => {
          return [...prev, this.augmentedHold.bundledItems[cur].itemIndices];
        }, [])
        .flat();

      this.updateLoadingContainers([this.hold.__indices.start]);
      MoveItemsUtils.removeLoadedItem(this.hold.__indices.start, fromIndices).finally(() =>
        this.resetDragged()
      );
    },
    cargoDropOnContainer(e: any, toHoldIndex: number, positionIndex: number): void {
      this.draggedOverHold = null;
      if (!isNaN(this.itemIndex)) return;
      const droppedItem = JSON.parse(e.dataTransfer.getData('text'));
      if (droppedItem.unloaded) {
        this.moveUnloadedItems(toHoldIndex, positionIndex, droppedItem.unloaded);
      } else if (droppedItem.holdIndex !== toHoldIndex || droppedItem.itemIndex !== positionIndex)
        this.moveLoadedItems(
          droppedItem.holdIndex,
          e.altKey ? droppedItem.item.itemIndices.slice(0, 1) : droppedItem.item.itemIndices,
          toHoldIndex,
          positionIndex,
          e.shiftKey
        );
    },
    cargoDropOnItem(e: any, toHoldIndex: number, positionIndex = 0): void {
      e.stopPropagation();
      this.draggedOverRow = null;
      if (!isNaN(this.itemIndex)) return;
      const droppedItem = JSON.parse(e.dataTransfer.getData('text'));
      if (droppedItem.unloaded) {
        this.moveUnloadedItems(toHoldIndex, positionIndex, droppedItem.unloaded);
      } else if (droppedItem.holdIndex !== toHoldIndex || droppedItem.itemIndex !== positionIndex)
        this.moveLoadedItems(
          droppedItem.holdIndex,
          e.altKey ? droppedItem.item.itemIndices.slice(0, 1) : droppedItem.item.itemIndices,
          toHoldIndex,
          positionIndex,
          e.shiftKey
        );
    },

    resetDragged(): void {
      this.updateLoadingContainers([]);
      this.selectedCargoIndices = [];
    },
    updateLoadingContainers(holdsIds: number[]): void {
      this.$emit('loadingContainers', holdsIds);
    },
    showContextMenu(e: MouseEvent, index: number): void {
      e.preventDefault();
      this.contextMenu.show = false;
      this.contextMenu.x = e.clientX;
      this.contextMenu.y = e.clientY;
      this.contextMenu.itemIndex = index;
      this.$nextTick(() => {
        this.contextMenu.show = true;
      });
    },
    goToRow(): void {
      if (this.contextMenu.itemIndex === undefined) {
        return;
      }
      const item = this.augmentedHold.items[this.contextMenu.itemIndex];
      let i = this.loadlistStore.loadlist.data.findIndex((input) =>
        itemUtils.compareItems(input, item)
      );
      if (i >= 0) {
        this.$router.push({ name: 'loadlist', query: { focus_row: `${i}` } });
      }
    },
  },
});
</script>

<style scoped>
[draggable] * {
  pointer-events: none;
}
.item-list {
  overflow-y: auto;
}
.item-container {
  cursor: grab;
}
.item-container:active {
  cursor: grabbing;
}
.item-container:last-child {
  padding-bottom: 70px;
}
.item-indicator {
  font-size: 18px;
}
.draggedOver {
  height: 64px;
  border-style: dashed;
  border-color: #4778b2;
  margin-bottom: 2px;
}
.draggedItem > * {
  background-color: lightgray;
}
.selectedCargo > * {
  background-color: lightgray;
}
.errorDrop {
  animation: color-change 1s;
}

@keyframes color-change {
  0% {
    background-color: red;
  }
  100% {
    background-color: none;
  }
}
</style>
