import {
  Cache,
  WebGLRenderer,
  Vector3,
  Box3,
  Scene,
  Object3D,
  PerspectiveCamera,
  HemisphereLight,
  AmbientLight,
  OrthographicCamera,
  Raycaster,
  Plane,
  Color,
  AxesHelper,
  Vector2,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Mutex } from 'async-mutex';

// import cannonDebugger from "cannon-es-debugger";

import { CargoScene } from './cargoScene';
import {
  pointerMove,
  pointerUp,
  pointerDown,
  keyDown,
  STATES,
  containerPointerDown,
  containerDblClick,
  containerHover,
  containerDrop,
  navHotkeys,
  containerPointerUp,
} from './eventHandlers';
import { eventBus } from './eventBus';
import ContainerUtils from '@/misc/containerUtils';
import { Item } from './item/item';
import { NestedItem } from './item/nestedItem';
import { HoldData } from '@/models/LoadlistModel';
import { InteractiveMode, Views, ViewSettings } from '@/models/GraphicsModel.js';
import { Container } from './container';
import { CompanySettings } from '@/models/UserCompanyModel';
const mutex = new Mutex();

const FOVS = {
  CLOSE: 30,
  FAR: 10,
};
const VIEWS: {
  TOP: Views;
  SIDE: Views;
  SIDE2: Views;
  FRONT: Views;
  THREED: Views;
  CUSTOM: Views;
  THREEDORTHO: Views;
  THREED_PERSPECTIVE: Views;
} = {
  TOP: 'top',
  SIDE: 'side',
  SIDE2: 'side2',
  FRONT: 'front',
  THREED: '3d',
  CUSTOM: 'custom',
  THREEDORTHO: '3d-ortho',
  THREED_PERSPECTIVE: '3d-perspective',
};
const INTERACTIVE_STATES: {
  DISABLED: InteractiveMode;
  CAMERA_ONLY: InteractiveMode;
  CONTAINER_MODE: InteractiveMode;
  FULL: InteractiveMode;
  FREE: InteractiveMode;
} = {
  DISABLED: null,
  CAMERA_ONLY: 'camera_only',
  CONTAINER_MODE: 'container_mode',
  FULL: 'full',
  FREE: 'free',
};

const physicsWorkerData: {
  positions: Float32Array;
  quaternions: Float32Array;
  sendTime: number;
  sendTimer: number;
  dt: number;
} = {
  positions: null,
  quaternions: null,
  sendTime: null,
  sendTimer: null,
  dt: 1 / 60,
};
let physicsWorker: Worker;

class SceneManagerC {
  state: {
    interaction: string;
    hoveredItem: Item | NestedItem;
    hoveredContainer: Container | undefined;
    selectedContainer: Container;
    tabIndex: number;
    defaultView: string;
    cogUpdateTimer: number;
    hideLabels: boolean;
    itemLabels: string[];
    show_nested_as_box: boolean;
  };
  eventBus = eventBus;
  renderer: WebGLRenderer;
  scene: Scene;
  orthoCamera: OrthographicCamera;
  perspectiveCamera: PerspectiveCamera;
  camera: OrthographicCamera | PerspectiveCamera;
  orbitControls: OrbitControls;
  raycaster: Raycaster;
  cargoScene: CargoScene;
  requestId: number;
  constructor() {
    this.state = {
      tabIndex: 0,
      hoveredItem: undefined,
      hoveredContainer: undefined,
      selectedContainer: undefined,
      defaultView: VIEWS.THREED,
      cogUpdateTimer: null,
      interaction: undefined,
      hideLabels: false,
      itemLabels: ['label'],
      show_nested_as_box: false,
    };
  }
  async init() {
    Cache.enabled = true;
    Object3D.DEFAULT_UP = new Vector3(0, 0, 1);

    this.renderer = new WebGLRenderer({ stencil: false, alpha: true });
    this.renderer.shadowMap.autoUpdate = false;
    // // this.renderer.setClearColor(0xffffff);
    // this.renderer.setClearColor(0x000000, 0);
    this.renderer.sortObjects = false;
    // this.renderer.gammaFactor = 2.2;

    this.scene = new Scene();
    // this.scene.add(new AmbientLight(0xaaaaaa, 2 * Math.PI));
    this.scene.add(new HemisphereLight(0xffffff, 0x000000, 1.5 * Math.PI));

    this.orthoCamera = new OrthographicCamera(
      window.innerWidth / -2,
      window.innerWidth / 2,
      window.innerHeight / 2,
      window.innerHeight / -2,
      -1000,
      1000
    );
    this.perspectiveCamera = new PerspectiveCamera(
      FOVS.CLOSE,
      window.innerWidth / window.innerHeight,
      0.1,
      2000
    );

    this.scene.add(this.orthoCamera, this.perspectiveCamera);
    this.camera = this.orthoCamera;

    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbitControls.rotateSpeed = 0.4;
    // this.orbitControls.enableKeys = false;
    this.renderer.getContext().canvas.addEventListener(
      'webglcontextlost',
      function (event: Event) {
        event.preventDefault();
        window.location.reload();
      },
      false
    );
    // Raycaster
    this.raycaster = new Raycaster();
    this.raycaster.params.Points.threshold = 0.1;
  }
  cameraListener(o: { target: OrbitControls }) {
    SceneManager.cargoScene?.onCameraChange(o.target);
    SceneManager.eventBus.emit('camera-change', o.target);
  }
  clearScene() {
    // TODO: requestId is never 0?
    if (this.requestId) cancelAnimationFrame(this.requestId);
    this.orbitControls.removeEventListener('change', this.cameraListener);
    if (this.cargoScene) {
      this.cargoScene.dispose();
      this.scene.remove(this.cargoScene);
      this.cargoScene = null;
    }
    clearTimeout(this.state.cogUpdateTimer);
    clearTimeout(physicsWorkerData.sendTimer);
    if (physicsWorker) physicsWorker.terminate();
    physicsWorkerData.positions = null;
    physicsWorkerData.quaternions = null;
    physicsWorkerData.sendTime = null;

    this.state.hoveredItem = undefined;
    this.state.tabIndex = 0;
    this.setInteractionState(null);
    // threeObject.renderer.forceContextLoss ( );
    this.renderer.dispose();
    this.renderer.clear();
    this.renderer.domElement.removeEventListener('pointerdown', pointerDown);
    this.renderer.domElement.removeEventListener('pointerdown', containerPointerDown);
    this.renderer.domElement.removeEventListener('pointerup', containerPointerUp);
    this.renderer.domElement.removeEventListener('dblclick', containerDblClick);
    this.renderer.domElement.removeEventListener('mousemove', containerHover);
    this.renderer.domElement.removeEventListener('dragover', containerHover);
    this.renderer.domElement.removeEventListener('drop', containerDrop);
    this.renderer.domElement.removeEventListener('pointermove', pointerMove);
    this.renderer.domElement.removeEventListener('pointerup', pointerUp);
    this.renderer.domElement.removeEventListener('keydown', keyDown);
    this.renderer.domElement.removeEventListener('keydown', navHotkeys);
  }
  async createScene(
    containersData: HoldData[],
    interactiveMode: InteractiveMode,
    width: number,
    height: number,
    viewSettings: ViewSettings,
    enableReuse: boolean
  ): Promise<Node> {
    const mergedSettings = {
      ...((containersData.length > 1
        ? containersData[0]?.setCamera
        : containersData[0]?.camera) || {
        view: this.state.defaultView || VIEWS.THREED,
      }),
      ...{
        hideLabels: this.state.hideLabels,
        itemLabels: this.state.itemLabels,
        show_nested_as_box: this.state.show_nested_as_box,
      },
      ...viewSettings,
    };

    const canvas = await mutex.runExclusive(async () => {
      // Try to reuse existing cargoScene if possible
      if (
        !this.cargoScene ||
        !enableReuse ||
        (this.cargoScene?.containers.children?.[0]?.userData.container.uuid &&
        containersData[0]?.uuid
          ? this.cargoScene?.containers.children?.length != containersData.length ||
            !containersData.some((c, i) => {
              return this.cargoScene?.containers.children?.[i]?.userData.container.uuid == c.uuid;
            })
          : !containersData.some((c, i) => {
              ContainerUtils.compareContainers(
                this.cargoScene?.containers.children?.[i]?.userData.container,
                c
              );
            }))
      ) {
        this.clearScene();
        this.cargoScene = new CargoScene(interactiveMode);
        await this.cargoScene.init(
          containersData,
          interactiveMode,
          mergedSettings.hideLabels,
          mergedSettings.itemLabels,
          mergedSettings.show_nested_as_box
        );

        this.scene.add(this.cargoScene);
      } else {
        // Reset cargo visibility settings
        this.cargoScene.getCargoes().forEach((i) => {
          i.visible = true;
          i.setOpacity(1.0);
          i.setColor();
        });
      }

      this.cargoScene.setCanvasSize(width);
      this.renderer.setSize(width, height);
      this.setView(mergedSettings);

      if (interactiveMode) {
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.domElement.addEventListener('keydown', navHotkeys);
        if (interactiveMode === INTERACTIVE_STATES.CONTAINER_MODE) {
          this.renderer.domElement.addEventListener('pointerdown', containerPointerDown);
          this.renderer.domElement.addEventListener('pointerup', containerPointerUp);
          this.renderer.domElement.addEventListener('dblclick', containerDblClick);
          this.renderer.domElement.addEventListener('mousemove', containerHover);
          this.renderer.domElement.addEventListener('dragover', containerHover);
          this.renderer.domElement.addEventListener('drop', containerDrop);
        }
        if (
          interactiveMode === INTERACTIVE_STATES.FULL ||
          interactiveMode === INTERACTIVE_STATES.FREE
        ) {
          this.renderer.domElement.addEventListener('pointerdown', pointerDown);
          this.renderer.domElement.addEventListener('pointermove', pointerMove);
          this.renderer.domElement.addEventListener('pointerup', pointerUp);
          this.renderer.domElement.addEventListener('keydown', keyDown);

          // Physics
          physicsWorker = new Worker(new URL('@/workers/physics.worker.js', import.meta.url));
          physicsWorker.onmessage = physicsWorkerMessages;
          physicsWorker.postMessage({
            event: 'init',
            containersData: containersData,
            interactiveMode,
          });
          physicsWorkerData.positions = new Float32Array(
            containersData.map((c) => c.items.length * 3).reduce((a, b) => a + b, 0)
          );
          physicsWorkerData.quaternions = new Float32Array(
            containersData.map((c) => c.items.length * 4).reduce((a, b) => a + b, 0)
          );
          requestDataFromPhysicsWorker();

          this.state.cogUpdateTimer = window.setInterval(
            () => SceneManager.cargoScene?.updateCog(),
            1000
          );
        }
        // Listen to changes in camera
        this.orbitControls.addEventListener('change', this.cameraListener);

        this.renderer.domElement.tabIndex = -1;
        animate();

        return this.renderer.domElement;
      } else {
        this.renderer.setPixelRatio(2);
        return this.getCanvasCopy();
      }
    });
    this.eventBus.emit('render-done');
    return canvas;
  }
  getCanvasCopy() {
    render();
    const c = SceneManager.renderer.domElement.cloneNode(true) as HTMLCanvasElement;
    const destCtx = c.getContext('2d');
    destCtx.drawImage(SceneManager.renderer.domElement, 0, 0);
    return c;
  }
  setCamera(toCameraView: 'perspective' | 'ortho' = null) {
    toCameraView =
      toCameraView || (this.camera instanceof OrthographicCamera ? 'perspective' : 'ortho');

    if (toCameraView == 'perspective') {
      this.perspectiveCamera.position.copy(this.orthoCamera.position);
      this.perspectiveCamera.aspect =
        parseInt(this.renderer.domElement.style.width, 10) /
        parseInt(this.renderer.domElement.style.height, 10);
      this.camera = this.perspectiveCamera;
      this.camera.fov = FOVS.CLOSE;
    } else {
      this.orthoCamera.position.copy(this.perspectiveCamera.position);
      this.camera = this.orthoCamera;
    }
    this.camera.updateProjectionMatrix();
    this.orbitControls.object = this.camera;
    this.orbitControls.update();
  }
  isPerspective() {
    return this.camera instanceof PerspectiveCamera;
  }
  setView(settings: ViewSettings) {
    let boundingBox = new Box3().setFromObject(this.cargoScene.containers);
    if (settings.rulerType) {
      this.cargoScene.removeRulers();
      this.cargoScene.createRulers(
        settings.rulerType.lengthDim == 'IN',
        settings.rulerType.withSubdividers,
        settings.rulerType.withFullTexts,
        settings.rulerType.withText
      );
      boundingBox = boundingBox.union(new Box3().setFromObject(this.cargoScene.rulers));
    }
    if (settings.containerMeasurements) {
      this.cargoScene.createContainerMeasurements(settings.containerMeasurements.lengthDim);
    }
    if (settings?.hideCog) {
      this.cargoScene.hideCog();
    }
    if (settings.containerNumbers) {
      this.cargoScene.showContainerNumbers();
    }
    if (settings.hideContainer) {
      this.cargoScene.hideContainers();
    }
    if (settings.backgroundColor) {
      this.scene.background = new Color(settings.backgroundColor);
    }
    if (settings.axesHelper) {
      this.scene.add(new AxesHelper(settings.axesHelper));
    }

    if (settings.view == VIEWS.TOP || settings.splitFloors) {
      this.cargoScene.separateFloors();
    } else {
      this.cargoScene.joinFloors();
    }

    switch (settings.highlight) {
      case 'byIndex': {
        const bb = this.cargoScene.showCargoesByIndex(
          settings.highlighted_cargo_indexes,
          settings.visible_cargo_indexes
        );
        if (settings.highlightMode == 'zoom' && bb) {
          const zoomed = bb.expandByVector(new Vector3(2.0, 2.0, 2.0));
          boundingBox = new Box3(
            new Vector3(
              Math.max(boundingBox.min.x, zoomed.min.x),
              Math.max(boundingBox.min.y, zoomed.min.y),
              Math.max(boundingBox.min.z, zoomed.min.z)
            ),
            new Vector3(
              Math.min(boundingBox.max.x, zoomed.max.x),
              Math.min(boundingBox.max.y, zoomed.max.y),
              Math.min(boundingBox.max.z, zoomed.max.z)
            )
          );
          // boundingBox = bb;
        }
        break;
      }
      case 'weightScale':
        this.cargoScene.showItemsByWeightScale();
        break;
    }

    const size = new Vector3();
    boundingBox.getSize(size);
    const center = new Vector3();
    boundingBox.getCenter(center);
    const maxLength = size.x;
    const maxWidth = size.y;
    const maxHeight = size.z;
    const maxFloorLength = Math.sqrt(maxLength * maxLength + maxWidth * maxWidth);
    const maxCubeLength = Math.sqrt(maxFloorLength * maxFloorLength + maxHeight * maxHeight);

    switch (settings.view) {
      case VIEWS.FRONT:
        this.setCameraFrustum(maxWidth, maxHeight);
        this.camera.position.set(boundingBox.max.x + maxCubeLength, center.y, center.z);
        this.orbitControls.target.copy(center);

        break;
      case VIEWS.SIDE:
        this.setCameraFrustum(maxLength, maxHeight);
        this.camera.position.set(center.x, boundingBox.min.y - maxCubeLength, center.z);
        this.orbitControls.target.copy(center);

        break;
      case VIEWS.SIDE2:
        this.setCameraFrustum(maxLength, maxHeight);
        this.camera.position.set(center.x, boundingBox.max.y + maxCubeLength, center.z);
        this.orbitControls.target.copy(center);

        break;
      case VIEWS.TOP:
        this.setCameraFrustum(maxLength, maxWidth);
        this.camera.position.set(center.x, center.y, boundingBox.max.z + maxCubeLength);
        this.orbitControls.target.copy(center);
        break;
      case VIEWS.THREED_PERSPECTIVE:
      case VIEWS.THREED: {
        this.setCameraFrustum(maxCubeLength, maxCubeLength);
        this.camera.position.set(center.x, -maxCubeLength, maxCubeLength);

        this.setCamera('perspective');

        const fov = (this.camera as PerspectiveCamera).fov * (Math.PI / 180);
        const height_width = Math.sqrt(size.y * size.y + size.z * size.z);

        if (height_width > maxLength) {
          const dist = (height_width * 0.5) / Math.sin(fov / 2);

          this.camera.position.set(
            center.x,
            center.y - Math.sqrt((dist * dist) / 2),
            center.z + Math.sqrt((dist * dist) / 2)
          );
        } else {
          const dist =
            (maxLength * 0.5) /
            Math.sin(fov / 2) /
            Math.min(maxLength / height_width, (this.camera as PerspectiveCamera).aspect);

          this.camera.position.set(
            center.x,
            boundingBox.min.y - Math.sqrt((dist * dist) / 2),
            boundingBox.max.z + Math.sqrt((dist * dist) / 2)
          );
        }
        this.orbitControls.target.copy(center);

        break;
      }
      case VIEWS.THREED_PERSPECTIVE:
      case VIEWS.THREEDORTHO: {
        this.setCameraFrustum(maxCubeLength, maxCubeLength);
        this.camera.position.set(center.x, -maxCubeLength, maxCubeLength);

        this.setCamera('perspective');
        (this.camera as PerspectiveCamera).fov = FOVS.FAR;

        const fov = (this.camera as PerspectiveCamera).fov * (Math.PI / 180);
        const height_width = Math.sqrt(size.y * size.y + size.z * size.z);

        if (height_width > maxLength) {
          const dist = (height_width * 0.5) / Math.sin(fov / 2);

          this.camera.position.set(
            center.x,
            center.y - Math.sqrt((dist * dist) / 2),
            center.z + Math.sqrt((dist * dist) / 2)
          );
        } else {
          const dist =
            (maxLength * 0.5) /
            Math.sin(fov / 2) /
            Math.min(maxLength / height_width, (this.camera as PerspectiveCamera).aspect);

          this.camera.position.set(
            center.x,
            boundingBox.min.y - Math.sqrt((dist * dist) / 2),
            boundingBox.max.z + Math.sqrt((dist * dist) / 2)
          );
        }
        this.orbitControls.target.copy(center);

        break;
      }
      default:
        this.setCameraFrustum(maxCubeLength, maxCubeLength);
        this.camera.position.fromArray(settings.pos);

        if (settings.isPerspective) {
          this.setCamera('perspective');
          this.camera.zoom = settings.zoom || this.camera.zoom;
        } else {
          (this.camera as OrthographicCamera).left = settings.l;
          (this.camera as OrthographicCamera).right = settings.r;
          (this.camera as OrthographicCamera).top = settings.t;
          (this.camera as OrthographicCamera).bottom = settings.b;
          this.camera.zoom = settings.z;
        }

        this.orbitControls.target.copy(new Vector3().fromArray(settings.lookAt));

        break;
    }
    if (settings.view === VIEWS.THREED_PERSPECTIVE) {
      this.camera.position.add(new Vector3(maxCubeLength, 0, 0));
    }
    this.camera.updateProjectionMatrix();

    this.orbitControls.enableRotate = this.orbitControls && this.isPerspective();
    this.orbitControls.update();

    // Setup camera
    this.cargoScene.cameraChange(this.orbitControls);

    if (settings.rulerType) {
      this.cargoScene.removeRulers();
      this.cargoScene.createRulers(
        settings.rulerType.lengthDim == 'IN',
        settings.rulerType.withSubdividers,
        settings.rulerType.withFullTexts,
        settings.rulerType.withText
      );
    }
  }
  setCameraFrustum(width: number, height: number) {
    this.setCamera('ortho');

    const divAspectRatio = 2;
    const viewAspectRatio2 = width / height;
    const combinedAspectratio = divAspectRatio / viewAspectRatio2;
    if (viewAspectRatio2 >= divAspectRatio) {
      this.orthoCamera.left = width / -2;
      this.orthoCamera.right = width / 2;
      this.orthoCamera.top = height / 2 / combinedAspectratio;
      this.orthoCamera.bottom = height / -2 / combinedAspectratio;
    } else {
      this.orthoCamera.left = (width / -2) * combinedAspectratio;
      this.orthoCamera.right = (width / 2) * combinedAspectratio;
      this.orthoCamera.top = height / 2;
      this.orthoCamera.bottom = height / -2;
    }
    this.orthoCamera.zoom = 1;
    this.orthoCamera.updateProjectionMatrix();
  }
  getViewSettings(): ViewSettings {
    return JSON.parse(
      JSON.stringify({
        isPerspective: this.camera instanceof PerspectiveCamera,
        pos: this.camera.position.toArray(),
        lookAt: this.orbitControls.target.clone().toArray(),
        z: this.orthoCamera.zoom,
        l: this.orthoCamera.left,
        r: this.orthoCamera.right,
        t: this.orthoCamera.top,
        b: this.orthoCamera.bottom,
        splitFloors: this.cargoScene.floorsAreSplit,
      })
    );
  }
  setViewSettings(viewSettings: ViewSettings): void {
    this.camera.position.add(new Vector3(...viewSettings.pos));
    this.orbitControls.target = new Vector3(...viewSettings.lookAt);
    this.orthoCamera.zoom = viewSettings.z;
    this.orthoCamera.left = viewSettings.l;
    this.orthoCamera.right = viewSettings.r;
    this.orthoCamera.top = viewSettings.t;
    (this.orthoCamera.bottom = viewSettings.b),
      (this.cargoScene.floorsAreSplit = viewSettings.splitFloors);
  }
  setInteractionState(state: string) {
    this.state.interaction = state;
    this.eventBus.emit('interaction-state-changed', state);
  }
  resetState() {
    if (this.cargoScene) {
      this.cargoScene.deselectAllCargoes();
      this.cargoScene.hideSnapHelper();
    }

    const selectBox = SceneManager.renderer.domElement.parentNode.querySelector(
      '#selectBox'
    ) as HTMLElement;
    if (selectBox) selectBox.style.display = 'none';

    this.eventBus.emit('select-cargoes', null);
    this.state.hoveredItem = undefined;

    if (SceneManager.orbitControls && this.isPerspective())
      SceneManager.orbitControls.enableRotate = true;

    this.setInteractionState(null);
  }
  saveContainerState() {
    this.resetState();
    this.cargoScene.saveState(this.getViewSettings());
  }
  resize(width: number, height: number) {
    this.renderer.setSize(width, height);
    this.cargoScene?.setCanvasSize(width);
  }
  setUserDefaults(settings: CompanySettings) {
    if (settings?.default_view) this.state.defaultView = settings.default_view;
    if (settings?.item_labels) this.state.itemLabels = settings.item_labels;
    if (settings?.show_nested_as_box) this.state.show_nested_as_box = settings.show_nested_as_box;
  }
  undo() {
    this.cargoScene.getSelectedItems().forEach((i) => {
      physicsWorker.postMessage({
        event: 'set',
        itemIndex: i.indexInContainer,
        position: i.prePosition,
        quaternion: i.preQuaternion,
        // Specify that we want actually transfer the memory, not copy it over. This is faster.
      });
    });
  }
  // Get point of top face of cargo or floor - used to load cargo by mouse into designated space
  getDropPoint(e: MouseEvent): Vector3 | null {
    const dim = (e.target as HTMLElement).getBoundingClientRect();
    const x = e.clientX - dim.left;
    const y = e.clientY - dim.top;
    this.raycaster.setFromCamera(
      new Vector2(
        (x / (e.target as HTMLElement).clientWidth) * 2 - 1,
        -(y / (e.target as HTMLElement).clientHeight) * 2 + 1
      ),
      this.camera
    );

    const intersects = this.raycaster.intersectObjects(
      [...this.cargoScene.getPartsAndItems().filter((i) => i.isCargo || i.name == 'Floor')],
      false
    );
    if (intersects.length && Math.abs((intersects[0].face?.normal.z || 0) - 1.0) < 0.001) {
      return intersects[0].point;
    }
    return null;
  }
}
const SceneManager = new SceneManagerC();

function physicsWorkerMessages(event: {
  data: { positions: Float32Array; quaternions: Float32Array };
}) {
  // console.log(event);
  // Get fresh data from the worker
  physicsWorkerData.positions = event.data.positions;
  physicsWorkerData.quaternions = event.data.quaternions;
  const children = SceneManager.cargoScene.getChildren();
  for (let i = 0; i < children.length; i++) {
    const item = children[i] as Item | NestedItem;
    if (item.isSelected) {
      item.position.set(
        physicsWorkerData.positions[i * 3 + 0],
        physicsWorkerData.positions[i * 3 + 1],
        physicsWorkerData.positions[i * 3 + 2]
      );
      item.quaternion.set(
        physicsWorkerData.quaternions[i * 4 + 0],
        physicsWorkerData.quaternions[i * 4 + 1],
        physicsWorkerData.quaternions[i * 4 + 2],
        physicsWorkerData.quaternions[i * 4 + 3]
      );
    }
  }

  // Delay the next step by the amount of dt remaining,
  // otherwise run it immediatly
  const delay = physicsWorkerData.dt * 1000 - (performance.now() - physicsWorkerData.sendTime);
  physicsWorkerData.sendTimer = window.setTimeout(requestDataFromPhysicsWorker, Math.max(delay, 0));
}

function requestDataFromPhysicsWorker() {
  if (physicsWorkerData.positions) {
    physicsWorkerData.sendTime = performance.now();
    physicsWorker.postMessage(
      {
        event: 'getData',
        dt: physicsWorkerData.dt,
        positions: physicsWorkerData.positions,
        quaternions: physicsWorkerData.quaternions,
        // Specify that we want actually transfer the memory, not copy it over. This is faster.
      },
      [physicsWorkerData.positions.buffer, physicsWorkerData.quaternions.buffer]
    );
  }
}

function render(): void {
  SceneManager.renderer.render(SceneManager.scene, SceneManager.camera);
  // console.log(SceneManager.renderer.info);
}

function animate() {
  SceneManager.requestId = requestAnimationFrame(animate);
  render();
}

export { SceneManager, render, physicsWorker, STATES, VIEWS, INTERACTIVE_STATES };
