<template>
  <v-hover v-slot="{ hover }">
    <v-alert
      v-bind="$attrs"
      @click="open = !open"
      :style="hasBody ? 'cursor:pointer' : ''"
      :elevation="hasBody && hover ? 3 : 0"
      class="toggle-alert">
      <b>
        <slot></slot>
        <span v-if="hasBody" class="float-right">
          <v-icon v-if="open">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </span>
      </b>
      <div v-if="body" class="toggle-body" :style="`max-height:${open ? '120px' : '0px'}`">
        {{ body }}
      </div>
    </v-alert>
  </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'toggle-alert',
  data: function () {
    return {
      open: false,
    };
  },
  props: {
    body: String,
  },
  computed: {
    hasBody(): boolean {
      return !!this.body;
    },
  },
  mounted(): void {},
  methods: {},
});
</script>

<style scoped>
.toggle-body {
  transition: max-height 100ms;
  overflow: hidden;
  height: auto;
}
</style>
