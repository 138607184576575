import { VCol } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VRow,{staticClass:"mb-2"},[_c(VCol,{staticClass:"py-0"},[_c(VTextField,{attrs:{"prepend-icon":"mdi-magnify","clearable":"","placeholder":"Search:","hide-details":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1)],1),(_vm.isLoading)?_c(VProgressCircular,{attrs:{"value":"true","indeterminate":"","color":"primary"}}):_vm._e(),_vm._t("items",null,{"paginatedItems":_vm.paginatedSets}),_c(VPagination,{attrs:{"length":_vm.numberOfPages,"total-visible":"8"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }