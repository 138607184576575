<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <v-tabs v-model="active_tab">
            <v-tab v-for="tab in tabs" :key="tab.title">{{ tab.title }}</v-tab>
            <v-tabs-items v-model="active_tab">
              <v-tab-item v-for="tab in tabs" :key="tab.key">
                <v-card flat v-if="tab.key === 'general'">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-textarea
                          label="Description"
                          v-model="currentHold.description"
                          outlined></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.L"
                          label="Inside length"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.W"
                          label="Inside width"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          ref="height"
                          v-model="currentHold.H"
                          v-if="!onlyFloor"
                          label="Inside height"></length-input-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <weight-input-field
                          v-model="currentHold.payload"
                          label="Payload"
                          hint="Weight limit"></weight-input-field>
                      </v-col>
                      <v-col>
                        <weight-input-field
                          v-model="currentHold.tare"
                          label="Tare"
                          hint="Used for Centre of Gravity and Axle weight calculations"></weight-input-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <length-input-field
                          :value="currentHold.max_length"
                          @change="$set(currentHold, 'max_length', $event || null)"
                          label="Max loading length"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          :value="currentHold.max_width"
                          @change="$set(currentHold, 'max_width', $event || null)"
                          label="Max loading width"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          :value="currentHold.max_height"
                          @change="$set(currentHold, 'max_height', $event || null)"
                          label="Max loading height"></length-input-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="currentHold.no_end_walls"
                          label="No end walls"></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-model="currentHold.no_side_walls"
                          label="No side walls"></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox v-model="currentHold.no_roof" label="No roof"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.floor_height"
                          label="Floor height"></length-input-field>
                      </v-col>
                      <v-col>
                        <div class="text-caption">Floor color</div>
                        <input
                          type="color"
                          style="width: 50px; height: 30px; cursor: pointer"
                          @input="$set(currentHold, 'floor_color', $event.target.value)"
                          :value="currentHold.floor_color || '#ffffff'" />
                      </v-col>
                    </v-row>
                    <v-row v-if="!currentHold.door">
                      <v-btn block @click="$set(currentHold, 'door', {})">Add door</v-btn>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.door.W"
                          label="Door width"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.door.H"
                          label="Door height"></length-input-field>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-model="currentHold.door.longside"
                          label="At longside"></v-checkbox>
                      </v-col>
                      <v-btn block @click="$set(currentHold, 'door', null)">Remove door</v-btn>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Cost [$, €, £, ...]:"
                          type="number"
                          v-model="currentHold.cost"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card flat v-else-if="tab.key === 'contours'">
                  <v-alert color="info" outlined prominent>
                    <ul>
                      <li>A {{ typeName }} can have maximum 8 contours</li>
                      <li>
                        Each contour is represented by a length and a height - only when both these
                        are set the contour will be functional
                      </li>
                      <li>
                        Spin the {{ typeName }} around (in the 3D view to the righ) to see all
                        contours
                      </li>
                    </ul>
                  </v-alert>
                  <v-card-text>
                    <p class="text-subtitle-1">Front bottom</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.front_bottom_contour_l"
                          label="Length"
                          :disabled="noEndWalls"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.front_bottom_contour_h"
                          label="Height"
                          :disabled="noEndWalls"></length-input-field>
                      </v-col>
                    </v-row>
                    <p class="text-subtitle-1">Front top</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.front_top_contour_l"
                          label="Length"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.front_top_contour_h"
                          label="Height"></length-input-field>
                      </v-col>
                    </v-row>
                    <p class="text-subtitle-1">Rear bottom</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.rear_bottom_contour_l"
                          label="Length"
                          :disabled="noEndWalls"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.rear_bottom_contour_h"
                          label="Height"
                          :disabled="noEndWalls"></length-input-field>
                      </v-col>
                    </v-row>

                    <p class="text-subtitle-1">Rear top</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.rear_top_contour_l"
                          label="Length"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.rear_top_contour_h"
                          label="Height"></length-input-field>
                      </v-col>
                    </v-row>

                    <p class="text-subtitle-1">Side 1 bottom</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.side1_bottom_contour_l"
                          label="Width"
                          :disabled="noSideWalls"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.side1_bottom_contour_h"
                          label="Height"
                          :disabled="noSideWalls"></length-input-field>
                      </v-col>
                    </v-row>

                    <p class="text-subtitle-1">Side 1 top</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.side1_top_contour_l"
                          label="Width"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.side1_top_contour_h"
                          label="Height"></length-input-field>
                      </v-col>
                    </v-row>

                    <p class="text-subtitle-1">Side 2 bottom</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.side2_bottom_contour_l"
                          label="Width"
                          :disabled="noSideWalls"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.side2_bottom_contour_h"
                          label="Height"
                          :disabled="noSideWalls"></length-input-field>
                      </v-col>
                    </v-row>

                    <p class="text-subtitle-1">Side 2 top</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.side2_top_contour_l"
                          label="Width"></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.side2_top_contour_h"
                          label="Height"></length-input-field>
                      </v-col>
                    </v-row>
                    <p class="text-subtitle-1">Bottom spacer</p>
                    <v-row>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.auto_bottom_spacer_h"
                          label="Auto bottom spacer height"
                          hint="Let the tool decide if a bottom spacer should be used"
                          persistent-hint></length-input-field>
                      </v-col>
                      <v-col>
                        <length-input-field
                          v-model="currentHold.contours.bottom_spacer_h"
                          label="Fixed bottom spacer height"
                          hint="The floor will always be raised with this height"
                          persistent-hint></length-input-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat v-else-if="tab.key === 'road'">
                  <v-card-text>
                    <v-row
                      ><v-col>
                        <length-input-field
                          v-model="currentHold.clearence"
                          label="Ground clearence"
                          hint="Applicable if axles are used"
                          persistent-hint></length-input-field> </v-col
                    ></v-row>

                    <v-card class="mt-3">
                      <v-card-title> Front axle set </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col>
                            <length-input-field
                              v-model="currentHold.axles.front_axle_x"
                              label="Position from front"></length-input-field>
                          </v-col>
                          <v-col>
                            <v-select
                              :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
                              v-model.number="currentHold.axles.front_axle_no"
                              label="Axles"
                              hint="Set 0 for kingpin"
                              persistent-hint></v-select>
                          </v-col>
                          <v-col>
                            <length-input-field
                              v-model="currentHold.axles.front_axle_spacing"
                              label="Spacing"></length-input-field>
                          </v-col>

                          <v-col cols="12">
                            <weight-input-field
                              :label="`Weight limit ${currentHold.tare ? '' : '(net)'}`"
                              :rules="[
                                (v) =>
                                  (currentHold.tare || 0) <
                                    (v || 0) + (currentHold.axles.rear_axle_max_weight || 0) ||
                                  'Axle weights is less than tare',
                              ]"
                              :min="0"
                              placemodified_holder="Weight"
                              v-model="currentHold.axles.front_axle_max_weight"
                              persistent-hint></weight-input-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card class="mt-3">
                      <v-card-title> Rear axle set </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <length-input-field
                              v-model="currentHold.axles.rear_axle_x"
                              label="Position from front"></length-input-field>
                          </v-col>
                          <v-col>
                            <v-select
                              :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
                              v-model.number="currentHold.axles.rear_axle_no"
                              label="Axles"></v-select>
                          </v-col>
                          <v-col>
                            <length-input-field
                              v-model="currentHold.axles.rear_axle_spacing"
                              label="Spacing"></length-input-field>
                          </v-col>
                          <v-col cols="12">
                            <weight-input-field
                              :label="`Weight limit ${currentHold.tare ? '' : ' (net)'}`"
                              :min="0"
                              :rules="[
                                (v) => {
                                  return (
                                    (currentHold.tare || 0) <
                                      (v || 0) + (currentHold.axles.front_axle_max_weight || 0) ||
                                    `Axle weights is less than tare`
                                  );
                                },
                              ]"
                              placemodified_holder="Weight"
                              v-model="currentHold.axles.rear_axle_max_weight"
                              persistent-hint></weight-input-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card class="mt-3">
                      <v-card-title>Decks</v-card-title>
                      <v-card-text>
                        <p class="text-subtitle-1">Front deck</p>
                        <v-row>
                          <v-col>
                            <length-input-field
                              v-model="currentHold.tables.front_table_l"
                              label="Length"></length-input-field>
                          </v-col>
                          <v-col>
                            <length-input-field
                              v-model="currentHold.tables.front_table_h"
                              label="Height"></length-input-field>
                          </v-col>
                          <v-col>
                            <v-checkbox
                              v-model="currentHold.tables.front_table_not_stackable"
                              label="Not stackable"></v-checkbox>
                          </v-col>
                        </v-row>
                        <p class="text-subtitle-1">Rear deck</p>
                        <v-row>
                          <v-col>
                            <length-input-field
                              v-model="currentHold.tables.rear_table_l"
                              label="Length"></length-input-field>
                          </v-col>
                          <v-col>
                            <length-input-field
                              v-model="currentHold.tables.rear_table_h"
                              label="Height"></length-input-field>
                          </v-col>
                          <v-col>
                            <v-checkbox
                              v-model="currentHold.tables.rear_table_not_stackable"
                              label="Not stackable"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card class="mt-3">
                      <v-card-title> Special transports </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <weight-input-field
                              label="Legal weight"
                              :min="0"
                              placemodified_holder="Weight"
                              :value="(currentHold.legal_limits || {}).weight"
                              @input="
                                currentHold.legal_limits = {
                                  ...currentHold.legal_limits,
                                  weight: $event || null,
                                }
                              "
                              :hint="`If one cargo exceeds legal weight then no other items will be co loaded on the same ${typeName}`"
                              :rules="[
                                (weight) =>
                                  Number(weight) == 0 ||
                                  Number(weight) > currentHold.tare ||
                                  `Legal weight should be larger than the tare of the trailer`,
                              ]"></weight-input-field>
                          </v-col>
                          <v-col>
                            <length-input-field
                              label="Legal height"
                              :min="0"
                              placemodified_holder="Height"
                              :value="(currentHold.legal_limits || {}).height"
                              @input="
                                currentHold.legal_limits = {
                                  ...currentHold.legal_limits,
                                  height: $event || null,
                                }
                              "
                              :rules="[
                                (length) => {
                                  return (
                                    Number(length) == 0 ||
                                    Number(length) <=
                                      Math.max(currentHold.H, currentHold.max_height) ||
                                    `The legal height should be less than the max height of the trailer`
                                  );
                                },
                              ]"
                              hint="Non-divisible loads can be loaded above the legal height, but only up to the max height"></length-input-field>
                          </v-col>
                        </v-row>

                        <p class="text-subtitle-1">Extendable Trailer</p>
                        <v-row>
                          <v-col>
                            <length-input-field
                              :value="(currentHold.legal_limits || {}).max_length_with_extension"
                              @input="
                                currentHold.legal_limits = {
                                  ...currentHold.legal_limits,
                                  max_length_with_extension: $event || null,
                                }
                              "
                              :rules="[
                                (length) => {
                                  return (
                                    Number(length) == 0 ||
                                    Number(length) > currentHold.L ||
                                    `Length needs to be larger than the length of trailer`
                                  );
                                },
                              ]"
                              label="Max Length With Extension"
                              hint="The total length the trailer can be extended to"></length-input-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
                <v-card
                  flat
                  v-else-if="tab.key === 'components'"
                  max-height="800"
                  style="overflow-y: scroll">
                  <v-card-actions>
                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" block dark v-bind="attrs" v-on="on">
                          <v-icon>mdi-plus</v-icon>
                          Add component
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-group
                          no-action
                          sub-group
                          v-for="part in customParts"
                          :key="part.type">
                          <template v-slot:activator>
                            <v-list-item-content>
                              <v-list-item-title>{{ part.type }}</v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <v-list-item
                            v-for="i in part.items"
                            :key="i.title"
                            link
                            @click="createParts(i.data)">
                            <v-list-item-title v-text="i.title"></v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list>
                    </v-menu>
                  </v-card-actions>
                  <v-card-text>
                    <template v-for="(part, partIndex) in currentHold.parts">
                      <edit-part-component
                        :index="partIndex"
                        v-model="currentHold.parts[partIndex]"
                        :key="part.id"
                        @delete="
                          currentHold.parts = currentHold.parts.filter((p) => p.id !== $event)
                        "></edit-part-component>
                    </template>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-col>
        <v-col cols="6">
          <scene-component
            v-if="renderedHold !== null"
            class="mt-8"
            :canvasWidth="800"
            :canvasHeight="600"
            :key="renderKey"
            interactiveMode="camera_only"
            :hold-object="renderedHold"
            :customViewSettings="{
              containerMeasurements: { lengthDim: length_dim },
              hideCog: true,
            }"></scene-component>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showSupportModal">
      <v-card>
        <v-card-title class="text-h5">Help</v-card-title>
        <v-card-text>
          <p>
            Creating or editing a {{ typeName }} can be a bit tricky. If you would like to add one
            not found in the Library and find it hard to create one - just drop an email to:
            <a :href="`mailto:support@cargo-planner.com?subject=Please add this ${typeName} for us`"
              >support@cargo-planner.com</a
            >
            with the dimensions and specifications and we will try to create it for you.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showSupportModal = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import Vue, { PropType } from 'vue';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import { SceneManager } from '@/graphics/sceneManager';
import createHold from '@/misc/containerUtils';
import { HoldData, HoldPart, ListType } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import EditPartComponent from './EditPart.vue';
export default Vue.extend({
  name: 'modify_hold',
  components: {
    sceneComponent,
    EditPartComponent,
  },
  props: {
    value: Object as PropType<HoldData>,
    base_type: {
      type: String,
      default: () => {
        return 'SEA';
      },
    },
  },

  data() {
    return {
      validForm: false,
      showSupportModal: false,
      renderKey: 1,
      updating: false,
      currentHold: (() => {
        let tmp = createHold.defaults(this.base_type as ListType);
        return {
          ...tmp,
          base_type: this.base_type as ListType,
          contours: tmp.contours || {},
          axles: tmp.axles || {},
          tables: tmp.tables || {},
          parts: tmp.parts || [],
          legal_limits: tmp.legal_limits || {},
        };
      })() as HoldData,
      renderedHold: null,
      active_tab: 0,
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    tabs(): { key: string; title: string; base_types: string[] }[] {
      return [
        {
          key: 'general',
          title: 'General',
          base_types: ['SEA', 'ROAD', 'AIR', 'PALL'],
        },
        {
          key: 'road',
          title: 'Road',
          base_types: ['ROAD', 'SEA'],
        },
        {
          key: 'contours',
          title: 'Contours',
          base_types: ['SEA', 'ROAD', 'AIR', 'PALL'],
        },
        {
          key: 'components',
          title: 'Components',
          base_types: ['SEA', 'ROAD', 'AIR', 'PALL'],
        },
      ].filter((t) => t.base_types.indexOf(this.base_type) > -1);
    },
    onlyFloor(): boolean {
      return (
        this.currentHold.no_side_walls &&
        this.currentHold.no_end_walls &&
        this.currentHold.no_roof &&
        !this.currentHold.contours?.front_top_contour_l &&
        !this.currentHold.contours?.rear_top_contour_l &&
        !this.currentHold.contours?.side1_top_contour_l &&
        !this.currentHold.contours?.side2_top_contour_l
      );
    },
    noSideWalls(): boolean {
      return this.currentHold.no_side_walls;
    },
    noEndWalls(): boolean {
      return this.currentHold.no_end_walls;
    },
    length_dim(): string {
      return this.miscStore.length_dim;
    },
    typeName(): string {
      return this.$typeNames(this.base_type);
    },
    customParts(): any[] {
      return [
        {
          type: 'Corners',
          items: [
            {
              title: 'Bottom rear corners',
              data: [
                {
                  type: 'corner',
                  L: 0.1,
                  W: 0.1,
                  H: this.currentHold.H * 0.5,
                  stick_to: ['floor', 'rear', 'side1'],
                  not_stackable: true,
                },
                {
                  type: 'corner',
                  L: 0.1,
                  W: 0.1,
                  H: this.currentHold.H * 0.5,
                  stick_to: ['floor', 'rear', 'side2'],
                  not_stackable: true,
                },
              ],
            },
            {
              title: 'Bottom front corners',
              data: [
                {
                  type: 'corner',
                  L: 0.1,
                  W: 0.1,
                  H: this.currentHold.H * 0.5,
                  stick_to: ['floor', 'front', 'side1'],
                  not_stackable: true,
                },
                {
                  type: 'corner',
                  L: 0.1,
                  W: 0.1,
                  H: this.currentHold.H * 0.5,
                  stick_to: ['floor', 'front', 'side2'],
                  not_stackable: true,
                },
              ],
            },
            {
              title: 'Top rear corners',
              data: [
                {
                  type: 'corner',
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                  stick_to: ['roof', 'rear', 'side1'],
                  not_stackable: true,
                },
                {
                  type: 'corner',
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                  stick_to: ['roof', 'rear', 'side2'],
                  not_stackable: true,
                },
              ],
            },
            {
              title: 'Top front corners',
              data: [
                {
                  type: 'corner',
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                  stick_to: ['roof', 'front', 'side1'],
                  not_stackable: true,
                },
                {
                  type: 'corner',
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                  stick_to: ['roof', 'front', 'side2'],
                  not_stackable: true,
                },
              ],
            },
          ],
        },
        {
          type: 'Frames',
          items: [
            {
              title: 'Bottom end frames',
              data: [
                {
                  type: 'frame',
                  H: 0.1,
                  L: 0.1,
                  stick_to: ['floor', 'front', 'side1', 'side2'],
                  not_stackable: true,
                },
                {
                  type: 'frame',
                  H: 0.1,
                  L: 0.1,
                  full_width: true,
                  stick_to: ['floor', 'rear', 'side1', 'side2'],
                  not_stackable: true,
                },
              ],
            },
            {
              title: 'Bottom side frames',
              data: [
                {
                  type: 'frame',
                  H: 0.1,
                  W: 0.1,
                  stick_to: ['floor', 'side1', 'front', 'rear'],
                  not_stackable: true,
                },
                {
                  type: 'frame',
                  H: 0.1,
                  W: 0.1,
                  stick_to: ['floor', 'side2', 'front', 'rear'],
                  not_stackable: true,
                },
              ],
            },
            {
              title: 'Top end frames',
              data: [
                {
                  type: 'frame',
                  H: 0.1,
                  L: 0.1,
                  full_width: true,
                  stick_to: ['roof', 'front', 'side1', 'side2'],
                  not_stackable: true,
                },
                {
                  type: 'frame',
                  H: 0.1,
                  L: 0.1,
                  full_width: true,
                  stick_to: ['roof', 'rear', 'side1', 'side2'],
                  not_stackable: true,
                },
              ],
            },
            {
              title: 'Top side frames',
              data: [
                {
                  type: 'frame',
                  H: 0.1,
                  W: 0.1,
                  full_length: true,
                  stick_to: ['roof', 'side1', 'front', 'rear'],
                  not_stackable: true,
                },
                {
                  type: 'frame',
                  H: 0.1,
                  W: 0.1,
                  full_length: true,
                  stick_to: ['roof', 'side2', 'front', 'rear'],
                  not_stackable: true,
                },
              ],
            },
          ],
        },
        {
          type: 'Walls',
          items: [
            {
              title: 'Crosswise wall',
              data: [
                {
                  type: 'wall',
                  pos: { x: this.currentHold.L * 0.5 },
                  L: 0.01,
                  stick_to: ['floor', 'side1', 'side2', 'roof'],
                  not_stackable: true,
                },
              ],
            },
            {
              title: 'Lengthwise wall',
              data: [
                {
                  type: 'wall',
                  pos: { y: this.currentHold.W * 0.5 },
                  W: 0.1,
                  stick_to: ['floor', 'roof', 'front', 'rear'],
                  not_stackable: true,
                },
              ],
            },
          ],
        },
        {
          type: 'Floor',
          items: [
            {
              title: 'Floor',
              data: [
                {
                  type: 'floor',
                  pos: { z: this.currentHold.H * 0.5 },
                  H: 0.1,
                  stick_to: ['side1', 'side2', 'front', 'rear'],
                },
              ],
            },
          ],
        },
        {
          type: 'Custom',
          items: [
            {
              title: 'Custom part',
              data: [
                {
                  type: 'custom',
                  pos: {
                    x: this.currentHold.L * 0.5,
                    y: this.currentHold.W * 0.5,
                    z: this.currentHold.H * 0.5,
                  },
                  L: 0.1,
                  W: 0.1,
                  H: 0.1,
                },
              ],
            },
          ],
        },
      ];
    },
  },
  watch: {
    value: {
      handler: function (v: HoldData) {
        if (v) {
          this.currentHold.name = v.name;
          if (this.currentHold.id != v.id) {
            this.updateCurrentHold(v);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    currentHold: {
      handler: function (val: HoldData): void {
        this.assembleHold(JSON.parse(JSON.stringify(val)));

        this.$emit('input', {
          ...this.renderedHold,
          cameraSettings: undefined,
        });
      },
      deep: true,
      immediate: true,
    },
    onlyFloor: {
      handler: function (val: boolean): void {
        if (val) this.$set(this.currentHold, 'H', 0);
      },
    },
    'currentHold.no_end_walls': {
      handler: function (val: boolean): void {
        if (val && this.currentHold.contours) {
          this.$set(this.currentHold.contours, 'front_bottom_contour_h', 0);
          this.$set(this.currentHold.contours, 'front_bottom_contour_l', 0);
          this.$set(this.currentHold.contours, 'rear_bottom_contour_h', 0);
          this.$set(this.currentHold.contours, 'rear_bottom_contour_l', 0);
        }
      },
    },
    'currentHold.no_side_walls': {
      handler: function (val: boolean): void {
        if (val && this.currentHold.contours) {
          this.$set(this.currentHold.contours, 'side1_bottom_contour_h', 0);
          this.$set(this.currentHold.contours, 'side1_bottom_contour_l', 0);
          this.$set(this.currentHold.contours, 'side2_bottom_contour_h', 0);
          this.$set(this.currentHold.contours, 'side2_bottom_contour_l', 0);
        }
      },
    },
  },
  beforeDestroy() {
    SceneManager.clearScene();
  },
  mounted() {
    if (!localStorage.getItem('cplHasSeenHoldSupport')) {
      this.showSupportModal = true;
      localStorage.setItem('cplHasSeenHoldSupport', 'true');
    }
  },
  methods: {
    assembleHold(hold: HoldData): void {
      let assembled_hold = createHold.assemble(hold);
      // Remove unused fields
      Object.keys(assembled_hold)
        .filter((key) => {
          let k = key as keyof HoldData;
          return (
            assembled_hold[k] &&
            assembled_hold[k].constructor === Object &&
            !Object.values(assembled_hold[k]).some((v) => {
              if (Array.isArray(v)) {
                return v.length;
              } else {
                return !!v;
              }
            })
          );
        })
        .forEach((key: keyof HoldData) => {
          delete assembled_hold[key];
        });
      this.renderedHold = assembled_hold;
      this.renderKey++;
    },
    updateCurrentHold(v: HoldData): void {
      // console.log("Updating current hold");
      let tmp = JSON.parse(JSON.stringify(v));
      this.currentHold = {
        ...tmp,
        contours: tmp.contours || {},
        axles: tmp.axles || {},
        tables: tmp.tables || {},
        parts: tmp.parts || [],
        legal_limits: tmp.legal_limits || {},
      };
    },
    async sleep(ms: number): Promise<void> {
      return new Promise((r) => setTimeout(r, ms));
    },
    createPart(part: Object): void {
      // return new HoldPart(data);
      if (!Array.isArray(this.currentHold.parts))
        this.$set(this.currentHold, 'parts', this.currentHold.parts || []);
      this.currentHold.parts.push(new HoldPart(part));
    },
    async createParts(data: any): Promise<void> {
      for (let i = 0; i < data.length; i++) {
        this.createPart(data[i]);
        await this.sleep(1);
      }
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-subtitle-1 {
  margin-bottom: 6px;
  margin-top: 24px;
}
</style>
