<template>
  <div>
    <container-library-component :holdType="$route.params.type">
      <template v-slot:items="{ paginatedItems: containers }">
        <v-expansion-panels focusable class="unselectable">
          <v-expansion-panel
            v-for="hold in containers"
            :key="hold.id"
            @click="$vuetify.goTo($event.target)">
            <v-expansion-panel-header>
              <span>
                <strong>
                  <span>
                    <v-icon v-if="!hold.default">mdi-account</v-icon>
                    {{ hold.name }}
                  </span>
                </strong>
                - ({{ hold.data.L | toLength }} x
                {{ hold.data.W | toLength }}
                <span v-if="hold.data.H > 0">x {{ hold.data.H | toLength }}</span>
                )
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <scene-component
                :hold-object="hold.data"
                :canvas-width="300"
                :canvas-height="150"></scene-component>
              <v-btn
                v-if="!hold.default && user.is_editor"
                @click="
                  $router.push({
                    name: 'modify_hold',
                    params: {
                      hold_id: hold.id,
                      type: hold.base_type,
                    },
                  })
                "
                block>
                <v-icon>mdi-pencil</v-icon>Edit
              </v-btn>
              <v-btn
                v-else
                @click="
                  $router.push({
                    name: 'modify_hold',
                    params: {
                      hold_id: hold.id,
                      type: hold.base_type,
                    },
                  })
                "
                block>
                <v-icon>mdi-magnify-plus-outline</v-icon>View
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </container-library-component>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import containerLibraryComponent from '@/components/Custom/ContainerLibrary.vue';
import setsLibraryComponent from '@/components/Custom/SetsLibrary.vue';

import sceneComponent from '@/components/Custom/SceneComponent.vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { User } from '@/models/UserCompanyModel';

export default Vue.extend({
  name: 'equipment',
  components: {
    containerLibraryComponent,
    setsLibraryComponent,
    sceneComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapStores(useMiscStore),
    user(): User {
      return this.miscStore.user;
    },
  },
  methods: {},
  filters: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
