<template>
  <div>
    <v-card>
      <v-card-title>
        <p>Settings</p>
      </v-card-title>
      <v-card-text v-if="isCompanySettings">
        <v-alert type="info" outlined>
          Company wide settings can be set below and be overriden by users. If all users must use
          the same setup - set below option to true in order to enforce all users to use the same
          preferences
        </v-alert>
        <v-switch v-model="enforced" label="Enforce company wide settings"></v-switch>
      </v-card-text>
      <v-card-text>
        <v-alert v-if="!isCompanySettings && preferences.enforced" type="error" outlined>
          Preferences are company managed
        </v-alert>
        <v-card flat :disabled="!isCompanySettings && preferences.enforced">
          <v-card-text>
            <v-expansion-panels multiple focusable>
              <v-expansion-panel @click="goToPanel">
                <v-expansion-panel-header>General settings </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- <v-card-subtitle> Units of Measurement </v-card-subtitle> -->
                  <v-row>
                    <v-col>
                      <v-select
                        label="Length unit when viewing and editing lengths"
                        v-model="length_dim"
                        :items="['MM', 'CM', 'DM', 'M', 'IN']"></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        label="Default length unit when creating new loadlists"
                        hint="When creating a new load list - automatically set the length dimension to this value"
                        v-model="default_length_dim"
                        :items="[null, 'MM', 'CM', 'DM', 'M', 'IN', 'FT']"
                        clearable></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                        label="Weight unit when viewing and editing weights"
                        v-model="weight_dim"
                        :items="['KG', 'LB']"></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        label="Default weight unit when creating new loadlists"
                        hint="When creating a new load list - automatically set the weight dimension to this value"
                        v-model="default_weight_dim"
                        :items="['KG', 'MT', 'LB']"
                        clearable></v-select>
                    </v-col>
                  </v-row>
                  <!-- <v-card-subtitle> Graphics </v-card-subtitle> -->
                  <v-row>
                    <v-col cols="6">
                      <v-switch v-model="dark_mode" label="Dark mode"></v-switch>
                      <v-select
                        label="Default graphics view"
                        v-model="default_view"
                        :items="[
                          { text: 'Top', value: 'top' },
                          { text: 'Side', value: 'side' },
                          { text: 'Front', value: 'front' },
                          { text: '3D', value: '3d' },
                          { text: '3d Perspective', value: '3d-perspective' },
                        ]"></v-select>
                      <v-select
                        label="Set item color based on property"
                        v-model="item_color_by_key"
                        clearable
                        :items="autocolor_keys"></v-select>
                      <v-select
                        class="mt-2"
                        v-model="itemLabels"
                        item-text="displayValue"
                        item-value="key"
                        :items="cargoLabels"
                        :menu-props="{ top: false, offsetY: true }"
                        multiple
                        attach
                        chips
                        label="Cargo labels"></v-select>
                      <v-checkbox
                        label="Display secondary equipments as cargoes"
                        v-model="show_nested_as_box"
                        hint="If enabled then secondary equipments, such as pallets, will be shown as a single cargo"
                        persistent-hint></v-checkbox>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel @click="goToPanel">
                <v-expansion-panel-header>Input table settings </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <input-table-settings
                    :isCompanySettings="isCompanySettings"></input-table-settings>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel @click="goToPanel">
                <v-expansion-panel-header>Default presets </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-select
                    v-model="defaultPresetMot"
                    v-bind:items="$list_types"
                    required
                    label="Select default preset for"></v-select>
                  <load-presets-table
                    :list_type="defaultPresetMot"
                    :showUpdatePreset="false"
                    :simpleMode="true"
                    :showUsePreset="true"
                    :isCompanySettings="isCompanySettings"
                    @selectedPreset="updateDefaultPreset($event)"
                    @close="showSelectPresetModal = false"></load-presets-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel @click="goToPanel">
                <v-expansion-panel-header>Loadplan settings</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="text-h6">Visible columns</p>
                  <loadplan-columns :isCompanySettings="isCompanySettings"></loadplan-columns>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- <v-dialog v-model="showSelectPresetModal" max-width="1400px">
      <v-card>
        <v-card-title>Select default preset</v-card-title>

        <v-card-text>
          <v-select
            v-model="defaultPresetMot"
            v-bind:items="$list_types"
            required
            label="For..."></v-select>
          <load-presets-table
            :list_type="defaultPresetMot"
            :showUpdatePreset="false"
            :simpleMode="true"
            :showUsePreset="true"
            :isCompanySettings="isCompanySettings"
            @selectedPreset="updateDefaultPreset($event)"
            @close="showSelectPresetModal = false"></load-presets-table>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-snackbar :timeout="5000" v-model="showSnackbar" bottom>
      {{ snackbarMessage }}
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { CompanySettings, UserPreferences } from '@/models/UserCompanyModel';
import itemProps, { CustomItemProperty } from '@/misc/itemProperties';
import InputTableSettings from './InputTableSettings.vue';
import LoadPresetsTable from './LoadPresetsTable.vue';
import { LoadConfiguration } from '@/models/LoadlistModel';
import LoadplanColumns from './LoadplanColumns.vue';
export default Vue.extend({
  props: {
    isCompanySettings: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
  },
  components: {
    InputTableSettings,
    LoadPresetsTable,
    LoadplanColumns,
  },
  data() {
    return {
      showSnackbar: false,
      snackbarMessage: '',
      showInputTableSettingsModal: false,
      showSelectPresetModal: false,
      defaultPresetMot: 'SEA',
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    cargoLabels(): { key: string; displayValue: string }[] {
      return [
        { key: 'sku', displayValue: 'SKU' },
        { key: 'label', displayValue: 'Name' },
        { key: 'class_id', displayValue: 'Class' },
        { key: 'lwh', displayValue: 'LxWxH' },
        { key: 'wt', displayValue: 'Weight' },
        { key: 'shipment_id', displayValue: 'Group/Consignment' },
        { key: 'priority', displayValue: 'Priority' },
      ];
    },
    enforced: {
      get(): boolean {
        return this.preferences.enforced;
      },
      set(value: boolean): void {
        this.update({ enforced: value });
      },
    },
    length_dim: {
      get(): string {
        return this.preferences.length_dim;
      },
      set(value: string): void {
        this.update({ length_dim: value });
      },
    },
    default_length_dim: {
      get(): string {
        return this.preferences.default_loadlist_length_dim;
      },
      set(value: string): void {
        this.update({
          default_loadlist_length_dim: value,
        });
      },
    },
    weight_dim: {
      get(): string {
        return this.preferences.weight_dim;
      },
      set(value: string): void {
        this.update({ weight_dim: value });
      },
    },
    default_weight_dim: {
      get(): string {
        return this.preferences.default_loadlist_weight_dim;
      },
      set(value: string): void {
        this.update({
          default_loadlist_weight_dim: value,
        });
      },
    },
    default_view: {
      get(): string {
        return this.preferences.default_view || '3d';
      },
      set(value: string): void {
        this.update({ default_view: value });
      },
    },
    itemLabels: {
      get(): string[] {
        return this.preferences.item_labels;
      },
      set(value: string[]): void {
        this.update({ item_labels: value });
      },
    },
    show_nested_as_box: {
      get(): boolean {
        return !!this.preferences.show_nested_as_box;
      },
      set(value: boolean): void {
        this.update({ show_nested_as_box: value });
      },
    },
    item_color_by_key: {
      get(): string {
        return this.preferences.item_color_by_key || null;
      },
      set(value: string): void {
        this.update({ item_color_by_key: value });
      },
    },
    preferences(): CompanySettings {
      if (!this.isCompanySettings && !this.miscStore.company_settings.enforced)
        return this.miscStore.user_preferences;
      return this.miscStore.company_settings;
    },
    custom_columns(): CustomItemProperty[] {
      return this.miscStore?.company_settings?.extra_columns || [];
    },
    dark_mode: {
      get(): boolean {
        return !!this.preferences.dark_mode;
      },
      set(value: boolean): void {
        this.$vuetify.theme.dark = !!value;
        this.update({ dark_mode: value });
      },
    },

    autocolor_keys(): { text: string; value: string }[] {
      return [
        ...itemProps
          .props()
          .filter((i) => ['shipment_id', 'class_id', 'destination', 'priority'].includes(i.key))
          .map((i) => {
            return { text: i.text, value: i.key };
          }),
        ...this.custom_columns.map((i) => {
          return { text: i.name, value: i.name };
        }),
      ];
    },
  },
  methods: {
    update(data: CompanySettings): void {
      if (!this.isCompanySettings)
        this.miscStore
          .updateMe({ preferences: data })
          .then((_) => {
            this.snackbarMessage = 'User settings updated';
            this.showSnackbar = true;
          })
          .catch((e) => {
            this.snackbarMessage = 'Could not update user settings';
            this.showSnackbar = true;
          });
      else
        this.miscStore
          .updateCompanySettings(data)
          .then((_) => {
            this.snackbarMessage = 'Company settings updated';
            this.showSnackbar = true;
          })
          .catch((e) => {
            this.snackbarMessage = 'Could not update company settings';
            this.showSnackbar = true;
          });
    },
    updateDefaultPreset(e: LoadConfiguration) {
      const default_presets = this.preferences.default_presets || {};
      default_presets[e.base_type] = e.id;
      this.update({
        default_presets,
      });
    },
    goToPanel(e: any) {
      setTimeout(() => this.$vuetify.goTo(e.target), 100);
    },
  },
});
</script>

<style scoped></style>
