<template>
  <div class="mb-4">
    <v-card flat>
      <v-card-title>
        <v-chip class="primary">1</v-chip>
        <span class="ml-2">Items</span>
      </v-card-title>
      <v-card-text v-if="rule.items">
        <item-selector-component v-model="rule.items" :items="items" :class_ids="class_ids" />
      </v-card-text>
    </v-card>

    <v-row>
      <v-icon x-large class="mx-auto">mdi-arrow-down-bold</v-icon>
    </v-row>
    <v-card flat class="mt-2">
      <v-card-title>
        <v-chip class="primary">2</v-chip>
        <span class="ml-2">Condition</span>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="rule.condition"
          :items="itemRules"
          :menu-props="{ maxHeight: '420' }"
          @change="
            () => {
              rule.value = undefined;
            }
          "
          label="Condition"></v-select>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-if="['max_qty', 'max_item_types'].includes(rule.condition)"
          label="Quantity"
          v-model.number="rule.value"
          min="0"
          step="1"
          type="number" />
        <length-input-field
          v-if="['max_height', 'max_stacking_height'].includes(rule.condition)"
          label="Height"
          :min="0"
          v-model="rule.value"></length-input-field>
        <v-select
          v-if="rule.condition === 'must_load_with_pattern'"
          :items="[
            { text: 'One block', value: 'one_block' },
            { text: 'Two block', value: 'two_block' },
            { text: 'Four block', value: 'four_block' },
            { text: 'No pattern building', value: 'one_by_one' },
          ]"
          v-model="rule.value"
          label="Pattern"
          hint="The higher block values includes the simpler variants. Two block can result in a one block solution"
          persistent-hint />
        <item-selector-component
          v-if="
            [
              'can_only_support',
              'cannot_support',
              'must_be_on_top_of',
              'must_be_loaded_before',
              'cannot_be_loaded_with',
            ].includes(rule.condition)
          "
          v-model="rule.value"
          :items="items"
          :class_ids="class_ids"
          :sub-selector="true" />
      </v-card-text>
    </v-card>
    <v-row>
      <v-icon x-large class="mx-auto">mdi-arrow-down-bold</v-icon>
    </v-row>
    <v-card flat class="mt-2">
      <v-card-title>
        <v-chip class="primary">3</v-chip>
        <span class="ml-2">Applicable to {{ typeName }}s</span>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="rule.in_containers_with_ids"
          :items="holds"
          item-text="name"
          item-value="id"
          :menu-props="{ maxHeight: '400' }"
          :placeholder="`Any ${typeName}s`"
          :label="`${capitalizedTypeName}s`"
          persistent-placeholder
          multiple>
          <template v-slot:selection="{ item, index }">
            <span v-if="index < rule.in_containers_with_ids.length - 2"
              >{{ item.name }},&nbsp;</span
            >
            <span v-else-if="index === rule.in_containers_with_ids.length - 2"
              >{{ item.name }} or&nbsp;</span
            >
            <span v-else>{{ item.name }}</span>
          </template>
        </v-select>
      </v-card-text>
    </v-card>
    <v-row class="text-right">
      <v-col>
        <v-btn class="mx-auto" color="error" @click="$emit('removeRule', ruleIndex)">
          <v-icon left>mdi-minus-circle</v-icon>Remove rule
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { HoldData, HoldInputItem, LoadRule } from '@/models/LoadlistModel';
import Vue, { PropType } from 'vue';
import ItemSelectorComponent from './ItemSelector.vue';
export default Vue.extend({
  components: { ItemSelectorComponent },
  name: 'load-rule',
  data: function () {
    return {
      rule: JSON.parse(JSON.stringify(this.value)) as LoadRule,
    };
  },
  props: {
    value: Object as PropType<LoadRule>,
    ruleIndex: Number,
    typeName: String,
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    class_ids: Array as PropType<string[]>,
    itemRules: Array as PropType<{ text: string; value: string }[]>,
  },
  watch: {
    rule: {
      handler(o): void {
        this.$emit('input', o);
      },
      deep: true,
    },
  },
  computed: {
    capitalizedTypeName(): string {
      return this.typeName[0].toUpperCase() + this.typeName.slice(1);
    },
  },
  mounted(): void {},
  methods: {},
});
</script>
