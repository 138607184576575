<template>
  <v-row>
    <v-col>
      <v-label v-if="label" class="mr-4">{{ label }}</v-label>
      <div class="d-flex">
        <v-select :items="units" v-model="unit" style="max-width: 140px; margin-top: 0"></v-select>
        <v-text-field
          style="margin-top: 0; margin-left: 2px"
          v-bind="$attrs"
          :value="displayValue"
          min="0"
          v-on="inputListeners"
          type="number"
          :error-messages="errors"
          :hint="`The factor used to calculate volumetric weight. Generally ranges between ${examples}`"></v-text-field>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
const FT_FACTOR = 0.453592 / 0.0283168;

import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'shipping-factor-input',
  data() {
    return {
      displayValue: undefined as number,
      errors: [] as string[],
      units: [
        { text: 'cm3/kg', value: 'metric-cm' },
        { text: 'kg/m3', value: 'metric-kg' },
        { text: 'cu in/lb', value: 'imperial-in' },
        { text: 'lb/cu ft', value: 'imperial-lb' },
      ],
      unit: ['IN', 'FT'].includes(useMiscStore().length_dim) ? 'metric-in' : 'metric-kg',
    };
  },
  props: {
    label: String,
    value: Number,
    sup: {
      type: Number,
      default: 1,
    },
    rules: Array as PropType<((v: number) => boolean | string)[]>,
  },
  computed: {
    examples(): string {
      switch (this.unit) {
        case 'metric-cm':
          return '4000-7000 cm3/kg';
        case 'metric-kg':
          return '142-250 kg/m3';
        case 'imperial-in':
          return '139-250 cu in/lb';
        default:
          return '7-12 lb/cu ft';
      }
    },

    inputListeners(): any {
      return {
        ...this.$listeners,
        input: (event: string) => {
          this.$emit('input', this.toInternalValue(event));
        },
        change: (event: string) => {
          this.$emit('change', this.toInternalValue(event));
        },
      };
    },
  },
  watch: {
    unit: {
      handler: function () {
        this.displayValue = Math.round(10 * this.fromSI(this.value)) / 10;
      },
      immediate: false,
    },
    value: {
      handler: function (a: number): void {
        if (a !== undefined && !isNaN(a)) {
          this.errors = (this.rules?.map((r) => r(a)).filter((v) => v !== true) as string[]) || [];
        }
        if (this.displayValue === undefined && !isNaN(this.value))
          this.displayValue = this.fromSI(this.value);
      },
      immediate: true,
    },
  },
  methods: {
    toInternalValue(valString: string): number | null {
      //Always round M to 3 decimals
      const valNumber = parseFloat(valString);
      if (!isNaN(valNumber)) {
        return Math.round(this.toSI(valNumber) * 1000) / 1000;
      }
      return null;
    },
    toSI(value: number): number {
      switch (this.unit) {
        case 'metric-cm':
          return 1000000 / value;
        case 'metric-kg':
          return value;
        case 'imperial-in':
          return 1000000 / (value * 36.127292);
        default:
          return value * FT_FACTOR;
      }
    },
    fromSI(value: number): number {
      switch (this.unit) {
        case 'imperial-lb':
          return value / FT_FACTOR;
        default:
          return this.toSI(value);
      }
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
