<template>
  <v-row>
    <v-col>
      <v-label class="mr-4">{{ label }}</v-label>
      <div class="d-flex align-center">
        <v-select :items="options" v-model="isPercentage" style="max-width: 140px"></v-select>
        <v-slider
          class="pt-8"
          v-if="isPercentage"
          v-model="percentageValue"
          thumb-label="always"
          :thumb-size="24"
          :thumb-color="percentageValue ? 'primary' : 'grey'"
          track-color="light-grey"
          :track-fill-color="percentageValue ? 'primary' : 'grey'"
          :max="35"
          :min="0"
          step="1"
          ticks="always"
          tick-size="1">
        </v-slider>
        <length-input-field
          style="margin-left: 2px"
          v-else
          :min="0"
          v-model="absoluteValue"></length-input-field>
      </div>
    </v-col>
    <v-col class="my-auto">
      <v-img
        class="mx-auto"
        width="200"
        :cover="false"
        src="@/assets/svg/explainers/allowed_overhang.svg" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'overhang-input',
  data() {
    return {
      options: [
        { text: 'Percentage', value: true },
        { text: 'Length', value: false },
      ],
      isPercentage: false,
      percentageValue: 0,
      absoluteValue: 0,
    };
  },
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: 'Allowed overhang',
    },
  },
  watch: {
    isPercentage(a: boolean) {
      this.$emit('input', 0);
    },
    percentageValue(a: number) {
      this.$emit('input', `${a}%`);
    },
    absoluteValue(a: number) {
      this.$emit('input', a);
    },
  },
  mounted() {
    if (String(this.value)?.includes('%')) {
      this.isPercentage = true;
      this.percentageValue = Number(String(this.value).split('%')[0]);
    } else if (!isNaN(Number(this.value))) {
      this.absoluteValue = Number(this.value);
    }
  },
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
