<template>
  <div>
    <v-expansion-panels focusable>
      <v-expansion-panel
        v-for="company in companies"
        @change="updateSelectedCompany(company)"
        :key="company.id">
        <v-expansion-panel-header>
          <span>
            <v-icon>mdi-domain</v-icon>
            {{ company.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="elevation-1">
          <calculation-stats
            v-if="selected_company"
            :company="selected_company.id"></calculation-stats>
          <v-data-table
            v-if="user.is_admin"
            v-bind:items="users"
            hide-default-footer
            class="elevation-2 mt-3"
            no-data-text="No users"
            :items-per-page="100">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Users</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="showAddUserModal" width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mb-2"
                      :disabled="createUsersDisabled"
                      @click="error_messages = []"
                      v-on="on">
                      <v-icon>mdi-plus</v-icon>Add
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">Create user</v-card-title>
                    <v-card-text>
                      <v-alert type="info" outlined
                        >An email will be sent to the user with below login credentials</v-alert
                      >
                      <v-form>
                        <v-text-field
                          label="Username"
                          :error-messages="new_user_errors.username"
                          v-model="new_user.username"
                          min="4"
                          maxlength="30"
                          required></v-text-field>
                        <v-text-field
                          label="Password (leave empty to auto generate)"
                          :error-messages="new_user_errors.password"
                          v-model="new_user.password"
                          maxlength="30"
                          autocomplete="new-password"
                          type="password"></v-text-field>
                        <v-text-field
                          label="Email"
                          :error-messages="new_user_errors.email"
                          v-model="new_user.email"
                          min="10"
                          maxlength="80"
                          required
                          type="email"></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click.stop="showAddUserModal = false">Close</v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        :disabled="new_user.username.length === 0 || new_user.email.length === 0"
                        @click.stop="createUser"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-dialog v-model="showInviteModal" width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mb-2"
                      :disabled="createUsersDisabled"
                      @click="error_messages = []"
                      v-on="on">
                      <v-icon>mdi-email</v-icon>invite
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">Invite user</v-card-title>
                    <v-card-text>
                      <v-alert type="info" outlined
                        >An email will be sent to the user with an invitation link</v-alert
                      >
                      <v-text-field
                        label="New Username (leave empty to be same as email)"
                        :error-messages="new_user_errors.username"
                        v-model="new_user.username"
                        type="username"></v-text-field>
                      <v-text-field
                        label="Email"
                        :error-messages="new_user_errors.email"
                        v-model="new_user.email"
                        min="10"
                        maxlength="80"
                        required
                        type="email"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click.stop="showInviteModal = false">Close</v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        :disabled="new_user.email.length === 0"
                        @click.stop="inviteUser"
                        >Send</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="showDeleteModal" width="600">
                  <v-card>
                    <v-card-title class="text-h5">Delete user</v-card-title>
                    <v-card-text>
                      <v-alert type="error" outlined
                        >Do you really want to delete the user? Select a user if you want to
                        transfer created load lists. All load lists created by the user will be
                        deleted if no user is selected for transfer.</v-alert
                      >
                      <v-data-table
                        :headers="transferTableHeaders"
                        :items="transferTableUsers"
                        show-select
                        :single-select="true"
                        hide-default-footer
                        hide-default-header
                        class="elevation-2 mt-3"
                        no-data-text="No users"
                        v-model="selectedTransferUsers"
                        item-key="email"
                        :items-per-page="100">
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        @click.stop="
                          selected_user = null;
                          selectedTransferUsers = [];
                          showDeleteModal = false;
                        "
                        >Cancel</v-btn
                      >
                      <v-spacer />
                      <v-btn
                        color="error"
                        @click.stop="
                          deleteUser(selected_user, selectedTransferUsers);
                          showDeleteModal = false;
                        "
                        >{{ deleteButtonText }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:header>
              <thead>
                <tr>
                  <th class="text-left">Username</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Last login</th>
                  <th class="text-left">
                    Isolated
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>mdi-help-circle</v-icon>
                      </template>
                      <span
                        >Isolated users can only see their own loadlists. Loadlists of isolated
                        users will automatically be created in a project with their username, and
                        can be accesses by non isolated users</span
                      >
                    </v-tooltip>
                  </th>
                  <th class="text-left">
                    Editor
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>mdi-help-circle</v-icon>
                      </template>
                      <span
                        >Users with Editor rights are able to create and modify equipment, presets
                        and projects</span
                      >
                    </v-tooltip>
                  </th>
                  <th class="text-left">
                    Developer
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>mdi-help-circle</v-icon>
                      </template>
                      <span
                        >Users with Developer rights are able to use the API - useful when doing
                        integrations</span
                      >
                    </v-tooltip>
                  </th>
                  <th class="text-left">
                    Administrator
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>mdi-help-circle</v-icon>
                      </template>
                      <span
                        >Users with Administrator rights are able to perform user management and
                        handle company details as well as subscriptions</span
                      >
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.username }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.last_login }}</td>
                <td>
                  <v-checkbox
                    :input-value="item.isolated"
                    @change="updateUser({ id: item.id, isolated: $event })"></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    :input-value="item.groups.includes('Editor')"
                    @change="
                      !!$event
                        ? updateUser({
                            id: item.id,
                            groups: ['Editor', ...item.groups],
                          })
                        : updateUser({
                            id: item.id,
                            groups: item.groups.filter((i) => i != 'Editor'),
                          })
                    "></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    :input-value="item.groups.includes('Developer')"
                    @change="
                      !!$event
                        ? updateUser({
                            id: item.id,
                            groups: ['Developer', ...item.groups],
                          })
                        : updateUser({
                            id: item.id,
                            groups: item.groups.filter((i) => i != 'Developer'),
                          })
                    "></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    :disabled="item.id == user.id"
                    :input-value="item.groups.includes('Administrator')"
                    @change="
                      !!$event
                        ? updateUser({
                            id: item.id,
                            groups: ['Administrator', ...item.groups],
                          })
                        : updateUser({
                            id: item.id,
                            groups: item.groups.filter((i) => i != 'Administrator'),
                          })
                    "></v-checkbox>
                </td>

                <td>
                  <v-btn
                    icon
                    :disabled="item.id === user.id"
                    v-on:click.native="
                      selected_user = item;
                      showDeleteModal = true;
                    "
                    ><v-icon>fa-trash</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-data-table
            v-if="user.is_admin"
            v-bind:items="invites"
            hide-default-footer
            class="elevation-2 mt-3"
            no-data-text="No pending invitations"
            :items-per-page="100">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Pending invitations</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:header>
              <thead>
                <tr>
                  <th class="text-left">Username</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.username }}</td>
                <td>{{ item.email }}</td>
                <td v-if="item.expired">Expired</td>
                <td v-else>Active</td>
                <td>
                  <v-btn icon v-on:click.native="removeInvite(item.email)"
                    ><v-icon>fa-trash</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-alert
            v-if="users.length >= maxNumberOfSeats"
            class="mt-4"
            color="info"
            icon="fa-lightbulb"
            outlined
            >Contact us if you want to add more than {{ maxNumberOfSeats }} users</v-alert
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      Information updated
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import {
  CompanyAdminUserSerializer,
  CompanyListSerializer,
  CompanyStoreTemplate,
  User,
} from '@/models/UserCompanyModel';
import CalculationStats from './Custom/CalculationStats.vue';
const MAX_NUMBER_OF_USERS = 5;

interface InvitationPropertiesSerializer {
  groups: string[];
  isolated: boolean;
}
interface InvitationSerializer {
  username: string;
  email: string;
  properties?: InvitationPropertiesSerializer;
}

export default Vue.extend({
  name: 'usermanagement',
  components: {
    CalculationStats,
  },
  data() {
    return {
      isLoading: false,
      new_user: {
        username: '',
        password: '',
        email: '',
      },
      new_user_errors: {
        username: [],
        password: [],
        email: [],
      },
      error_messages: [] as string[],
      showAddUserModal: false,
      showInviteModal: false,
      showDeleteModal: false,
      showSnackbar: false,
      companies: [] as CompanyListSerializer[],
      users: [] as CompanyAdminUserSerializer[],
      invites: [] as InvitationSerializer[],
      selected_user: null as CompanyAdminUserSerializer,
      selected_company: null as CompanyListSerializer,
      selectedTransferUsers: [] as CompanyAdminUserSerializer[],
      transferTableHeaders: [
        { text: 'User name', value: 'username' },
        { text: 'Email', value: 'email' },
      ],
    };
  },
  mounted() {
    API.getCompanies().then((response) => {
      this.companies = response.data;
    });
  },
  computed: {
    ...mapStores(useMiscStore),
    maxNumberOfSeats(): number {
      return this.selected_company?.seats;
    },
    createUsersDisabled(): boolean {
      return this.users.length + this.invites.length >= this.maxNumberOfSeats || this.lite_version;
    },
    transferTableUsers(): CompanyAdminUserSerializer[] {
      return this.users.filter((u) => u.email !== this.selected_user?.email);
    },
    deleteButtonText(): string {
      return this.selectedTransferUsers.length
        ? 'Transfer load lists and delete user'
        : 'Delete load lists and user';
    },
    company(): CompanyStoreTemplate {
      return this.miscStore.company;
    },
    user(): User {
      return this.miscStore.user;
    },
    lite_version(): boolean {
      return this.miscStore.lite_version;
    },
  },
  methods: {
    updateSelectedCompany(company: CompanyListSerializer): void {
      if (company !== null) {
        this.selected_company = company;
        this.getUsers();
        this.getInvites();
      }
    },
    getUsers(): void {
      API.getUsers(this.selected_company.id).then((response) => {
        this.users = response.data;
      });
    },
    getInvites(): void {
      API.getInvites(this.selected_company.id).then((response) => {
        this.invites = response.data;
      });
    },
    updateUser(user: CompanyAdminUserSerializer): void {
      API.updateUserFromCompany(this.selected_company.id, user.id, user)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    removeInvite(email: string): void {
      API.deleteInvite(this.selected_company.id, email)
        .then((response) => {
          this.getInvites();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(
      user: CompanyAdminUserSerializer,
      transferUsers: CompanyAdminUserSerializer[]
    ): void {
      const transferUserId = transferUsers.length ? transferUsers[0].id : null;
      console.log(user);
      API.deleteUserFromCompany(this.selected_company.id, user.id, transferUserId)
        .then((response) => {
          this.getUsers();
          this.showAddUserModal = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createUser(): void {
      API.addUser(this.selected_company.id, this.new_user)
        .then((response) => {
          this.getUsers();
          this.showAddUserModal = false;

          this.new_user_errors.username = [];
          this.new_user_errors.password = [];
          this.new_user_errors.email = [];
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.new_user_errors.username = [];
            this.new_user_errors.password = [];
            this.new_user_errors.email = [];

            if ('username' in error.response.data) {
              this.new_user_errors.username = error.response.data.username;
            }
            if ('password' in error.response.data) {
              this.new_user_errors.password = error.response.data.password;
            }
            if ('email' in error.response.data) {
              this.new_user_errors.email = error.response.data.email;
            }
          }
        });
    },
    inviteUser(): void {
      API.inviteUser({
        company: this.selected_company.id,
        email: this.new_user.email,
        username: this.new_user.username,
      })
        .then((response) => {
          this.showInviteModal = false;
          this.new_user_errors.email = [];
          this.getInvites();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.new_user_errors.email = [];
            if ('email' in error.response.data) {
              this.new_user_errors.email = error.response.data.email;
            }
            if ('username' in error.response.data) {
              this.new_user_errors.username = error.response.data.username;
            }
          }
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type='file'] {
  display: none;
}
</style>
