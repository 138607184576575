<template>
  <div
    class="border-r"
    style="
      border-right: 1px solid lightgray;
      position: relative;
      margin-top: -200px;
      padding-top: 200px;
      overflow-x: scroll;
      height: 101vh;
      margin-bottom: -125px;
      margin-left: -50px;
      padding-left: 50px;
    "
    v-resize.quiet="setScrollheight">
    <v-navigation-drawer
      left
      clipped
      width="400"
      hide-overlay
      floating
      :value="show"
      id="projectsDrawer"
      permanent
      ref="navigation">
      <v-virtual-scroll :items="loadlist_groups" item-height="60" :height="`${scrollheight}px`">
        <template v-slot:default="{ item }">
          <v-list-item
            :key="item.id"
            :value="activeGroupId === item.id"
            @click="updateActiveGroup(item.id)"
            @dragover="$event.preventDefault()"
            @dragenter="dragEnter($event)"
            @dragleave="dragLeave($event)"
            @drop="dropList($event, item.id)">
            <v-list-item-content style="pointer-events: none">
              <v-list-item-title
                :class="{ 'font-weight-bold': activeGroupId === item.id }"
                style="text-overflow: ellipsis">
                <v-icon v-if="item.private" :color="item.data.color">mdi-folder-key-outline</v-icon>
                <v-icon v-else :color="item.data.color">mdi-folder-outline</v-icon>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar v-if="!readonly">
              <v-menu :nudge-width="100">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="updateActiveGroup(item.id)">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editProject(item)">
                    <v-list-item-title>
                      <v-icon left>mdi-pen</v-icon>Edit project
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="showDeleteProjectModal = true">
                    <v-icon left color="error">mdi-delete</v-icon>Delete project
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-avatar>
          </v-list-item>
        </template>
      </v-virtual-scroll>

      <v-divider></v-divider>
      <v-list-item
        :value="!activeGroupId"
        @click="updateActiveGroup(undefined)"
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        @dragover="$event.preventDefault()"
        @drop="dropList($event, undefined)">
        <v-list-item-content style="pointer-events: none">
          <v-list-item-title :class="{ 'font-weight-bold': !activeGroupId }">
            <v-icon left>mdi-folder-account-outline</v-icon>All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <!-- Project modals -->
    <v-dialog v-model="showEditProjectModal" width="1000">
      <v-card>
        <v-card-title class="text-h5"
          >{{ projectTemplate.id ? 'Edit' : 'Create new' }} project</v-card-title
        >
        <v-card-text>
          <v-text-field
            label="Name"
            v-model="projectTemplate.name"
            :rules="nameRules"
            required></v-text-field>
          <v-row>
            <v-col>
              <v-card-text class="d-flex pl-0">
                <span class="mr-4 text-body-1">Color</span>
                <input type="color" v-model="projectTemplate.data.color" />
              </v-card-text>
            </v-col>
            <v-col v-if="!projectTemplate.id || projectTemplate.owner === user.id">
              <v-checkbox
                v-model="projectTemplate.private"
                label="Private"
                hint="A private project and it's load plans will only be accessible to this user."
                persistent-hint></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col>
              <v-select
                v-model="projectTemplate.data.default_type"
                @input="projectTemplate.data.default_preset = null"
                v-bind:items="$list_types"
                label="Select default list type"
                required></v-select>
            </v-col>
            <v-col>
              <v-text-field
                @mousedown="showSelectPresetModal = true"
                :disabled="!projectTemplate.data.default_type"
                :value="
                  projectTemplate.data.default_preset
                    ? projectTemplate.data.default_preset.name
                    : ''
                "
                label="Select default preset"></v-text-field>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-menu
                v-model="showStartDatePicker"
                :close-on-content-click="false"
                :return-value.sync="projectTemplate.data"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="projectTemplate.data.start_date"
                    label="Start date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable></v-text-field>
                </template>
                <v-date-picker v-model="projectTemplate.data.start_date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showStartDatePicker = false"> Close </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field
                :value="toRruleText()"
                @input="projectTemplate.data.rrule = toRruleString($event)"
                label="Reccuring">
                <template v-slot:append>
                  <v-icon color="green" v-if="!!projectTemplate.data.rrule"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon color="red" v-else>mdi-alert-circle</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-alert type="info" outlined>
            Reccuring examples:
            <ul></ul>
            <li>Every weekday</li>
            <li>Every 2 weeks on Tuesday</li>
            <li>Every week on Monday, Wednesday</li>
            <li>Every week on Monday, Wednesday until March 2, 2023</li>
            <li>Every month on the 2nd last Friday for 7 times</li>
            <li>Every 6 months</li>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showEditProjectModal = false">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="projectTemplate.name.length < 1 && projectTemplate.name.length > 50"
            @click.stop="saveProject"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteProjectModal" v-if="activeGroup" width="600">
      <v-card>
        <v-card-title class="text-h5"> Delete project {{ activeGroup.name }} </v-card-title>
        <v-card-text>
          Do you really want to delete the project "{{ activeGroup.name }}"?
          <strong>All containing loadlists and loadplans will also be deleted</strong>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showDeleteProjectModal = false">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click.stop="
              showDeleteProjectModal = false;
              showConfirmDeleteProjectModal = true;
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDeleteProjectModal" width="600">
      <v-card>
        <v-card-title class="text-h5">Confirm Delete project</v-card-title>
        <v-card-text>
          <strong>Are you sure?</strong>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showConfirmDeleteProjectModal = false">Cancel</v-btn>
          <v-spacer />
          <v-btn color="error" @click.stop="deleteProject">Yes, delete it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSelectPresetModal" max-width="1400px">
      <load-presets-table-component
        :list_type="projectTemplate.data.default_type"
        :showUpdatePreset="false"
        :simpleMode="true"
        :showUsePreset="true"
        @selectedPreset="updateDefaultPreset($event)"
        @close="showSelectPresetModal = false"></load-presets-table-component>
    </v-dialog>
    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      {{ snackbarInfo }}
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import LoadPresetsTableComponent from '@/components/Custom/LoadPresetsTable.vue';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { LoadlistGroup, LoadConfiguration, PresetInfo } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { APIResponse } from '@/models/APIModel';
import { RRule } from 'rrule';
import { User } from '@/models/UserCompanyModel';
import { getSerializerError } from '@/misc/errorUtils';
export default Vue.extend({
  name: 'projects',
  components: {
    LoadPresetsTableComponent,
  },
  props: {
    groupId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      activeGroupId: null as number | null,
      isLoading: false,
      showEditProjectModal: false,
      showDeleteProjectModal: false,
      showConfirmDeleteProjectModal: false,
      showSelectPresetModal: false,
      scrollheight: 600,
      rawRrule: null,
      showStartDatePicker: false,
      snackbarInfo: null,
      showSnackbar: false,
      dragTarget: null,
      projectTemplate: {
        id: null,
        name: '',
        private: false,
        owner: null,
        data: {
          color: null,
          rrule: null,
          default_type: null,
          default_preset: null,
        },
      } as LoadlistGroup,
      nameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => v.length <= 50 || 'Name must be less than 50 characters',
      ],
    };
  },
  computed: {
    ...mapStores(useLoadlistStore),
    activeGroup(): LoadlistGroup {
      return this.loadlist_groups.find((f: LoadlistGroup) => f.id === this.activeGroupId);
    },
    loadlist_groups(): LoadlistGroup[] {
      return this.loadlistStore.loadlistGroups;
    },
    user(): User {
      return useMiscStore().user;
    },
  },
  mounted() {
    this.setScrollheight();
  },
  methods: {
    setScrollheight(): void {
      const e = (this.$refs.navigation as any).$el as HTMLElement;
      this.scrollheight = e.clientHeight - 60;
    },
    updateActiveGroup(groupId: number): void {
      this.activeGroupId = groupId;
      this.$emit('activeGroupChange', groupId);
    },
    updateDefaultPreset(preset: LoadConfiguration): void {
      this.projectTemplate.data.default_preset = {
        id: preset.id,
        name: preset.name,
      } as PresetInfo;
    },
    saveProject(): void {
      this.isLoading = true;
      const func = this.projectTemplate.id ? API.updateLoadlistGroup : API.createLoadlistGroup;
      func(this.projectTemplate)
        .then((data: APIResponse) => {
          this.syncGroups();
          this.setEmptyProjectTemplate();
          this.showEditProjectModal = false;
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.snackbarInfo = getSerializerError(error.response.data);
          } else {
            this.snackbarInfo = 'Unknown error when saving';
            console.log(error);
          }
          this.showSnackbar = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteProject(): void {
      this.isLoading = true;
      this.showConfirmDeleteProjectModal = false;
      API.deleteLoadlistGroup(this.activeGroupId)
        .then((data: APIResponse) => {
          this.updateActiveGroup(null);
          this.syncGroups();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    dragEnter(event: DragEvent): void {
      this.dragTarget = event.target;
      this.dragTarget.focus();
    },
    dragLeave(event: DragEvent): void {
      if (this.dragTarget == event.target) {
        this.dragTarget.blur();
        this.dragTarget = null;
      }
    },
    dropList(event: DragEvent, groupId: number): void {
      this.dragTarget?.blur();
      this.isLoading = true;
      const droppedLoadlistId = event.dataTransfer.getData('text');
      API.patchLoadlist({
        id: droppedLoadlistId,
        group: groupId ? groupId.toString() : '',
      })
        .then((data: APIResponse) => {
          this.updateActiveGroup(this.activeGroupId);
          this.$emit('loadlistAddedToGroup');

          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
        });
    },
    syncGroups(): void {
      this.loadlistStore.getLoadlistGroups();
    },
    toRruleText(): string {
      if (!this.rawRrule) return null;
      try {
        return this.rawRrule.toText();
      } catch (error) {
        return null;
      }
    },
    newProject(): void {
      this.setEmptyProjectTemplate();
      this.showEditProjectModal = true;
    },
    setEmptyProjectTemplate(): void {
      this.projectTemplate = {
        id: null,
        name: '',
        private: false,
        owner: null,
        data: {
          color: null,
          rrule: null,
          default_type: null,
          default_preset: null,
        },
      };
      this.rawRrule = null;
    },
    editProject(item: LoadlistGroup): void {
      this.projectTemplate = JSON.parse(JSON.stringify(item));
      this.showEditProjectModal = true;
      try {
        this.rawRrule = RRule.fromString(item?.data?.rrule);
      } catch (error) {
        this.rawRrule = null;
      }
    },
    toRruleString(input: string): string {
      try {
        return RRule.fromText(input).toString();
      } catch (error) {
        return null;
      }
    },
  },
  watch: {
    groupId: {
      handler: function (groupId: number): void {
        this.activeGroupId = groupId;
      },
      immediate: true,
    },
  },
});
</script>

<style scoped>
input::-webkit-color-swatch-wrapper {
  padding: 0;
}
input::-webkit-color-swatch {
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
input::-moz-color-swatch {
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
</style>
