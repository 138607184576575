<template>
  <v-container>
    <v-dialog v-model="show" width="410">
      <v-card>
        <v-card-title class="text-h5">Payment method</v-card-title>
        <v-card-text class="pb-0">
          <p>Please select your preferred payment method.</p>
          <div>
            <v-btn class="primary" @click="$emit('useCredit')">
              <v-icon left>mdi-credit-card</v-icon>
              Credit card
            </v-btn>
          </div>
          <div>
            <v-btn class="primary display-block mt-4" @click="showBankTransferModal = true">
              <v-icon left>mdi-bank</v-icon>
              Bank transfer
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showBankTransferModal" width="500">
      <v-card>
        <v-card-title class="text-h5">Bank transfer</v-card-title>
        <v-card-text class="pb-0">
          <p>
            If you want us to issue an invoice payable through bank-transfer - drop us an email with
            your company details to
            <a :href="bankTransferEmailTemplate">support@cargo-planner.com</a>.
          </p>
          <p>Once the request has been sent, we will get back to you as soon as possible.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showBankTransferModal = false">Close</v-btn>
          <v-spacer />
          <v-btn class="primary ml-auto" :href="bankTransferEmailTemplate"> Send Email </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { CompanySerializer, SubscriptionPlan } from '@/models/UserCompanyModel';
export default Vue.extend({
  props: {
    visible: Boolean,
    companyData: Object as PropType<CompanySerializer>,
    selectedPlan: Object as PropType<SubscriptionPlan>,
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    bankTransferEmailTemplate(): string {
      const { name, vat, street_name, postal_code, city, country } = this.companyData;

      const planName = this.selectedPlan?.product?.name ?? '';
      return `mailto:support@cargo-planner.com
      ?subject=Invoice request - CargoPlanner
      &body=Company: ${name || ''}${'%0D%0A'}VAT: ${vat || ''}${'%0D%0A'}Street name: ${
        street_name || ''
      }${'%0D%0A'}Postal code: ${
        postal_code || ''
      }${'%0D%0A'}City: ${city || ''}${'%0D%0A'}Country: ${
        country || ''
      }${'%0D%0A'}Plan: ${planName}`;
    },
  },
  data() {
    return {
      showBankTransferModal: false,
    };
  },
  methods: {},
});
</script>

<style scoped></style>
