<template>
  <div v-if="set" class="mb-4" style="page-break-before: always">
    <!-- This should only run on the first container of a set with more than 1 container -->
    <div>
      <p class="set-title text-h4">{{ setName }}: {{ augmentedSet.set.name }}</p>
      <scene-component
        :set="augmentedSet"
        :key="set.uuid"
        :canvas-width="800"
        :canvas-height="400"
        :custom-view-settings="{
          view: '3d-ortho',
        }"
        @renderDone="sceneRendered = true">
        <template v-slot:annotations="slotProps">
          <annotations-component
            v-if="sceneRendered"
            :showAnnotationControls="!locked && !readonly"
            :annotations="set.annotations"
            :uuid="set.uuid"
            :sceneDiv="slotProps.sceneDiv"></annotations-component>
        </template>
      </scene-component>
      <v-alert class="text-subtitle-1" type="warning" outlined
        >Cargo-Planner {{ setName }}s is a visual guide, and does not consider the balance or weight
        distribution of the {{ setName.toLowerCase() }} as a whole</v-alert
      >
      <v-card>
        <v-card-text>
          <v-row dense style="white-space: nowrap">
            <v-col>
              <p class="text-h6">{{ setName }}</p>
              <table>
                <tbody>
                  <tr>
                    <td>Payload:</td>
                    <td>{{ augmentedSet.payload | toWeight }}</td>
                  </tr>
                  <tr>
                    <td>Max Volume:</td>
                    <td>{{ augmentedSet.maxVolume | toVolume }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>

            <v-col>
              <p class="text-h6">Utilization</p>
              <table>
                <tbody>
                  <tr>
                    <td>Items:</td>
                    <td>
                      {{ augmentedSet.items_count }}
                    </td>
                  </tr>
                  <tr v-if="loadplan.settings.shipping_factor">
                    <td class="pr-2">Chargeable WT:</td>
                    <td>
                      {{
                        augmentedSet.chargableWeight(loadplan.settings.shipping_factor) | toWeight
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Net Wt:</td>
                    <td>
                      {{ augmentedSet.weight | toWeight }} ({{
                        augmentedSet.weightUtilization | percentage
                      }}
                      %)
                    </td>
                  </tr>
                  <tr v-if="augmentedSet.tare > 0">
                    <td>Gross Wt:</td>
                    <td>
                      {{ augmentedSet.grossWeight | toWeight }}
                    </td>
                  </tr>

                  <tr>
                    <td>Volume:</td>
                    <td>
                      {{ augmentedSet.volume | toVolume }}
                      <span v-if="augmentedSet.volumeUtilization">
                        ({{ augmentedSet.volumeUtilization | percentage }}
                        %)
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col>
              <p class="text-h6">Equipment</p>
              <table>
                <tbody>
                  <tr v-for="eq in augmentedSet.equipmentSummary()" :key="eq.name">
                    <td>{{ eq.name }}:</td>
                    <td>
                      {{ eq.count }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import annotationsComponent from '@/components/Custom/Annotations.vue';
import { Set } from '@/models/SetsModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { Loadlist, Loadplan } from '@/models/LoadlistModel';
import { AugmentedSet } from '@/models/augmented/set';

export default Vue.extend({
  name: 'set-display',
  components: {
    sceneComponent,
    annotationsComponent,
  },
  props: {
    set: Object as PropType<Set>,
    locked: Boolean,
  },
  data() {
    return {
      sceneRendered: false,
    };
  },
  computed: {
    ...mapStores(useLoadlistStore),
    readonly(): boolean {
      return this.loadlistStore.readonly;
    },
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    augmentedSet(): AugmentedSet {
      return new AugmentedSet(this.set, this.loadplan.holds);
    },
  },
});
</script>
