import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"md":"8"}},[_c(VSelect,{attrs:{"items":_vm.$list_types,"value":_vm.currentType,"hide-details":"","outlined":"","dense":""},on:{"input":_vm.changeListType},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c(VIcon,[_vm._v(" "+_vm._s(`$vuetify.icons.${item.value}`))]),_vm._v("   "+_vm._s(item.text)+" ")]}}])})],1),(_vm.sets_enabled)?_c(VCol,[_c(VSwitch,{attrs:{"label":'Show sets'},model:{value:(_vm.showSets),callback:function ($$v) {_vm.showSets=$$v},expression:"showSets"}})],1):_vm._e(),_c(VCol,[_c(VTooltip,{attrs:{"bottom":"","disabled":_vm.user.is_editor},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c(VBtn,{attrs:{"disabled":!_vm.user.is_editor,"color":"success","ripple":false,"block":""},on:{"click":function($event){_vm.$route.name === 'sets'
                  ? _vm.$router.push({
                      name: 'modify_set',
                      params: { type: _vm.currentType },
                    })
                  : _vm.$router.push({
                      name: 'modify_hold',
                      params: { type: _vm.currentType },
                    })}}},[_c(VIcon,[_vm._v("mdi-plus-circle")]),_vm._v(" Create new ")],1)],1)]}}])},[_c('span',[_vm._v("You need to be a Administrator to perform this action")])])],1)],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }