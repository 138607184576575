<template>
  <v-container>
    <v-row>
      <v-col cols="10" offset="1" sm="6" offset-sm="3">
        <v-card>
          <v-card-title>
            <img
              style="width: 100%; max-width: 300px; margin-left: auto; margin-right: auto"
              src="../../assets/svg/logo_black.svg" />
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="reset_confirm" autocomplete="on" id="reset-confirm-form">
              <v-text-field
                label="New password"
                v-model="new_password1"
                placeholder=" "
                type="password"
                autocomplete="current-password"></v-text-field>
              <v-text-field
                label="New password (again)"
                v-model="new_password2"
                placeholder=" "
                type="password"
                autocomplete="current-password"></v-text-field>
            </v-form>
            <v-alert v-if="formSent" outlined type="success">
              <p>
                Your password is now updated.
                <router-link to="/login/">Login here</router-link>
              </p>
            </v-alert>
            <v-alert v-if="errorMessages.length" outlined type="error">
              <ul>
                <li v-for="message in errorMessages" :key="message">
                  {{ message }}
                </li>
              </ul>
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              color="primary"
              type="submit"
              :loading="isLoading"
              :disabled="
                new_password1.length < 6 || new_password1 != new_password2 || isLoading || formSent
              "
              form="reset-confirm-form"
              >Set new password</v-btn
            >
          </v-card-actions>
          <v-card-text class="text-center">
            <p>
              Back to login
              <router-link to="/login/">Click here</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import API from '@/API';
import Vue from 'vue';

export default Vue.extend({
  name: 'password_reset',
  data() {
    return {
      isLoading: false,
      new_password1: '',
      new_password2: '',
      formSent: false,
      errorMessages: [],
    };
  },
  computed: {},
  methods: {
    reset_confirm() {
      this.isLoading = true;
      API.passwordResetConfirm({
        new_password1: this.new_password1,
        new_password2: this.new_password2,
        uid: this.$route.params.uid,
        token: this.$route.params.token,
      })
        .then(() => {
          this.isLoading = false;
          this.formSent = true;
        })
        .catch((e) => {
          if (e?.response?.data) {
            this.errorMessages = Object.values(e.response.data).flat();
          }
          this.isLoading = false;
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
