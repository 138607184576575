export default {
  equalObjects(
    first: any,
    second: any,
    skip_fields: string[] = [],
    false_is_null = false
  ): boolean {
    try {
      const b = new Map(Object.entries(second || {}));
      const equalValues = !Object.entries(first || {})
        .filter(([key, _]) => !skip_fields.includes(key))
        .some(([key, val]) => {
          const bVal = b.get(key) as any;
          b.delete(key);
          if (this.nullOrUndefined(val, false_is_null)) {
            // this value should also be nullish (but not 0 or false)
            return !this.nullOrUndefined(bVal, false_is_null);
          }
          if (val instanceof Object && bVal) {
            return !this.equalObjects(val, bVal, skip_fields);
          }
          return (
            bVal !== val &&
            (this.nullOrUndefined(bVal, false_is_null) || bVal.toString() !== val.toString())
          );
        });

      // we don't need to compare these values
      skip_fields.forEach((key) => b.delete(key));

      // we are not interested in null fields that doesn't exist in the other:
      const cleaned = new Map(
        [...b.entries()].filter(([_, val]) => !this.nullOrUndefined(val, false_is_null))
      );
      return equalValues && cleaned.size == 0;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  nullOrUndefined(val: any, false_is_null: boolean): boolean {
    return val === null || val === undefined || (false_is_null && val === false);
  },
};
