import '@fortawesome/fontawesome-free/css/all.css';
import '@mdi/font/css/materialdesignicons.css';
import 'typeface-roboto/index.css';
import Vue from 'vue';
import router from './router';
import App from './App.vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import dimTools from './misc/dimTools';
import filters from './filters/index';
import Vuetify from 'vuetify/lib';
import WeightInputField from '@/components/Custom/WeightInputField.vue';
import LengthInputField from '@/components/Custom/LengthInputField.vue';
import RotationInputField from '@/components/Custom/RotationInputField.vue';
import TourComponent from '@/components/Custom/Tour.vue';
import IconComponent from '@/components/Custom/SvgIcon.vue';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';
import { useMiscStore } from './stores/miscStore';

const debug = process.env.NODE_ENV !== 'production';
const CARGO_PLANNER_BLUE = '#4678b2';
const urlParams = new URLSearchParams(window.location.search);
const inIframe = !(window === window.parent);
// If in Iframe mode - use provided primary color or default one
const PRIMARY_COLOR =
  inIframe && urlParams.get('primary') ? urlParams.get('primary') : CARGO_PLANNER_BLUE;

declare global {
  interface Window {
    chatwootSDK: any;
    bugsnagClient: any;
    clipboardData: any;
    $chatwoot: any;
    cpl: {
      $vueBus: any;
      renderCount: number;
      currentRendering: number;
    };
  }
}

Vue.prototype.$hideChatWidget = () => {
  if (window?.$chatwoot) window.$chatwoot.toggleBubbleVisibility('hide');
};
Vue.prototype.$populateChatUser = () => {};

if (!debug) {
  window.bugsnagClient = bugsnag({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    collectUserIp: false,
    notifyReleaseStages: ['production'],
  });
  window.bugsnagClient.use(bugsnagVue, Vue);

  // Chatwoot stuff
  if (!inIframe) {
    (function (d, t) {
      const BASE_URL = 'https://chatwoot.cargo-planner.com';
      const g = d.createElement(t) as any;
      const s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + '/packs/js/sdk.js';
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: 'bz8YjkcG7eR3p8cmvrmCruJo',
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');

    window.addEventListener('afterprint', function (event) {
      window.$chatwoot.toggleBubbleVisibility('show');
    });

    // TODO check this
    window.addEventListener('chatwoot:ready', function () {
      if (miscStore.is_authenticated) {
        Vue.prototype.$populateChatUser(
          miscStore.user?.id,
          miscStore.user?.email,
          miscStore.company?.name
        );
      }
    });

    Vue.prototype.$populateChatUser = (userId: number, email: string, company: string) => {
      window.$chatwoot?.setUser(userId, {
        email: email,
        name: company,
      });
    };
  }
}

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(Vuetify);

Vue.component('length-input-field', LengthInputField);
Vue.component('weight-input-field', WeightInputField);
Vue.component('rotation-input-field', RotationInputField);
Vue.component('app-tour', TourComponent);
Vue.component('icon', IconComponent);

if (inIframe) {
  const navigation = urlParams.get('navigation');
  if (navigation) {
    Vue.prototype.$navigation = navigation.split(',');
  }
}

Vue.prototype.$list_types = [
  { text: 'Sea', value: 'SEA' },
  { text: 'Road', value: 'ROAD' },
  { text: 'Air', value: 'AIR' },
  { text: 'Packages and pallets', value: 'PALL' },
];
Vue.prototype.$toSI = dimTools.toSI;
Vue.prototype.$fromSI = dimTools.fromSI;
Vue.prototype.$listTypeDisplayNames = (t: string) => {
  return Vue.prototype.$list_types.find(
    (listType: { text: string; value: string }) => listType.value === t
  ).text;
};
Vue.prototype.$typeNames = (t: string) => {
  switch (t) {
    case 'PALL':
      return 'pallet';
    case 'ROAD':
      return 'trailer';
    case 'AIR':
      return 'ULD';
    default:
      return 'container';
  }
};
Vue.prototype.$setNames = (t: string) => {
  switch (t) {
    case 'AIR':
      return 'Aircraft';
    case 'ROAD':
      return 'Road train';
    case 'SEA':
      return 'Vessel';
    case 'PALL':
      return 'Warehouse';
    default:
      return 'Set';
  }
};
Vue.config.productionTip = false;

// Vue.use(filters);
// for (const filter in filters) {
//   Vue.filter(filter, (filters as any)[filter]);
// }

let cspNonce: string | undefined = undefined;
const metaTag = document.querySelector('meta[property="csp-nonce"]');
if (metaTag) {
  cspNonce = metaTag.getAttribute('content') as string;
} else {
  console.log('Meta tag not found');
}

new Vue({
  el: '#app',
  router,
  pinia,
  filters,
  vuetify: new Vuetify({
    theme: {
      options: { customProperties: true, cspNonce: cspNonce },
      themes: {
        light: {
          primary: PRIMARY_COLOR,
        },
        dark: {
          primary: '#3482DB',
        },
      },
    },
    icons: {
      iconfont: 'mdi',
      values: {
        ROAD: {
          component: IconComponent,
          props: {
            icon: 'truck',
          },
        },
        SEA: {
          component: IconComponent,
          props: {
            icon: 'container',
          },
        },
        AIR: {
          component: IconComponent,
          props: {
            icon: 'airplane',
          },
        },
        PALL: {
          component: IconComponent,
          props: {
            icon: 'pallet',
          },
        },
        COG: {
          component: IconComponent,
          props: {
            icon: 'cog',
          },
        },
      },
    },
  }),
  render: (h) => h(App),
}).$mount('#app');

let miscStore = useMiscStore();

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'account_settings' &&
    to.name !== 'company_billing' &&
    miscStore.is_authenticated
  ) {
    if (!miscStore.user.accepted_policy) next('/profile/account');
    else if (!miscStore.company.valid_subscription) {
      next(miscStore.user.is_admin ? '/company/billing' : '/profile/account');
    } else next();
  } else {
    next();
  }
  next();
});
