import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"height100"},[(_vm.loadlist)?_c('div',{staticClass:"d-flex flex-column height100"},[_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'detail' && _vm.$route.name !== 'detail_set'),expression:"$route.name !== 'detail' && $route.name !== 'detail_set'"}],attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"py-0 px-0",attrs:{"id":"mainTitle"}},[(!_vm.hide_navigation)?_c(VTabs,{staticClass:"no-print",attrs:{"fixed-tabs":"","centered":"","vertical":_vm.$vuetify.breakpoint.xsOnly,"slider-color":"primary","active-class":"active-tab","hide-slider":"","optional":""}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.key,staticClass:"enumerated",attrs:{"ripple":false,"exact-path":"","to":tab.to,"disabled":tab.disabled,"id":tab.id}},[_c('span',[_vm._v(_vm._s(tab.label))])])}),1):_vm._e()],1),_c(VDivider),_c('loadlist-info-component',{staticClass:"px-4",attrs:{"id":"loadlist-info"},on:{"showUnloadedItems":function($event){return _vm.expandUnloadedItems()}}})],1),_c('router-view'),(_vm.showUnloadedItemsView)?_c('unloaded-items-component',{ref:"unloadedItemsComponent"}):_vm._e(),_c('calc-modal'),_c('p',{staticClass:"text-center only-print pt-3",staticStyle:{"font-size":"10px"}},[_vm._v("www.cargo-planner.com")]),_c(VSnackbar,{attrs:{"value":_vm.saving,"timeout":-1,"outlined":"","rounded":"pill","color":"success","elevation":"24","center":"","bottom":""}},[_c(VProgressCircular,{staticClass:"mr-4",attrs:{"indeterminate":""}}),_vm._v(" Saving... ")],1)],1):_c('div',[_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":100,"color":"primary"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }