<template>
  <v-card class="mx-auto text-center" v-if="stats.length">
    <v-card-text>
      <v-sheet class="v-sheet--offset mx-auto">
        <v-sparkline
          :labels="stats.map((i) => `${i.month} ${i.total}`)"
          :value="stats.map((i) => i.total)"
          color="primary"
          line-width="2"
          stroke-linecap="round"
          padding="16"
          smooth
          label-size="5"></v-sparkline>
      </v-sheet>
    </v-card-text>
    <v-card-text>
      <div class="text-h4 font-weight-thin">Number of calculations</div>
      <div class="">Total within the company past 6 months</div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import API from '@/API';
import Vue, { PropType } from 'vue';

interface CompanyStats {
  month: string;
  total: number;
}

export default Vue.extend({
  name: 'calculation-stats',
  data() {
    return {
      stats: [] as CompanyStats[],
    };
  },
  props: {
    company: {
      type: Number,
      required: true,
    },
  },
  watch: {
    company: {
      handler: function (a) {
        API.getCompanyStats(a).then((response) => {
          this.stats = response.data.map((i: CompanyStats) => {
            const date = new Date(i.month);
            const short_month = date.toLocaleString('default', {
              month: 'short',
            });
            return {
              total: i.total,
              month: short_month,
            };
          });
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useMiscStore),
  },
  created() {},
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
