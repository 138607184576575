import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VToolbar,{staticClass:"my-2 no-print",attrs:{"dense":""}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},on),[_c(VIcon,[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Go back to load list")])]),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.print}},[_c(VIcon,[_vm._v("mdi-printer")])],1),_c(VBtn,{attrs:{"icon":"","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":_vm.getXlsx}},[_c(VIcon,[_vm._v("fa-file-excel")])],1)],1),_c(VAlert,{staticClass:"text-subtitle-1 mt-2",attrs:{"color":"info","outlined":""}},[_c('strong',[_vm._v("Summary:")]),_c('p',[_vm._v(" Total weight: "+_vm._s(_vm._f("toWeight")(_vm.summary.weight))+" "),_c('br'),_vm._v(" Total volume: "+_vm._s(_vm._f("toVolume")(_vm.summary.volume))+" "),_c('br'),_vm._v(" Total items: "+_vm._s(_vm.summary.no)+" items ")])]),_c('summary-table')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }