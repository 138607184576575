<template>
  <div v-if="displayConfiguration">
    <v-alert type="info" outlined>Drag rows up/down to change the loading order</v-alert>
    <v-toolbar dense class="my-1 ml-2 no-padding" elevation="0" v-if="!hideAddGroup">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="newGroup" v-on="on" outlined>
            <v-icon>mdi-folder-plus-outline</v-icon>
            <span class="ml-1">Add group</span>
          </v-btn>
        </template>
        <span>Add new group to set</span>
      </v-tooltip>
    </v-toolbar>
    <draggable
      v-model="set.data.slots"
      class="slots"
      group="slot"
      v-bind="dragOptions"
      @start="dragSlot = true"
      @end="dragSlot = false"
      @change="reorder">
      <div
        v-for="(slot, slot_index) in set.data.slots"
        :key="JSON.stringify(slot.name)"
        class="outline-row">
        <v-col class="py-0 px-0" style="background-color: white">
          <div class="d-flex align-center" style="min-height: 56px">
            <!-- <span class="my-auto mx-1">{{slot_index + 1 }}</span> -->
            <v-icon class="draggable">mdi-drag</v-icon>
            <slot name="header" :setSlot="slot" :slotIndex="slot_index">
              <v-btn
                icon
                v-if="expandedGroups.indexOf(slot_index) < 0"
                @click="expandedGroups.push(slot_index)"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
              <v-btn
                icon
                v-else
                @click="expandedGroups.splice(expandedGroups.indexOf(slot_index), 1)"
                ><v-icon>mdi-chevron-down</v-icon></v-btn
              >
              <div class="py-2 d-flex flex-grow-1 align-center">
                <div>
                  {{
                    editingName == slot_index
                      ? ''
                      : (slot.name || `Group ${slot_index + 1}`) +
                        ` (${slot.configurations[displayConfiguration[slot_index]].group.length})`
                  }}
                </div>
                <v-tooltip bottom v-if="editingName != slot_index">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="my-auto"
                      color="rgba(0,0,0,0.25)"
                      icon
                      x-small
                      v-on="on"
                      @click="editSlotName(slot_index)">
                      <v-icon>mdi-pencil-outline</v-icon></v-btn
                    >
                  </template>
                  Edit group name
                </v-tooltip>

                <v-text-field
                  style="max-width: 200px"
                  :ref="'slot-name-' + slot_index"
                  v-if="editingName == slot_index"
                  :value="slot.name"
                  :rules="nameRules"
                  required
                  @keydown="keyDownSlotName($event, slot_index)"
                  @blur="blurSlotName($event, slot)"></v-text-field>
                <v-select
                  class="ml-4"
                  style="max-width: 180px"
                  v-if="slot.configurations.length > 1"
                  :items="
                    slot.configurations.map((_, i) => {
                      return {
                        text: `Configuration ${i + 1}`,
                        value: i,
                      };
                    })
                  "
                  v-model="displayConfiguration[slot_index]"
                  @change="changeConfiguration(slot_index)"
                  hide-details
                  outlined
                  dense></v-select>
              </div>
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="success" v-on="on" @click="$emit('addToSlot', slot_index)">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                    <span class="ml-1">Container</span>
                  </v-btn>
                </template>
                Add new container to this group
              </v-tooltip>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-on="on" v-bind="attrs">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    :disabled="slot_index === set.data.slots.length - 1"
                    @click="shiftDown(slot_index)">
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-down-thin-circle-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> Shift down </v-list-item-content>
                  </v-list-item>
                  <v-list-item link :disabled="slot_index == 0" @click="shiftUp(slot_index)">
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-up-thin-circle-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> Shift up </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item link @click="duplicateGroup(slot_index)">
                <v-list-item-icon>
                  <v-icon>mdi-content-copy</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Duplicate
                </v-list-item-content>
              </v-list-item> -->
                  <v-list-item link @click="newConfiguration(slot_index)">
                    <v-list-item-icon>
                      <v-icon>mdi-plus-circle-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> New configuration </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    :disabled="slot.configurations.length == 1"
                    link
                    @click="
                      slot.configurations.splice(displayConfiguration[slot_index], 1);
                      displayConfiguration[slot_index] = 0;
                      rerender();
                    ">
                    <v-list-item-icon>
                      <v-icon color="yellow">mdi-minus-circle-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> Remove configuration </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    link
                    :disabled="
                      slot.configurations[displayConfiguration[slot_index]].group.length < 2
                    "
                    @click="splitGroup(slot_index)">
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-split-horizontal</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> Split group </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="
                      moveModel.slotIndex = slot_index;
                      showMoveModal = true;
                    ">
                    <v-list-item-icon>
                      <v-icon>mdi-cursor-move</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> Move group </v-list-item-content>
                  </v-list-item>
                  <v-list-item link @click="deleteGroup(slot_index)">
                    <v-list-item-icon>
                      <v-icon color="red">mdi-delete-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> Delete group </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </slot>
          </div>
          <div v-if="expandedGroups.indexOf(slot_index) >= 0" class="pb-2">
            <draggable
              v-model="slot.configurations[displayConfiguration[slot_index]].group"
              group="containers"
              class="containers"
              v-bind="dragOptions"
              @start="dragContainer = true"
              @end="dragContainer = false"
              @change="reorder">
              <v-row
                v-for="(ht, ht_index) in slot.configurations[displayConfiguration[slot_index]]
                  .group"
                class="outline-row pt-1 item"
                :key="`${slot_index}-${ht_index}`"
                align="center"
                justify="center">
                <v-icon class="draggable">mdi-drag</v-icon>
                <v-col cols="9" md="9" class="py-0">
                  <div class="d-flex">
                    <div class="my-auto">
                      <label>{{ position_name(slot_index, ht_index) }}.</label>
                    </div>
                    <div class="ml-4">
                      <label class="font-weight-bold">
                        {{ containerOf(ht).name }}
                        {{ ht.props ? '*' : '' }}
                      </label>
                      <div>
                        {{ containerOf(ht).L | toLength(false) }}
                        x
                        {{ containerOf(ht).W | toLength(false) }}
                        x
                        {{ (containerOf(ht).H || containerOf(ht).max_height) | toLength(true) }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="2" md="2" class="py-0">
                  <v-row class="justify-end">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="openContainerDetails(slot_index, ht)"
                          icon
                          v-on="on"
                          :ripple="false">
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Open container details</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="removeContainer(slot_index, ht_index)"
                          icon
                          v-on="on"
                          :ripple="false">
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove from group</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-row>
            </draggable>
          </div>
        </v-col>
      </div>
    </draggable>
    <v-dialog v-model="showMoveModal" v-if="showMoveModal" width="800">
      <v-card>
        <v-card-title class="text-h5">Move Group</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col>
                <v-row>
                  <length-input-field
                    class="mx-2"
                    v-model="moveModel.x"
                    label="X (Sideways)"></length-input-field>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <length-input-field
                    class="mx-2"
                    v-model="moveModel.y"
                    label="Y (Depth)"></length-input-field>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <length-input-field
                    class="mx-2"
                    v-model="moveModel.z"
                    label="Z (Up/Down)"></length-input-field>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click.stop="
              moveGroup(moveModel.slotIndex);
              showMoveModal = false;
            "
            >Move</v-btn
          >
          <v-btn text @click.stop="showMoveModal = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Configuration, SetSlot, SetType } from '@/models/SetsModel';
import { HoldData, HoldType, Rotation } from '@/models/LoadlistModel';
import rotationInputField from '@/components/Custom/RotationInputField.vue';
import lengthInputField from '@/components/Custom/LengthInputField.vue';
import draggable from 'vuedraggable';
export default Vue.extend({
  name: 'set_layout',
  props: {
    set: Object as PropType<SetType>,
    displayConfiguration: Array as PropType<number[]>,
    hideAddGroup: Boolean,
  },
  components: {
    draggable,
    rotationInputField,
    lengthInputField,
  },
  data() {
    return {
      expandedSlots: [],
      selectedSlots: [],
      nameRules: [
        (v: string) => !!v || 'A name is required',
        (v: string) => (v && v.length <= 100) || 'Name must be less than 100 characters',
      ],
      expandedGroups: [],
      editingName: undefined as number,
      defaultSpacing: 0.1,
      placementMode: 'Row',
      showMoveModal: false,
      moveModel: { x: 0, y: 0, z: 0, slotIndex: 0 },
      dragSlot: false,
      dragContainer: false,
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      },
    };
  },
  methods: {
    reorder() {
      this.$emit('reorder');
    },
    rerender() {
      this.$emit('rerender');
    },
    updateName(input: string, slotIndex: number) {
      this.set.data.slots[slotIndex].name = input;
    },
    blurSlotName(event: FocusEvent, slot: SetSlot) {
      slot.name = (event.target as any)._value;
      this.editingName = undefined;
    },
    keyDownSlotName(input: KeyboardEvent, slotIndex: number) {
      if (input.key == 'Enter') {
        const refs = this.$refs as any;
        const ref = refs['slot-name-' + slotIndex][0];
        ref.blur();
      }
    },
    changeConfiguration(slotIndex: number) {
      this.rerender();
    },
    removeContainer(slot_index: number, ht_index: number) {
      if (slot_index === null || ht_index === null) return;
      this.displayedSlot(slot_index).group.splice(ht_index, 1);
    },
    openContainerDetails(slotIndex: number, holdType: HoldType) {
      this.$emit('containerSelect', { slotIndex, holdType });
    },
    position_name(slot_index: number, ht_index: number): string {
      const ht =
        this.set.data.slots[slot_index].configurations[this.displayConfiguration[slot_index]].group[
          ht_index
        ];
      if (ht.position_name) {
        return ht.position_name;
      }
      let count = 0;
      for (let i = 0; i < slot_index; i++) {
        count += this.set.data.slots[i].configurations[this.displayConfiguration[i]].group.length;
      }
      return `${count + ht_index + 1}`;
    },
    containerOf(ht: HoldType): HoldData {
      const hold = this.set.data.container_types.find((ct) => ct.id === ht.container_type_id);
      if (hold) {
        return {
          ...this.set.data.container_types.find((ct) => ct.id === ht.container_type_id),
          ...(ht.props
            ? Object.fromEntries(Object.entries(ht.props).filter(([_, v]) => v != null))
            : ht.props),
        };
      }
      return undefined;
    },
    newGroup() {
      this.set.data.slots.push({
        configurations: [{ group: [] }],
        name: `Group ${this.set.data.slots.length + 1}`,
      });
      this.displayConfiguration.push(0);
      this.editSlotName(this.set.data.slots.length - 1);
    },
    getLastSelectedGroup() {
      return this.displayedSlot(this.getLastSelectedSlotIndex());
    },
    getLastSelectedSlotIndex() {
      for (let i = this.set.data.slots.length - 1; i >= 0; i--) {
        if (this.selectedSlots.length > 0 && this.selectedSlots.indexOf(i) < 0) {
          continue;
        }
        return i;
      }
    },
    // calculatePosition(prev: HoldType, current: HoldData, rotation: Rotation) {
    //   let position = { x: 0, y: 0, z: 0 };
    //   if (!prev) {
    //     return position;
    //   }

    //   let direction = "x";
    //   switch (this.placementMode) {
    //     case "Row":
    //       direction = "x";
    //       break;
    //     case "Column":
    //       direction = "y";
    //       break;
    //     case "Side by side":
    //       let alt = this.getLastSelectedGroup();
    //       if (alt.group.length % 2 === 1) {
    //         direction = "y";
    //       } else if (alt.group.length > 1) {
    //         prev = alt.group[alt.group.length - 2];
    //       }
    //       break;
    //   }
    //   let prevContainer = this.containerOf(prev);
    //   let pl = this.calculateLengthOfContainer(prevContainer, prev.rotation[2]);
    //   let pw = this.calculateWidthOfContainer(prevContainer, prev.rotation[2]);

    //   if (direction === "x") {
    //     let l = this.calculateLengthOfContainer(current, rotation[2]);
    //     return {
    //       x:
    //         prev.position.x +
    //         pl / 2 +
    //         prevContainer.L / 2 +
    //         this.defaultSpacing -
    //         (current.L / 2 - l / 2),
    //       y: prev.position.y,
    //       z: prev.position.z,
    //     };
    //   } else {
    //     let w = this.calculateWidthOfContainer(current, rotation[2]);
    //     return {
    //       x: prev.position.x,
    //       y:
    //         prev.position.y +
    //         pw / 2 +
    //         prevContainer.W / 2 +
    //         this.defaultSpacing -
    //         (current.W / 2 - w / 2),
    //       z: prev.position.z,
    //     };
    //   }
    // },
    calculateLengthOfContainer(container: HoldData, rotationZ: number) {
      if (container) {
        return (
          Math.abs(Math.cos(rotationZ)) * container.L +
          Math.abs(Math.cos(Math.PI / 2 - rotationZ)) * container.W
        );
      }
    },
    calculateWidthOfContainer(container: HoldData, rotationZ: number) {
      if (container) {
        return (
          Math.abs(Math.cos(rotationZ)) * container.W +
          Math.abs(Math.cos(Math.PI / 2 - rotationZ)) * container.L
        );
      }
    },
    newConfiguration(slotIndex: number) {
      this.set.data.slots[slotIndex].configurations.push({ group: [] });
      this.displayConfiguration[slotIndex] =
        this.set.data.slots[slotIndex].configurations.length - 1;
      this.rerender();
    },
    splitGroup(slot_index: number) {
      const newGroups = this.displayedSlot(slot_index).group.map((ht, ht_index) => {
        return {
          name: `Group ${slot_index + 1 + ht_index}`,
          configurations: [{ group: [ht] }],
        };
      });
      this.set.data.slots.splice(slot_index, 1, ...newGroups);
      this.displayConfiguration.splice(slot_index, 1, ...newGroups.map(() => 0));
    },
    // TODO reimplement duplicate group?
    // duplicateGroup(slot_index: number) {
    //   const newSlot = JSON.parse(
    //     JSON.stringify(this.displayedSlot(slot_index))
    //   ) as Configuration;
    //   const old = newSlot.group.find((g) => g);
    //   let last_hold = this.getLastHoldType(false);
    //   let last_pos = this.calculatePosition(
    //     last_hold,
    //     this.containerOf(old),
    //     old.rotation
    //   );
    //   let old_pos = old.position;
    //   let diff = {
    //     x: last_pos.x - old_pos.x,
    //     y: last_pos.y - old_pos.y,
    //     z: last_pos.z - old_pos.z,
    //   };
    //   newSlot.group.forEach((ht: HoldType) => {
    //     ht.position.x += diff.x;
    //     // ht.position.y += diff.y;
    //     ht.position.z += diff.z;
    //   });
    //   this.set.data.slots.push({ configurations: [newSlot] });
    //   this.displayConfiguration.push(0);
    // },
    getLastHoldType(selected: boolean) {
      for (let i = this.set.data.slots.length - 1; i >= 0; i--) {
        if (selected && this.selectedSlots.length > 0 && this.selectedSlots.indexOf(i) < 0) {
          continue;
        }
        const group = this.displayedSlot(i).group;
        for (let j = group.length - 1; j >= 0; j--) {
          return group[j];
        }
      }
    },
    deleteGroup(slot_index: number) {
      this.set.data.slots.splice(slot_index, 1);
      this.displayConfiguration.splice(slot_index, 1);
      const index = this.selectedSlots.indexOf(slot_index);
      if (index >= 0) {
        this.selectedSlots.splice(index, 1);
      }
      this.rerender();
    },
    displayedSlot(slot_index: number): Configuration {
      return this.set.data.slots[slot_index].configurations[this.displayConfiguration[slot_index]];
    },
    moveGroup(slotIndex: number) {
      this.displayedSlot(slotIndex).group.forEach((ht) => {
        ht.position.x += this.moveModel.x;
        ht.position.y += this.moveModel.y;
        ht.position.z += this.moveModel.z;
      });
      this.moveModel.x = 0;
      this.moveModel.y = 0;
      this.moveModel.z = 0;
      this.rerender();
      // this.moveModel = { x: 0, y: 0, z: 0 };
    },
    shiftDown(slotIndex: number) {
      const slots = [...this.set.data.slots];
      const next = slots[slotIndex + 1];
      if (next) {
        slots[slotIndex + 1] = this.set.data.slots[slotIndex];
        slots[slotIndex] = next;
        this.set.data.slots = slots;
      }
      this.rerender();
    },
    shiftUp(slotIndex: number) {
      const slots = [...this.set.data.slots];
      const prev = slots[slotIndex - 1];
      if (prev) {
        slots[slotIndex - 1] = this.set.data.slots[slotIndex];
        slots[slotIndex] = prev;
        this.set.data.slots = slots;
      }
      this.rerender();
    },
    editSlotName(slotIndex: number) {
      this.editingName = slotIndex;
      setTimeout(() => {
        const refs = this.$refs as any;
        const ref = refs['slot-name-' + slotIndex][0];
        ref.focus();
      });
    },
  },
});
</script>

<style scoped>
>>> .v-data-table__expanded {
  box-shadow: none !important;
}
.no-padding >>> .v-toolbar__content {
  padding: 0px !important;
}
.slots,
.containers {
  background-color: white;
}
.outline-row {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background: #f1f1f1;
  margin: 0;
}
.outline-row:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.ghost {
  opacity: 0;
}
</style>
