<template>
  <v-dialog v-model="show" width="600">
    <v-card :loading="isLoading">
      <v-card-title class="text-h5" primary-title>Copy loadlist</v-card-title>
      <v-card-text>
        <v-form v-model="newLoadlistValid">
          <v-text-field
            label="Name of copy"
            v-model="newLoadlist.name"
            :rules="nameRules"
            required></v-text-field>

          <v-select
            v-model="newLoadlist.list_type"
            v-bind:items="$list_types"
            required
            label="Select type of loadlist for copy"></v-select>

          <v-textarea v-model="newLoadlist.notes" label="Notes" outlined></v-textarea>
        </v-form>

        <v-checkbox
          v-model="newLoadlist.with_loadplan"
          label="Also copy loadplan(s)"
          hide-details></v-checkbox>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text @click.stop="$emit('close', false)">Close</v-btn>
        <v-spacer />
        <v-btn color="primary" :disabled="!newLoadlistValid" @click.stop="copyList()"
          >Make copy</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import API from '@/API';
import { APIResponse } from '@/models/APIModel';
import { LoadlistBase, ListType } from '@/models/LoadlistModel';
export default Vue.extend({
  name: 'copy-loadlist',
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    name: {
      default: '',
      type: String,
    },
    loadlistToCopy: Object as PropType<LoadlistBase>,
  },
  data() {
    return {
      isLoading: false,
      loadlistCopyId: null as string,
      newLoadlist: {
        name: '',
        list_type: (localStorage.getItem('cplLastLoadlistType') || 'SEA') as ListType,
        data: [],
        notes: '',
        with_loadplan: false,
      },
      newLoadlistValid: false,
      nameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => v.length <= 50 || 'Name must be less than 50 characters',
      ],
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    loadlistToCopy: {
      handler: function (selectedList: LoadlistBase) {
        this.loadlistCopyId = selectedList.id;
        this.newLoadlist.name = selectedList.name + ' copy';
      },
      immediate: true,
    },
  },
  methods: {
    copyList(): void {
      this.isLoading = true;
      API.copyLoadlist(this.loadlistCopyId, this.newLoadlist)
        .then((response: APIResponse) => {
          this.$router.push({ name: 'loadlists', params: { page: '1' } });
          this.$emit('close', true);
        })
        .catch((error: any) => {
          console.log('Error copying loadlist');
          this.$emit('close', false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>
<style scoped></style>
