<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Presets</v-toolbar-title>
      </v-toolbar>
      <load-presets-table-component
        class="mt-2"
        :list_type="loadlist.list_type"
        :holds="holds"
        :settings="settings"
        :palletTypes="palletTypes"
        :showUsePreset="true"
        @close="show = false">
      </load-presets-table-component>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import loadPresetsTableComponent from '@/components/Custom/LoadPresetsTable.vue';
import { HoldData, Loadlist } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { CalculationSettings } from '@/models/CalculationModel';

export default Vue.extend({
  name: 'load-configurations-modal',
  components: {
    loadPresetsTableComponent,
  },
  props: {
    visible: {
      type: Boolean,
    },
    holds: {
      type: Array as PropType<HoldData[]>,
      default: null,
    },
    palletTypes: {
      type: Array as PropType<HoldData[]>,
      default: null,
    },
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: null,
    },
  },
  computed: {
    ...mapStores(useLoadlistStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
});
</script>

<style scoped></style>
