<template>
  <v-container>
    <v-tabs>
      <v-tab key="general" to="/profile/general">General</v-tab>
      <v-tab key="account" to="/profile/account">Account</v-tab>
    </v-tabs>
    <router-view></router-view>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'profile',
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
