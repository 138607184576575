import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Import from Cargo Library ")]),_c(VCardText,[_c(VCol,[_c(VRow,[_c(VCheckbox,{attrs:{"label":"Convert length and weight dimensions to fit the loadlist","disabled":!_vm.lengthDim || !_vm.weightDim},model:{value:(_vm.convertDimensions),callback:function ($$v) {_vm.convertDimensions=$$v},expression:"convertDimensions"}})],1)],1),_c('cargo-library',{attrs:{"preSelected":_vm.value.map((i) => i.label)},on:{"addItems":_vm.addItems,"removeItems":_vm.removeItems}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.show = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }