import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5",attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.title))]),(_vm.description)?_c(VCardText,{staticClass:"whitespace-pre"},[_vm._v(_vm._s(_vm.description))]):_vm._e(),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.denyButton))]),_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.confirmColor},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('confirm')}}},[_vm._v(_vm._s(_vm.confirmButton))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }