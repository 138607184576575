import { CustomItemProperty } from '@/misc/itemProperties';
import { HoldInputItem } from './LoadlistModel';
import { ImportTemplate } from './ExcelImport';

export interface CompanyListSerializer {
  id?: number;
  name: string;
  seats?: number;
}

export interface ProfileCompanySerializer {
  id?: number;
  name: string;
  subscription?: any;
  settings?: CompanySettings;
}

export interface UserSerializer {
  id?: number;
  username: string;
  email: string;
  accepted_policy?: boolean;
  company?: ProfileCompanySerializer;
  is_active?: boolean;
  is_sso?: boolean;
  isolated?: boolean;
  preferences: any;
  password: string;
  old_password?: string;
  groups?: string[];
}

export class User {
  id?: number;
  username: string;
  email: string;
  accepted_policy: boolean;
  preferences: UserPreferences;
  is_sso: boolean;
  isolated: boolean;
  groups?: string[];

  constructor(user?: UserSerializer) {
    if (user) {
      this.id = user.id;
      this.username = user.username;
      this.email = user.email;
      this.accepted_policy = user.accepted_policy;
      this.preferences = user.preferences;
      this.is_sso = user.is_sso;
      this.isolated = user.isolated;
      this.groups = user.groups;
    } else {
      this.id = null;
      this.username = '';
      this.email = '';
      this.accepted_policy = false;
      this.preferences = {} as UserPreferences;
      this.is_sso = false;
      this.isolated = false;
      this.groups = [];
    }
  }

  get is_admin(): boolean {
    return this.groups.includes('Administrator');
  }

  get is_editor(): boolean {
    return this.groups.includes('Editor');
  }

  get is_developer(): boolean {
    return this.groups.includes('Developer');
  }
}

export interface CompanySerializer {
  id?: number;
  name: string;
  logo?: string | null;
  subscription: SubscriptionSerializer;
  street_name?: string | null;
  postal_code?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  vat?: string | null;
}

export interface CustomMetric {
  name?: string;
  unit?: string;
  formula?: string;
  summable?: boolean;
  discard_nested?: boolean;
  only_summary?: boolean;
}

export interface UserPreferences {
  length_dim?: string;
  weight_dim?: string;
  default_view?: string;
  default_loadlist_length_dim?: string;
  default_loadlist_weight_dim?: string;
  hidden_input_columns?: string[];
  default_presets?: any;
  visible_loadplan_columns?: string[];
  visible_workspace_columns?: string[];
  item_labels?: string[];
  dark_mode?: boolean;
  default_input_row?: HoldInputItem;
  show_nested_as_box?: boolean;
  item_color_by_key?: string;
}

export interface CompanySettings extends UserPreferences {
  enforced?: boolean;
  metrics?: CustomMetric[];
  extra_columns?: CustomItemProperty[];
  import_templates?: ImportTemplate[];
}

export class Company {
  id?: number;
  name: string;
  subscription: SubscriptionSerializer;
  settings?: CompanySettings;

  constructor(company?: ProfileCompanySerializer) {
    if (company) {
      this.id = company.id;
      this.name = company.name;
      this.subscription = company.subscription;
      this.settings = company.settings;
    } else {
      this.id = null;
      this.name = '';
      this.subscription = {} as SubscriptionSerializer;
    }
  }
  get sets_enabled(): boolean {
    return this.subscription.sets_enabled;
  }

  get plan(): string {
    return this.subscription.plan;
  }

  get has_ending_subscription(): boolean {
    return !!this.subscription.end;
  }

  get subscription_end(): Date {
    return new Date(this.subscription.end);
  }

  get valid_subscription(): boolean {
    if (!this.subscription.end) return true;
    const end = this.subscription_end;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    return end >= today;
  }
}

export interface SubscriptionSerializer {
  plan?: string;
  start?: string;
  end?: string;
  sets_enabled?: boolean;
}

export interface CompanyAdminUserSerializer {
  id?: number;
  username: string;
  email: string;
  is_active?: boolean;
  last_login?: string;
  groups?: string[];
  isolated?: boolean;
  // password: string; // writeOnly
  // company: number; // writeOnly
}

export interface CompanyStoreTemplate {
  id?: number;
  name?: string;
  subscription_end?: Date;
  sets_enabled?: boolean;
  valid_subscription?: boolean;
  has_ending_subscription?: boolean;
  plan: string;
}

export interface SubscriptionPlan {
  id: any;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  unit_amount: number;
  recurring: SubscriptionPlanRecurring;
  product?: SubscriptionPlanProduct;
  nickname?: string;
  tiers?: any[];
}

export interface SubscriptionPlanRecurring {
  aggregate_usage?: any;
  interval?: string;
  interval_count?: number;
  trial_period_days?: number | null;
  usage_type?: string;
}

export interface SubscriptionPlanProduct {
  description?: string;
  name?: string;
  metadata?: {
    type?: string;
    plan?: string;
  };
}

export type WebhookEvent = 'loadlist_saved';

export interface Webhook {
  id?: string;
  event: WebhookEvent;
  description?: string;
  url: string;
  secret: string;
  created_date?: string;
  modified_date?: string;
}
