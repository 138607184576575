<template>
  <v-simple-table class="cargo-table" dense>
    <thead>
      <tr>
        <th></th>
        <th
          class="text-center px-2"
          v-for="column in allColumns.filter(
            (column) => !['w', 'h', 'w#additional', 'h#additional'].includes(column.key)
          )"
          :key="column.key + column.dim"
          :colspan="['l', 'l#additional'].includes(column.key) ? 3 : 1"
          :class="{
            'left-border': ['l', 'wt', 'l#additional', 'wt#additional'].includes(column.key),
            'right-border': ['wt', 'wt#additional'].includes(column.key),
          }">
          <span v-if="column.key === 'l'">
            {{ loadlist.length_dim }}
          </span>
          <span
            v-else-if="
              column.key &&
              (column.key.startsWith('l#additional') || column.key.startsWith('wt#additional'))
            ">
            {{ column.dim }}
          </span>
          <span v-else-if="column.key === 'wt'">
            {{ loadlist.weight_dim }}
          </span>
          <span v-else-if="column.key === 'chargable_wt'">
            {{ loadlist.weight_dim }}
          </span>
          <span v-else></span>
        </th>
      </tr>
      <tr>
        <th class="text-left"></th>
        <th
          class="text-left"
          v-for="column in allColumns"
          :key="column.key + column.dim"
          :class="{
            'left-border': ['l', 'wt', 'l#additional', 'wt#additional'].includes(column.key),
            'right-border': ['wt', 'wt#additional'].includes(column.key),
          }">
          {{ column.text }}
        </th>

        <th class="text-left" v-for="metric in customMetrics" :key="metric.name">
          {{ metric.name }} <span v-if="metric.unit">[{{ metric.unit | uppercase }}]</span>
        </th>
      </tr>
    </thead>
    <template>
      <tbody v-for="(item, index) in bundledItems" :key="index">
        <table-item
          v-for="(o, index2) in childItems(item)"
          :key="index2"
          :item="o.item"
          :depth="o.depth"
          :columns="allColumns"
          :customMetrics="customMetrics"
          :hold="item.from_container ? item.from_container : item" />
      </tbody>
    </template>

    <tfoot v-if="container">
      <tr>
        <td></td>
        <td v-for="column in allColumns" :key="column.key + column.dim" class="font-weight-bold">
          <template v-if="column.summable">
            <template v-if="column.key === 'qty'">
              <span v-if="container.numberOfPallets" class="font-weight-bold">
                {{ container.numberOfPallets }}
                <span class="font-weight-regular">({{ container.items_count }})</span>
              </span>
              <span v-else> {{ container.items_count }}</span>
            </template>
            <template v-else-if="column.key === 'wt'">
              {{ toLoadlistDim(container.weight) }}
            </template>
            <template v-else-if="column.key === 'wt#additional'">
              {{ toWeightDim(container.weight, column.dim) }}
            </template>
            <template v-else-if="column.key === 'chargable_wt'">
              {{ toLoadlistDim(container.chargableWeight(loadplan.settings.shipping_factor)) }}
            </template>
            <template v-else-if="column.type === 'custom'">
              {{
                bundledItems
                  .filter((i) => i.metadata && !isNaN(i.metadata[column.key]))
                  .map((i) => i.metadata[column.key] * (i.qty || 1))
                  .reduce((acc, cur) => {
                    return acc + cur;
                  }, 0)
              }}
            </template>
            <template v-else>
              {{
                bundledItems

                  .filter((i) => parseInt(i[column.key]))
                  .map((i) => parseInt(i[column.key]) * (i.qty || 1))
                  .reduce((acc, cur) => {
                    return acc + cur;
                  }, 0)
              }}
            </template>
          </template>
        </td>

        <td class="text-left font-weight-bold" v-for="metric in customMetrics" :key="metric.name">
          <span v-if="metric.summable">
            {{
              container.cargoes.reduce((acc, item, index) => {
                return acc + calculateFormula(metric.formula, item, container.hold);
              }, 0) | roundTwoDecimals
            }}</span
          >
          <span v-else></span>
        </td>
      </tr>
    </tfoot>
  </v-simple-table>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { useMiscStore } from '@/stores/miscStore';
import ItemProperties, { CustomItemProperty, ItemProperty } from '@/misc/itemProperties';
import dimTools from '@/misc/dimTools';
import { HoldData, HoldItem, Loadlist, Loadplan } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { AugmentedHold } from '@/models/augmented/hold';
import { CompanySettings, CustomMetric } from '@/models/UserCompanyModel';
import { Parser } from 'expr-eval';
import TableItem from './TableItem.vue';
import itemProperties from '@/misc/itemProperties';
import itemUtils from '@/misc/itemUtils';
import tableUtils from '@/misc/tableUtils';

export default Vue.extend({
  components: { TableItem },
  name: 'loadplan-table',
  props: {
    container: {
      type: Object as PropType<AugmentedHold>,
      default: null,
    },
    bundledItems: {
      type: Array as PropType<HoldItem[]>,
      default: () => [] as HoldItem[],
    },
    presentationSettings: {
      type: Object as PropType<CompanySettings>,
      default: null,
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    lengthDims: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    weightDims: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    additionalLengthDim: {
      type: String,
      default: null,
    },
    additionalWeightDim: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapStores(useLoadlistStore, useMiscStore),
    defaultColumns(): ItemProperty[] {
      let columns = this.columns;
      this.lengthDims
        .filter((dim) => dim !== this.loadlist.length_dim)
        .forEach((dim) => {
          const additional = ['l#additional$' + dim, 'w#additional$' + dim, 'h#additional$' + dim];

          let index = columns.findIndex((i) => i.startsWith('wt#additional'));
          if (index < 0) index = columns.findIndex((i) => i.startsWith('h#additional'));
          if (index < 0) index = columns.indexOf('wt');
          if (index < 0) index = columns.indexOf('h');

          if (index >= 0) {
            columns = [...columns.slice(0, index + 1), ...additional, ...columns.slice(index + 1)];
          }
        });
      this.weightDims
        .filter((dim) => dim !== this.loadlist.weight_dim)
        .forEach((dim) => {
          const additional = 'wt#additional$' + dim;
          let index = columns.findIndex((i) => i.startsWith('h#additional'));
          if (index < 0) index = columns.indexOf('h');
          if (index >= 0) {
            columns = [...columns.slice(0, index + 1), additional, ...columns.slice(index + 1)];
          }
        });

      return columns
        .map((i) => {
          return (
            ItemProperties.props().find((j) => j.key == i) || {
              dim: i.substring(i.indexOf('$') + 1),
              ...ItemProperties.props().find(
                (j: any) => j.key.indexOf('#') >= 0 && i.startsWith(j.key)
              ),
            }
          );
        })
        .filter((i) => !!i);
    },
    extraColumns(): CustomItemProperty[] {
      return this.miscStore?.company_settings?.extra_columns || [];
    },
    allColumns(): ItemProperty[] {
      return this.defaultColumns.concat(
        (this.container?.customColumns || []).map((key) => ({
          ...itemProperties.default(),
          key,
          text: key,
          type: 'custom',
          desc: this.extraColumns.find((v) => v.name == key)?.desc || '',
          summable: this.extraColumns.find((v) => v.name == key)?.type === 'number' || false,
        }))
      );
    },
    customMetrics(): CustomMetric[] {
      return this.presentationSettings?.metrics || [];
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
  },
  filters: {
    uppercase(val: string): string {
      return val ? val.toUpperCase() : '';
    },
    roundDim(val: number, dim: string): number {
      return dimTools.roundForDim(val, dim);
    },
  },
  methods: {
    childItems(item: HoldItem): { depth: number; item: HoldItem }[] {
      const denest = (items: HoldItem[], depth: number): { depth: number; item: HoldItem }[] => {
        return items
          .map((item) => {
            if (item.from_container?.items?.length)
              return [
                { depth, item },
                ...denest(itemUtils.bundledItems(item.from_container.items), depth + 1),
              ];
            return [{ depth, item }];
          })
          .flat();
      };
      return denest([item], 0);
    },
    valueForDim(value: number, dim: string): number {
      return dimTools.roundForDim(value * dimTools.fromSI(dim), dim);
    },
    toLengthDim(value: number, dim: string): number {
      return this.valueForDim(dimTools.toSI(this.loadlist.length_dim) * value, dim);
    },
    toWeightDim(value: number, dim: string): number {
      return this.valueForDim(value, dim);
    },
    toLoadlistDim(value: number): number {
      return this.valueForDim(value, this.loadlist.weight_dim);
    },
    chargableWeight(item: HoldItem): number {
      return Math.max(item.WT, item.L * item.W * item.H * this.loadplan.settings.shipping_factor);
    },
    calculateFormula(formula: any, item: HoldItem, hold: HoldData): number {
      return tableUtils.calculateFormula(formula, item, hold);
    },
  },
});
</script>

<style scoped>
.cargo-table th,
.cargo-table td {
  border-collapse: collapse;
}
.cargo-table tbody tr td {
  border-bottom: none !important;
}
.cargo-table tbody tr:last-child td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
.cargo-table tbody tr:not(:only-child) td:first-child {
  border-left: 3px solid #4778b2;
}
.left-border {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.right-border {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

@media print {
  .cargo-table {
    page-break-inside: avoid;
  }

  .cargo-table thead tr th,
  .cargo-table tbody tr td,
  .cargo-table tfoot tr td {
    font-size: 0.75rem !important;
    padding: 0 8px !important;
  }
}
</style>
