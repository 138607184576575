<template>
  <div>
    <div>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <slot name="view" v-bind="{ renderKey }">
            <scene-component
              :set="renderSet"
              :canvas-width="800"
              :canvas-height="300"
              :key="renderKey"
              :customViewSettings="{
                view: '3d-ortho',
                containerNumbers: true,
              }"></scene-component>
          </slot>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Quantity [#]:"
            type="number"
            persistent-hint
            hint="(Number of sets available)"
            :value="modified_set.qty"
            @change="$set(modified_set, 'qty', parseInt($event) || null)"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Cost [$, €, £, ...]:"
            type="number"
            persistent-hint
            :hint="`(A number, relative to other ${typeName}s and sets)`"
            :value="modified_set.cost"
            @change="$set(modified_set, 'cost', parseFloat($event) || null)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <weight-input-field
            label="Payload"
            :min="0"
            persistent-hint
            hint="(Max weight)"
            v-model="modified_set.payload"></weight-input-field>
        </v-col>
        <v-col cols="6">
          <length-input-field
            label="Max volume"
            :min="0"
            :sup="3"
            persistent-hint
            hint="(Max volume)"
            v-model="modified_set.max_volume"></length-input-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-subtitle-1 pt-8">
          Configurations in {{ setName().toLowerCase() }}
        </v-col>
      </v-row>
      <v-row dense align="center">
        <v-col>
          <layout-section :set="setType" :displayConfiguration="displayConfiguration" hideAddGroup>
            <template #header="{ setSlot, slotIndex }">
              <div class="d-flex align-center flex-grow-1">
                <!-- Name the Group -->
                <span class="text-subtitle-1 px-4">{{ setSlot.name }}</span>
                <v-spacer />
                <!-- Show the different slots of the group -->
                <v-select
                  class="ml-4"
                  style="max-width: 300px"
                  :disabled="setSlot.configurations.length < 0"
                  :items="
                    setSlot.configurations.map((configuration, i) => {
                      return {
                        text: configurationSummary(configuration),
                        value: i,
                        configuration,
                      };
                    })
                  "
                  :value="displayConfiguration[slotIndex]"
                  @change="(e) => handleConfigChange(e, slotIndex)"
                  hide-details
                  outlined
                  dense>
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                      <v-list-item-icon>
                        <v-icon color="success" v-if="active">mdi-check-circle</v-icon>
                        <v-icon v-else>mdi-circle-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </div>
            </template>
          </layout-section>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-subtitle-1 pt-8">
          Equipment used in this {{ setName().toLowerCase() }}
        </v-col>
      </v-row>
      <v-row
        dense
        align="center"
        v-for="(hold, index) in modified_set.container_types"
        :key="hold.id">
        <v-col cols="12">
          <v-expansion-panels focusable class="unselectable">
            <v-expansion-panel @click="$vuetify.goTo($event.target)">
              <v-expansion-panel-header>{{ hold.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <inline-hold-edit-component
                  v-model="modified_set.container_types[index]"
                  :basetype="modified_set.base_type">
                  <!-- <template v-slot:view="{ renderKey }">
                                    <scene-component
                                      :hold-object="hold"
                                      :canvas-width="400"
                                      :canvas-height="200"
                                      :key="renderKey"
                                    ></scene-component>
                                  </template> -->
                </inline-hold-edit-component>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
// import createHold from "@/misc/containerUtils.js";
import Vue, { PropType } from 'vue';
import inlineHoldEditComponent from '@/components/Custom/InlineHoldEdit.vue';
import LayoutSection from '../SetBuilder/LayoutSection.vue';
import { SetType, SetTypeData } from '@/models/SetsModel';
import SceneComponent from './SceneComponent.vue';
import { AugmentedSet } from '@/models/augmented/set';
import { HoldDataWithPosition } from '@/models/LoadlistModel';
import { Configuration } from '@/models/SetsModel';
// TODO: turn into ts
export default Vue.extend({
  name: 'modify-set',
  components: {
    inlineHoldEditComponent,
    LayoutSection,
    SceneComponent,
  },
  data() {
    return {
      renderKey: 1,
      modified_set: JSON.parse(JSON.stringify(this.value)) as SetTypeData,
    };
  },
  props: {
    value: Object as PropType<SetTypeData>,
    basetype: String,
  },
  computed: {
    typeName(): string {
      return this.$typeNames(this.basetype);
    },
    setType(): SetType {
      return { data: this.modified_set } as SetType;
    },
    renderSet(): AugmentedSet {
      return AugmentedSet.fromSetType(this.modified_set, this.displayConfiguration);
    },
    displayConfiguration(): number[] {
      return this.modified_set.slots.map((s) => {
        if (!s.configurations.find((c) => c.disabled) && s.configurations.length > 1) {
          s.configurations.forEach((c, i) => (c.disabled = i !== 0));
        }
        return Math.max(
          s.configurations.findIndex((c) => !c.disabled),
          0
        );
      });
    },
  },
  watch: {
    modified_set: {
      handler: function () {
        // let modified_set = createHold.assemble(
        //   JSON.parse(JSON.stringify(val))
        // );
        this.$emit('input', this.modified_set);
        // console.log(this.modified_set);

        this.renderKey++;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setName(): string {
      return this.$setNames(this.modified_set.base_type);
    },
    /**
     * Converts a configuration into something like "4x PMC/P6P (LD)"
     */
    configurationSummary(configuration: Configuration): string {
      const summary = configuration.group
        .map((g) => this.modified_set.container_types.find((ct) => ct.id == g.container_type_id))
        .filter((v) => v)
        .map((v) => [{ container: v, count: 1 }])
        .reduce((list, v) => {
          if (list.length === 0 || list[list.length - 1].container.id !== v[0].container.id) {
            list.push(...v);
          } else {
            list[list.length - 1].count += 1;
          }
          return list;
        }, []);
      return summary.map(({ container, count }) => `${count}x${container.name}`).join(', ');
    },
    /**
     * A change to what configuration is visible/active
     * @param activeConfig The configuration that should be active
     * @param slotIndex what slot is changing
     */
    handleConfigChange(activeConfig: number, slotIndex: number): void {
      this.modified_set.slots[slotIndex].configurations.forEach(
        (c, i) => (c.disabled = i !== activeConfig)
      );
      this.renderKey++;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
