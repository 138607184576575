<template>
  <v-container>
    <v-tabs>
      <v-tab key="company" to="/company/general">General settings</v-tab>
      <v-tab :disabled="!miscStore.user.is_admin" key="billing" to="/company/billing"
        >Billing</v-tab
      >
    </v-tabs>
    <router-view></router-view>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';

export default Vue.extend({
  name: 'company_index',
  computed: {
    ...mapStores(useMiscStore),
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
