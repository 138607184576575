<template>
  <div>
    <v-data-table
      v-bind:items="sortedHolds"
      :headers-length="numberOfColumns"
      :items-per-page="holdsPerPage"
      hide-default-footer
      item-key="uid"
      id="workspaceTable"
      style="white-space: nowrap">
      <template v-slot:header>
        <thead v-if="!isMobile">
          <tr class="text-left">
            <th>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    id="workspaceTableColumnsButton"
                    color="primary"
                    class="no-print"
                    v-on="on"
                    icon
                    small
                    @click="showWorkspaceTableColumnsModal = true">
                    <v-icon>mdi-table-cog</v-icon>
                  </v-btn>
                </template>
                <span>Table settings</span>
              </v-tooltip>
            </th>

            <th
              class="text-subtitle-1 font-weight-bold"
              v-for="col in selectedColumns"
              :key="col + orderBy">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a v-on="on" @click="orderBy = orderBy === col ? '' : col"
                    >{{ allColumns[col].title }}
                  </a>
                  <a @click="isAsc = !isAsc" v-if="orderBy === col">
                    <v-icon v-if="isAsc">mdi-sort-alphabetical-ascending</v-icon
                    ><v-icon v-else>mdi-sort-alphabetical-descending</v-icon></a
                  >
                </template>
                <span class="no-print">{{ allColumns[col].desc }}</span>
              </v-tooltip>
            </th>

            <th class="text-subtitle-1 font-weight-bold">View</th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="props">
        <tr
          v-if="
            !!filteredSets.size &&
            filteredSets.has(sortedHolds[props.index].hold.set_uuid) &&
            (props.index === 0 ||
              sortedHolds[props.index].hold.set_uuid !== sortedHolds[props.index - 1].hold.set_uuid)
          "
          :class="{ 'v-data-table__mobile-row': isMobile }">
          <td :class="{ 'v-data-table__mobile-row': isMobile }" class="text-h6">
            {{ filteredSets.get(sortedHolds[props.index].hold.set_uuid).name }}
          </td>
        </tr>
        <tr
          draggable="true"
          @dragstart="dragstart($event, props.item.__indices.start)"
          class="text-left"
          style="page-break-inside: avoid"
          v-bind:class="{
            draggedOver: props.index === draggedOverRow,
            'v-data-table__mobile-table-row': isMobile,
          }"
          v-on:drop="dropOnRow($event, props.item.__indices.start)"
          v-on:dragover.prevent="draggedOverRow = props.index"
          v-on:dragleave="draggedOverRow = null">
          <td :class="{ 'v-data-table__mobile-row': isMobile }">
            <div class="v-data-table__mobile-row__header" v-if="isMobile"></div>
            <v-checkbox
              :input-value="checkedUnits.indexOf(props.item.__indices) >= 0"
              @change="checkRow(props.item.__indices)"
              :ripple="false">
              <template v-slot:label>
                <span v-if="props.item.__indices.end > props.item.__indices.start">
                  <b>{{ props.item.__indices.start + 1 }}</b> to
                  {{ props.item.__indices.end + 1 }}
                </span>
                <span v-else>
                  {{ props.item.__indices.start + 1 }}
                </span>
              </template></v-checkbox
            >
          </td>
          <td
            class="text-subtitle-1"
            style="white-space: pre-line"
            :class="{ 'v-data-table__mobile-row': isMobile }"
            v-for="col in selectedColumns"
            :key="col">
            <div class="v-data-table__mobile-row__header" v-if="isMobile">
              {{ allColumns[col].title }}
            </div>
            <div v-if="col === 'usedSpace'">
              <v-tooltip bottom v-if="props.item.oogValues.length">
                <template v-slot:activator="{ on }">
                  <span v-on="on"><v-icon color="orange">mdi-information</v-icon> OoG</span>
                </template>
                <span class="no-print">{{ props.item.oogSummary }}</span>
              </v-tooltip>
            </div>
            <v-chip
              v-else-if="col === 'name' && sortedHolds[props.index].hasCoordinates()"
              @click="$emit('showMapModal', sortedHolds[props.index].hold.uuid)">
              <v-icon small>fa-map</v-icon>
            </v-chip>
            {{ allColumns[col].f(props.item) }}
          </td>

          <td
            class="text-subtitle-1 workspaceInteractive"
            @click="gotoInteractiveView(props.item.__indices.start)"
            :class="{ 'v-data-table__mobile-row': isMobile }">
            <scene-component
              class="my-1"
              id="sceneComponent"
              style="cursor: pointer"
              :hold-object="props.item.hold"
              :key="props.item.hold.uid"
              :canvas-width="sceneWidth"
              :canvas-height="sceneHeight"></scene-component>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-pagination
      class="no-print"
      v-if="!searchInput"
      v-bind:length="noResultPageButtons"
      :value="currentPage"
      @input="changeResultPage"></v-pagination>
    <workspace-table-columns
      v-if="showWorkspaceTableColumnsModal"
      :visible="showWorkspaceTableColumnsModal"
      @close="showWorkspaceTableColumnsModal = false"
      :default-columns="defaultColumns"
      :columns="columnsAsList"></workspace-table-columns>
  </div>
</template>

<script lang="ts">
interface ColumnProperty {
  title: string;
  desc: string;
  f: Function;
  sortBy: Function;
}
import Vue from 'vue';

import sceneComponent from '@/components/Custom/SceneComponent.vue';

import {
  HoldData,
  HoldDataWithIndices,
  HoldItem,
  Indices,
  Loadlist,
  Loadplan,
} from '@/models/LoadlistModel';
import { PropType } from 'vue';
import { Event } from 'three';
import { CalcData, CalculationSettings } from '@/models/CalculationModel';
import { GroupedSet } from '@/models/SetsModel';
import WorkspaceTableColumns from '@/components/Modals/WorkspaceTableColumns.vue';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';

import { AugmentedHold } from '@/models/augmented/hold';
import { CustomMetric, UserPreferences } from '@/models/UserCompanyModel';
import tableUtils from '@/misc/tableUtils';
const HOLDS_PER_PAGE = 25;
export default Vue.extend({
  name: 'workspace-table',
  components: {
    sceneComponent,
    WorkspaceTableColumns,
  },
  props: {
    holds: Array as PropType<HoldDataWithIndices[]>,
    sets: Array as PropType<GroupedSet[]>,
    searchInput: String,
    checkedUnits: Array as PropType<Indices[]>,
    print: Boolean,
    groupSimilar: Boolean,
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: (): CalculationSettings => {
        return null;
      },
    },
  },
  data() {
    return {
      orderBy: '',
      isAsc: false,
      currentResultPage: 1,
      holdsPerResultPage: HOLDS_PER_PAGE,
      draggedOverRow: null,
      spaceInfoMenuItems: [
        { value: 'used', text: 'Used space' },
        { value: 'free', text: 'Free space' },
      ],
      showWorkspaceTableColumnsModal: false,
      selectedSpaceItem: 0,
      columns: {
        name: {
          title: 'Type',
          desc: 'Name of equipment',
          f: (h: AugmentedHold) => {
            return `${h.name}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.name;
          },
        },
        itemsCount: {
          title: 'Pcs',
          desc: 'Number of loaded cargoes',
          f: (h: AugmentedHold) => {
            return `${h.items_count}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.items_count;
          },
        },
        weight: {
          title: 'Weight (net)',
          desc: 'Sum of all cargo weights',
          f: (h: AugmentedHold) => {
            return (
              `${this.$options.filters.toWeight(h.weight)}` +
              ` (${this.$options.filters.percentage(h.weightUtilization)} %)`
            );
          },
          sortBy: (h: AugmentedHold) => {
            return h.weight;
          },
        },
        grossWeight: {
          title: 'Weight (gross)',
          desc: 'Sum of all cargo weights plus tare',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.toWeight(h.grossWeight)}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.grossWeight;
          },
        },
        chargeable: {
          title: 'Chargeable weight',
          desc: 'The greater of actual weight and volumetric weight, the dimensional weight factor can be set in settings',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.toWeight(
              h.chargableWeight(this.settings.shipping_factor)
            )}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.chargableWeight(this.settings.shipping_factor);
          },
        },
        volume: {
          title: 'Volume',
          desc: 'Utilized volume',
          f: (h: AugmentedHold) => {
            return (
              `${this.$options.filters.toVolume(h.volume)}` +
              (h.volumeUtilization
                ? `(${this.$options.filters.percentage(h.volumeUtilization)} %)`
                : '')
            );
          },
          sortBy: (h: AugmentedHold) => {
            return h.volume;
          },
        },
        area: {
          title: 'Area',
          desc: 'Utilized area',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.toArea(h.usedArea, true)}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.usedArea;
          },
        },
        freeVolume: {
          title: 'Free volume',
          desc: 'Free volume',
          f: (h: AugmentedHold) => {
            return (
              (isNaN(h.freeVolumePercentage)
                ? ''
                : `${this.$options.filters.toVolume(h.freeVolume)}`) +
              (h.freeVolumePercentage
                ? `(${this.$options.filters.percentage(h.freeVolumePercentage)} %)`
                : '')
            );
          },
          sortBy: (h: AugmentedHold) => {
            return h.freeVolume;
          },
        },
        usedSpace: {
          title: 'Used space',
          desc: 'Used length, width and height',
          f: (h: AugmentedHold) => {
            return `L: ${this.$options.filters.toLength(
              h.usedLength
            )}\nW: ${this.$options.filters.toLength(
              h.usedWidth
            )}\nH: ${this.$options.filters.toLength(h.usedHeight)}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.usedLength * h.usedWidth * (h.usedHeight || 1);
          },
        },
        fullHeight: {
          title: 'Full height',
          desc: 'Weight from ground to the top of the highest cargo',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.toLength(h.fullHeight)}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.fullHeight;
          },
        },
        freeSpace: {
          title: 'Free space',
          desc: 'Free space length, width and height',
          f: (h: AugmentedHold) => {
            return `L: ${this.$options.filters.toLength(
              Math.max(h.freeLength, 0)
            )}\nW: ${this.$options.filters.toLength(
              Math.max(h.freeWidth, 0)
            )}\nH: ${this.$options.filters.toLength(Math.max(h.freeHeight, 0))}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.freeLength * h.freeWidth * (h.freeHeight || 1);
          },
        },
        oog: {
          title: 'Out of Gauge',
          desc: 'Out of Gauge metrics in each dimension',
          f: (h: AugmentedHold) => {
            return h.oogValues
              .map(
                (i) =>
                  `${i.title}: ${i.type === 'length' ? this.$options.filters.toLength(i.value) : this.$options.filters.toWeight(i.value)}`
              )
              .join('\n');
          },
          sortBy: (h: AugmentedHold) => {
            return h.usedLength * h.usedWidth * (h.usedHeight || 1);
          },
        },
        shipments: {
          title: 'Shipments',
          desc: 'List of groups/shipments',
          f: (h: AugmentedHold) => {
            return `${h.hold.shipments?.length ? h.hold.shipments.join(',\n') : ''}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.hold.shipments?.length ? h.hold.shipments.join(',') : 1;
          },
        },
        classes: {
          title: 'Classes',
          desc: 'List of classes',
          f: (h: AugmentedHold) => {
            return `${[...new Set(h.cargoes.filter((i) => i.class_id).map((i) => i.class_id))].join(
              ', '
            )}`;
          },
          sortBy: (h: AugmentedHold) => {
            return [...new Set(h.cargoes.filter((i) => i.class_id).map((i) => i.class_id))];
          },
        },
        prios: {
          title: 'Priorities',
          desc: 'Max and min priorities',
          f: (h: AugmentedHold) => {
            const prios = h.cargoes.filter((i) => i.priority).map((i) => i.priority);
            if (prios.length) {
              const max = Math.max(...prios);
              const min = Math.min(...prios);
              return `${max}${min != max ? '-' + min : ''}`;
            }
            return '';
          },
          sortBy: (h: AugmentedHold) => {
            return Math.max(...h.cargoes.map((i) => i.priority));
          },
        },
        ldm: {
          title: 'Loading meters',
          desc: 'Mainly used in the EU. Sum of all cargoes on the ground divided by 2.4',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.roundTwoDecimals(h.ldm)} M`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.ldm;
          },
        },
        linearFeet: {
          title: 'Linear feet',
          desc: 'Mainly used in the North America. Total length being utilized. If less than half width is used, only half of that section length is used.',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.roundTwoDecimals(h.linearFeet)} FT`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.linearFeet;
          },
        },
        longestItem: {
          title: 'Longest item',
          desc: 'Longest item in container',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.toLength(h.longestItem)}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.longestItem;
          },
        },
        widestItem: {
          title: 'Widest item',
          desc: 'Widest item in container',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.toLength(h.widestItem)}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.widestItem;
          },
        },
        tallestItem: {
          title: 'Tallest item',
          desc: 'Tallest item in container',
          f: (h: AugmentedHold) => {
            return `${this.$options.filters.toLength(h.tallestItem)}`;
          },
          sortBy: (h: AugmentedHold) => {
            return h.tallestItem;
          },
        },
      } as { [key: string]: ColumnProperty },
    };
  },
  watch: {
    orderBy: function (a): void {
      if (this.$route.query.orderby != a)
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { ...this.$route.query, orderby: a },
        });
    },
    isAsc: function (a): void {
      if (this.$route.query.asc != a)
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { ...this.$route.query, asc: a },
        });
    },
    groupSimilar: function (a: boolean): void {
      if (this.$route.query.groupSimilar != String(a))
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { ...this.$route.query, groupSimilar: String(a) },
        });

      this.changeResultPage(1);
    },
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
    selectedColumns(): string[] {
      return this.preferences?.visible_workspace_columns ?? this.defaultColumns;
    },
    customMetrics(): CustomMetric[] {
      return this.miscStore?.company.settings?.metrics || [];
    },
    allColumns(): { [key: string]: ColumnProperty } {
      return Object.fromEntries(
        Object.entries(this.columns).concat(
          this.customMetrics
            .filter((m) => m.summable)
            .map((m) => {
              const calculate = (hold: AugmentedHold) =>
                hold.cargoes
                  .map((item) => tableUtils.calculateFormula(m.formula, item, hold.hold))
                  .reduce((a, b) => a + b, 0);
              return [
                m.name,
                {
                  title: `${m.name}`,
                  desc: `Custom metric: ${m.name}`,
                  f: (hold: AugmentedHold) => {
                    const v = calculate(hold);
                    switch (m.unit) {
                      case 'm':
                        return `${this.$options.filters.toLength(v)}`;
                      case 'kg':
                        return `${this.$options.filters.toWeight(v)}`;
                      default:
                        return `${v}`;
                    }
                  },
                  sortBy: (hold: AugmentedHold) => {
                    return calculate(hold);
                  },
                } as ColumnProperty,
              ];
            })
        )
      );
    },
    columnsAsList(): { key: string; desc: string; title: string; metric?: CustomMetric }[] {
      return Object.entries(this.allColumns).map(([key, value]) => {
        return {
          key,
          title: value.title,
          desc: value.desc,
        };
      });
    },
    defaultColumns(): string[] {
      return Object.keys(this.columns).filter((i) =>
        ['name', 'itemsCount', 'weight', 'volume', 'usedSpace'].includes(i)
      );
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xsOnly;
    },
    sceneWidth(): number {
      if (this.$vuetify.breakpoint.lgAndDown) return 400;
      return 500;
    },
    sceneHeight(): number {
      return this.sceneWidth * 0.5;
    },
    sortedHolds(): AugmentedHold[] {
      let holds = this.holds.map((h) => new AugmentedHold(h));
      if (this.orderBy.length > 0) {
        holds = [...holds].sort((a, b) => {
          return (
            (this.isAsc ? 1 : -1) *
            (this.allColumns[this.orderBy].sortBy(a) > this.allColumns[this.orderBy].sortBy(b)
              ? 1
              : -1)
          );
        });
      }

      return holds.slice(
        (this.currentPage - 1) * this.holdsPerPage,
        (this.currentPage - 1) * this.holdsPerPage + this.holdsPerPage
      );
    },
    holdsPerPage(): number {
      if (this.print) {
        return 1000;
      }
      return HOLDS_PER_PAGE;
    },
    currentPage(): number {
      if (this.print || this.searchInput) {
        return 1;
      }
      return this.currentResultPage;
    },
    noResultPageButtons(): number {
      return Math.ceil(Math.max(this.holds.length / this.holdsPerPage, 1));
    },
    typeName(): string {
      return this.$typeNames(this.loadlist.list_type);
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    loadplan_version(): number {
      return this.loadlistStore.loadplan_version;
    },
    liteVersion(): boolean {
      return this.miscStore.lite_version;
    },
    filteredSets(): Map<string, any> {
      return new Map(
        this.sets
          .filter((s) => s.containers.length > 1)
          .map((s, i) => [s.uuid, { name: `${i + 1} - ${s.name}` }])
      );
    },
    numberOfColumns(): number {
      return 2 + this.selectedColumns.length;
    },
  },
  created(): void {
    if (this.$route.query.page !== undefined) {
      this.currentResultPage = parseInt(this.$route.query.page as string, 10);
    }

    this.isAsc = this.$route.query.asc === 'true';
    this.orderBy = (this.$route.query.orderby as string) || '';
  },
  methods: {
    changeResultPage(page: number): void {
      this.currentResultPage = page;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { ...this.$route.query, page: String(page) },
      });
    },
    checkRow(row: Indices): void {
      this.$emit('toggleSelectedRow', row);
    },
    dragstart(e: DragEvent, holdIndex: number) {
      e.dataTransfer.setData('text', JSON.stringify({ moveFromIndex: holdIndex }));
    },
    dropOnRow(e: DragEvent, holdIndex: number): void {
      this.draggedOverRow = null;
      const draggedData = JSON.parse(e.dataTransfer.getData('text'));

      if (draggedData.moveFromIndex !== undefined) {
        const holds = [...this.loadplan.holds];
        const hold = holds[draggedData.moveFromIndex];
        holds.splice(draggedData.moveFromIndex, 1);
        holds.splice(holdIndex, 0, hold);

        this.updateLoadplanHolds({
          replace: holds.length,
          holds: holds,
        });
      } else if (draggedData?.unloaded?.length) {
        this.loadlistStore
          .calculateLoadplan({
            items: draggedData.unloaded,
            containers: [this.loadplan.holds[holdIndex]],
          })
          .then((solution) => {
            if (solution.containers?.length) {
              this.updateLoadplanHolds({
                index: holdIndex,
                replace: 1,
                holds: solution.containers,
              });
            }
          });
      }
    },
    gotoInteractiveView(index: number): void {
      this.$emit('sceneClick', index);
    },
    updateLoadplanHolds(data: { index?: number; replace?: number; holds?: HoldData[] }): void {
      this.loadlistStore.updateLoadplanHolds(data);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media print {
  #workspaceTable thead tr th,
  #workspaceTable tbody tr td {
    font-size: 0.7rem !important;
    padding: 6px;
  }
  #workspaceTable thead tr th:nth-child(2),
  #workspaceTable tbody tr td:nth-child(2) {
    max-width: 70px;
    white-space: pre-wrap;
  }
}

/* #workspaceTable tr:hover {
  background: #fff !important;
} */
.draggedOver td {
  font-weight: bold;
}
</style>
