import { VCol } from 'vuetify/lib/components/VGrid';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,[_c(VCol,[(_vm.label)?_c(VLabel,{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c(VSelect,{staticStyle:{"max-width":"140px","margin-top":"0"},attrs:{"items":_vm.units},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}}),_c(VTextField,_vm._g(_vm._b({staticStyle:{"margin-top":"0","margin-left":"2px"},attrs:{"value":_vm.displayValue,"min":"0","type":"number","error-messages":_vm.errors,"hint":`The factor used to calculate volumetric weight. Generally ranges between ${_vm.examples}`}},'v-text-field',_vm.$attrs,false),_vm.inputListeners))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }