import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"items":_vm.loadplanVersions,"label":"Current loadplan version"},model:{value:(_vm.loadplan_version),callback:function ($$v) {_vm.loadplan_version=$$v},expression:"loadplan_version"}}),_c(VBtn,{attrs:{"ripple":false},on:{"click":function($event){return _vm.addLoadplanVersion()}}},[_vm._v("Add new version")]),_c(VBtn,{staticClass:"ml-2",attrs:{"ripple":false,"disabled":_vm.loadplanVersions.length <= 1},on:{"click":_vm.removeLoadplanVersion}},[_vm._v("Remove this version")])],1),_c(VCol,[_c(VTextarea,{attrs:{"label":"Notes:","outlined":"","rows":"3"},model:{value:(_vm.loadplan_notes),callback:function ($$v) {_vm.loadplan_notes=$$v},expression:"loadplan_notes"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }