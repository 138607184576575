<template>
  <div>
    <v-btn color="success" @click="addRule"> <v-icon>mdi-plus</v-icon>Add new rule </v-btn>
    <v-expansion-panels class="py-4 px-1" ref="loadRules" v-model="panel">
      <v-expansion-panel v-for="(ruleWithHeader, ruleIndex) in rulesWithHeaders" :key="ruleIndex">
        <v-expansion-panel-header>
          <p>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <!-- <v-switch
                  :value="!ruleWithHeader.rule.disabled"
                  @click.stop="toggleRule(ruleIndex)"
                  v-on="on"
                  icon
                  :ripple="false">
                </v-switch> -->
                <v-btn @click.stop="toggleRule(ruleIndex)" v-on="on" icon :ripple="false">
                  <v-icon v-if="ruleWithHeader.rule.disabled">mdi-checkbox-blank-outline</v-icon>
                  <v-icon v-else>mdi-checkbox-marked-outline</v-icon>
                </v-btn>
              </template>
              <span>Enable / disable this rule</span>
            </v-tooltip>
            <strong>{{ ruleWithHeader.title }}</strong>
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <load-rule-component
            :ref="'load-rule-' + ruleIndex"
            :itemRules="itemRules"
            :value="ruleWithHeader.rule"
            @input="(v) => updateRule(v, ruleIndex)"
            :key="ruleIndex"
            :ruleIndex="ruleIndex"
            :holds="holds"
            :items="items"
            :typeName="typeName"
            :class_ids="class_ids"
            @removeRule="removeRule" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-alert v-if="!rulesWithHeaders.length" type="info" outlined>No custom rules in use</v-alert>
  </div>
</template>

<script lang="ts">
import { HoldData, HoldInputItem, ItemSelector, LoadRule } from '@/models/LoadlistModel';
import Vue, { PropType } from 'vue';
import LoadRuleComponent from './LoadRule.vue';
import itemProperties, { CustomItemProperty, ItemProperty } from '@/misc/itemProperties';
import { useMiscStore } from '@/stores/miscStore';
import { mapStores } from 'pinia';

export default Vue.extend({
  name: 'custom-rules-editor',
  components: {
    LoadRuleComponent,
  },
  data: function () {
    return {
      panel: null,
      rulesWithHeaders: [] as { rule: LoadRule; title: string }[],
      itemRules: [
        {
          text: 'Cannot be loaded in',
          value: 'cannot_be_loaded_in',
        },
        {
          text: 'Cannot be loaded with',
          value: 'cannot_be_loaded_with',
        },
        {
          text: 'Cannot exceed quantity',
          value: 'max_qty',
        },
        {
          text: 'Cannot exceed SKU types',
          value: 'max_item_types',
        },
        {
          text: 'Cannot support',
          value: 'cannot_support',
        },
        {
          text: 'Can only support',
          value: 'can_only_support',
        },
        {
          text: 'Must be on top of',
          value: 'must_be_on_top_of',
        },
        {
          text: 'Max height',
          value: 'max_height',
        },
        {
          text: 'Max stacking height',
          value: 'max_stacking_height',
        },
        {
          text: 'Must be loaded in',
          value: 'must_be_loaded_in',
        },
        {
          text: 'Must be loaded before',
          value: 'must_be_loaded_before',
        },
        {
          text: 'Must be loaded first',
          value: 'must_be_loaded_first',
        },
        {
          text: 'Must be loaded along centerline',
          value: 'must_be_loaded_along_centerline',
        },
        {
          text: 'Must be loaded into secondary (palletize) containers',
          value: 'load_into_secondary',
        },
        {
          text: 'Must load with pattern',
          value: 'must_load_with_pattern',
        },
      ],
    };
  },
  props: {
    rules: Array as PropType<LoadRule[]>,
    typeName: String,
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    class_ids: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
  },
  computed: {
    ...mapStores(useMiscStore),
    columnNames(): { key: string; text: string }[] {
      return [
        ...itemProperties.props().map((i) => {
          return { key: i.key, text: i.text };
        }),
        ...this.extraColumns.map((i) => {
          return {
            key: i.name,
            text: i.name,
          };
        }),
      ];
    },
    extraColumns(): CustomItemProperty[] {
      return this.miscStore?.company_settings?.extra_columns || [];
    },
  },
  watch: {
    rules: {
      handler: function (rules: LoadRule[]): void {
        if (rules) {
          this.setRulesWithHeaders(rules);
        }
      },
      immediate: true,
    },
  },
  methods: {
    addRule(): void {
      this.$emit('addRule', {
        items: [
          {
            property: 'sku',
            comparison: 'eq',
            value: 0,
            scope: 'cargo',
          },
        ],
        condition: 'cannot_be_loaded_in',
        value: null,
        in_containers_with_ids: [],
      } as LoadRule);
      this.panel = (this.$refs as any).loadRules.items.length;
    },
    removeRule(i: number): void {
      this.panel = null;
      this.$emit('removeRule', i);
    },
    toggleRule(i: number): void {
      this.rules[i].disabled = !this.rules[i].disabled;
    },
    updateRule(v: any, ruleIndex: number): void {
      this.rules[ruleIndex] = v;
      this.setRulesWithHeaders(this.rules);
    },
    getRuleName(rule: LoadRule, ruleIndex: number): string {
      const itemSelectors = (
        Array.isArray(rule.items) ? rule.items : [rule.items]
      ) as ItemSelector[];
      const propertyName = itemSelectors
        .map((is) => {
          return (
            this.columnNames.find((prop) => prop.key === is?.property)?.text || 'Selected items'
          );
        })
        .join(' AND ');
      // this.itemProperties.find((prop) => prop.key === itemSelector?.property)?.text ||
      // 'Selected Items';
      const conditionName = this.itemRules.find(
        (itemRule) => itemRule.value === rule.condition
      ).text;
      const numberOfContainers = rule.in_containers_with_ids.length;
      return `Rule ${ruleIndex + 1} - ${propertyName} → ${conditionName} → ${
        numberOfContainers > 0
          ? `${numberOfContainers} ${this.typeName}(s)`
          : `Any ${this.typeName}s`
      }`;
    },
    setRulesWithHeaders(rules: LoadRule[]): void {
      this.rulesWithHeaders = rules.map((rule, index) => ({
        rule,
        title: this.getRuleName(rule, index),
      }));
    },
  },
});
</script>
