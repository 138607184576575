<template>
  <v-dialog v-model="show" width="600">
    <v-card :loading="isLoading">
      <v-card-title class="text-h5" primary-title>Quotation</v-card-title>

      <v-card-text>
        <v-select
          v-model="currency"
          :items="currencies"
          item-text="name"
          return-object
          required
          label="Select currency"></v-select>
        <div class="mb-4">Set default quotation per type</div>
        <div v-for="holdType in holdTypes" :key="holdType.id">
          <v-text-field
            type="number"
            :label="holdType.name"
            :suffix="currency.code"
            v-model="quotations[holdType.id]"></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click.stop="$emit('close', false)">Close</v-btn>
        <v-spacer />
        <v-btn class="primary" text @click.stop="updateQuotation">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import { Currencies } from '@/misc/currencies';
import { KeyValue } from '@/models/LoadlistModel';
export default Vue.extend({
  name: 'quotation',
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    quotations: {
      type: Object as PropType<KeyValue>,
      default: () => ({}) as KeyValue,
    },
    holdTypes: {
      type: Array as PropType<{ id: number; name: string }[]>,
      default: () => [] as { id: number; name: string }[],
    },
  },
  data() {
    return {
      isLoading: false,
      currency: Currencies.find((c) => c.code === 'USD'),
      currencies: Currencies,
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    updateQuotation(): void {
      this.$emit('update', {
        currency: this.currency,
        quotationsForTypes: this.quotations,
      });
      this.$emit('close', false);
    },
  },
});
</script>
<style scoped></style>
