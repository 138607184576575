<template>
  <div>
    <v-alert v-if="withLine" type="info" outlined
      >While the optimal route has been calculated, only straight lines are shown between stops on
      the map
    </v-alert>
    <div id="mapContainer" style="height: 600px; width: 100%" class="px-2"></div>
  </div>
</template>

<script lang="ts">
import mapboxgl, {
  LngLatBounds,
  Map,
  Marker,
  Popup,
  // @ts-ignore
} from 'mapbox-gl/dist/mapbox-gl-csp';

import { Feature, FeatureCollection } from 'geojson';
import Vue, { PropType } from 'vue';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
import mbx from '@mapbox/mapbox-sdk/services/geocoding';
import { Coordinates } from '@/models/LoadlistModel';
interface ExtCoordinates extends Coordinates {
  color: string;
  label: string;
  destination: string;
}
export default Vue.extend({
  name: 'mapbox-map',
  data: function () {
    return {
      map: undefined as Map,
      currentMarkers: undefined as Marker[],
      client: undefined as any,
    };
  },
  props: {
    points: Array as PropType<ExtCoordinates[]>,
    pol: String,
    pod: String,
    withLine: Boolean,
  },
  computed: {
    merged(): {
      lat: number;
      lng: number;
      color: string;
      destination: string;
      labels: {
        label: string;
        count: number;
      }[];
    }[] {
      const merged = [] as {
        lat: number;
        lng: number;
        destination: string;
        color: string;
        labels: { label: string; count: number }[];
      }[];
      this.points.forEach((p) => {
        const index = merged.findIndex((m) => m.lat == p.lat && m.lng == p.lng);
        if (index < 0) {
          merged.push({
            labels: [{ label: (p as any).label, count: 1 }],
            ...p,
          });
        } else {
          const label = (p as any).label;
          const labelIndex = merged[index].labels.findIndex((l) => l.label == label);
          if (labelIndex < 0) {
            merged[index].labels.push({ label, count: 1 });
          } else {
            merged[index].labels[labelIndex].count += 1;
          }
        }
      });
      return merged;
    },
  },
  mounted(): void {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;

    mapboxgl.workerUrl = new URL('mapbox-gl/dist/mapbox-gl-csp-worker', import.meta.url);
    this.client = mbx({ accessToken: mapboxgl.accessToken });
    this.createMap();
    this.mapSetup();
  },
  watch: {
    points() {
      this.createMap();
      this.mapSetup();
    },
  },
  methods: {
    createMap(): void {
      if (!this.map) {
        this.map = new Map({
          container: 'mapContainer',
          style: 'mapbox://styles/mapbox/light-v10',
          center: [0, 0],
          zoom: 1,
        });
      }
    },
    getBounds(
      pol: [number, number],
      pod: [number, number]
    ): {
      min: [number, number];
      max: [number, number];
    } {
      const joined = this.points.map((a) => [a.lat, a.lng] as [number, number]);
      if (pol) {
        joined.push([pol[1], pol[0]]);
      }
      if (pod) {
        joined.push([pod[1], pod[0]]);
      }
      const min = joined.reduce((a, b) => {
        return [a[0] < b[0] ? a[0] : b[0], a[1] < b[1] ? a[1] : b[1]];
      });
      const max = joined.reduce((a, b) => {
        return [a[0] < b[0] ? b[0] : a[0], a[1] < b[1] ? b[1] : a[1]];
      });
      const diff = [max[0] - min[0], max[1] - min[1]] as [number, number];
      return {
        min,
        max,
      };
    },
    getGeoPoints(pol: [number, number], pod: [number, number]): FeatureCollection {
      const polFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: pol,
        },
        properties: {
          title: `Port of Loading: ${this.pol}`,
          description: '',
          color: 'rgb(70, 120, 178)',
        },
      } as Feature;
      const podFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: pod,
        },
        properties: {
          title: `Port of Destination: ${this.pod}`,
          description: '',
          color: 'rgb(70, 120, 178)',
        },
      } as Feature;
      const features = [] as Feature[];
      if (pol) {
        features.push(polFeature);
      }
      features.push(
        ...this.merged.map((point, index) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [point.lng, point.lat] as [number, number],
            },
            properties: {
              title: `${index + 1}. ${point.destination}`,
              description: point.labels
                .map(({ label, count }) => `${label} - ${count} pcs`)
                .join('<br/>'),
              color: point.color,
            },
          } as Feature;
        })
      );
      if (pod) {
        features.push(podFeature);
      }

      return {
        type: 'FeatureCollection',
        features,
      };
    },
    getLineOfTravel(geoPoints: FeatureCollection): Feature {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: geoPoints.features.map(
            (p) => (p.geometry as any).coordinates as [number, number]
          ),
        },
      };
    },
    async mapSetup(): Promise<void> {
      if (!this.points || this.points?.length == 0) {
        return;
      }
      const pol = await this.polCoordinates();
      const pod = await this.podCoordinates();
      const { min, max } = this.getBounds(pol, pod);
      const geoPoints = this.getGeoPoints(pol, pod);
      if (this.currentMarkers !== undefined) {
        this.currentMarkers.forEach((marker) => {
          marker.remove();
        });
      }
      this.currentMarkers = [];

      const setupMap = () => {
        this.map.resize();
        this.map.fitBounds(new LngLatBounds([min[1], min[0]], [max[1], max[0]]), {
          padding: 40,
          maxZoom: 10,
          duration: 800,
        });
        // html points
        geoPoints.features.forEach((p) => {
          const marker = new Marker({ color: p.properties.color })
            .setLngLat((p.geometry as any).coordinates)
            .setPopup(
              new Popup().setHTML(
                `<div><b>${p.properties.title}</b><br/>${p.properties.description}</div>`
              )
            )
            .addTo(this.map);
          this.currentMarkers.push(marker);
        });
        if (this.map.getLayer('route')) {
          this.map.removeLayer('route');
          this.map.removeSource('line');
        }
        if (this.withLine) {
          // Line of travel
          this.map.addSource('line', {
            type: 'geojson',
            data: this.getLineOfTravel(geoPoints),
          });
          this.map.addLayer({
            id: 'route',
            type: 'line',
            source: 'line',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#888',
              'line-width': 8,
            },
          });
        }
      };

      if (this.map.loaded()) {
        setupMap();
      } else {
        this.map.on('load', () => {
          setupMap();
        });
      }
    },
    polCoordinates(): Promise<[number, number]> {
      return this.geocode(this.pol);
    },
    podCoordinates(): Promise<[number, number]> {
      return this.geocode(this.pod);
    },
    async geocode(name: string): Promise<[number, number]> {
      if (!name) return;
      return this.client
        .forwardGeocode({ query: name, limit: 1 })
        .send()
        .then((response: any) => {
          return response.body.features[0].center;
        });
    },
  },
});
</script>
