import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Sharing")]),_c(VCardText,[_c(VAlert,{attrs:{"type":"info","outlined":""}},[_vm._v(" You can provide access to a load plan through a sharable link which does not require authentication ")]),_c(VSelect,{attrs:{"solo":"","items":_vm.publicAccessOptions},model:{value:(_vm.public_access),callback:function ($$v) {_vm.public_access=$$v},expression:"public_access"}}),_c(VTextField,{ref:"urlTextField",attrs:{"disabled":!_vm.public_access,"single-line":"","readonly":"","outlined":"","value":_vm.thisUrl},on:{"click":_vm.selectLink},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,{attrs:{"icon":"","color":"primary","disabled":!_vm.public_access},on:{"click":_vm.copyLink}},[_c(VIcon,_vm._g({},on),[_vm._v("fa-clone")])],1)]}}])},[_vm._v(" Copy link to clipboard ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.loadlist)?_c(VBtn,{attrs:{"href":`mailto:?subject=Cargo-Planner Load plan: ${_vm.loadlist.name}&body=${_vm.thisUrl}`,"icon":"","disabled":!_vm.public_access,"color":"primary"}},[_c(VIcon,_vm._g({},on),[_vm._v("fa-share")])],1):_vm._e()]}}])},[_vm._v(" Send in email ")])]},proxy:true}])}),_c('strong',[_vm._v("Notice:")]),_vm._v(" When sharing is enabled - anyone who enters above URL will be able to access this load plan. Evaluate this if doing very confidential work and be restrictive to whom you share the link with. Treat it like a secret. ")],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.show = false}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }