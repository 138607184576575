<template>
  <div @keydown="cellKeyDown" @contextmenu="showMenu" tabindex="1">
    <div
      @paste="pasteData"
      @copy="copyOrCutData(false)"
      @cut="copyOrCutData(true)"
      ref="eventDiv"
      class="eventDiv"
      contenteditable="true"></div>

    <div ref="wrapper" @scroll="stopEditMode" class="rowWrapper">
      <div :style="{ height: `${itemHeight}px` }">
        <div class="th" :style="{ width: `${firstColumnWidth}px`, padding: 0 }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                :disabled="disableFirstCell"
                v-on="on"
                icon
                small
                @click="$emit('firstCellClicked')">
                <v-icon>mdi-table-cog</v-icon>
              </v-btn>
            </template>
            <span>Table settings</span>
          </v-tooltip>
        </div>
        <v-tooltip top v-for="(header, headerIndex) in headers" v-bind:key="header.key">
          <template v-slot:activator="{ on }">
            <div
              class="th th-top"
              :style="[
                {
                  width: `${headerSizes[headerIndex]}px`,
                  fontWeight: header.required ? `bold` : `normal`,
                },
              ]"
              v-on="on"
              @click="selectColumn(headerIndex)"
              :class="[
                {
                  dark: $vuetify.theme.dark,
                  thSelected:
                    headerIndex >= selectedCells.start.col && headerIndex <= selectedCells.end.col,
                },
              ]">
              <div v-if="header.input == 'checkbox'" style="display: flex">
                <span>
                  <input
                    type="checkbox"
                    :disabled="disabled"
                    style="margin-right: 4px"
                    :checked="!!filledRows.length && filledRows.every((i) => i[header.key])"
                    :ripple="false"
                    @click.stop="toggleBooleanColumn(header.key)" />
                  {{ header.text }}</span
                >
              </div>
              <span v-else>{{ header.text }}</span>
              <div class="resizeHandle" @mousedown="(e) => resize(e, headerIndex)"></div>
              <div class="filter-by" v-if="header.type == 'string'">
                <input
                  v-if="filterBy == header.key"
                  :style="[{ width: `${headerSizes[headerIndex] - 10}px` }]"
                  autocomplete="off"
                  type="search"
                  name="query"
                  @keydown.esc="(e) => toggleFilterBy(e, filterBy)"
                  @input="filterType"
                  @click="filterClick" />
                <v-btn icon x-small @click="(e) => toggleFilterBy(e, header.key)">
                  <v-icon v-if="filterBy !== header.key">mdi-filter-outline</v-icon>
                  <v-icon v-else>mdi-close-circle-outline</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <span>{{ header.desc }} </span>
        </v-tooltip>
      </div>
      <v-virtual-scroll
        ref="virtualScroller"
        :items="filteredObjects"
        :item-height="itemHeight"
        bench="20"
        :max-height="tableHeight"
        :width="scrollViewWidth"
        max-width="none"
        style="overflow-x: hidden"
        @scroll="virtualScrollin">
        <template v-slot="{ item, index }">
          <div
            :class="{
              'row-even': index % 2 == 0,
              'row-odd': index % 2 != 0,
              dark: $vuetify.theme.dark,
              focused: index === focusRow,
            }"
            :id="index === focusRow ? 'focusedRow' : ''">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  v-on="!!item.warnings ? on : {}"
                  class="th"
                  :style="{
                    width: `${firstColumnWidth}px`,
                  }"
                  @click="selectRow(item.index)"
                  :class="[
                    {
                      dark: $vuetify.theme.dark,

                      thSelected:
                        item.index >= selectedCells.start.row &&
                        item.index <= selectedCells.end.row,
                    },
                    { warningInfo: !!item.warnings },
                  ]">
                  {{ item.index + 1 }}
                </div>
              </template>
              <div>
                <div v-for="warning in item.warnings" :key="warning">
                  {{ warningDesc(warning) }}
                </div>
              </div>
            </v-tooltip>
            <div
              v-for="(column, columnIndex) in headers"
              class="td"
              :style="{ width: `${headerSizes[columnIndex]}px`, position: 'relative' }"
              v-bind:key="column.key"
              @mousedown="mouseDown($event, item.index, columnIndex)"
              @mousemove="mouseMove($event, item.index, columnIndex)"
              @mouseup="
                mouseIsDown = false;
                dragCopy = false;
                dragCopyMemory.clear();
              "
              @touchstart="touchStart($event, item.index, columnIndex)"
              @dblclick="dblClick($event, item.index, columnIndex)"
              :class="[
                {
                  primaryCell: item.index === primaryCell.row && columnIndex === primaryCell.col,
                },
                {
                  selectedCells:
                    (selectedCells.end.row - selectedCells.start.row > 0 ||
                      selectedCells.end.col - selectedCells.start.col > 0) &&
                    item.index >= selectedCells.start.row &&
                    columnIndex >= selectedCells.start.col &&
                    item.index <= selectedCells.end.row &&
                    columnIndex <= selectedCells.end.col,
                },
              ]">
              <input
                v-if="column.input === 'checkbox'"
                :disabled="disabled"
                type="checkbox"
                :checked="item[column.key]"
                @input="setValue(item.index, column.key, $event.target.checked)" />
              <!-- <div
                v-else-if="column.input === 'color'"
                :disabled="disabled"
                style="width: 100%; height: 100%"
                v-bind:style="{
                  'background-color': item[column.key] || '',
                }"
              ></div> -->
              <input
                type="color"
                v-else-if="column.input === 'color'"
                :disabled="disabled"
                style="width: 100%; height: 100%"
                @input="setValue(item.index, column.key, $event.target.value)"
                :value="item[column.key] || '#ffffff'" />

              <select
                v-else-if="column.input === 'select'"
                class="td-select"
                :style="{ color: $vuetify.theme.dark ? 'white' : '' }"
                :disabled="disabled"
                :value="item[column.key]"
                @change="setValue(item.index, column.key, $event.target.value)">
                <option v-for="value in column.values" :key="value.key" :value="value.key">
                  {{ value.text }}
                </option>
              </select>
              <orientation-picker
                v-else-if="column.input === 'orientations_picker'"
                style="margin-top: 0px; width: 110px"
                :value="item[column.key]"
                @input="setValue(item.index, column.key, $event)"
                :item="item"
                single-line
                dense
                flat></orientation-picker>

              <div
                v-else-if="column.input === 'hold_select'"
                @blur="cellBlur($event, item.index, columnIndex)"
                @paste.prevent="pasteData($event)"
                @input="autocomplete($event, column.key)"
                v-bind:style="{
                  color: disabled ? 'grey' : !$vuetify.theme.dark ? 'black' : 'white',
                }">
                <v-tooltip top v-if="item[column.key] && item[column.key].length">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" style="height: 100%; width: 100%">
                      {{
                        (
                          holdsLibrary.find((hl) => hl.id === item[column.key][0]) || {
                            name: 'Unknown',
                          }
                        ).name
                      }}...
                    </div>
                  </template>
                  <span style="white-space: pre-line"
                    >{{
                      (item[column.key] || [])
                        .map(
                          (h) =>
                            (
                              holdsLibrary.find((hl) => hl.id === h) || {
                                name: 'Unknown',
                              }
                            ).name
                        )
                        .join('\n')
                    }}
                  </span>
                </v-tooltip>
              </div>
              <div
                v-else-if="column.input === 'bundle_picker'"
                @blur="cellBlur($event, item.index, columnIndex)"
                @paste.prevent="pasteData($event)"
                @input="autocomplete($event, column.key)"
                v-bind:style="{
                  color: disabled ? 'grey' : !$vuetify.theme.dark ? 'black' : 'white',
                }">
                <v-tooltip top v-if="item[column.key] && item[column.key]?.bundles?.length">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" style="height: 100%; width: 100%">
                      {{ item[column.key].bundles.length }} bundle{{
                        item[column.key].bundles.length > 1 ? 's' : ''
                      }}
                    </div>
                  </template>
                  <span style="white-space: pre-line"
                    >{{ (item[column.key]?.bundles || []).map((b) => b.label).join('\n') }}
                  </span>
                </v-tooltip>
              </div>
              <div
                v-else
                v-bind:style="{
                  color: disabled ? 'grey' : !$vuetify.theme.dark ? 'black' : 'white',
                  background: 'transparent',
                }"
                @blur="cellBlur($event, item.index, columnIndex)"
                @paste.prevent="pasteData($event)"
                v-text="item[column.key]"
                @input="inputActions($event, column.key, item)"></div>
              <div
                v-if="
                  !disabled &&
                  selectedCells.end.row === item.index &&
                  selectedCells.end.col === columnIndex &&
                  (selectedCells.start.row === selectedCells.end.row || dragCopy)
                "
                class="bluedot"
                @mousedown="dragCopyStart"></div>
              <div
                v-if="
                  selectedCells.start.row === item.index &&
                  selectedCells.start.col === columnIndex &&
                  isAutocompleteColumn(column.key) &&
                  autocompleteItems.length
                "
                class="autocomplete"
                v-bind:style="{
                  background: $vuetify.theme.dark ? 'black' : 'white',
                }">
                <div
                  v-for="libItem in autocompleteItems"
                  :key="libItem.data.id"
                  :class="`${libItem.focus ? 'focus' : ''}`"
                  @click="fillWithItem(libItem)">
                  {{ libItem[libItem.prop] }}
                </div>
                <span class="tip">Cargo Library Autocomplete</span>
              </div>
              <div
                class="diameter text-caption grey--text text--darken-1"
                v-if="item.geometry == 'cylinder' && (column.key == 'l' || column.key == 'w')">
                <span v-if="isPrimary(item.index, columnIndex)">diameter</span>
                <span v-else>dia.</span>
              </div>
              <div
                class="diameter text-caption grey--text text--darken-1"
                v-if="item.geometry == 'hollow_cylinder' && column.key == 'l'">
                outer dia.
              </div>
              <div
                class="diameter text-caption grey--text text--darken-1"
                v-if="item.geometry == 'hollow_cylinder' && column.key == 'w'">
                inner dia.
              </div>
            </div>
          </div>
        </template>
      </v-virtual-scroll>
    </div>

    <v-menu v-model="menu.show" :position-x="menu.x" :position-y="menu.y" absolute offset-y>
      <v-list>
        <v-list-item :disabled="!undoBuffer.length" @click="undo()">
          <v-list-item-title
            >Undo <span class="float-right"><kbd>Ctrl</kbd> <kbd>Z</kbd></span></v-list-item-title
          >
        </v-list-item>
        <v-list-item :disabled="primaryCell.row === undefined" @click="copyOrCutData(false)">
          <v-list-item-title
            >Copy <span class="float-right"><kbd>Ctrl</kbd> <kbd>C</kbd></span></v-list-item-title
          >
        </v-list-item>
        <v-list-item :disabled="primaryCell.row === undefined" @click="copyOrCutData(true)">
          <v-list-item-title
            >Cut <span class="float-right"><kbd>Ctrl</kbd> <kbd>X</kbd></span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="primaryCell.row === undefined"
          @click="insertEmptyRow(primaryCell.row)">
          <v-list-item-title>Insert empty row</v-list-item-title>
        </v-list-item>
        <v-list-item :disabled="selectedCells.start.row === undefined" @click="deleteSelectedRows">
          <v-list-item-title>Delete selected rows</v-list-item-title>
        </v-list-item>
        <v-list-item @click="mergeSimilarRows">
          <v-list-item-title>Merge similar rows</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>

        <v-menu offset-x right open-on-hover>
          <template v-slot:activator="{ on }">
            <v-list-item @click.stop.prevent v-on="on">
              <v-list-item-title>Sort by...</v-list-item-title>
              <v-list-item-action>
                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item @click="sortByKey('sku')">
              <v-list-item-title>SKU</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sortByKey('label')">
              <v-list-item-title>Name</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sortByKey('l')">
              <v-list-item-title>Length</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sortByKey('w')">
              <v-list-item-title>Width</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sortByKey('h')">
              <v-list-item-title>Height</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sortByKey('wt')">
              <v-list-item-title>Weight</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sortByKey('shipment_id')">
              <v-list-item-title>Group/Shipment</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider></v-divider>

        <v-list-item @click="deleteAllRows">
          <v-list-item-title>Delete all rows</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          @click="item.cb"
          :disabled="item.disabled || (item.selectedRowRequired && isNaN(selectedCells.start.row))">
          <v-list-item-title>{{ item.title }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ItemProperty } from '@/misc/itemProperties';
import OrientationPicker from '@/components/Custom/OrientationPicker.vue';
import { MenuItem } from './index.vue';
import { Warning, shortDesc } from '@/misc/itemWarnings';
import { Cargo, LengthDim, WeightDim, HoldInputItem, Hold } from '@/models/LoadlistModel';
import { useMiscStore } from '@/stores/miscStore';
// If we need a maximum amount of rows, this makes sense. This is an upper limit
const MAX_ROWS = 100000;

// The minimal amount of rows that we will have in internalObjects
const MIN_ROWS = 200;
// How close we need to be to the edge before we start adding more rows to internalObjects
const BUFFER = 50;

interface AutocompleteItem extends Cargo {
  focus: boolean;
  query_match?: number;
  prop: string;
}
interface UndoTransactionValue {
  index: number;
  key: string;
  value: any;
}
interface UndoTransaction {
  primaryCell: {
    row: number;
    key: string;
  };
  values: UndoTransactionValue[];
}

interface HoldInputItemWithWarnings extends HoldInputItem {
  index: number;
  warnings: Warning[];
}

const autocompleteColumns = ['sku', 'label'] as const;
type AutocompleteColumn = (typeof autocompleteColumns)[number];

export default Vue.extend({
  name: 'sheet',
  components: { OrientationPicker },
  data() {
    return {
      internalObjects: [] as HoldInputItemWithWarnings[],
      primaryCell: {
        row: undefined,
        col: undefined,
      },
      selectedCells: {
        start: {
          row: undefined,
          col: undefined,
        },
        end: {
          row: undefined,
          col: undefined,
        },
        maxRow: undefined,
        minRow: undefined,
      },
      mouseIsDown: false,
      menu: {
        show: false,
        x: 0,
        y: 0,
      },
      isEditing: false,
      lastTouch: null as string,
      dragCopy: false,
      dragCopyMemory: new Map<number, any>(),
      autocompleteItems: [] as AutocompleteItem[],
      query: '',
      undoBuffer: [] as UndoTransaction[],
      resizing: undefined,
      headerSizes: this.headers.map((h) => JSON.parse(JSON.stringify(h.width))),
      bench: 20,
      filterBy: undefined,
      filterQuery: '',
      filterDelay: undefined,
    };
  },
  props: {
    value: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    headers: {
      type: Array as PropType<ItemProperty[]>,
      default: () => [] as ItemProperty[],
    },
    pasteColumnSplitPattern: {
      type: RegExp,
      default: () => /[xX*]/,
    },
    menuItems: {
      type: Array as PropType<MenuItem[]>,
      default: () => [] as MenuItem[],
    },
    firstColumnWidth: {
      type: Number,
      default: 40,
    },
    itemHeight: {
      type: Number,
      default: 30,
    },
    maxRows: {
      type: Number,
      default: MAX_ROWS,
    },
    cargoes: {
      type: Array as PropType<Cargo[]>,
      default: () => [] as Cargo[],
    },
    warnings: {
      type: Array as PropType<{ id: Warning; indexes: number[] }[]>,
      default: () => [] as { id: Warning; indexes: number[] }[],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultRow: {
      type: Object as PropType<HoldInputItem>,
      default: () => {
        return {} as HoldInputItem;
      },
    },
    focusRow: {
      type: Number,
      default: null,
    },
    lengthDim: String as PropType<LengthDim>,
    weightDim: String as PropType<WeightDim>,
    disableFirstCell: Boolean,
    disableAutocomplete: Boolean,
  },
  watch: {
    warnings: {
      handler(val) {
        this.internalObjects = JSON.parse(JSON.stringify(this.internalObjects));
        this.internalObjects.forEach((i) => (i.warnings = undefined));

        this.warnings
          .flatMap((w) =>
            w.indexes.map((i) => {
              return { i, ids: [w.id] };
            })
          )
          .filter(({ i, ids }, index, list) => {
            const first = list.findIndex((a) => a.i == i);
            if (first == index) {
              return true;
            } else {
              list[first].ids.push(...ids);
            }
          })
          .forEach(({ i, ids }) => {
            Vue.set(this.internalObjects[i], 'warnings', ids);
          });
      },
      immediate: false,
    },
    value: {
      handler(val) {
        this.internalObjects = JSON.parse(JSON.stringify(val))
          .slice(0, this.maxRows)
          .map((i: HoldInputItemWithWarnings, index: number) => {
            i.index = index;
            return i;
          });
        this.extendInternalObjects();
      },
      immediate: true,
    },
    primaryCell: {
      handler: function (val, oldVal) {
        this.resetSelectedCells();
        if (oldVal.row !== undefined && oldVal.col !== undefined) {
          if (this.isEditing) this.stopEditMode();
        }

        this.selectedCells.start.col = val.col;
        this.selectedCells.start.row = val.row;
        this.selectedCells.end.col = val.col;
        this.selectedCells.end.row = val.row;
        this.$emit('selected-row', this.primaryCell.row);
      },
      deep: true,
    },
    defaultRow: {
      handler(val) {
        this.internalObjects = this.filledRows as HoldInputItemWithWarnings[];
        this.extendInternalObjects();
      },
    },
    headers: {
      handler(val) {
        this.headerSizes = JSON.parse(JSON.stringify(this.headers.map((h) => h.width)));
      },
    },
  },
  computed: {
    filteredObjects(): HoldInputItemWithWarnings[] {
      const indexed = this.internalObjects.map((i, index) => ({ ...i, index }));
      if (this.filterBy && this.filterQuery) {
        let q = this.filterQuery.toLowerCase().trim();
        let filtered = indexed.filter((i) =>
          String(i[this.filterBy as keyof HoldInputItem])
            .toLowerCase()
            .includes(q)
        );
        return filtered;
      }
      return indexed;
    },
    tableHeight(): number {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 200;
        case 'sm':
          return 400;
        case 'md':
          return 500;
        case 'lg':
          return 500;
        case 'xl':
          return 800;
      }
    },
    wrapperRef(): any {
      return this.$refs.wrapper;
    },
    virtualScrollerRef(): any {
      return this.$refs.virtualScroller;
    },
    eventDivRef(): any {
      return this.$refs.eventDiv;
    },
    scrollViewWidth(): number {
      // Starting at 15 to make room for the scroll-bar on the far right
      // The scroll bar is actually different sizes, but 15 seems to cover all of them
      // This leaves a visual bug where there is an empty gap to the scroll bar in some browsers.
      return this.headerSizes.reduce((prev, next) => prev + next) + 15 + this.firstColumnWidth;
    },
    filledRows(): HoldInputItem[] {
      return this.internalObjects.filter((i) => i.l && i.w && i.h && i.wt);
    },
    holdsLibrary(): Hold[] {
      return useMiscStore().holds;
    },
  },
  mounted(): void {
    document.addEventListener('mousemove', this.documentMouseMove);
    if (this.focusRow !== null && this.focusRow >= 0) {
      this.setPrimaryCell(0, this.focusRow);
    }
  },
  beforeDestroy(): void {
    document.removeEventListener('mousemove', this.documentMouseMove);
    document.removeEventListener('mouseup', this.resizeStop);
    document.removeEventListener('mousemove', this.resizeMove);
  },
  methods: {
    resizeStop(): void {
      this.resizing = undefined;
      document.removeEventListener('mouseup', this.resizeStop);
      document.removeEventListener('mousemove', this.resizeMove);
    },
    resize(e: MouseEvent, headerIndex: number): void {
      this.resizing = {
        index: headerIndex,
        x: e.clientX,
        initial: this.headerSizes[headerIndex],
      };
      document.addEventListener('mouseup', this.resizeStop);
      document.addEventListener('mousemove', this.resizeMove);
    },
    resizeMove(e: MouseEvent): void {
      const offset = e.clientX - this.resizing.x;
      this.headerSizes[this.resizing.index] = Math.max(30, this.resizing.initial + offset);
      this.headerSizes = JSON.parse(JSON.stringify(this.headerSizes));
    },
    virtualScrollin(): void {
      if (this.virtualScrollerRef._data.last + BUFFER > this.internalObjects.length) {
        this.extendInternalObjects();
      }
    },
    extendInternalObjects(count?: number): void {
      const initialLength = this.internalObjects.length;
      for (
        let i = initialLength;
        i < Math.min(this.maxRows, initialLength + (count || MIN_ROWS));
        i++
      ) {
        this.internalObjects.push(
          JSON.parse(JSON.stringify(this.defaultRow)) as HoldInputItemWithWarnings
        );
      }
    },
    getObjects(): HoldInputItem[] {
      return JSON.parse(JSON.stringify(this.internalObjects));
    },
    getObject(index: number): HoldInputItem {
      if (
        index !== undefined &&
        index >= 0 &&
        this.internalObjects.length > index &&
        this.internalObjects[index]
      ) {
        return JSON.parse(JSON.stringify(this.internalObjects[index]));
      }
      return null;
    },
    warningDesc(w: Warning): string {
      return shortDesc(w);
    },
    isPrimary(row: number, column: number): boolean {
      return this.primaryCell.row == row && this.primaryCell.col == column;
    },
    showMenu(e: MouseEvent): void {
      e.preventDefault();
      this.menu.show = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;
      this.$nextTick(() => {
        this.menu.show = true;
      });
    },
    setPrimaryCell(col: number, row: number): void {
      this.autocompleteItems = [];
      this.primaryCell = {
        col: col === undefined ? this.primaryCell.col : col,
        row: row === undefined ? this.primaryCell.row : row,
      };
      this.$nextTick(() => {
        this.setVisibleCell();
      });
    },
    dragCopyStart() {
      this.dragCopy = true;
      this.stopEditMode();
      this.selectedCells.maxRow = this.selectedCells.minRow = this.primaryCell.row;
      this.dragCopyMemory.clear();
    },
    touchStart(e: TouchEvent, rowIndex: number, columnIndex: number): void {
      if (this.lastTouch !== '' + rowIndex + columnIndex) {
        this.lastTouch = '' + rowIndex + columnIndex;
        setTimeout(() => {
          this.lastTouch = null;
        }, 300);
        return;
      }
      e.preventDefault();
      if (this.headers[columnIndex].type === 'text')
        this.startEditMode(this.primaryCell.col, this.primaryCell.row, false);
    },

    mouseDown(e: MouseEvent, rowIndex: number, columnIndex: number): void {
      if (e.which !== 1) return;
      if (
        !this.isEditing ||
        this.primaryCell.col !== columnIndex ||
        this.primaryCell.row !== rowIndex
      ) {
        if (!this.dragCopy) {
          this.selectedCells.start.col = columnIndex;
          this.selectedCells.start.row = rowIndex;
          this.selectedCells.end.col = columnIndex;
          this.selectedCells.end.row = rowIndex;
          this.setPrimaryCell(columnIndex, rowIndex);
        }

        this.mouseIsDown = true;
      }
    },
    documentMouseMove(e: MouseEvent): void {
      if (!this.mouseIsDown) return;
      const rect = this.virtualScrollerRef.$el.getBoundingClientRect();
      if (e.clientY > rect.bottom) {
        this.virtualScrollerRef.$el.scrollTop += 10;
      } else if (e.clientY < rect.top) {
        this.virtualScrollerRef.$el.scrollTop -= 10;
      }
    },
    mouseMove(e: MouseEvent, rowIndex: number, columnIndex: number): void {
      if (!this.mouseIsDown) return;

      if (!this.dragCopy) {
        if (columnIndex < this.primaryCell.col) {
          this.selectedCells.start.col = columnIndex;
          this.selectedCells.end.col = this.primaryCell.col;
        } else if (columnIndex > this.primaryCell.col) {
          this.selectedCells.start.col = this.primaryCell.col;
          this.selectedCells.end.col = columnIndex;
        } else {
          this.selectedCells.start.col = columnIndex;
          this.selectedCells.end.col = columnIndex;
        }
      }

      if (rowIndex < this.primaryCell.row) {
        this.selectedCells.start.row = rowIndex;
        this.selectedCells.end.row = this.primaryCell.row;
      } else if (rowIndex > this.primaryCell.row) {
        this.selectedCells.start.row = this.primaryCell.row;
        this.selectedCells.end.row = rowIndex;
      } else {
        this.selectedCells.start.row = rowIndex;
        this.selectedCells.end.row = rowIndex;
      }

      if (this.dragCopy) {
        let cols = [
          ...Array(this.selectedCells.end.col - this.selectedCells.start.col + 1).keys(),
        ].map((j) => j + this.selectedCells.start.col);

        this.selectedCells.maxRow = Math.max(this.selectedCells.maxRow, this.selectedCells.end.row);
        this.selectedCells.minRow = Math.min(
          this.selectedCells.minRow,
          this.selectedCells.start.row
        );
        const undoTransaction = {
          primaryCell: {
            row: this.primaryCell.row,
            key: this.headers[this.primaryCell.col].key,
          },
          values: [] as UndoTransactionValue[],
        };

        this.filteredObjects
          .filter(
            (o) => o.index >= this.selectedCells.minRow && o.index <= this.selectedCells.maxRow
          )
          .forEach((o) => {
            const i = o.index;

            if (!this.dragCopyMemory.has(i)) {
              this.dragCopyMemory.set(
                i,
                cols.map((k) => o[this.headers[k].key as keyof HoldInputItem])
              );
            }

            cols.forEach((j) => {
              undoTransaction.values.push({
                index: i,
                key: this.headers[j].key,
                value: this.dragCopyMemory.get(i)[j - this.selectedCells.start.col],
              });
              this.setValue(
                i,
                this.headers[j].key,
                this.selectedCells.start.row <= i && this.selectedCells.end.row >= i
                  ? this.internalObjects[this.primaryCell.row][
                      this.headers[j].key as keyof HoldInputItem
                    ]
                  : this.dragCopyMemory.get(i)[j - this.selectedCells.start.col],
                true
              );
            });
          });
        this.addToUndoBuffer(undoTransaction);
      }
    },
    selectColumn(index: number): void {
      this.setSelectedFields(
        { row: 0, col: index },
        { row: this.internalObjects.length - 1, col: index }
      );
    },
    selectRow(index: number): void {
      this.setSelectedFields({ row: index, col: 0 }, { row: index, col: this.headers.length - 1 });
    },
    setSelectedFields(
      start: { row: number; col: number },
      end: { row: number; col: number }
    ): void {
      this.primaryCell = start;
      this.$once('selected-row', () => {
        this.selectedCells.start.col = start.col;
        this.selectedCells.start.row = start.row;
        this.selectedCells.end.col = end.col;
        this.selectedCells.end.row = end.row;
      });
    },
    deselectPrimaryField(): void {
      let selected = JSON.parse(JSON.stringify(this.selectedCells));
      this.primaryCell = { row: undefined, col: undefined };
      this.$once('selected-row', () => {
        this.selectedCells = selected;
      });
    },
    dblClick(e: MouseEvent, rowIndex: number, colIndex: number): void {
      if (this.headers[colIndex].cellAction !== undefined) {
        this.headers[colIndex].cellAction();
        return;
      }
      if (this.headers[colIndex].input === 'text') this.startEditMode(colIndex, rowIndex, false);
    },
    cellBlur(e: any, row: number, col: number): void {
      const textContent = e.target.textContent as string;
      this.setValue(row, this.headers[col].key, textContent);
    },
    cellKeyDown(e: KeyboardEvent): void {
      if (this.disabled || this.primaryCell.col === undefined || this.primaryCell === undefined)
        return;

      switch (e.key) {
        case 'Tab':
          e.preventDefault();

          if (e.shiftKey && this.primaryCell.col > 0) {
            this.setPrimaryCell(this.primaryCell.col - 1, undefined);
          } else if (!e.shiftKey && this.primaryCell.col < this.headers.length - 1) {
            this.setPrimaryCell(this.primaryCell.col + 1, undefined);
          }

          break;

        case 'Enter':
          e.preventDefault();

          if (this.autocompleteItems.length) {
            const item =
              this.autocompleteItems.find((item) => item.focus) ||
              this.autocompleteItems.find((_) => true);

            this.fillWithItem(item);
            return;
          }

          this.setPrimaryCell(undefined, this.primaryCell.row + 1);

          break;
        case 'Escape':
          e.preventDefault();
          this.stopEditMode();
          this.resetSelectedCells();
          this.primaryCell.row = this.primaryCell.col = undefined;

          break;
        case 'ArrowUp':
          e.preventDefault();
          if (this.autocompleteItems.length) {
            let index = this.autocompleteItems.findIndex((item) => item.focus);
            if (index >= 0) {
              this.autocompleteItems[index].focus = false;
              index -= 1;
              if (index >= 0) {
                this.autocompleteItems[index].focus = true;
              }
              return;
            }
          }
          if (e.shiftKey) {
            this.shiftSelectedArea(-1, 0);
            return;
          }
          this.setPrimaryCell(undefined, Math.max(this.primaryCell.row - 1, 0));
          break;
        case 'ArrowDown':
          e.preventDefault();
          if (this.autocompleteItems.length) {
            let index = this.autocompleteItems.findIndex((item) => item.focus);
            if (index >= 0) {
              this.autocompleteItems[index].focus = false;
            }
            index = index < 0 ? 0 : index + 1;
            if (index < this.autocompleteItems.length) {
              this.autocompleteItems[index].focus = true;
              return;
            }
          }
          if (e.shiftKey) {
            this.shiftSelectedArea(1, 0);
            return;
          }
          this.setPrimaryCell(
            undefined,
            Math.min(this.primaryCell.row + 1, this.internalObjects.length - 1)
          );
          break;

        case 'ArrowRight':
          if (this.isEditing) return;
          e.preventDefault();
          if (e.shiftKey) {
            this.shiftSelectedArea(0, 1);
            return;
          }
          this.setPrimaryCell(
            Math.min(this.primaryCell.col + 1, this.headers.length - 1),
            undefined
          );

          break;

        case 'ArrowLeft':
          if (this.isEditing) return;
          e.preventDefault();
          if (e.shiftKey) {
            this.shiftSelectedArea(0, -1);
            return;
          }
          this.setPrimaryCell(Math.max(this.primaryCell.col - 1, 0), undefined);
          break;
        case 'z':
          if (e.ctrlKey || e.metaKey) {
            if (this.isEditing) this.stopEditMode();
            this.undo();
            return;
          }
        case 'a':
          if (e.ctrlKey || e.metaKey) {
            if (this.isEditing) this.stopEditMode();
            this.selectAll();
            return;
          }

        default:
          if ((e.ctrlKey || e.metaKey) && !this.isEditing) {
            this.eventDivRef.focus({ preventScroll: true });
            return;
          }
          if (!this.isEditing && (e.key === 'Backspace' || e.key === 'Delete')) {
            this.clearFields();
            break;
          }

          if (this.headers[this.primaryCell.col].cellAction) {
            this.headers[this.primaryCell.col].cellAction();
            return;
          }
          switch (this.headers[this.primaryCell.col].input) {
            case 'checkbox':
              e.preventDefault();

              if (e.code === 'Space') this.toggleBooleanFields();
              break;

            case 'select':
              if (e.code === 'Space') {
                const cells = this.virtualScrollerRef.$el.getElementsByClassName('primaryCell');
                cells[cells.length - 1].click();
              }
              break;
            default:
              if (!this.isEditing && e.key.length === 1) {
                this.startEditMode(this.primaryCell.col, this.primaryCell.row, true);
              }
              break;
          }
      }
    },
    shiftSelectedArea(row: number, col: number): void {
      if (row < 0) {
        if (this.selectedCells.end.row > this.primaryCell.row) {
          this.selectedCells.end.row = Math.max(this.selectedCells.end.row + row, 0);
        } else {
          this.selectedCells.start.row = Math.max(this.selectedCells.start.row + row, 0);
        }
      } else if (row > 0) {
        if (this.selectedCells.start.row < this.primaryCell.row) {
          this.selectedCells.start.row += row;
        } else {
          this.selectedCells.end.row += row;
        }
      }
      if (col < 0) {
        if (this.selectedCells.end.col > this.primaryCell.col) {
          this.selectedCells.end.col = Math.max(this.selectedCells.end.col + col, 0);
        } else {
          this.selectedCells.start.col = Math.max(this.selectedCells.start.col + col, 0);
        }
      } else if (col > 0) {
        if (this.selectedCells.start.col < this.primaryCell.col) {
          this.selectedCells.start.col = Math.min(
            this.selectedCells.start.col + col,
            this.headers.length - 1
          );
        } else {
          this.selectedCells.end.col = Math.min(
            this.selectedCells.end.col + col,
            this.headers.length - 1
          );
        }
      }
    },
    selectAll(): void {
      this.selectedCells = {
        start: {
          row: 0,
          col: 0,
        },
        end: {
          row: this.internalObjects.length - 1,
          col: this.headers.length - 1,
        },
        minRow: 0,
        maxRow: this.internalObjects.length - 1,
      };
    },
    startEditMode(col: number, row: number, clearData: boolean): void {
      const cells = this.virtualScrollerRef.$el.getElementsByClassName('primaryCell');
      const cell = cells[cells.length - 1];

      if (this.headers[col].input !== 'text' || !cell) return;
      if (document.createRange) {
        const range = document.createRange();
        range.selectNodeContents(cell.firstChild);
        range.collapse(false);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
      if (clearData) cell.firstChild.textContent = '';
      cell.firstChild.contentEditable = 'true';
      cell.style.overflow = 'visible';
      cell.firstChild.style.zIndex = '100';
      cell.firstChild.style.position = 'absolute';
      cell.firstChild.style.backgroundColor = 'inherit';
      cell.firstChild.focus({ preventScroll: true });
      this.isEditing = true;
    },
    stopEditMode(): void {
      if (!this.isEditing) return;
      const cells = this.virtualScrollerRef.$el.getElementsByClassName('primaryCell');
      const cell = cells[cells.length - 1];
      if (!cell || !cell.firstChild.style) return;
      cell.firstChild.contentEditable = 'inherit';
      cell.style.overflow = 'hidden';
      cell.firstChild.style.zIndex = 'auto';
      cell.firstChild.style.position = 'static';
      cell.firstChild.style.backgroundColor = 'transparent';
      (this.$el as any).focus({ preventScroll: true });

      // Unselect any selected text
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
      this.isEditing = false;
    },
    toggleBooleanFields(): void {
      for (let i = this.selectedCells.start.row; i <= this.selectedCells.end.row; i++) {
        for (let j = this.selectedCells.start.col; j <= this.selectedCells.end.col; j++) {
          if (this.headers[j].type === 'bool')
            this.setValue(
              i,
              this.headers[j].key,
              !(this.internalObjects[i] as any)[this.headers[j].key]
            );
        }
      }
    },
    toggleBooleanColumn(key: any): void {
      let v = !this.filledRows.every((i) => i[key as keyof HoldInputItem]);
      this.filledRows.forEach((i) => {
        Vue.set(i, key, v);
      });
    },
    clearFields(selectedCells?: {
      start: { row: any; col: any };
      end: { row: any; col: any };
    }): void {
      const fields = selectedCells || this.selectedCells;
      const undoTransaction = {
        primaryCell: {
          row: this.primaryCell.row,
          key: this.headers[this.primaryCell.col].key,
        },
        values: [] as UndoTransactionValue[],
      };
      const multiCol = fields.end.col - fields.start.col > 0;
      for (let i = fields.start.row; i <= fields.end.row; i++) {
        for (let j = fields.start.col; j <= fields.end.col; j++) {
          const key = this.headers[j].key as keyof HoldInputItem;
          const value = multiCol ? this.defaultRow[key] : undefined;
          undoTransaction.values.push({
            index: i,
            key,
            value: this.internalObjects[i][key],
          });
          this.setValue(i, this.headers[j].key, value, true);
        }
        if (this.internalObjects[i].from_container)
          this.setValue(i, 'from_container', undefined, true);
      }
      this.addToUndoBuffer(undoTransaction);
    },
    pasteData(e: ClipboardEvent): void {
      e.preventDefault();
      this.stopEditMode();
      this.resetSelectedCells();

      const paste = (e.clipboardData || window.clipboardData).getData('text');
      const lines = paste.trim().split('\n');
      let maxCol = 0;
      let maxRow = 0;
      const undoTransaction = {
        primaryCell: {
          row: this.primaryCell.row,
          key: this.headers[this.primaryCell.col].key,
        },
        values: [] as UndoTransactionValue[],
      };

      // Only trigger autocompletes if this is a paste to labels and only labels
      const fetchAutocomplete =
        this.headers[this.primaryCell.col].key === 'sku' && lines[0].split('\t').length === 1;

      this.extendInternalObjects(lines.length);

      for (let i = 0; i < lines.length && this.primaryCell.row + i < this.maxRows; i++) {
        const cols = lines[i].split('\t');
        for (let colIndex = cols.length - 1; colIndex >= 0; colIndex--) {
          if (this.headers[this.primaryCell.col + colIndex].key !== 'l') {
            continue;
          }
          const dimArr = cols[colIndex].toString().split(this.pasteColumnSplitPattern);
          if (dimArr.length === 3) {
            cols.splice(colIndex, 1, ...dimArr);
          }
        }
        for (let j = 0; j < cols.length && this.primaryCell.col + j < this.headers.length; j++) {
          if (cols[j].length > 0) {
            const val =
              this.internalObjects[this.primaryCell.row + i][
                this.headers[this.primaryCell.col + j].key as keyof HoldInputItem
              ];
            undoTransaction.values.push({
              index: this.primaryCell.row + i,
              key: this.headers[this.primaryCell.col + j].key,
              value: val,
            });

            let parsedData = cols[j];
            switch (this.headers[this.primaryCell.col + j].type) {
              case 'bool':
                parsedData = ['true', 'y', 'yes'].includes(cols[j].toLowerCase());
                break;
              case 'list':
                parsedData = JSON.parse(cols[j]);
                break;
              case 'integer':
                const number = parseInt(cols[j]);
                parsedData = isNaN(number) ? undefined : number;
                break;
              case 'object':
                parsedData = JSON.parse(cols[j]);
                break;
            }
            this.setValue(
              this.primaryCell.row + i,
              this.headers[this.primaryCell.col + j].key,
              parsedData,
              true
            );
            if (fetchAutocomplete) {
              const matches = this.getAutocompleteForColumn(parsedData, 'sku');
              // Only trigger auto-complete if we only have a single match for this item
              if (
                matches.length >= 1 &&
                matches[0].sku.trim().toLowerCase() === parsedData.trim().toLowerCase()
              ) {
                const newItem = this.autoToInternal(matches[0]);
                newItem.qty = 1;
                this.internalObjects[this.primaryCell.row + i] = newItem;
              }
            }

            maxRow = Math.max(maxRow, i);
            maxCol = Math.max(maxCol, j);
          }
        }
      }
      this.addToUndoBuffer(undoTransaction);
      this.selectedCells.start.col = this.primaryCell.col;
      this.selectedCells.start.row = this.primaryCell.row;
      this.selectedCells.end.col = this.primaryCell.col + maxCol;
      this.selectedCells.end.row = this.primaryCell.row + maxRow;
    },
    copyOrCutData(isCut: boolean): void {
      let output = '';
      const undoTransaction = {
        primaryCell: {
          row: this.primaryCell.row,
          key: this.headers[this.primaryCell.col].key,
        },
        values: [] as UndoTransactionValue[],
      };
      for (let i = this.selectedCells.start.row; i <= this.selectedCells.end.row; i++) {
        for (let j = this.selectedCells.start.col; j <= this.selectedCells.end.col; j++) {
          const val = this.internalObjects[i][this.headers[j].key as keyof HoldInputItem] || '';

          if (val !== Object(val)) output += val + '\t';
          else output += JSON.stringify(val) + '\t';

          if (isCut) {
            undoTransaction.values.push({
              index: i,
              key: this.headers[j].key,
              value: val,
            });
            this.setValue(i, this.headers[j].key, '', true);
          }
        }
        output = output.replace(/.$/, '\n');
      }
      if (isCut) this.addToUndoBuffer(undoTransaction);
      (navigator as any).clipboard.writeText(output);
      (this.$el as any).focus({ preventScroll: true });
    },
    toggleFilterBy(e: MouseEvent, key: string): void {
      if (this.filterBy == key) {
        this.filterBy = undefined;
        this.filterQuery = '';
      } else {
        this.filterBy = key;
      }
      e.stopImmediatePropagation();
    },
    filterClick(e: MouseEvent): void {
      this.primaryCell = { row: undefined, col: undefined };
      e.stopImmediatePropagation();
    },
    filterType(e: KeyboardEvent): void {
      let q = (e.target as any).value;
      if (this.filterDelay) {
        clearTimeout(this.filterDelay);
      }
      // we use a delay to avoid lag when typing.
      // Start searching 500ms after typing has ended
      this.filterDelay = setTimeout(() => {
        this.filterQuery = q;
      }, 500);
    },
    resetSelectedCells(): void {
      this.selectedCells.start.col = undefined;
      this.selectedCells.start.row = undefined;
      this.selectedCells.end.col = undefined;
      this.selectedCells.end.row = undefined;
    },
    insertEmptyRow(row: number): void {
      this.internalObjects = [
        ...this.internalObjects.slice(0, row),
        {} as HoldInputItemWithWarnings,
        ...this.internalObjects.slice(row, this.internalObjects.length),
      ].slice(0, this.maxRows);
    },
    deleteSelectedRows(): void {
      this.internalObjects.splice(
        this.selectedCells.start.row,
        this.selectedCells.end.row - this.selectedCells.start.row + 1
      );
    },
    mergeSimilarRows(): void {
      const merged = [] as HoldInputItemWithWarnings[];

      this.internalObjects
        .filter((i) => i.label && i.l && i.w && i.h && i.wt)
        .forEach((item, itemIndex) => {
          const index = merged.findIndex(
            (b: HoldInputItem) =>
              item.label === b.label &&
              item.l == b.l &&
              item.w == b.w &&
              item.h == b.h &&
              item.wt == b.wt
          );

          if (index > -1) {
            if (!isNaN(item.qty) && !isNaN(merged[index].qty))
              merged[index].qty = parseInt(merged[index].qty as any) + parseInt(item.qty as any);
          } else {
            merged.push(item);
          }
        });

      this.internalObjects = merged;
      this.extendInternalObjects();
    },
    deleteAllRows(): void {
      this.clearFields({
        start: {
          row: 0,
          col: 0,
        },
        end: {
          row: this.internalObjects.length - 1,
          col: this.headers.length - 1,
        },
      });
    },
    setValue(
      index: number,
      key: string,
      value: string | number | boolean | number[] | Object,
      doNotAddToUndoBuffer: boolean = false
    ): void {
      if (!doNotAddToUndoBuffer) {
        this.addToUndoBuffer({
          primaryCell: {
            row: index,
            key: key,
          },
          values: [
            {
              index: index,
              key: key,
              value: this.internalObjects[index][key as keyof HoldInputItem],
            },
          ],
        });

        if (this.undoBuffer.length > 20) this.undoBuffer.shift();
      }

      this.$set(this.internalObjects[index], key, value);
    },
    addToUndoBuffer(o: UndoTransaction): void {
      this.undoBuffer.push(o);
      if (this.undoBuffer.length == 1) {
        this.$emit('enableUndo', true);
      }
      if (this.undoBuffer.length > 20) this.undoBuffer.shift();
    },
    undo() {
      if (this.undoBuffer.length) {
        const obj = this.undoBuffer.pop();
        obj.values.forEach((i) => {
          this.setValue(i.index, i.key, i.value, true);
        });

        this.primaryCell = {
          row: obj.primaryCell.row,
          col: this.headers.findIndex((i) => obj.primaryCell.key === i.key),
        };
        this.resetSelectedCells();
      }
      if (this.undoBuffer.length == 0) {
        this.$emit('enableUndo', false);
      }
    },
    setVisibleCell(): void {
      const cells = this.virtualScrollerRef.$el.getElementsByClassName('primaryCell');

      if (cells.length > 0) {
        const cellRect = cells[cells.length - 1].getBoundingClientRect();
        const parentRect = this.$el.getBoundingClientRect();
        const buffer = cellRect.height * 0.3;
        // If at bottom
        if (cellRect.bottom > parentRect.bottom - cellRect.height)
          this.virtualScrollerRef.$el.scrollTop += cellRect.bottom - parentRect.bottom + buffer;
        // If at top
        else if (cellRect.top < parentRect.top + cellRect.height)
          this.virtualScrollerRef.$el.scrollTop +=
            cellRect.top - parentRect.top - cellRect.height - buffer;
        // If at right
        if (cellRect.right > parentRect.right) this.wrapperRef.scrollLeft += cellRect.width;
        // If at left
        else if (cellRect.left < parentRect.left) this.wrapperRef.scrollLeft -= cellRect.width;
      } else {
        this.virtualScrollerRef.$el.scrollTop =
          this.itemHeight * this.primaryCell.row - this.itemHeight * 0.3;
      }
    },
    sortByKey(key: string): void {
      const sortItems = require('vuetify/lib/util/helpers.js').sortItems;
      this.internalObjects = sortItems([...this.internalObjects], [key], [true]);
    },
    inputActions(event: any, column: string, item: HoldInputItem): void {
      if (this.isAutocompleteColumn(column)) {
        this.autocomplete(event, column);
      }
      if (item.geometry == 'cylinder') {
        if (column === 'l') item.w = event.target.textContent;
        if (column === 'w') item.l = event.target.textContent;
      }
    },
    autocomplete(event: any, column: AutocompleteColumn): void {
      if (this.disableAutocomplete) {
        return;
      }
      this.autocompleteItems = [];
      this.query = event.target.textContent.trim();
      if (this.query.length) {
        this.autocompleteItems = this.getAutocompleteForColumn(this.query, column)
          .sort((a, b) => a.query_match - b.query_match)
          .slice(0, 20);
      }
    },
    getAutocompleteForColumn(query: string, columnName: AutocompleteColumn) {
      const lowerCasedQuery = query.toLowerCase().trim();
      return this.cargoes
        .map((item) => {
          const propertyName = columnName === 'label' ? 'name' : columnName;
          const propertyValue = item[propertyName]?.toLowerCase();

          return {
            ...item,
            focus: false,
            query_match: propertyValue ? propertyValue.indexOf(lowerCasedQuery) : -1,
            prop: propertyName,
          };
        })
        .filter(({ query_match }) => query_match >= 0);
    },
    fillWithItem(item: AutocompleteItem): void {
      this.autocompleteItems = [];

      const newItem = this.autoToInternal(item);
      newItem.qty = 1;

      if (this.internalObjects.length <= this.primaryCell.row) {
        this.internalObjects = [...this.internalObjects, newItem];
      } else {
        Vue.set(this.internalObjects, this.primaryCell.row, newItem);
      }
    },
    autoToInternal(item: AutocompleteItem): HoldInputItemWithWarnings {
      let newItem = JSON.parse(JSON.stringify(item.data)) as HoldInputItem;
      let l = item.data.l;
      let w = item.data.w;
      let h = item.data.h;
      let wt = item.data.wt;
      const from_container = JSON.parse(JSON.stringify(item.data.from_container));
      if (this.lengthDim && item.length_dim && this.lengthDim != item.length_dim) {
        // convert lengths
        const lengthFactor = this.$toSI(item.length_dim) / this.$toSI(this.lengthDim);
        l *= lengthFactor;
        w *= lengthFactor;
        h *= lengthFactor;
      }
      if (this.weightDim && item.weight_dim && this.weightDim != item.weight_dim) {
        // convert weights
        const weightFactor = this.$toSI(item.weight_dim) / this.$toSI(this.weightDim);
        wt *= weightFactor;
      }
      let metadata = {};
      if (!!newItem.metadata) {
        for (const [key, value] of Object.entries(newItem.metadata)) {
          (metadata as any)[key] = value;
        }
      }
      Object.keys(newItem).forEach((key) => {
        if (newItem[key as keyof HoldInputItem] === Object(newItem[key as keyof HoldInputItem]))
          newItem[key as keyof HoldInputItem] = JSON.parse(
            JSON.stringify(newItem[key as keyof HoldInputItem])
          ) as never;
      });
      newItem = {
        ...newItem,
        ...metadata,
        from_container,
        l,
        w,
        h,
        wt,
      };
      return newItem as HoldInputItemWithWarnings;
    },
    isAutocompleteColumn(value: string): value is AutocompleteColumn {
      return autocompleteColumns.includes(value as AutocompleteColumn);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.eventDiv {
  color: transparent;
  width: 1px;
  height: 1px;
}

*:focus {
  outline: none;
}

.primaryCell {
  -webkit-box-shadow: inset 0px 0px 0px 2px rgb(75, 137, 255);
  -moz-box-shadow: inset 0px 0px 0px 2px rgb(75, 137, 255);
  box-shadow: inset 0px 0px 0px 2px rgb(75, 137, 255);
}

.td.selectedCells {
  background-color: rgba(0, 94, 255, 0.1) !important;
}

.editCell {
  overflow: hidden;
  white-space: nowrap;
}

.th,
.td {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 3px;
  height: 30px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
.th {
  cursor: pointer;
  text-align: center;
  background-color: #eee;
}
.th.dark {
  background-color: inherit;
}

.th.thSelected {
  background: #ccc;
}
.th-top {
  border-top: 1px solid #ddd;
  position: relative;
}
.th-left {
  border-left: 1px solid #ddd;
  position: sticky;
  z-index: 1;
  left: 0;
  border-right: solid 1px #ddd;
}

.resizeHandle {
  visibility: hidden;
  cursor: col-resize;
  position: absolute;
  right: 0px;
  top: 0;
  width: 5px;
  border-right: solid 1px;
  border-left: solid 1px;
  height: 100%;
}
.th-top:hover .resizeHandle {
  visibility: visible;
}
.filter-by {
  visibility: hidden;
  position: absolute;
  right: 10px;
  top: 1px;
}
.filter-by .v-btn {
  background-color: #eee;
}
.filter-by:has(input) .v-btn {
  background-color: inherit;
}
.filter-by input {
  background-color: white;
  border: 1px solid #ccc;
  margin-right: -25px;
  border-radius: 5px;
  padding: 0 5px;
}
.thSelected:hover .filter-by,
.filter-by:has(input) {
  visibility: visible;
}
.warningInfo::before {
  content: '*';
  color: red;
  position: absolute;
  left: 5px;
  top: 2px;
}

.td-select {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  width: 100%;
}

.row-odd .td {
  background-color: rgb(249, 251, 254);
}
.row-even .td {
  background-color: white;
}
.dark.row-odd .td {
  background-color: #222;
}
.dark.row-even .td {
  background-color: black;
}

.rowWrapper {
  white-space: nowrap;
  overflow-x: auto;
  border-bottom: solid #ddd;
}

.bluedot {
  position: absolute;
  background-color: rgb(75, 137, 255);
  width: 7px;
  height: 7px;
  right: 0px;
  bottom: 0px;
}
.bluedot:hover {
  cursor: ns-resize;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.diameter {
  position: absolute;
  right: 0;
  bottom: 0;
}

.autocomplete {
  position: absolute;
  width: 250px;
  top: 30px;
  border: 1px solid #aaa;
  z-index: 2;
  box-shadow: #ddd 5px 2px 10px 0px;
  border-radius: 4px;
  overflow: hidden;
}
.autocomplete > .tip {
  color: #888;
  font-size: 0.7em;
  padding: 4px;
  text-align: right;
  display: block;
}
.autocomplete > div {
  border-bottom: 1px solid #ccc;
  padding: 6px;
}

.autocomplete > div:hover {
  background: #ddd;
  cursor: pointer;
}
.autocomplete > div.focus {
  background-color: rgb(75, 137, 255);
  color: white;
}

kbd {
  background: grey !important;
}
</style>
