<template>
  <tr>
    <td style="print-color-adjust: exact">
      <div :style="`padding-left: ${depth * 12}px`">
        <v-icon :style="{ color: item.color }" v-if="!item.from_container">fa-cube</v-icon>
        <!-- <v-icon v-else>mdi-folder-open-outline</v-icon> -->
      </div>
    </td>
    <td
      class="text-left"
      :class="{
        'font-weight-bold': item.from_container,
        'left-border': ['l', 'wt', 'l#additional', 'wt#additional'].includes(column.key),
        'right-border': ['wt', 'wt#additional'].includes(column.key),
      }"
      v-for="column in columns"
      :key="column.key + column.dim">
      <template v-if="column.key === 'label'">
        <span :style="`padding-left: ${depth * 12}px`">{{ item[column.key] }}</span>
      </template>
      <template v-else-if="column.key === 'chargable_wt'">
        <template v-if="loadplan.settings.shipping_factor">{{
          toLoadlistDim(chargableWeight(item))
        }}</template>
      </template>
      <template v-else-if="column.key === 'wt'">
        <template v-if="item.wt">{{ item.wt | roundTwoDecimals }} </template>
        <template v-else-if="item.WT">{{ valueForDim(item.WT, loadlist.weight_dim) }}</template>
      </template>
      <template v-else-if="column.key === 'wt#additional'">
        <template v-if="item.wt">{{ toWeightDim(item.WT, column.dim) }}</template>
        <template v-else-if="item.WT">{{ valueForDim(item.WT, column.dim) }}</template>
      </template>
      <template v-else-if="column.key === 'qty'">
        {{ item.qty }}
      </template>
      <template v-else-if="column.key === 'l'">
        <template v-if="item.l">{{ item.l | roundDim(loadlist.length_dim) }}</template>
        <template v-else-if="item.L">{{ valueForDim(item.L, loadlist.length_dim) }}</template>
      </template>
      <template v-else-if="column.key === 'l#additional'">
        <template v-if="item.l">{{ toLengthDim(item.l, column.dim) }}</template>
        <template v-else-if="item.L">{{ valueForDim(item.L, column.dim) }}</template>
      </template>
      <template v-else-if="column.key === 'w'">
        <template v-if="item.w">{{ item.w | roundDim(loadlist.length_dim) }}</template>
        <template v-else-if="item.W">{{ valueForDim(item.W, loadlist.length_dim) }}</template>
      </template>
      <template v-else-if="column.key === 'w#additional'">
        <template v-if="item.w">{{ toLengthDim(item.w, column.dim) }}</template>
        <template v-else-if="item.W">{{ valueForDim(item.W, column.dim) }}</template>
      </template>
      <template v-else-if="column.key === 'h'">
        <template v-if="item.h">{{ item.h | roundDim(loadlist.length_dim) }}</template>
        <template v-else-if="item.H">{{ valueForDim(item.H, loadlist.length_dim) }}</template>
      </template>
      <template v-else-if="column.key === 'h#additional'">
        <template v-if="item.h">{{ toLengthDim(item.h, column.dim) }}</template>
        <template v-else-if="item.H">{{ valueForDim(item.H, column.dim) }}</template>
      </template>
      <template v-else-if="column.type === 'bool'">
        {{ item[column.key] ? 'Yes' : '' }}
      </template>
      <template v-else-if="column.summable && item[column.key]">
        {{ (item.qty || 1) * item[column.key] }}
      </template>
      <template v-else-if="column.type == 'custom'">
        {{ item.metadata ? item.metadata[column.key] : '' }}
      </template>
      <template v-else> {{ item[column.key] }} </template>
    </td>

    <td class="text-left" v-for="metric in customMetrics" :key="metric.name">
      <span v-if="metric.only_summary || (metric.discard_nested && index2 > 0)"></span>
      <span v-else>{{ calculateFormula(metric.formula, item, hold) | roundTwoDecimals }}</span>
    </td>
  </tr>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ItemProperty } from '@/misc/itemProperties';
import dimTools from '@/misc/dimTools';
import { HoldData, HoldItem, Loadlist, Loadplan } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { CustomMetric } from '@/models/UserCompanyModel';
import tableUtils from '@/misc/tableUtils';

export default Vue.extend({
  name: 'loadplan-table-item',
  props: {
    item: {
      type: Object as PropType<HoldItem>,
      default: null,
    },
    hold: {
      type: Object as PropType<HoldData>,
      default: null,
    },
    depth: Number,
    columns: {
      type: Array as PropType<ItemProperty[]>,
      default: () => [] as ItemProperty[],
    },
    customMetrics: {
      type: Array as PropType<CustomMetric[]>,
      default: [],
    },
  },

  computed: {
    ...mapStores(useLoadlistStore),
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
  },
  filters: {
    uppercase(val: string): string {
      return val ? val.toUpperCase() : '';
    },
    roundDim(val: number, dim: string): number {
      return dimTools.roundForDim(val, dim);
    },
  },
  methods: {
    valueForDim(value: number, dim: string): number {
      return dimTools.roundForDim(value * dimTools.fromSI(dim), dim);
    },
    toLengthDim(value: number, dim: string): number {
      return this.valueForDim(dimTools.toSI(this.loadlist.length_dim) * value, dim);
    },
    toWeightDim(value: number, dim: string): number {
      return this.valueForDim(value, dim);
    },
    toLoadlistDim(value: number): number {
      return this.valueForDim(value, this.loadlist.weight_dim);
    },
    chargableWeight(item: HoldItem): number {
      return Math.max(item.WT, item.L * item.W * item.H * this.loadplan.settings.shipping_factor);
    },
    calculateFormula(formula: any, item: HoldItem, hold: HoldData) {
      return tableUtils.calculateFormula(formula, item, hold);
    },
  },
});
</script>

<style scoped>
.cargo-table th,
.cargo-table td {
  border-collapse: collapse;
}
.cargo-table tbody tr td {
  border-bottom: none !important;
}
.cargo-table tbody tr:last-child td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
.cargo-table tbody tr:not(:only-child) td:first-child {
  border-left: 3px solid #4778b2;
}
.left-border {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.right-border {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

@media print {
  .cargo-table {
    page-break-inside: avoid;
  }

  .cargo-table thead tr th,
  .cargo-table tbody tr td,
  .cargo-table tfoot tr td {
    font-size: 0.75rem !important;
    padding: 0 8px !important;
  }
}
</style>
