<template>
  <v-row>
    <v-col>
      <v-select
        v-bind:items="loadplanVersions"
        v-model="loadplan_version"
        label="Current loadplan version"></v-select>
      <v-btn @click="addLoadplanVersion()" :ripple="false">Add new version</v-btn>
      <v-btn
        @click="removeLoadplanVersion"
        class="ml-2"
        :ripple="false"
        :disabled="loadplanVersions.length <= 1"
        >Remove this version</v-btn
      >
    </v-col>

    <v-col>
      <v-textarea label="Notes:" v-model="loadplan_notes" outlined rows="3"></v-textarea>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import { Loadlist } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
interface LoadplanVersion {
  text: number;
  value: number;
}

export default Vue.extend({
  name: 'loadplan-version',
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapStores(useLoadlistStore),
    loadplanVersions(): LoadplanVersion[] {
      return Array.from({ length: this.loadlist.result.versions.length }, (value, key) => ({
        text: key + 1,
        value: key,
      }));
    },
    loadplan_version: {
      get(): number {
        return this.loadlistStore.loadplan_version;
      },
      set(versionValue: number): void {
        this.loadlistStore.setLoadplanVersion(versionValue);
      },
    },
    loadplan_notes: {
      get(): string {
        return this.loadlistStore.loadplan.notes;
      },
      set(value: string): void {
        this.loadlistStore.setLoadplanProperty({
          key: 'notes',
          value: value,
        });
      },
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
  },
  watch: {},
  created() {},
  methods: {
    addLoadplanVersion(): void {
      this.loadlistStore.addLoadplanVersion();
    },
    removeLoadplanVersion(): void {
      this.loadlistStore.removeLoadplanVersion();
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
