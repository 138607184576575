import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"scrollable":"","persistent":"","width":"800"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Privacy Policy")]),_c(VCardText,[_c('p',{staticClass:"pre"},[_vm._v(_vm._s(_vm.policy))])]),(!_vm.user.accepted_policy)?_c(VCardActions,[_c(VCheckbox,{attrs:{"label":"Check here to indicate that you have read and agree to the terms of our Privacy Policy"},model:{value:(_vm.hasAcceptedPolicy),callback:function ($$v) {_vm.hasAcceptedPolicy=$$v},expression:"hasAcceptedPolicy"}})],1):_vm._e(),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.update()}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }