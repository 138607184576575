<template>
  <v-dialog v-model="show" width="600">
    <v-card :loading="isLoading">
      <v-card-title class="text-h5" primary-title>Delete loadlist</v-card-title>
      <v-card-text>
        <p>
          Would you really like to delete the loadlist
          <i>{{ loadlistName }}</i
          >?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click.stop="$emit('close', false)">Cancel</v-btn>
        <v-spacer />
        <v-btn color="error" @click.stop="deleteList()">Yes, delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { APIResponse } from '@/models/APIModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';

export default Vue.extend({
  name: 'delete-loadlist',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loadlistName: {
      default: '',
      type: String,
    },
    loadlistId: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapStores(useLoadlistStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    deleteList(): void {
      this.isLoading = true;
      API.deleteLoadlist(this.loadlistId)
        .then((success: APIResponse) => {
          this.clearLoadlist();
          this.$emit('deleted', true);
        })
        .catch((error: any) => {
          this.$emit('close', false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearLoadlist(): void {
      this.loadlistStore.clearLoadlist();
    },
  },
});
</script>
<style scoped></style>
