<template>
  <div>
    <v-row class="mb-2">
      <v-col class="py-0">
        <v-text-field
          prepend-icon="mdi-magnify"
          clearable
          placeholder="Search:"
          v-model="searchString"
          hide-details></v-text-field>
      </v-col>
    </v-row>

    <v-progress-circular
      v-if="isLoading"
      value="true"
      indeterminate
      color="primary"></v-progress-circular>

    <slot name="items" v-bind:paginatedItems="paginatedSets"> </slot>

    <v-pagination
      v-bind:length="numberOfPages"
      total-visible="8"
      v-model="currentPage"></v-pagination>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { ListType } from '@/models/LoadlistModel';
import { SetType } from '@/models/SetsModel';
import Vue, { PropType } from 'vue';

const SETS_PER_PAGE = 10;

export default Vue.extend({
  name: 'sets-library',
  data() {
    return {
      searchString: '',
      currentPage: 1,
    };
  },
  props: {
    setType: {
      type: String as PropType<ListType>,
      required: false,
    },
  },
  computed: {
    ...mapStores(useMiscStore),
    sets(): SetType[] {
      return this.miscStore.sets.filter(
        (i) =>
          (!this.setType || i.base_type === this.setType) &&
          (this.searchString
            ? i.name.toLowerCase().includes(this.searchString.toLowerCase())
            : true)
      );
    },
    paginatedSets(): SetType[] {
      return this.sets.slice(
        (this.currentPage - 1) * SETS_PER_PAGE,
        (this.currentPage - 1) * SETS_PER_PAGE + SETS_PER_PAGE
      );
    },
    numberOfPages(): number {
      return Math.ceil(Math.max(this.sets.length / SETS_PER_PAGE, 1));
    },
    isLoading(): boolean {
      return this.miscStore.isLoading;
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
  },
  watch: {
    setType() {
      this.currentPage = 1;
    },
    searchString() {
      this.currentPage = 1;
    },
  },
  created() {},
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
