import { v4 as uuidv4 } from 'uuid';
import {
  HoldData,
  HoldDataWithPosition,
  HoldItem,
  HoldItemType,
  HoldPart,
  ListType,
} from '@/models/LoadlistModel';
import { Set } from '@/models/SetsModel';
import itemUtils from './itemUtils';
const containerPartColor = '#222222';
const EPSILON = 0.01;

export default {
  compareContainers(a: HoldData, b: HoldData): boolean {
    return (
      !!a &&
      !!b &&
      !!a === !!b &&
      a.id === b.id &&
      a.items_count == b.items_count &&
      a.WT === b.WT &&
      a.items.every((a_item, a_item_index) => {
        return (
          itemUtils.compareItems(a_item, b.items[a_item_index]) &&
          Math.abs(a_item?.pos?.x - b.items[a_item_index]?.pos?.x) < EPSILON &&
          Math.abs(a_item?.pos?.y - b.items[a_item_index]?.pos?.y) < EPSILON &&
          Math.abs(a_item?.pos?.z - b.items[a_item_index]?.pos?.z) < EPSILON
        );
      })
    );
  },
  getAllCargoIndices(item: HoldItemType, container: HoldData): number[] {
    const indexes: number[] = [];

    container.items
      .filter((i) => i.qty !== 0)
      .forEach((i, index) => {
        if (itemUtils.compareItems(item, i)) indexes.push(index);
        else if (i.from_container && this.getAllCargoIndices(item, i.from_container).length) {
          indexes.push(index);
        }
      });

    return indexes;
  },
  compareSets(a: Set, b: Set, holds: HoldData[]): boolean {
    return (
      !!a === !!b &&
      a.type_id == b.type_id &&
      a.containers.length == b.containers.length &&
      a.containers.every((a_container, index) => {
        return this.compareContainers(
          holds.find((h) => h.uuid == a_container.uuid),
          holds.find((h) => h.uuid == b.containers[index].uuid)
        );
      })
    );
  },
  createEmpty(from: HoldData): HoldData {
    return {
      ...from,
      items_count: 0,
      items: Array.isArray(from.items)
        ? from.items.filter((j) => j.qty === 0 && !['spacer'].includes(j.label))
        : [],
      WT: 0,
      volume: 0,
      shipments: null,
      uuid: uuidv4(),
      contours: from.contours ? { ...from.contours, bottom_spacer_h: 0 } : undefined,
      items_bb: { min: { x: 0, y: 0, z: 0 }, max: { x: 0, y: 0, z: 0 } },
    };
  },
  createSetFromHold(hold: HoldData): Set {
    const uuid = uuidv4();
    hold.set_uuid = uuid;
    return {
      name: hold.name,
      type_id: hold.id + 1000,
      uuid,
      containers: [
        {
          position: { x: 0, y: 0, z: 0 },
          rotation: [0, 0, 0, 'XYZ'],
          uuid: hold.uuid,
        },
      ],
      payload: hold.payload,
      cost: undefined,
    };
  },
  renderableSet(set: Set, holds: HoldData[]): HoldDataWithPosition[] {
    const rendering = set.containers
      .map((pc) => {
        const hold = holds.find((h) => h.uuid == pc.uuid);
        if (!hold) {
          return null;
        }
        return {
          position: pc.position,
          rotation: pc.rotation,
          position_name: pc.position_name,
          ...hold,
        } as HoldDataWithPosition;
      })
      .filter((h) => h);

    return rendering;
  },

  oogValues(c: HoldData): { value: number; title: string; type: string }[] {
    return [
      {
        value: -c.items_bb.min.y,
        title: 'Overhang Side 1',
        type: 'length',
      },
      {
        value: c.items_bb.max.y - c.W,
        title: 'Overhang Side 2',
        type: 'length',
      },
      {
        value: -c.items_bb.min.x,
        title: 'Overhang Front',
        type: 'length',
      },
      {
        value: c.items_bb.max.x - c.L,
        title: 'Overhang Rear',
        type: 'length',
      },
      {
        value: c.H > 0 ? c.items_bb.max.z - c.H : 0,
        title: 'Top',
        type: 'length',
      },
      {
        value: c.legal_limits?.height > 0 ? c.items_bb.max.z - c.legal_limits.height : 0,
        title: 'Exceeds legal height',
        type: 'length',
      },
      {
        value: c.WT > c.legal_limits?.weight ? c.WT - c.legal_limits.weight : 0,
        title: 'Exceeds legal weight',
        type: 'weight',
      },
      {
        value: c.legal_limits?.extended || 0,
        title: 'Extended trailer',
        type: 'length',
      },
    ].filter((i) => i.value > 0.01);
  },
  maxVolume(hold: HoldData): number {
    const height = hold.max_height > 0 && !hold.H ? hold.max_height : hold.H;

    if (height < 0.001) return NaN;

    let containerVolume = hold.L * hold.W * height;

    // Remove contours volume
    if (containerVolume && hold.contours) {
      containerVolume -=
        (hold.contours.front_bottom_contour_l * hold.contours.front_bottom_contour_h ||
          0 + hold.contours.rear_bottom_contour_l * hold.contours.rear_bottom_contour_h ||
          0 + hold.contours.front_top_contour_l * hold.contours.front_top_contour_h ||
          0 + hold.contours.rear_top_contour_l * hold.contours.rear_top_contour_h ||
          0) *
        hold.W *
        0.5;

      containerVolume -=
        (hold.contours.side1_bottom_contour_l * hold.contours.side1_bottom_contour_h ||
          0 + hold.contours.side2_bottom_contour_l * hold.contours.side2_bottom_contour_h ||
          0 + hold.contours.side1_top_contour_l * hold.contours.side1_top_contour_h ||
          0 + hold.contours.side2_top_contour_l * hold.contours.side2_top_contour_h ||
          0) *
        hold.L *
        0.5;
    }
    return containerVolume;
  },
  defaults(base_type: ListType): HoldData {
    switch (base_type) {
      case 'SEA':
        return {
          L: 12.01,
          W: 2.34,
          H: 2.39,
          payload: 22000.0,
          door: {
            H: 2.39,
            W: 2.34,
          },
        } as HoldData;
      case 'ROAD':
        return {
          L: 13.0,
          W: 2.3,
          H: 3.0,
          payload: 20000.0,
          floor_height: 0.1,
          clearence: 1.0,
          axles: {
            front_axle_x: 1,
            rear_axle_x: 9.0,
            rear_axle_no: 3.0,
            rear_axle_spacing: 1.0,
          },
        } as HoldData;
      case 'AIR':
        return {
          L: 3.17,
          W: 2.23,
          H: 1.62,
          payload: 1588.0,
          contours: {
            front_bottom_contour_l: 0.44,
            rear_bottom_contour_l: 0.44,
            front_bottom_contour_h: 0.44,
            rear_bottom_contour_h: 0.44,
          },
        } as HoldData;
      case 'PALL':
        return {
          L: 1.2,
          W: 0.8,
          H: 0.0,
          payload: 1000.0,
          no_end_walls: true,
          no_side_walls: true,
          no_roof: true,
          floor_height: 0.14,
          max_height: 2.0,
        } as HoldData;
    }
  },
  createNestedItemFromHold(hold: HoldData, addLabelUUID?: boolean): HoldItem {
    let label = hold.name;
    if (addLabelUUID) label += ` (${uuidv4().substring(0, 4)})`;
    const item = {
      label: label, // Perhaps this should be selectable
      from_container: hold,
      // TODO: check if this is actually correct
      L: Math.max(hold.items_bb.max.x - hold.items_bb.min.x, hold.L),
      W: Math.max(hold.items_bb.max.y - hold.items_bb.min.y, hold.W),
      H: Math.max(hold.items_bb.max.z - hold.items_bb.min.z, hold.H) + (hold.floor_height || 0),
      WT: hold.WT + (hold.tare || 0),
    } as HoldItem;
    item.l = item.L;
    item.w = item.W;
    item.h = item.H;
    item.wt = item.WT;
    return item;
  },
  assemble(hold: HoldData): HoldData {
    hold.items = [];
    hold.max_height = hold.max_height || null;
    //Bottom spacer for contours
    if (hold.contours) {
      if (hold.contours.bottom_spacer_h) {
        const bottom_frame = hold.bottom_frame || {};
        hold.items.push({
          label: 'spacer',
          qty: 0,
          color: '#80694b',
          pos: {
            x:
              (hold.L +
                (hold.contours.front_bottom_contour_l || 0) -
                (hold.contours.rear_bottom_contour_l || 0)) *
              0.5,
            y:
              (hold.W +
                (hold.contours.side1_bottom_contour_l || 0) -
                (hold.contours.side2_bottom_contour_l || 0)) *
              0.5,
            z: hold.contours.bottom_spacer_h * 0.5,
          },
          L:
            hold.L -
            (hold.contours.rear_bottom_contour_l || 0) -
            (hold.contours.front_bottom_contour_l || 0) -
            2 * (bottom_frame.end_frame_w || 0),
          W:
            hold.W -
            (hold.contours.side1_bottom_contour_l || 0) -
            (hold.contours.side2_bottom_contour_l || 0) -
            2 * (bottom_frame.side_frame_w || 0),
          H: hold.contours.bottom_spacer_h,
        });
      }
    }

    //Rear table
    if (hold.tables) {
      //Double deck

      if (hold.tables.rear_table_l * hold.tables.rear_table_h > 0) {
        let thickness = hold.floor_height || 0.01;
        hold.items.push(
          {
            label: 'rear',
            qty: 0,
            color: containerPartColor,
            pos: {
              x: hold.L - hold.tables.rear_table_l / 2,
              y: hold.W / 2,
              z: hold.tables.rear_table_h - thickness / 2,
            },
            L: hold.tables.rear_table_l,
            W: hold.W,
            H: thickness,
            // nothing_below: true, TODO - OK to remove?
            not_stackable: hold.tables.rear_table_not_stackable,
          },
          {
            label: 'rear',
            qty: 0,
            color: containerPartColor,
            pos: {
              x: hold.L - hold.tables.rear_table_l + thickness / 2,
              y: hold.W * 0.5,
              z: hold.tables.rear_table_h * 0.5 - thickness,
            },
            L: thickness,
            W: hold.W,
            H: hold.tables.rear_table_h,
          }
        );
      }

      //Front table
      if (hold.tables.front_table_l * hold.tables.front_table_h > 0) {
        let thickness = hold.floor_height || 0.01;

        hold.items.push(
          {
            label: 'front',
            qty: 0,
            color: containerPartColor,
            pos: {
              x: hold.tables.front_table_l / 2,
              y: hold.W / 2,
              z: hold.tables.front_table_h - thickness / 2,
            },
            L: hold.tables.front_table_l,
            W: hold.W,
            H: thickness,
            // nothing_below: true, TODO - OK to remove?
            not_stackable: hold.tables.front_table_not_stackable,
          },
          {
            label: 'front',
            qty: 0,
            color: containerPartColor,
            pos: {
              x: hold.tables.front_table_l - thickness / 2,
              y: hold.W / 2,
              z: hold.tables.front_table_h * 0.5 - thickness,
            },
            L: thickness,
            W: hold.W,
            H: hold.tables.front_table_h,
          }
        );
      }
    }

    const contours = {
      ...{
        front_bottom_contour_l: 0,
        front_bottom_contour_h: 0,
        rear_bottom_contour_l: 0,
        rear_bottom_contour_h: 0,
        side1_bottom_contour_l: 0,
        side1_bottom_contour_h: 0,
        side2_bottom_contour_l: 0,
        side2_bottom_contour_h: 0,
        front_top_contour_l: 0,
        front_top_contour_h: 0,
        rear_top_contour_l: 0,
        rear_top_contour_h: 0,
        side1_top_contour_l: 0,
        side1_top_contour_h: 0,
        side2_top_contour_l: 0,
        side2_top_contour_h: 0,
      },
      ...(hold.contours || {}),
    };

    if (Array.isArray(hold.parts)) {
      const full_bottom_length =
        hold.L - contours.front_bottom_contour_l - contours.rear_bottom_contour_l;
      const full_top_length = hold.L - contours.front_top_contour_l - contours.rear_top_contour_l;
      const full_bottom_width =
        hold.W - contours.side1_bottom_contour_l - contours.side2_bottom_contour_l;
      const full_top_width = hold.W - contours.side1_top_contour_l - contours.side2_top_contour_l;

      hold.parts.forEach((part: HoldPart) => {
        let L = part.L || 0;
        let W = part.W || 0;
        let H = part.H || 0;
        const pos = part.pos;

        //  L & X
        if (part.stick_to.includes('floor') && part.stick_to.includes('roof')) {
          if (part.stick_to.includes('front') && part.stick_to.includes('rear')) {
            L = Math.max(full_bottom_length, full_top_length);
            pos.x =
              hold.L * 0.5 +
              Math.max(contours.front_bottom_contour_l, contours.front_top_contour_l) * 0.5 -
              Math.max(contours.rear_bottom_contour_l, contours.rear_top_contour_l) * 0.5;
          } else if (part.stick_to.includes('front')) {
            pos.x =
              Math.max(contours.front_bottom_contour_l, contours.front_top_contour_l) + L * 0.5;
          } else if (part.stick_to.includes('rear')) {
            pos.x =
              hold.L -
              Math.max(contours.rear_bottom_contour_l, contours.rear_top_contour_l) -
              L * 0.5;
          }
        } else if (part.stick_to.includes('floor')) {
          if (part.stick_to.includes('front') && part.stick_to.includes('rear')) {
            L = full_bottom_length;
            pos.x =
              hold.L * 0.5 +
              contours.front_bottom_contour_l * 0.5 -
              contours.rear_bottom_contour_l * 0.5;
          } else if (part.stick_to.includes('front')) {
            pos.x = contours.front_bottom_contour_l + L * 0.5;
          } else if (part.stick_to.includes('rear')) {
            pos.x = hold.L - contours.rear_bottom_contour_l - L * 0.5;
          }
        } else if (part.stick_to.includes('roof')) {
          if (part.stick_to.includes('front') && part.stick_to.includes('rear')) {
            L = full_top_length;
            pos.x =
              hold.L * 0.5 + contours.front_top_contour_l * 0.5 - contours.rear_top_contour_l * 0.5;
          } else if (part.stick_to.includes('front')) {
            pos.x = contours.front_top_contour_l + L * 0.5;
          } else if (part.stick_to.includes('rear')) {
            pos.x = hold.L - contours.rear_top_contour_l - L * 0.5;
          }
        } else if (part.stick_to.includes('front') && part.stick_to.includes('rear')) {
          L = hold.L;
          pos.x = hold.L * 0.5;
        } else if (part.stick_to.includes('front')) {
          pos.x = L * 0.5;
        } else if (part.stick_to.includes('rear')) {
          pos.x = hold.L - L * 0.5;
        }

        //  W & Y
        if (part.stick_to.includes('floor') && part.stick_to.includes('roof')) {
          if (part.stick_to.includes('side1') && part.stick_to.includes('side2')) {
            W = Math.max(full_bottom_width, full_top_width);
            pos.y =
              hold.W * 0.5 +
              Math.max(contours.side1_bottom_contour_l, contours.side1_top_contour_l) * 0.5 -
              Math.max(contours.side2_bottom_contour_l, contours.side2_top_contour_l) * 0.5;
          } else if (part.stick_to.includes('side1')) {
            pos.y =
              Math.max(contours.side1_bottom_contour_l, contours.side1_top_contour_l) + W * 0.5;
          } else if (part.stick_to.includes('side2')) {
            pos.y =
              hold.W -
              Math.max(contours.side2_bottom_contour_l, contours.side2_top_contour_l) -
              W * 0.5;
          }
        } else if (part.stick_to.includes('floor')) {
          if (part.stick_to.includes('side1') && part.stick_to.includes('side2')) {
            W = full_bottom_width;
            pos.y =
              hold.W * 0.5 +
              contours.side1_bottom_contour_l * 0.5 -
              contours.side2_bottom_contour_l * 0.5;
          } else if (part.stick_to.includes('side1')) {
            pos.y = contours.side1_bottom_contour_l + W * 0.5;
          } else if (part.stick_to.includes('side2')) {
            pos.y = hold.W - contours.side2_bottom_contour_l - W * 0.5;
          }
        } else if (part.stick_to.includes('roof')) {
          if (part.stick_to.includes('side1') && part.stick_to.includes('side2')) {
            W = full_top_width;
            pos.y =
              hold.W * 0.5 +
              contours.side1_top_contour_l * 0.5 -
              contours.side2_top_contour_l * 0.5;
          } else if (part.stick_to.includes('side1')) {
            pos.y = contours.side1_top_contour_l + W * 0.5;
          } else if (part.stick_to.includes('side2')) {
            pos.y = hold.W - contours.side2_top_contour_l - W * 0.5;
          }
        } else if (part.stick_to.includes('side1') && part.stick_to.includes('side2')) {
          W = hold.W;
          pos.y = hold.W * 0.5;
        } else if (part.stick_to.includes('side1')) {
          pos.y = W * 0.5;
        } else if (part.stick_to.includes('side2')) {
          pos.y = hold.W - W * 0.5;
        }

        let max_top_contour = 0;
        let max_bottom_contour = 0;
        if (part.stick_to.includes('front')) {
          max_top_contour = Math.max(max_top_contour, contours.front_top_contour_h);
          max_bottom_contour = Math.max(max_bottom_contour, contours.front_bottom_contour_h);
        }
        if (part.stick_to.includes('rear')) {
          max_top_contour = Math.max(max_top_contour, contours.rear_top_contour_h);
          max_bottom_contour = Math.max(max_bottom_contour, contours.rear_bottom_contour_h);
        }
        if (part.stick_to.includes('side1')) {
          max_top_contour = Math.max(max_top_contour, contours.side1_top_contour_h);
          max_bottom_contour = Math.max(max_bottom_contour, contours.side1_bottom_contour_h);
        }
        if (part.stick_to.includes('side2')) {
          max_top_contour = Math.max(max_top_contour, contours.side2_top_contour_h);
          max_bottom_contour = Math.max(max_bottom_contour, contours.side2_bottom_contour_h);
        }

        //  H & Z
        if (part.stick_to.includes('floor') && part.stick_to.includes('roof')) {
          H = hold.H;
          pos.z = hold.H * 0.5;
        } else if (part.stick_to.includes('floor')) {
          pos.z = H * 0.5;
        } else if (part.stick_to.includes('roof')) {
          pos.z = hold.H - H * 0.5;
        }

        if (!isNaN(pos.x) && !isNaN(pos.y) && !isNaN(pos.z) && !isNaN(L) && !isNaN(W) && !isNaN(H))
          hold.items.push({
            label: part.type,
            qty: 0,
            not_stackable: part.not_stackable || part.stick_to.includes('roof'),
            color: containerPartColor,
            pos,
            L,
            W,
            H,
          });
      });
    }

    return hold;
  },
};
