<template>
  <v-card-text>
    <v-card>
      <v-card-text>
        <v-row dense align="center" v-for="hold in holds" :key="hold.id">
          <v-col cols="10" md="11">
            <span>
              <strong>
                {{ hold.data.name }}
              </strong>
              - ({{ hold.data.L | toLength }} x
              {{ hold.data.W | toLength }}
              <span v-if="hold.data.H > 0">x {{ hold.data.H | toLength }}</span>
              )
            </span>
          </v-col>
          <v-col cols="2" md="1">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="selectHold(hold.data)" large icon v-on="on" :ripple="false">
                  <v-icon color="green">mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              <span>Select</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Hold, HoldData } from '@/models/LoadlistModel';

export default Vue.extend({
  name: 'select_slot_container_type_row',
  props: {
    holds: Array as PropType<Hold[]>,
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    selectHold(hold: HoldData) {
      this.$emit('containerSelected', hold);
    },
  },
});
</script>

<style scoped>
/* .v-dialog__content {
  z-index: 1000 !important;
} */
</style>
