<template>
  <div class="height100">
    <div v-if="loadlist" class="d-flex flex-column height100">
      <v-card v-show="$route.name !== 'detail' && $route.name !== 'detail_set'" outlined>
        <v-card-title id="mainTitle" class="py-0 px-0">
          <v-tabs
            v-if="!hide_navigation"
            fixed-tabs
            centered
            :vertical="$vuetify.breakpoint.xsOnly"
            class="no-print"
            slider-color="primary"
            active-class="active-tab"
            hide-slider
            optional>
            <v-tab
              v-for="tab in tabs"
              :key="tab.key"
              :ripple="false"
              exact-path
              :to="tab.to"
              :disabled="tab.disabled"
              :id="tab.id"
              class="enumerated">
              <span>{{ tab.label }}</span>
            </v-tab>
          </v-tabs>
        </v-card-title>
        <v-divider></v-divider>
        <loadlist-info-component
          id="loadlist-info"
          @showUnloadedItems="expandUnloadedItems()"
          class="px-4"></loadlist-info-component>
      </v-card>
      <router-view></router-view>

      <unloaded-items-component
        v-if="showUnloadedItemsView"
        ref="unloadedItemsComponent"></unloaded-items-component>

      <calc-modal></calc-modal>

      <p class="text-center only-print pt-3" style="font-size: 10px">www.cargo-planner.com</p>

      <v-snackbar
        :value="saving"
        :timeout="-1"
        outlined
        rounded="pill"
        color="success"
        elevation="24"
        center
        bottom>
        <v-progress-circular indeterminate class="mr-4"></v-progress-circular>
        Saving...
      </v-snackbar>
    </div>
    <div v-else>
      <div class="text-center">
        <v-progress-circular indeterminate v-bind:size="100" color="primary"></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import CalcModal from '@/components/Modals/Calculation.vue';
import unloadedItemsComponent from '@/components/Custom/UnloadedItems.vue';
import LoadlistInfoComponent from '@/components/LoadlistDetail/LoadlistInfo.vue';
import { Loadlist, Loadplan } from '@/models/LoadlistModel';
import { getSerializerError } from '@/misc/errorUtils';

export default Vue.extend({
  name: 'loadlist',
  components: {
    CalcModal,
    unloadedItemsComponent,
    LoadlistInfoComponent,
  },
  data() {
    return {
      isLoading: false,
      showShareDialog: false,
      sharableURL: null,
    };
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    requiredInput(): boolean {
      return !!(
        this.loadlist?.data?.length &&
        this.loadlist?.weight_dim &&
        this.loadlist?.length_dim
      );
    },
    saving(): boolean {
      return this.loadlistStore.saving;
    },
    has_loadplan(): boolean {
      return this.loadplan && this.loadplan.holds.length > 0;
    },
    showUnloadedItemsView(): boolean {
      return (
        !!this.loadplan?.holds && ['detail', 'workspace', 'detail_set'].includes(this.$route.name)
      );
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    loadplan_version(): number {
      return this.loadlistStore.loadplan_version;
    },
    loadlist_result_has_changed(): boolean {
      return this.loadlistStore.loadlist_result_has_changed;
    },
    is_readonly(): boolean {
      return this.loadlistStore.readonly || this.loadlistStore.is_locked;
    },
    hide_navigation(): boolean {
      return !!this.$route.query.hide_navigation || this.is_readonly;
    },
    tabs(): {
      label: string;
      to: any;
      id: string;
      disabled: boolean;
      key: string;
    }[] {
      const tabs = [
        {
          label: 'Data',
          to: { name: 'loadlist' },
          id: 'mainDataTab',
          key: 'data',
          disabled: false,
        },
        {
          label: 'Setup',
          disabled: !this.requiredInput,
          to: { name: 'setup', params: { version: this.loadplan_version } },
          id: 'mainEquipmentTab',
          key: 'setup',
        },
        {
          label: 'Workspace',
          disabled: !this.requiredInput,
          to: { name: 'workspace', params: { version: this.loadplan_version } },
          id: 'mainWorkspaceTab',
          key: 'workspace',
        },
        {
          label: 'Load plan',
          disabled: !this.has_loadplan,
          to: {
            name: 'loadplan',
            params: { version: this.loadplan_version, page: 0 },
          },
          id: 'mainLoadplanTab',
          key: 'loadplan',
        },
      ];
      if (Vue.prototype.$navigation?.length > 0) {
        return tabs
          .filter((t) => Vue.prototype.$navigation.indexOf(t.key) >= 0)
          .map((t) => {
            return { ...t, to: { ...t.to } };
          });
      }
      return tabs;
    },
  },
  created(): void {
    this.isLoading = true;

    if (!this.$route.query.no_fetch) {
      if (!this.is_authenticated)
        API.instance.defaults.headers.common['Signature'] = this.$route.query.s;

      this.getLoadlist(this.$route.params.id)
        .then((success) => {
          this.isLoading = false;
          this.loadlistStore.setLoadplanVersion(parseInt(this.$route.params.version) || 0);
          // We need to fetch query params now because they might dissapear in the reroute:
          if (!this.is_authenticated) {
            API.instance.defaults.headers.common['Loadlist'] = this.loadlist.id;
            this.miscStore.setUserPreference({
              key: 'length_dim',
              value: this.$route.query.length_dim || 'CM',
            });
            this.miscStore.setUserPreference({
              key: 'weight_dim',
              value: this.$route.query.weight_dim || 'KG',
            });
          }

          // Reroute to loadplan view if locked
          if (this.is_readonly && this.$route.name !== 'loadplan')
            this.$router.replace({
              name: 'loadplan',
              params: { version: String(this.loadplan_version), page: '0' },
            });
        })
        .catch((e) => {
          if (e?.response?.data) {
            this.miscStore.error_message = getSerializerError(e.response.data);
          }
          this.logout();
        });
    }
  },
  beforeRouteLeave(to: never, from: never, next: any) {
    this.loadlistStore.saveLoadlistResult().finally(() => next());
  },
  destroyed() {
    this.clearLoadlist();
    delete API.instance.defaults.headers.common['Loadlist'];
  },
  methods: {
    expandUnloadedItems(): void {
      (this.$refs.unloadedItemsComponent as any).expand();
    },
    getLoadlist(id: string): Promise<Loadlist> {
      return this.loadlistStore.getLoadlist(id);
    },
    clearLoadlist(): void {
      this.loadlistStore.clearLoadlist();
    },
    logout(): void {
      this.miscStore.logout(null);
    },
  },
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.enumerated-parent {
  counter-reset: tabs;
}
.v-tab.enumerated {
  counter-increment: tabs;
}
.v-tab.enumerated > span::before {
  content: counter(tabs) '. ';
}
/* .fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
} */
.active-tab {
  border-bottom: 2px solid var(--v-primary-base);
}
</style>
