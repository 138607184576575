import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_vm._t("view",function(){return [_c('scene-component',{key:_vm.renderKey,attrs:{"set":_vm.renderSet,"canvas-width":800,"canvas-height":300,"customViewSettings":{
              view: '3d-ortho',
              containerNumbers: true,
            }}})]},null,{ renderKey: _vm.renderKey })],2)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Quantity [#]:","type":"number","persistent-hint":"","hint":"(Number of sets available)","value":_vm.modified_set.qty},on:{"change":function($event){_vm.$set(_vm.modified_set, 'qty', parseInt($event) || null)}}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Cost [$, €, £, ...]:","type":"number","persistent-hint":"","hint":`(A number, relative to other ${_vm.typeName}s and sets)`,"value":_vm.modified_set.cost},on:{"change":function($event){_vm.$set(_vm.modified_set, 'cost', parseFloat($event) || null)}}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('weight-input-field',{attrs:{"label":"Payload","min":0,"persistent-hint":"","hint":"(Max weight)"},model:{value:(_vm.modified_set.payload),callback:function ($$v) {_vm.$set(_vm.modified_set, "payload", $$v)},expression:"modified_set.payload"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('length-input-field',{attrs:{"label":"Max volume","min":0,"sup":3,"persistent-hint":"","hint":"(Max volume)"},model:{value:(_vm.modified_set.max_volume),callback:function ($$v) {_vm.$set(_vm.modified_set, "max_volume", $$v)},expression:"modified_set.max_volume"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-subtitle-1 pt-8"},[_vm._v(" Configurations in "+_vm._s(_vm.setName().toLowerCase())+" ")])],1),_c(VRow,{attrs:{"dense":"","align":"center"}},[_c(VCol,[_c('layout-section',{attrs:{"set":_vm.setType,"displayConfiguration":_vm.displayConfiguration,"hideAddGroup":""},scopedSlots:_vm._u([{key:"header",fn:function({ setSlot, slotIndex }){return [_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('span',{staticClass:"text-subtitle-1 px-4"},[_vm._v(_vm._s(setSlot.name))]),_c(VSpacer),_c(VSelect,{staticClass:"ml-4",staticStyle:{"max-width":"300px"},attrs:{"disabled":setSlot.configurations.length < 0,"items":setSlot.configurations.map((configuration, i) => {
                    return {
                      text: _vm.configurationSummary(configuration),
                      value: i,
                      configuration,
                    };
                  }),"value":_vm.displayConfiguration[slotIndex],"hide-details":"","outlined":"","dense":""},on:{"change":(e) => _vm.handleConfigChange(e, slotIndex)},scopedSlots:_vm._u([{key:"item",fn:function({ item, attrs, on }){return [_c(VListItem,_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c(VListItemIcon,[(active)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_c(VIcon,[_vm._v("mdi-circle-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true)})],1)]}}])})],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-subtitle-1 pt-8"},[_vm._v(" Equipment used in this "+_vm._s(_vm.setName().toLowerCase())+" ")])],1),_vm._l((_vm.modified_set.container_types),function(hold,index){return _c(VRow,{key:hold.id,attrs:{"dense":"","align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VExpansionPanels,{staticClass:"unselectable",attrs:{"focusable":""}},[_c(VExpansionPanel,{on:{"click":function($event){return _vm.$vuetify.goTo($event.target)}}},[_c(VExpansionPanelHeader,[_vm._v(_vm._s(hold.name))]),_c(VExpansionPanelContent,[_c('inline-hold-edit-component',{attrs:{"basetype":_vm.modified_set.base_type},model:{value:(_vm.modified_set.container_types[index]),callback:function ($$v) {_vm.$set(_vm.modified_set.container_types, index, $$v)},expression:"modified_set.container_types[index]"}})],1)],1)],1)],1)],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }