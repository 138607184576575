import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"no-print"},[(!!_vm.steps)?_c(VOverlay,{attrs:{"value":_vm.show,"opacity":"0.2"}},[_c(VCard,{ref:"tooltip",staticClass:"tooltip",attrs:{"id":"popperTooltip","min-width":"400","max-width":"500","outlined":""}},[_c(VCardTitle,[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.currentTitle))])]),_c(VCardText,{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(_vm.currentContent)}}),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","disabled":_vm.currentStep === 0},on:{"click":_vm.previousStep}},[_vm._v("Previous")]),_c(VBtn,{attrs:{"text":"","disabled":_vm.currentStep >= _vm.steps.length - 1},on:{"click":_vm.nextStep}},[_vm._v("Next")]),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.finishTour}},[_vm._v("Close")])],1),_c('div',{attrs:{"id":"arrow","data-popper-arrow":""}})],1)],1):_vm._e(),(!!_vm.steps && !_vm.show)?_c(VBtn,{staticClass:"primary",staticStyle:{"z-index":"10"},attrs:{"fixed":"","fab":"","bottom":"","left":""},on:{"click":_vm.startTour}},[_c(VIcon,[_vm._v("mdi-help")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }