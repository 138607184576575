<template>
  <v-dialog v-model="show" scrollable persistent width="800">
    <v-card>
      <v-card-title class="text-h5">Terms and Conditions</v-card-title>
      <v-card-text>
        <p>Last updated: March 09, 2019</p>

        <p>
          These Terms and Conditions (&quot;Terms&quot;, &quot;Terms and Conditions&quot;) govern
          your relationship with Cargo-Planner.com website (the &quot;Service&quot;) operated by
          T&ouml;rnblom Software AB (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).
        </p>

        <p>Please read these Terms and Conditions carefully before using the Service.</p>

        <p>
          Your access to and use of the Service is conditioned on your acceptance of and compliance
          with these Terms. These Terms apply to all visitors, users and others who access or use
          the Service.
        </p>

        <p>
          By accessing or using the Service you agree to be bound by these Terms. If you disagree
          with any part of the terms then you may not access the Service.
        </p>

        <h2>Subscriptions</h2>

        <p>
          Some parts of the Service are billed on a subscription basis
          (&quot;Subscription(s)&quot;). You will be billed in advance on a recurring and periodic
          basis (&quot;Billing Cycle&quot;). Billing cycles are set either on a monthly or annual
          basis, depending on the type of subscription plan you select when purchasing a
          Subscription.
        </p>

        <p>
          At the end of each Billing Cycle, your Subscription will automatically renew under the
          exact same conditions unless you cancel it or T&ouml;rnblom Software AB cancels it. You
          may cancel your Subscription renewal either through your online account management page or
          by contacting T&ouml;rnblom Software AB customer support team.
        </p>

        <p>
          A valid payment method, including credit card and bank transfer, is required to process
          the payment for your Subscription. You shall provide T&ouml;rnblom Software AB with
          accurate and complete billing information including company name, address, state, zip
          code, and a valid payment method information. For Eurpean customers, a valid VAT number is
          also required. By submitting such payment information, you automatically authorize
          T&ouml;rnblom Software AB to charge all Subscription fees incurred through your account to
          any such payment instruments.
        </p>

        <p>
          Should automatic billing fail to occur for any reason, T&ouml;rnblom Software AB will
          issue an electronic invoice indicating that you must proceed manually, within a certain
          deadline date, with the full payment corresponding to the billing period as indicated on
          the invoice.
        </p>

        <h2>Free Trial</h2>

        <p>
          T&ouml;rnblom Software AB may, at its sole discretion, offer a Subscription with a free
          trial for a limited period of time (&quot;Free Trial&quot;).
        </p>

        <p>
          At any time and without notice, T&ouml;rnblom Software AB reserves the right to (i) modify
          the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.
        </p>

        <h2>Fee Changes</h2>

        <p>
          T&ouml;rnblom Software AB, in its sole discretion and at any time, may modify the
          Subscription fees for the Subscriptions. Any Subscription fee change will become effective
          at the end of the then-current Billing Cycle.
        </p>

        <p>
          T&ouml;rnblom Software AB will provide you with a reasonable prior notice of any change in
          Subscription fees to give you an opportunity to terminate your Subscription before such
          change becomes effective.
        </p>

        <p>
          Your continued use of the Service after the Subscription fee change comes into effect
          constitutes your agreement to pay the modified Subscription fee amount.
        </p>

        <h2>Refunds</h2>

        <p>Except when required by law, paid Subscription fees are non-refundable.</p>

        <h2>Content</h2>

        <p>
          Our Service allows you to post, link, store, share and otherwise make available certain
          information, text, graphics, videos, or other material (&quot;Content&quot;). You are
          responsible for the Content that you post to the Service, including its legality,
          reliability, and appropriateness.
        </p>

        <p>
          By posting Content to the Service, you grant us the right and license to use, modify,
          perform, display, reproduce, and distribute such Content on and through the Service. You
          retain any and all of your rights to any Content you submit, post or display on or through
          the Service and you are responsible for protecting those rights.
        </p>

        <p>
          You represent and warrant that: (i) the Content is yours (you own it) or you have the
          right to use it and grant us the rights and license as provided in these Terms, and (ii)
          the posting of your Content on or through the Service does not violate the privacy rights,
          publicity rights, copyrights, contract rights or any other rights of any person.
        </p>

        <h2>Accounts</h2>

        <p>
          When you create an account with us, you must provide us information that is accurate,
          complete, and current at all times. Failure to do so constitutes a breach of the Terms,
          which may result in immediate termination of your account on our Service.
        </p>

        <p>
          You are responsible for safeguarding the password that you use to access the Service and
          for any activities or actions under your password, whether your password is with our
          Service or a third-party service.
        </p>

        <p>
          You agree not to disclose your password to any third party. You must notify us immediately
          upon becoming aware of any breach of security or unauthorized use of your account.
        </p>

        <p>
          You may not use as a username the name of another person or entity or that is not lawfully
          available for use, a name or trade mark that is subject to any rights of another person or
          entity other than you without appropriate authorization, or a name that is otherwise
          offensive, vulgar or obscene.
        </p>

        <h2>Links To Other Web Sites</h2>

        <p>
          Our Service may contain links to third-party web sites or services that are not owned or
          controlled by T&ouml;rnblom Software AB.
        </p>

        <p>
          T&ouml;rnblom Software AB has no control over, and assumes no responsibility for, the
          content, privacy policies, or practices of any third party web sites or services. You
          further acknowledge and agree that T&ouml;rnblom Software AB shall not be responsible or
          liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with use of or reliance on any such content, goods or services available
          on or through any such web sites or services.
        </p>

        <p>
          We strongly advise you to read the terms and conditions and privacy policies of any
          third-party web sites or services that you visit.
        </p>

        <h2>Termination</h2>

        <p>
          We may terminate or suspend your account immediately, without prior notice or liability,
          for any reason whatsoever, including without limitation if you breach the Terms.
        </p>

        <p>
          Upon termination, your right to use the Service will immediately cease. If you wish to
          terminate your account, you may simply discontinue using the Service.
        </p>

        <h2>Limitation Of Liability</h2>

        <p>
          Our Service&nbsp;is a stowage optimization software which tries to find the best and most
          efficient stowage pattern(s) in cargo carrier(s), but the proposed stowage pattern(s) are
          in no event guaranteed to be (i) optimal; (ii) feasible (iii) secure. It is up to you to
          verify the proposed stowage pattern(s).&nbsp;
        </p>

        <p>
          In no event shall T&ouml;rnblom Software AB, nor its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect, incidental, special,
          consequential or punitive damages, including without limitation, loss of profits, data,
          use, goodwill, or other intangible losses, resulting from (i) your access to or use of or
          inability to access or use the Service; (ii) any conduct or content of any third party on
          the Service; (iii) any content obtained from the Service; and (iv) unauthorized access,
          use or alteration of your transmissions or content, whether based on warranty, contract,
          tort (including negligence) or any other legal theory, whether or not we have been
          informed of the possibility of such damage, and even if a remedy set forth herein is found
          to have failed of its essential purpose.
        </p>

        <h2>Disclaimer</h2>

        <p>
          Your use of the Service is at your sole risk. The Service is provided on an &quot;AS
          IS&quot; and &quot;AS AVAILABLE&quot; basis. The Service is provided without warranties of
          any kind, whether express or implied, including, but not limited to, implied warranties of
          merchantability, fitness for a particular purpose, non-infringement or course of
          performance.
        </p>

        <p>
          T&ouml;rnblom Software AB its subsidiaries, affiliates, and its licensors do not warrant
          that a) the Service will function uninterrupted, secure or available at any particular
          time or location; b) any errors or defects will be corrected; c) the Service is free of
          viruses or other harmful components; or d) the results of using the Service will meet your
          requirements.
        </p>

        <h2>Governing Law</h2>

        <p>
          These Terms shall be governed and construed in accordance with the laws of Sweden, without
          regard to its conflict of law provisions.
        </p>

        <p>
          Our failure to enforce any right or provision of these Terms will not be considered a
          waiver of those rights. If any provision of these Terms is held to be invalid or
          unenforceable by a court, the remaining provisions of these Terms will remain in effect.
          These Terms constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us regarding the Service.
        </p>

        <h2>Changes</h2>

        <p>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any
          time. If a revision is material we will try to provide at least 30 days notice prior to
          any new terms taking effect. What constitutes a material change will be determined at our
          sole discretion.
        </p>

        <p>
          By continuing to access or use our Service after those revisions become effective, you
          agree to be bound by the revised terms. If you do not agree to the new terms, please stop
          using the Service.
        </p>

        <h2>Contact Us</h2>

        <p>If you have any questions about these Terms, please contact us.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn text :loading="loading" :disabled="loading" @click.stop="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'terms-and-conditions-modal', //
  props: {
    visible: Boolean,
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {},
  methods: {
    update() {},
  },
  filters: {
    date(timestamp: number): Date {
      console.log(timestamp, 'timestamp');
      return new Date(timestamp);
    },
  },
});
</script>

<style>
.pre {
  white-space: pre-wrap;
}
</style>
