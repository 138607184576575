import {
  Group,
  CanvasTexture,
  Vector3,
  Box3,
  Sprite,
  SpriteMaterial,
  Material,
  Texture,
  Object3D,
  BufferGeometry,
  MeshLambertMaterial,
  Euler,
  Event,
  Intersection,
  Raycaster,
  Mesh,
  BoxHelper,
  BoxGeometry,
  LineDashedMaterial,
  MeshBasicMaterial,
  DoubleSide,
  Color,
  Box3Helper,
} from 'three';

import { Item } from './item/item';
import { NestedItem } from './item/nestedItem';
import { createContainerParts } from './createContainerParts';
import ItemHelper from '@/misc/itemUtils';
import { eventBus } from './eventBus';
import { physicsWorker } from './sceneManager';
import { HoldData } from '@/models/LoadlistModel';
import { cloneMaterial } from './utils';
import { InteractiveMode } from '@/models/GraphicsModel';
import { palette } from '@/misc/colorUtils';

class Container extends Object3D {
  items = new Group();
  parts = new Group();
  cogObject: Sprite;
  center: Vector3;
  centerObject: Sprite;
  selectionBox: Mesh;
  constructor(container_data: HoldData) {
    super();
    // @ts-ignore
    this.type = 'Container';
    this.userData.container = container_data;
    this.center = new Vector3(
      container_data.L * 0.5,
      container_data.W * 0.5,
      container_data.H * 0.5
    );
  }

  async init(
    interactiveMode: InteractiveMode,
    item_index: number,
    hideLabels: boolean,
    itemLabels: string[],
    show_nested_as_box: boolean,
    maxPPM: number = 256
  ): Promise<void> {
    if (this.userData.container.items.length) {
      // let uniqueObjects = [];
      for (let i = 0; i < this.userData.container.items.length; i++) {
        const item = this.userData.container.items[i];
        // this.userData.container.items.forEach((item) => {
        let geometry = undefined;
        let material = undefined;

        // if (true && item.qty > 0) {
        //   let foundIndex = uniqueObjects.findIndex(
        //     el =>
        //       ItemHelper.compareItems(item, el[0]) &&
        //       item.geometry === el[0].geometry
        //   );
        //   if (foundIndex > -1) {
        //     uniqueObjects[foundIndex].push(item);
        //   } else {
        //     uniqueObjects.push([item]);
        //   }
        // } else {

        if (item.from_container && !show_nested_as_box && !item.bundling?.is_bundle) {
          const nestedItem = new NestedItem(
            item,
            i + item_index,
            !!interactiveMode,
            hideLabels,
            itemLabels,
            show_nested_as_box,
            maxPPM
          );
          await nestedItem.init();
          this.items.add(nestedItem);
        } else {
          if (
            item.qty === 0 &&
            item.label?.startsWith('intersecting:') &&
            interactiveMode !== 'full'
          ) {
            continue;
          }
          for (let j = 0; j < this.items.children.length; j++) {
            const existing = this.items.children[j] as Item;
            if (
              existing.isCargo &&
              ItemHelper.compareDimensions(item, existing.userData.item) &&
              item.color === existing.userData.item.color &&
              item.geometry === existing.userData.item.geometry
            ) {
              geometry = existing.geometry;

              if (item.not_stackable === existing.userData.item.not_stackable) {
                material = interactiveMode ? cloneMaterial(existing.material) : existing.material;
                break;
              }
            }
          }
          this.items.add(
            new Item(
              item,
              i + item_index,
              geometry,
              material as MeshLambertMaterial,
              !!interactiveMode,
              hideLabels,
              itemLabels,
              maxPPM
            )
          );
        }

        // }
      }

      // var dummy = new Object3D();
      // uniqueObjects.forEach(obj => {
      //   let geometry = new BoxGeometry(obj[0].L, obj[0].W, obj[0].H);
      //   let texture_data = createBoxCargoTexture(obj[0]);
      //   let material = new MeshBasicMaterial({
      //     map: texture_data.texture
      //   });
      //   setCargoTextureUV(geometry, texture_data);
      //   geometry = new BufferGeometry().fromGeometry(geometry);
      //   let mesh = new InstancedMesh(geometry, material, obj.length);
      //   for (var i = 0; i < obj.length; i++) {
      //     dummy.position.set(obj[i].x, obj[i].y, obj[i].z);
      //     if (obj[i].rotation) {
      //       dummy.rotation.fromArray(obj[i].rotation);
      //     }
      //     dummy.updateMatrix();
      //     mesh.setMatrixAt(i, dummy.matrix);
      //   }
      //   mesh.geometry.computeBoundingBox();
      //   let h = new BoxHelper(mesh, 0xff0000);
      //   this.items.add(mesh, h);
      // });
    }
    this.parts.name = 'container_parts';
    this.parts.position.set(0, 0, 0);
    this.items.position.addScaledVector(this.center, -1);

    // this.parts.position.set(
    //   this.userData.container.L * 0.5,
    //   this.userData.container.W * 0.5,
    //   this.userData.container.H * 0.5
    // );
    await createContainerParts(this.userData.container, this.parts);
    this.add(this.items, this.parts);

    if (this.userData.container.preview) {
      this.parts.children.forEach((c) => {
        if (c.type === 'LineSegments' || c.type === 'Line') {
          (c as any).material = new LineDashedMaterial({
            dashSize: 0.1,
            gapSize: 0.1,
            color: palette.clouds.dark,
          });
          (c as any).computeLineDistances();
        }
        if (c.type === 'Mesh' && c.name == 'Floor') {
          (c as any).material = new MeshBasicMaterial({
            color: palette.clouds.normal,
            transparent: false,
            side: DoubleSide,
          });
        }
      });
    }

    //Centerpoint
    this.centerObject = new Sprite(
      new SpriteMaterial({
        map: createCenterTexture(),
        color: 0xffffff,
        depthTest: false,
        transparent: true,
        opacity: 0.5,
      })
    );
    this.centerObject.scale.multiplyScalar(this.userData.container.L * 0.025);
    this.centerObject.position.set(
      0,
      0,
      this.userData.container.H ? 0 : this.userData.container.max_height / 2.0 || 0
    );
    this.add(this.centerObject);
    if (!interactiveMode) this.centerObject.visible = false;

    //COG
    this.cogObject = new Sprite(
      new SpriteMaterial({
        map: createCogTexture(),
        color: 0xffffff,
        depthTest: false,
        transparent: true,
        opacity: 0.5,
      })
    );
    this.cogObject.scale.multiplyScalar(this.userData.container.L * 0.05);
    this.updateCog();
    this.add(this.cogObject);

    if (!interactiveMode && !this.userData.container.items_count) {
      this.hideCog();
    }

    // For debugging purpose
    if (this.userData.container.spaces) {
      this.userData.container.spaces.list.forEach((i: { bb: Box3 }) => {
        const ems = i.bb;

        const box = new Box3();
        box.setFromCenterAndSize(
          new Vector3(
            ems.min.x + (ems.max.x - ems.min.x) * 0.5,
            ems.min.y + (ems.max.y - ems.min.y) * 0.5,
            ems.min.z + (ems.max.z - ems.min.z) * 0.5
          ),
          new Vector3(ems.max.x - ems.min.x, ems.max.y - ems.min.y, ems.max.z - ems.min.z)
        );

        function getRandomColor() {
          const letters = '0123456789ABCDEF';
          let color = '#';
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }

        const helper = new Box3Helper(box, new Color(getRandomColor()));
        this.items.add(helper);
      });
    }
    if (interactiveMode === 'container_mode') {
      this.createSelectionBox();
    }
    if (this.userData.partOfSet) {
      const position = this.userData.container.position || { x: 0, y: 0, z: 0 };
      const rotation = this.userData.container.rotation || [0, 0, 0, 'XYZ'];

      this.quaternion.setFromEuler(new Euler(rotation[0], rotation[1], rotation[2], rotation[3]));

      // The position is set to be centered in the y-direction and 0 at floor level and 0 at left side of container.
      // We set the position on the entire object to still be able to rotate around the objects center axis
      this.position.set(position.x, position.y, position.z);
    }
    this.position.add(new Vector3(this.center.x, this.center.y, this.center.z));
  }

  createSelectionBox(): void {
    this.updateWorldMatrix(true, true);

    // Get the dimensions of the selection box
    // only use the general parts, not the items,
    // as a selection box with OoG items will be misplaced with the position
    const aabb = new Box3();
    aabb.setFromObject(this.parts);
    const boxSize = aabb.getSize(new Vector3());

    const boxGeometry = new BoxGeometry(boxSize.x, boxSize.y, boxSize.z);
    // compensate for the floor height of parts not being the same size as the object in general
    boxGeometry.translate(0, 0, boxSize.z / 2 - (this.userData.container.floor_height || 0)); // TODO fix offset
    this.selectionBox = new Mesh(
      boxGeometry,
      new MeshLambertMaterial({
        color: 0x8ce085,
        transparent: true,
        opacity: 0.4,
      })
    );

    this.selectionBox.visible = false;

    this.selectionBox.scale.set(1.01, 1.01, 1.01);
    if (this.center.z > 0) {
      this.selectionBox.position.set(
        this.selectionBox.position.x,
        this.selectionBox.position.y,
        this.selectionBox.position.z - boxSize.z / 2
      );
    }
    this.add(this.selectionBox);

    // this.scale.set(0.99, 0.99, 1.0);

    this.updateMatrix();
  }

  getBox3(): Box3 {
    const boxhelper = new BoxHelper(this);
    const box3 = new Box3();
    box3.setFromObject(boxhelper);
    return box3;
  }

  setSelectionOpacity(opacity = 0): void {
    if (this.selectionBox) {
      (this.selectionBox.material as Material).opacity = opacity;
    }
  }

  setSelectionVisibility(visible = false): void {
    if (this.selectionBox) {
      this.selectionBox.visible = visible;
    }
  }

  setSelectionColor(selected: boolean) {
    if (this.selectionBox) {
      (this.selectionBox.material as MeshBasicMaterial).color = selected
        ? new Color(0x4678b2)
        : new Color(0x8ce085);
    }
  }

  dispose(): void {
    deleteobjects(this);
    this.cogObject = undefined;
    this.centerObject = undefined;
    this.items = undefined;
    this.parts = undefined;
  }

  saveState(): void {
    let allCargoBoundingBox: Box3 = undefined;
    let noItems = 0;
    this.getCargoes().forEach((child) => {
      if (child.userData.item.from_container)
        noItems += child.userData.item.from_container.items_count;
      else noItems++;
      const boundingBox = new Box3().setFromObject(child);

      allCargoBoundingBox = allCargoBoundingBox
        ? allCargoBoundingBox.union(boundingBox)
        : boundingBox.clone();

      child.userData.item.pos = JSON.parse(JSON.stringify(child.position));
      child.userData.item.rotation = child.rotation.clone().toArray();
      child.userData.item.bb = boundingBox.clone();
    });
    this.userData.container.items_count = noItems;
    this.userData.container.items_bb = allCargoBoundingBox
      ? JSON.parse(JSON.stringify(allCargoBoundingBox))
      : new Box3(new Vector3(), new Vector3());
  }
  raycast(raycaster: Raycaster, intersects: Intersection<Object3D>[]): void {
    if (this.selectionBox) {
      const i: Intersection<Object3D>[] = [];
      this.selectionBox?.raycast(raycaster, i);
      if (i.length > 0) {
        intersects.push({ ...i[0], object: this });
      }
    }
  }

  updateCog(): void {
    const cog = new Vector3(
      this.userData.container.L * 0.5,
      this.userData.container.W * 0.5,
      this.userData.container.H * 0.5
    ).multiplyScalar(this.userData.container.tare || 0);
    let totalWeight = this.userData.container.tare || 0;

    this.getCargoes()
      .filter((cargo) => cargo.userData.item.WT)
      .forEach((cargo) => {
        cog.add(cargo.position.clone().multiplyScalar(cargo.userData.item.WT));
        totalWeight += cargo.userData.item.WT;
      });

    if (totalWeight > 0) cog.divideScalar(totalWeight);
    this.cogObject.position.copy(cog);
    this.cogObject.position.addScaledVector(this.center, -1);
    eventBus.emit('cog-updated', cog);
  }
  hideCog(): void {
    this.cogObject.visible = false;
    this.centerObject.visible = false;
  }

  hideContainerParts(): void {
    this.children
      .filter((child) => child.name == 'container_parts')
      .forEach((c) => (c.visible = false));
  }

  getCargoes(): (Item | NestedItem)[] {
    return this.items.children.filter((i: Item | NestedItem) => i.isCargo) as (Item | NestedItem)[];
  }

  getSelectedItems(): (Item | NestedItem)[] {
    return this.items.children.filter((i: Item) => i.isSelected) as (Item | NestedItem)[];
  }

  getPartsAndItems(): (Item | NestedItem)[] {
    return [
      ...this.items.children,
      ...this.parts.children.filter((p) => p.type != 'Line' && p.name != 'Door'),
    ] as (Item | NestedItem)[];
  }

  selectAllCargoes(): void {
    this.getCargoes().forEach((c) => c.select());
  }

  deselectAllCargoes(): void {
    this.getCargoes().forEach((c) => c.deselect());
  }

  // place a number on top of the container
  showContainerNumber(number: number): void {
    const text = this.userData.container.position_name || `${number}`;
    const cargoCanvasTexture = document.createElement('canvas');
    const ctx = cargoCanvasTexture.getContext('2d');
    const canvasSize = 64;
    const canvasWidth = canvasSize * text.length;
    cargoCanvasTexture.width = canvasWidth;
    cargoCanvasTexture.height = canvasSize;
    ctx.textAlign = 'center';
    ctx.fillStyle = '#ffffff';
    ctx.strokeStyle = '#333333';
    ctx.lineWidth = 3;
    ctx.textBaseline = 'middle';
    ctx.font = '64px Roboto, monospace';
    ctx.fillText(text, canvasWidth * 0.5, canvasSize * 0.5 + ctx.lineWidth);
    ctx.lineWidth = 2;
    ctx.strokeText(text, canvasWidth * 0.5, canvasSize * 0.5 + 2);

    // document.getElementsByTagName("body")[0].appendChild(cargoCanvasTexture);

    const canvas = new CanvasTexture(cargoCanvasTexture);

    const sprite = new Sprite(
      new SpriteMaterial({
        map: canvas,
        color: 0xffffff,
        depthTest: false,
        sizeAttenuation: true,
      })
    );
    let scale = 0.8;
    if (Math.min(this.userData.container.L, this.userData.container.W) < 1.0) {
      scale = 0.4;
    }
    sprite.scale.set(text.length * scale, scale, scale);
    let height = 0;
    if (this.userData.container.H === 0) {
      height = this.userData.container.max_height / 2.0 || 0;
    }
    sprite.position.set(0, 0, height);
    this.add(sprite);
  }

  showItemsByWeightScale(): void {
    const maxItemWeight = Math.max(...this.getCargoes().map((i) => i.userData.item.WT));

    this.getCargoes().forEach((i) => {
      const weightPercentage = i.userData.item.WT / maxItemWeight;
      const [r, g, b] = [2.0 * weightPercentage, 2.0 * (1 - weightPercentage), 0];

      const hex = parseInt(
        componentToHex(Math.round(clamp(r, 0, 1) * 255)) +
          componentToHex(Math.round(clamp(g, 0, 1) * 255)) +
          componentToHex(Math.round(clamp(b, 0, 1) * 255)),
        16
      );
      i.setOpacity(weightPercentage);
      i.setColor(hex);
    });
  }

  showCargoesByIndex(highlighted: number[], visible: number[]): Box3 {
    let highlighted_bb: Box3 = undefined;

    this.getPartsAndItems()
      .filter((i) => !i.isCargo && i.type == 'Item')
      .forEach((i) => {
        i.setOpacity(0.2);
      });
    this.getCargoes().forEach((c) => {
      const bb = new Box3().setFromObject(c);
      if (highlighted.includes(c.indexInContainer)) {
        if (highlighted_bb) {
          highlighted_bb = highlighted_bb.union(bb);
        } else {
          highlighted_bb = bb;
        }
      } else if (visible.includes(c.indexInContainer)) {
        c.setOpacity(0.1);
      } else {
        c.visible = false;
      }
    });
    return highlighted_bb;
  }

  showAll(): void {
    this.getCargoes().forEach((c) => {
      c.setOpacity(1.0);
      c.visible = true;
    });
  }

  unloadSelectedItems(): number[] {
    const removedItemsData = [];
    for (let i = this.items.children.length - 1; i >= 0; i--) {
      const item = this.items.children[i] as Item | NestedItem;
      if (item.isSelected) {
        removedItemsData.push(item.indexInContainer);
        item.isCargo = false;
        item.visible = false;
        physicsWorker.postMessage({
          event: 'remove',
          itemIndex: item.indexInContainer,
        });
      }
    }
    return removedItemsData;
  }
}

export { Container, deleteobjects };

type TexturedMaterial = Material & { map: Texture };
type Node = Object3D & {
  geometry: BufferGeometry;
  material: TexturedMaterial | TexturedMaterial[];
};
function disposeNode(node: Node): void {
  if (node.geometry) {
    node.geometry.dispose();
    node.geometry = undefined;
  }
  if (node.material) {
    disposeMaterial(node.material);
  }
  // node.boxBody = undefined;
  node.userData = undefined;
  node = undefined;
}

function disposeMaterial(material: TexturedMaterial | TexturedMaterial[]): void {
  if (Array.isArray(material)) {
    material.forEach((m: TexturedMaterial | TexturedMaterial[]) => {
      disposeMaterial(m);
    });
  } else {
    if (material.map) {
      material.map.dispose();
      material.map = undefined;
    }
    material.dispose();
    material = undefined;
  }
}

function deleteobjects(node: Node | Group | Container): void {
  if (!node) return;
  for (let i = node.children.length - 1; i >= 0; i--) {
    const child = node.children[i] as Node;
    deleteobjects(child);
    disposeNode(child);
    node.remove(child);
  }
}

function createCogTexture() {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const canvasWidth = 64;
  const canvasHeight = 64;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  let startAngle = 0;
  let endAngle = Math.PI / 2;
  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.arc(canvasWidth * 0.5, canvasHeight * 0.5, canvasWidth * 0.5, startAngle, endAngle);
  ctx.closePath();
  ctx.fill();

  startAngle += Math.PI * 0.5;
  endAngle += Math.PI * 0.5;
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.arc(canvasWidth * 0.5, canvasHeight * 0.5, canvasWidth * 0.5, startAngle, endAngle);
  ctx.closePath();
  ctx.fill();

  startAngle += Math.PI * 0.5;
  endAngle += Math.PI * 0.5;
  ctx.fillStyle = 'black';
  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.arc(canvasWidth * 0.5, canvasHeight * 0.5, canvasWidth * 0.5, startAngle, endAngle);
  ctx.closePath();
  ctx.fill();

  startAngle += Math.PI * 0.5;
  endAngle += Math.PI * 0.5;
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.arc(canvasWidth * 0.5, canvasHeight * 0.5, canvasWidth * 0.5, startAngle, endAngle);
  ctx.closePath();
  ctx.fill();

  return new CanvasTexture(canvas);
}

function createCenterTexture() {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const canvasWidth = 16;
  const canvasHeight = 16;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  let startAngle = 0;
  let endAngle = Math.PI * 2;
  ctx.beginPath();
  ctx.moveTo(canvasWidth * 0.5, canvasHeight * 0.5);
  ctx.arc(canvasWidth * 0.5, canvasHeight * 0.5, canvasWidth * 0.5, startAngle, endAngle);
  ctx.fillStyle = '#ff0000';
  ctx.closePath();
  ctx.fill();

  return new CanvasTexture(canvas);
}

const clamp = function (val: number, min: number, max: number) {
  return Math.min(Math.max(val, min), max);
};
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}
