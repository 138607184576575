import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VSnackbar,{attrs:{"timeout":5000,"bottom":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" Column settings updated ")]),_vm._l((_vm.columns),function(column){return _c(VTooltip,{key:column.key,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VChip,_vm._g({staticClass:"ma-2",attrs:{"label":"","color":_vm.selectedColumns.includes(column.key) ? 'primary' : 'gray'},on:{"click":function($event){return _vm.toggleColumn(column.key)}}},on),[_vm._v(" "+_vm._s(column.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(column.desc))])])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }