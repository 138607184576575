import { render, staticRenderFns } from "./CopyLoadlist.vue?vue&type=template&id=281dbd62&scoped=true"
import script from "./CopyLoadlist.vue?vue&type=script&lang=ts"
export * from "./CopyLoadlist.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281dbd62",
  null
  
)

export default component.exports