import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.loadlist)?_c('div',[(_vm.augmentedSets.length)?_c(VCarousel,{staticClass:"text-center",attrs:{"height":"250","hide-delimiters":"","showArrows":_vm.augmentedSets.length > 1,"show-arrows-on-hover":""}},_vm._l((_vm.augmentedSets),function(auSet,i){return _c(VCarouselItem,{key:i},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(i + 1)+": "+_vm._s(auSet.set.name))]),_c('scene-component',{key:auSet.set.uuid,attrs:{"set":auSet,"canvas-width":400,"canvas-height":200,"customViewSettings":{
            view: '3d',
          },"centered":""}})],1)}),1):_vm._e(),_c('div',[(_vm.loadlist.notes || _vm.loadplan.notes)?_c(VAlert,{attrs:{"type":"info","outlined":""}},[(_vm.loadlist.notes)?_c('p',[_vm._v(_vm._s(_vm.loadlist.notes))]):_vm._e(),(_vm.loadplan.notes)?_c('p',[_vm._v(" "+_vm._s(_vm.loadplan.notes)+" ")]):_vm._e()]):_vm._e()],1),_c('loadlist-summary',{attrs:{"loadlist":_vm.loadlist}})],1):_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":50,"color":"primary"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }