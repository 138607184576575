<template>
  <v-dialog v-model="show" scrollable width="800">
    <v-card>
      <v-toolbar dark color="primary">
        <v-card-title class="text-h5">Required equipment for cargo</v-card-title>
      </v-toolbar>
      <v-card-text>
        <div class="my-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <td><strong>Must be loaded in either:</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="id in containerIdsForItem" :key="id">
                  <td>
                    <v-btn icon @click="removeLoadInId(id)" :ripple="false">
                      <v-icon color="red">mdi-minus-circle</v-icon>
                    </v-btn>
                    {{ allContainers.find((i) => i.id === id).name }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider />
        </div>
        <v-tabs v-model="tab">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item.key">
            {{ item.text }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabs" :key="item.key">
            <v-simple-table headers-length="2" v-if="item.key === 'loadlist_equipment'">
              <template v-slot:default>
                <tbody>
                  <tr v-for="container in selectedContainers" :key="container.id">
                    <td>
                      <v-btn
                        icon
                        :disabled="containerIdsForItem.includes(container.id)"
                        :ripple="false"
                        @click="addLoadInId(container.id)">
                        <v-icon color="green">mdi-plus-circle</v-icon>
                      </v-btn>
                      {{ container.name }}
                    </td>
                  </tr>
                  <tr v-for="set in selectedSets" :key="set.id">
                    <td>
                      <strong><v-icon>mdi-chevron-down</v-icon> {{ set.name }}</strong>

                      <table>
                        <tbody>
                          <tr v-for="container in set.container_types" :key="container.id">
                            <td>
                              <v-btn
                                icon
                                :disabled="containerIdsForItem.includes(container.id)"
                                :ripple="false"
                                @click="addLoadInId(container.id)">
                                <v-icon color="green">mdi-plus-circle</v-icon>
                              </v-btn>
                              {{ container.name }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div v-if="item.key == 'library'" class="pt-2">
              <v-alert type="info">
                <strong
                  >Be sure to also add the container / trailer / pallet type when you select
                  equipment as well!</strong
                >
              </v-alert>
              <v-select
                v-bind:items="$list_types"
                v-model="baseTypeForContainerID"
                hide-details
                outlined
                dense></v-select>

              <container-library :holdType="baseTypeForContainerID">
                <template v-slot:items="{ paginatedItems: containers }">
                  <v-simple-table headers-length="2">
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="container in containers" :key="container.id">
                          <td>
                            <v-btn
                              icon
                              :disabled="containerIdsForItem.includes(container.id)"
                              :ripple="false"
                              @click="addLoadInId(container.id)">
                              <v-icon color="green">mdi-plus-circle</v-icon>
                            </v-btn>
                            {{ container.name }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </container-library>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click.stop="show = false" :ripple="false">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import { ListType, Hold, HoldData } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import ContainerLibrary from '../Custom/ContainerLibrary.vue';
import { SetTypeData } from '@/models/SetsModel';
export default Vue.extend({
  components: { ContainerLibrary },
  name: 'load-in-modal',
  props: {
    containerIdsForItem: {
      type: Array as PropType<number[]>,
    },
    baseTypeForContainerID: {
      type: String as PropType<ListType>,
      default: 'SEA',
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      tab: null as { key: string; text: string },
    };
  },

  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    allContainers(): HoldData[] {
      return this.miscStore.holds
        .map((h) => h.data)
        .concat(this.selectedSets.flatMap((set) => set.container_types));
    },
    selectedContainers(): HoldData[] {
      return [
        ...this.loadlistStore.loadplan.selected_holds,
        ...this.loadlistStore.loadplan.pallet_types,
      ];
    },
    selectedSets(): SetTypeData[] {
      return this.loadlistStore.loadplan.set_types;
    },
    tabs(): { key: string; text: string }[] {
      return [
        { key: 'loadlist_equipment', text: 'Loadlist Equipment' },
        { key: 'library', text: 'Library' },
      ];
    },
  },

  methods: {
    addLoadInId(id: number): void {
      this.$emit('addLoadInId', id);
    },
    removeLoadInId(id: number): void {
      this.$emit('removeLoadInId', id);
    },
  },
});
</script>
<style scoped></style>
