import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"scrollable":"","width":"800"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Multi factor authentication")])],1),_c(VCardText,[_c('div',{staticClass:"my-2"},[(_vm.devices.length)?_c(VAlert,{attrs:{"type":"success","outlined":""}},[_vm._v("MFA is enabled ")]):_c(VAlert,{attrs:{"type":"warning","outlined":""}},[_vm._v("MFA id disabled. Setup to increase the security of your account. ")]),(_vm.devices.length)?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Created")]),_c('th',{staticClass:"text-left"},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.devices),function(device){return _c('tr',{key:device.id},[_c('td',[_vm._v(_vm._s(device.name))]),_c('td',[_vm._v(_vm._s(new Date(device.created_at).toLocaleDateString()))]),_c('td',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.selectedDevice = device;
                      _vm.showRemoveDeviceModal = true;}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)])}),0)]},proxy:true}],null,false,1082015909)}):_c(VAlert,{attrs:{"type":"info","outlined":""}},[_vm._v("You do not have and MFA devices. ")])],1)]),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"ripple":false},on:{"click":function($event){$event.stopPropagation();_vm.showAddDeviceModal = true}}},[_vm._v("Add device")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","ripple":false},on:{"click":function($event){$event.stopPropagation();_vm.show = false}}},[_vm._v("Ok")])],1)],1),_c(VDialog,{attrs:{"scrollable":"","width":"800"},model:{value:(_vm.showAddDeviceModal),callback:function ($$v) {_vm.showAddDeviceModal=$$v},expression:"showAddDeviceModal"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Add device")])],1),_c(VCardText,[(!_vm.deviceCode)?_c('div',{staticClass:"my-2"},[_c(VTextField,{attrs:{"rules":_vm.nameRules,"label":"Name of device"},model:{value:(_vm.newDeviceName),callback:function ($$v) {_vm.newDeviceName=$$v},expression:"newDeviceName"}}),_c(VBtn,{attrs:{"color":"success","disabled":!_vm.newDeviceName,"ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.createDevice.apply(null, arguments)}}},[_vm._v("Add")])],1):_c(VAlert,{staticClass:"my-2",attrs:{"type":"success","outlined":""}},[_c('p',[_vm._v("Scan the code below in your authentiactor application.")]),_c('canvas',{ref:"qrCanvas"})])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","ripple":false},on:{"click":function($event){$event.stopPropagation();_vm.deviceCode = null;
            _vm.showAddDeviceModal = false;}}},[_vm._v("Ok")])],1)],1)],1),_c(VDialog,{attrs:{"scrollable":"","width":"800"},model:{value:(_vm.showRemoveDeviceModal),callback:function ($$v) {_vm.showRemoveDeviceModal=$$v},expression:"showRemoveDeviceModal"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Remove device")])],1),_c(VCardText,[_c(VAlert,{staticClass:"my-2",attrs:{"type":"warning","outlined":""}},[_c('p',[_vm._v("Are you sure you want to remove this device?")])])],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"ripple":false},on:{"click":function($event){$event.stopPropagation();_vm.showRemoveDeviceModal = false}}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.deleteDevice.apply(null, arguments)}}},[_vm._v("Yes, delete")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }