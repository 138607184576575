<template>
  <v-card flat>
    <v-card-text>
      <v-snackbar :timeout="5000" v-model="showSnackbar" bottom>
        Column settings updated
      </v-snackbar>

      <v-tooltip bottom v-for="column in columns" :key="column.key">
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            @click="toggleColumn(column.key)"
            class="ma-2"
            label
            :color="selectedColumns.includes(column.key) ? 'primary' : 'gray'">
            {{ column.text }}
          </v-chip>
        </template>
        <span>{{ column.desc }}</span>
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import ItemProperties, { ItemProperty } from '@/misc/itemProperties';
import { CompanySettings, UserPreferences } from '@/models/UserCompanyModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import tableUtils from '@/misc/tableUtils';
export default Vue.extend({
  props: {
    isCompanySettings: Boolean,
  },
  computed: {
    ...mapStores(useMiscStore),
    columns(): ItemProperty[] {
      return ItemProperties.props().filter((i: ItemProperty) => !i.additional && !i.writeOnly);
    },
    preferences(): CompanySettings {
      if (!this.isCompanySettings && !this.miscStore.company_settings.enforced)
        return this.miscStore.user_preferences;
      return this.miscStore.company_settings;
    },
  },
  watch: {
    preferences: {
      handler(val: UserPreferences): void {
        if (val?.visible_loadplan_columns)
          this.selectedColumns = JSON.parse(JSON.stringify(val.visible_loadplan_columns));
        else this.selectedColumns = JSON.parse(JSON.stringify(tableUtils.defaultLoadlanColumns));
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      showSnackbar: false,
      selectedColumns: [] as string[],
    };
  },
  methods: {
    update(): void {
      if (this.miscStore.is_authenticated) {
        if (this.isCompanySettings) {
          this.miscStore
            .updateCompanySettings({
              visible_loadplan_columns: this.selectedColumns,
            })
            .then((_) => (this.showSnackbar = true));
        } else {
          this.miscStore
            .updateMe({
              preferences: {
                visible_loadplan_columns: this.selectedColumns,
              },
            })
            .then((_) => (this.showSnackbar = true));
        }
      } else this.showSnackbar = true;
      this.$emit('columnsUpdated', this.selectedColumns);
    },
    toggleColumn(key: string): void {
      const index = this.selectedColumns.indexOf(key);
      if (index >= 0) {
        this.selectedColumns.splice(index, 1);
      } else {
        this.selectedColumns.push(key);
      }
      this.update();
    },
  },
});
</script>

<style></style>
