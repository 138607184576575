export const getSerializerError = (error: any): string => {
  if (typeof error === 'string' || error instanceof String) return error as string;
  for (const key in error) {
    if (error[key].constructor == Object) {
      if (Object.keys(error[key]).length === 0) continue;
      return `${key} - ${getSerializerError(error[key])} `;
    }
    if (Array.isArray(error[key]) && error[key].length) {
      for (let i = 0; i < error[key].length; i++) {
        if (error[key][i].constructor == Object) {
          if (Object.keys(error[key][i]).length)
            return `${key} - Row ${i + 1} - ${getSerializerError(error[key][i])} `;
          else continue;
        }
        return `${key} - ${getSerializerError(error[key])} `;
      }
    }
    return error[key];
  }
};
