<template>
  <div>
    <v-dialog v-model="is_calculating" persistent width="800">
      <v-card>
        <v-card-title>Calculating...</v-card-title>
        <v-card-text>
          <v-progress-linear
            class="my-3"
            :value="progress"
            color="primary"
            height="25"></v-progress-linear>
          <v-alert color="info" icon="fa-lightbulb" outlined
            >You can change the timeout in "Load settings". A longer timeout generates better
            solutions.</v-alert
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      {{ calculation_info_message }}
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Loadplan } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import Vue from 'vue';

export default Vue.extend({
  name: 'calculation-modal',
  watch: {
    calculation_info_message(a: string): void {
      if (a) {
        this.showSnackbar = true;
      }
    },
    is_calculating(a: boolean): void {
      if (a) {
        this.elapsedSeconds = 0;
        this.elapsedMillis = 0;
        this.calculationTimer = window.setInterval(() => {
          this.elapsedMillis += 100;
          if (this.elapsedMillis % 1000 === 0) {
            this.elapsedSeconds += 1;
            if (!this.is_calculating || this.elapsedSeconds >= this.timeout)
              clearInterval(this.calculationTimer);
          }
        }, 100);
      }
    },
  },
  computed: {
    ...mapStores(useLoadlistStore),
    progress(): number {
      return (this.elapsedMillis / (this.timeout * 1000)) * 100;
    },
    timeout(): number {
      if (this.loadplan) {
        const primaryTimeout = this.loadplan?.settings?.timeout || 0;
        return primaryTimeout + (this.loadplan?.pallet_types?.length > 0 ? primaryTimeout : 0);
      }
      return 0;
    },
    is_calculating(): boolean {
      return this.loadlistStore.is_calculating;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    calculation_info_message(): string {
      return this.loadlistStore.calculation_info_message;
    },
  },
  data() {
    return {
      elapsedSeconds: 0,
      elapsedMillis: 0,
      calculationTimer: null as number,
      showSnackbar: false,
    };
  },
});
</script>
