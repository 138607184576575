<template>
  <div>
    <v-select
      label="Container"
      :items="previousSelectedHoldDatas"
      :value="holdData"
      item-text="name"
      return-object
      @change="onContainerTypeSelected">
      <template v-slot:prepend-item>
        <v-list-item @mousedown.prevent @click="showChangeContainerTypeDialog = true">
          <v-list-item-content>
            <v-list-item-title> Browse all container types </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:no-data>
        <div></div>
      </template>
    </v-select>

    <select-container-type-component
      v-if="showChangeContainerTypeDialog"
      :visible="showChangeContainerTypeDialog"
      :set="set"
      @containerSelected="onContainerTypeSelected"
      @close="showChangeContainerTypeDialog = false">
    </select-container-type-component>
  </div>
</template>

<script lang="ts">
import { HoldData } from '@/models/LoadlistModel';
import { SetType } from '@/models/SetsModel';
import selectContainerTypeComponent from '@/components/SetBuilder/SelectContainerType.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'set_select_container_dropdown',
  components: {
    selectContainerTypeComponent,
  },
  props: {
    set: Object as PropType<SetType>,
    selectedHoldData: Object as PropType<HoldData>,
  },
  data() {
    return {
      showChangeContainerTypeDialog: false,
      previousSelectedHoldDatas: [] as HoldData[],
      holdData: null as HoldData,
    };
  },
  methods: {
    onContainerTypeSelected(holdData: HoldData) {
      this.showChangeContainerTypeDialog = false;
      this.holdData = holdData;
      this.updatePreviousHoldDatas(holdData);
      this.$emit('containerTypeSelected', holdData);
    },
    updatePreviousHoldDatas(holdData: HoldData): void {
      this.previousSelectedHoldDatas = [holdData, ...this.set.data.container_types].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
  },
  watch: {
    selectedHoldData: {
      handler: function (holdData: HoldData | null): void {
        this.holdData = holdData;
        if (holdData) {
          this.updatePreviousHoldDatas(holdData);
        }
      },
      immediate: true,
    },
  },
});
</script>
