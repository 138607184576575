import ContainerUtils from '@/misc/containerUtils';
import {
  HoldData,
  HoldInputItem,
  HoldItem,
  HoldItemType,
  UnloadedItem,
} from '@/models/LoadlistModel';
import objectUtils from './objectUtils';

function infinityFlatHoldItems(a: HoldData): HoldItem[] {
  return a.items
    .map((i) => (i?.from_container?.items ? infinityFlatHoldItems(i.from_container) : [i]))
    .flat(Infinity) as HoldItem[];
}
export default {
  compareItems(a: HoldItemType, b: HoldItemType): boolean {
    return (
      !!a &&
      !!b &&
      (a.sku ? a.sku === b.sku : this.compareDimensions(a, b) && Math.abs(a.wt - b.wt) < 0.01) &&
      a.shipment_id == b.shipment_id &&
      a.class_id == b.class_id
      // a.priority == b.priority Should this be fixed?
    );
  },
  compareDimensions(a: HoldItemType, b: HoldItemType): boolean {
    return (
      a.label?.trim() === b.label?.trim() &&
      Math.abs(a.l - b.l) < 0.001 &&
      Math.abs(a.w - b.w) < 0.001 &&
      Math.abs(a.h - b.h) < 0.001
    );
  },
  getUnloadedItemsFromList(
    loadlist_original: HoldInputItem[],
    holds: HoldData[],
    unloaded_with_reasons: UnloadedItem[]
  ): UnloadedItem[] {
    const unloaded_items: HoldInputItem[] = this.bundledInputItems(
      JSON.parse(JSON.stringify(loadlist_original))
    );

    for (const hold of holds) {
      for (const item of infinityFlatHoldItems(hold).filter((i) => i.qty > 0)) {
        for (let i = unloaded_items.length - 1; i >= 0; i--) {
          if (this.compareItems(unloaded_items[i], item)) {
            unloaded_items[i].qty -= item.qty || 1;

            if (unloaded_items[i].qty <= 0) {
              unloaded_items.splice(i, 1);
            }
            break;
          }
        }
      }
    }

    return this.bundledInputItems(unloaded_items).map((i) => {
      return {
        uid: JSON.stringify(Object.values(i)),
        reason: unloaded_with_reasons.find((j) => this.compareItems(i, j))?.reason,
        ...i,
      };
    });
  },
  removeItemsFromLoadlist(
    loadlist_original: HoldInputItem[],
    items: UnloadedItem[]
  ): HoldInputItem[] {
    const filtered_items: HoldInputItem[] = JSON.parse(JSON.stringify(loadlist_original));

    for (let i = filtered_items.length - 1; i >= 0; i--) {
      if (!filtered_items[i].qty) continue;
      for (let j = items.length - 1; j >= 0; j--) {
        if (this.compareItems(filtered_items[i], items[j])) {
          if (filtered_items[i].qty >= items[j].qty) {
            filtered_items[i].qty -= items[j].qty;
            items[j].qty = 0;
          } else {
            items[j].qty -= filtered_items[i].qty;
            filtered_items[i].qty = 0;
          }
          if (filtered_items[i].qty <= 0) {
            filtered_items.splice(i, 1);
            break;
          }
        }
      }
    }

    return filtered_items;
  },
  itemsFlat(cargoes: HoldItem[]): HoldItem[] {
    return cargoes.reduce((acc, cur) => {
      if (cur.from_container) return acc.concat(this.itemsFlat(cur.from_container.items || []));
      acc.push(cur);
      return acc;
    }, [] as HoldItem[]);
  },
  bundledInputItems(cargoes: HoldInputItem[]): HoldInputItem[] {
    const bundled = [] as HoldInputItem[];

    cargoes
      .filter((i) => i.qty > 0)
      .forEach((item) => {
        const index = bundled.findIndex((existingItem: HoldItem) => {
          return this.compareItems(existingItem, item);
        });

        if (index > -1) {
          bundled[index].qty += item.qty || 1;
        } else {
          bundled.push(item);
        }
      });
    return bundled;
  },
  bundledItems(cargoes: HoldItem[], leaf_nodes_only = false): HoldItem[] {
    const bundled = [] as HoldItem[];
    if (leaf_nodes_only) {
      cargoes = this.itemsFlat(cargoes);
    }
    cargoes
      .filter((i) => i.qty !== 0)
      .forEach((item, itemIndex) => {
        const index = bundled.findIndex((existingItem: HoldItem) => {
          return item.from_container
            ? ContainerUtils.compareContainers(existingItem.from_container, item.from_container)
            : this.compareItems(existingItem, item);
        });

        if (index > -1) {
          bundled[index].qty += item.qty || 1;
          bundled[index].itemIndices.push(itemIndex);
        } else {
          bundled.push({ ...item, qty: item.qty || 1, itemIndices: [itemIndex] });
        }
      });
    return bundled;
  },
  equalInputItems(fromAPI: any, fromSheet: any): boolean {
    return objectUtils.equalObjects(fromAPI, fromSheet, ['index']);
  },
};
