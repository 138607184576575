import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VSelect,{attrs:{"label":"Container","items":_vm.previousSelectedHoldDatas,"value":_vm.holdData,"item-text":"name","return-object":""},on:{"change":_vm.onContainerTypeSelected},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,{on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){_vm.showChangeContainerTypeDialog = true}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Browse all container types ")])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true},{key:"no-data",fn:function(){return [_c('div')]},proxy:true}])}),(_vm.showChangeContainerTypeDialog)?_c('select-container-type-component',{attrs:{"visible":_vm.showChangeContainerTypeDialog,"set":_vm.set},on:{"containerSelected":_vm.onContainerTypeSelected,"close":function($event){_vm.showChangeContainerTypeDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }