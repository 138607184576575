import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCardText,[_c(VCard,[_c(VCardText,_vm._l((_vm.holds),function(hold){return _c(VRow,{key:hold.id,attrs:{"dense":"","align":"center"}},[_c(VCol,{attrs:{"cols":"10","md":"11"}},[_c('span',[_c('strong',[_vm._v(" "+_vm._s(hold.data.name)+" ")]),_vm._v(" - ("+_vm._s(_vm._f("toLength")(hold.data.L))+" x "+_vm._s(_vm._f("toLength")(hold.data.W))+" "),(hold.data.H > 0)?_c('span',[_vm._v("x "+_vm._s(_vm._f("toLength")(hold.data.H)))]):_vm._e(),_vm._v(" ) ")])]),_c(VCol,{attrs:{"cols":"2","md":"1"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"large":"","icon":"","ripple":false},on:{"click":function($event){return _vm.selectHold(hold.data)}}},on),[_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Select")])])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }