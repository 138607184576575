<template>
  <div>
    <v-data-table
      v-bind:items="sortedSets"
      :headers-length="6"
      :items-per-page="resultsPerPage"
      hide-default-footer
      hide-default-header
      :page="resultPage"
      item-key="uuid"
      id="setWorkspaceTable"
      style="white-space: nowrap">
      <template v-slot:header>
        <thead>
          <tr class="text-left">
            <th class="text-subtitle-1 font-weight-bold">{{ setName }}</th>
            <th class="text-subtitle-1 font-weight-bold">Pcs</th>
            <th class="text-subtitle-1 font-weight-bold">Weight</th>
            <th class="text-subtitle-1 font-weight-bold">Volume</th>
            <th class="text-subtitle-1 text-center font-weight-bold">View</th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr class="set-row">
          <td class="no-print text-subtitle-1">
            <v-checkbox
              @change="checkRow(item.__indices.start)"
              :input-value="checkedSets.includes(item.__indices.start)"
              :ripple="false">
              <template slot:label>
                <span v-if="item.__indices.end > item.__indices.start">
                  <b>{{ item.__indices.start + 1 }}</b> to
                  {{ item.__indices.end + 1 }}
                </span>
                <span v-else> {{ item.__indices.start + 1 }}.</span>
                <b>&nbsp;{{ item.set.name }}</b>
              </template>
            </v-checkbox>
          </td>
          <td class="text-subtitle-1">
            {{ item.items_count }}
          </td>
          <td class="text-subtitle-1">
            <v-icon v-if="item.weightUtilization > 0.9" color="red">mdi-alert</v-icon>
            <div class="d-flex flex-column">
              <span
                >Net: {{ item.weight | toWeight }} ({{
                  item.weightUtilization | percentage
                }}%)</span
              >
              <span v-if="item.tare > 0">Gross: {{ item.grossWeight | toWeight }}</span>
              <span v-if="settings && settings.shipping_factor">
                Chargable:
                {{ item.chargableWeight(settings.shipping_factor) | toWeight }}
              </span>
            </div>
          </td>
          <td class="text-subtitle-1">
            {{ item.volume | toVolume }}
            <span v-if="item.volumeUtilization">
              ({{ item.volumeUtilization | percentage }} %)
            </span>
            <!-- </span> -->
          </td>
          <td style="width: 800px; cursor: pointer" @click="sceneClick(item.set.uuid)">
            <scene-component
              :set="item"
              :key="item.set.uuid + renderKey"
              :canvas-width="800"
              :canvas-height="300"
              :customViewSettings="customView(item)" />
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-pagination
      class="no-print"
      v-if="!searchInput"
      v-bind:length="noResultPageButtons"
      v-model="resultPage"
      @input="changeResultPage"></v-pagination>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import { VIEWS } from '@/graphics/sceneManager';
import { ViewSettings } from '@/models/GraphicsModel';
import { HoldDataWithIndices, Loadlist, Loadplan } from '@/models/LoadlistModel';
import { GroupedSet } from '@/models/SetsModel';
import { AugmentedSet } from '@/models/augmented/set';
import { CalculationSettings } from '@/models/CalculationModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
export default Vue.extend({
  name: 'set-workspace',
  props: {
    sets: Array as PropType<GroupedSet[]>,
    searchInput: String,
    checkedSets: Array,
    filteredHolds: Array as PropType<HoldDataWithIndices[]>,
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: (): CalculationSettings => {
        return null;
      },
    },
  },
  components: {
    sceneComponent,
  },
  data() {
    return {
      resultsPerPage: 5,
      resultPage: 1,
      renderKey: 0,
    };
  },
  watch: {},
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    setName(): string {
      return this.$setNames(this.loadlist.list_type);
    },
    augmentedSets(): AugmentedSet[] {
      this.renderKey++;
      return this.sets
        .map((set) => new AugmentedSet(set, this.loadplan.holds))
        .filter((s) => {
          return s.rendering.length > 0;
        });
    },
    sortedSets(): AugmentedSet[] {
      const sets = this.augmentedSets;

      let currentPage = this.resultPage;
      if (this.searchInput) {
        currentPage = 1;
      }

      return sets
        .filter((s) =>
          s.set.containers.some((c) => this.filteredHolds.find((h) => h.uuid == c.uuid))
        )
        .slice(
          (currentPage - 1) * this.resultsPerPage,
          (currentPage - 1) * this.resultsPerPage + this.resultsPerPage
        );
    },
    noResultPageButtons(): number {
      return Math.ceil(Math.max(this.sets.length / this.resultsPerPage, 1));
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    loadplan_version(): number {
      return this.loadlistStore.loadplan_version;
    },
    lite_version(): boolean {
      return this.miscStore.lite_version;
    },
  },
  created(): void {
    if (this.$route.query.print) {
      this.resultsPerPage = 1000;
    } else if (this.$route.query.setpage !== undefined) {
      this.resultPage = parseInt(this.$route.query.setpage as string, 10);
    }
  },
  methods: {
    changeResultPage(page: number): void {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { ...this.$route.query, page: String(page) },
      });
    },

    sceneClick(uuid: string): void {
      if (this.lite_version) {
        return;
      }
      this.$router.push({
        name: 'detail_set',
        params: {
          version: String(this.loadplan_version),
          set: uuid,
        },
      });
    },
    customView(set: AugmentedSet): ViewSettings {
      const orthoView = { view: VIEWS.THREEDORTHO };
      if (set.rendering.length > 1) {
        return { ...orthoView };
      }
      return orthoView;
    },

    checkRow(row: number): void {
      this.$emit('toggleSetRow', row);
    },
  },
  filters: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#setWorkspaceTable tr:hover {
  background: #fff !important;
}
</style>
