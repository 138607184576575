<template>
  <v-container text-center>
    <v-row>
      <v-col cols="10" offset="1" sm="6" offset-sm="3">
        <v-card>
          <v-card-title>
            <img
              style="width: 100%; max-width: 300px; margin-left: auto; margin-right: auto"
              src="../../assets/svg/logo_black.svg" />
          </v-card-title>
          <v-card-title class="text-h5"
            ><span v-if="$route.query.code">Invite sign up</span
            ><span v-else>10 day free trial</span></v-card-title
          >
          <v-card-text>
            <v-form ref="form" @submit.prevent="register" v-model="valid" lazy-validation>
              <v-text-field
                label="Username"
                autocomplete="username"
                v-model="new_user.username"
                :rules="usernameRules"
                name="username"
                :error-messages="errors['username']"></v-text-field>
              <v-text-field
                label="Email"
                autocomplete="email"
                name="email"
                v-model="new_user.email"
                type="email"
                :disabled="'invitation_code' in new_user"
                :rules="emailRules"
                :error-messages="errors['email']"></v-text-field>
              <v-text-field
                label="Company name"
                v-model="new_user.company"
                :disabled="'invitation_code' in new_user"
                :rules="companyRules"
                :error-messages="errors['company']"></v-text-field>

              <v-checkbox v-model="acceptTerms" :rules="acceptTermRules" required>
                <template v-slot:label>
                  <div>
                    I agree to the
                    <a @click="showPrivacyPolicy = true">Privacy Policy</a> and
                    <a @click="showTocModal = true">Terms and Conditions</a>
                  </div>
                </template>
              </v-checkbox>

              <v-btn block type="submit" color="primary" :loading="isLoading"
                >Sign up and get started</v-btn
              >
            </v-form>
          </v-card-text>
          <v-card-text>
            <a @click="$router.push('/login/')">Login page</a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showSuccessRegisterModal" persistent width="600">
      <v-card>
        <v-card-title class="text-h5">You are now registered!</v-card-title>
        <v-card-text>
          We will soon activate your account, usually within minutes. You will then get an
          activation email. Should you not hear from us, please do not hesitate to contact us
          <a href="mailto:support@cargo-planner.com?subject=Account activation">here</a>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="$router.push('/login/')" color="primary" :ripple="false"
            >Go to login page</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPrivacyPolicy" scrollable width="800">
      <v-card>
        <v-card-title class="text-h5">Privacy Policy</v-card-title>
        <v-card-text>
          <p class="pre">{{ privacyPolicy }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showPrivacyPolicy = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <terms-and-conditions-modal
      :visible="showTocModal"
      @close="showTocModal = false"></terms-and-conditions-modal>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import API from '@/API';
import TermsAndConditionsModal from '@/components/Modals/TermsAndConditions.vue';

interface Errors {
  username: string[];
  email: string[];
  company: string[];
}

interface NewUser {
  username: string;
  email: string;
  company: string;
  invitation_code?: string;
}

export default Vue.extend({
  name: 'register',
  components: {
    TermsAndConditionsModal,
  },
  data() {
    return {
      isLoading: false,
      new_user: {
        username: '',
        email: '',
        company: '',
      } as NewUser,
      usernameRules: [
        (v: string) => !!v || 'Username is required',
        (v: string) => v.indexOf(' ') < 0 || 'No whitespace in username',
        (v: string) => (v && v.length <= 50) || 'Username must be less than 50 characters',
      ],

      emailRules: [
        (v: string) => !!v || 'Company E-mail is required',
        (v: string) => /.+@.+/.test(v) || 'Company E-mail must be valid',
        (v: string) => !v.includes('qq') || 'Company email please',
      ],
      companyRules: [
        (v: string) => !!v || 'Company name is required',
        (v: string) => (v && v.length <= 100) || 'Name must be less than 100 characters',
      ],
      acceptTermRules: [(v: string) => !!v || 'You must agree to continue'],
      errors: {
        username: [],
        email: [],
        company: [],
      } as Errors,
      acceptTerms: false,
      showPrivacyPolicy: false,
      showTocModal: false,
      showSuccessRegisterModal: false,
      privacyPolicy: '',
      valid: true,
    };
  },
  computed: {},
  mounted() {
    API.getPrivacyPolicy().then((response) => {
      this.privacyPolicy = response.data.data;
    });

    if (this.$route.query.code) this.new_user.invitation_code = this.$route.query.code as string;
    this.new_user.company = this.$route.query.c ? (this.$route.query.c as string) : '';
    this.new_user.email = this.$route.query.e ? (this.$route.query.e as string) : '';
  },
  methods: {
    register(): void {
      for (const errorName in this.errors) {
        this.errors[errorName as keyof Errors] = [];
      }

      if ((this.$refs.form as any).validate()) {
        this.isLoading = true;

        API.register(this.new_user)
          .then((response) => {
            this.isLoading = false;
            if ('invitation_code' in this.new_user) {
              window.location.replace(window.location.origin);
            } else {
              this.showSuccessRegisterModal = true;
            }
          })
          .catch((error) => {
            this.isLoading = false;

            if (error.response && error.response.data) {
              for (const errorName in error.response.data) {
                if (errorName in this.errors) {
                  this.errors[errorName as keyof Errors] = error.response.data[errorName];
                }
              }
            }
          });
      }
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pre {
  white-space: pre-wrap;
}
</style>
