<template>
  <div>
    <v-row class="mb-2" justify="end" align="end">
      <v-col class="py-0">
        <v-text-field
          ref="search"
          prepend-icon="mdi-magnify"
          clearable
          placeholder="Search:"
          v-model="searchString"
          hide-details></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-combobox
          v-model="selectedTags"
          :items="visible_tags"
          label="Filter"
          multiple
          hide-details
          clearable
          chips></v-combobox>
      </v-col>
    </v-row>

    <v-progress-circular
      v-if="isLoading"
      value="true"
      indeterminate
      color="primary"></v-progress-circular>

    <slot name="items" v-bind:paginatedItems="paginatedHolds" v-bind:allContainers="allHolds">
    </slot>

    <v-pagination
      v-bind:length="numberOfPages"
      total-visible="8"
      v-model="currentPage"></v-pagination>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { HoldData, Hold, ListType } from '@/models/LoadlistModel';
import Vue, { PropType } from 'vue';

const HOLDS_PER_PAGE = 10;

export default Vue.extend({
  name: 'container-library',
  data() {
    return {
      searchString: '',
      currentPage: 1,
      tags: [
        {
          value: 'lowerdeck',
          text: 'Lower Deck',
          only_for: ['AIR'],
        },
        {
          value: 'maindeck',
          text: 'Main Deck',
          only_for: ['AIR'],
        },
        {
          value: 'special',
          text: 'Special',
          only_for: ['SEA', 'ROAD'],
        },
        {
          value: 'ours',
          text: 'Ours',
        },
        {
          value: 'default',
          text: 'Default',
        },
      ],
      selectedTags: [],
    };
  },
  props: {
    holdType: String as PropType<ListType>,
  },
  computed: {
    ...mapStores(useMiscStore),
    allHolds(): Hold[] {
      return this.miscStore.holds;
    },
    holds(): Hold[] {
      return this.allHolds.filter(
        (i) =>
          i.base_type === this.holdType &&
          (this.searchString
            ? i.name.toLowerCase().includes(this.searchString.toLowerCase())
            : true) &&
          (this.selectedTags.length ? this.holdPassesFilter(i) : true)
      );
    },
    paginatedHolds(): Hold[] {
      return this.holds.slice(
        (this.currentPage - 1) * HOLDS_PER_PAGE,
        (this.currentPage - 1) * HOLDS_PER_PAGE + HOLDS_PER_PAGE
      );
    },
    numberOfPages(): number {
      return Math.ceil(Math.max(this.holds.length / HOLDS_PER_PAGE, 1));
    },
    isLoading(): boolean {
      return this.miscStore.isLoading;
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    visible_tags(): { value: string; text: string; only_for?: string[] }[] {
      return this.tags.filter((t) => (t.only_for ? t.only_for.includes(this.holdType) : true));
    },
  },
  watch: {
    holdType() {
      this.currentPage = 1;
    },
    searchString() {
      this.currentPage = 1;
    },
  },
  created() {},
  methods: {
    holdPassesFilter(h: Hold): boolean {
      for (let i = 0; i < this.selectedTags.length; i++) {
        switch (this.selectedTags[i].value) {
          case 'default':
            if (h.default) return true;
            break;
          case 'ours':
            if (!h.default) return true;
            break;
          case 'lowerdeck':
            if (
              !h.data.contours ||
              h.data?.contours?.front_bottom_contour_l ||
              h.data?.contours?.rear_bottom_contour_l ||
              h.data?.contours?.side1_bottom_contour_l ||
              h.data?.contours?.side2_bottom_contour_l
            )
              return true;
            break;
          case 'maindeck':
            if (
              !h.data.contours ||
              h.data?.contours?.front_top_contour_l ||
              h.data?.contours?.rear_top_contour_l ||
              h.data?.contours?.side1_top_contour_l ||
              h.data?.contours?.side2_top_contour_l
            )
              return true;
            break;
          case 'special':
            if (
              h.data.no_end_walls ||
              h.data.no_side_walls ||
              h.data.no_roof ||
              h.data?.tables?.front_table_l ||
              h.data?.tables?.rear_table_l ||
              h.data?.legal_limits
            )
              return true;
            break;
        }
      }
      return false;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
