import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.always_open)?_c(VBtnToggle,{staticClass:"mr-4",staticStyle:{"float":"right"},attrs:{"borderless":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VBtn,{attrs:{"text":"","elevation":"0","small":""}},[(_vm.open !== 0)?_c('span',[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-down")]),_vm._v(" Show more ")],1):_c('span',[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-up")]),_vm._v(" Show less ")],1)])],1):_vm._e(),(_vm.always_open)?_c(VCardText,{staticClass:"pt-0"},[_vm._t("default")],2):_c(VExpansionPanels,{staticClass:"mb-4",staticStyle:{"margin-top":"-20px"},attrs:{"flat":"","accordion":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VExpansionPanel,[_c(VExpansionPanelContent,[_c(VDivider,{staticClass:"mb-8 mt-2"}),_vm._t("default")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }