<template>
  <v-container>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="6" class="pl-0 py-0">
            <select-container-dropdown-component
              :selectedHoldData="holdData"
              :set="set"
              @containerTypeSelected="onContainerTypeSelected">
            </select-container-dropdown-component>
          </v-col>
          <v-col cols="6" class="pr-0 py-0">
            <v-select
              :items="set.data.slots"
              v-model="selectedSlot"
              item-text="name"
              return-object
              label="Add to group"
              @change="containerDetailsChange" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 py-0">
            <v-select
              :items="['Row', 'Column', 'Above', 'Below', 'Side by side']"
              v-model="placementMode"
              label="Placement"
              @change="containerDetailsChange" />
          </v-col>
          <v-col class="py-0">
            <length-input-field
              v-model="spacing"
              :min="-100000"
              @input="containerDetailsChange"
              :label="`Spacing to previous ${typeName}`"></length-input-field>
          </v-col>
          <v-col class="pr-0 py-0">
            <rotation-input-field
              v-model="rotation[2]"
              @input="containerDetailsChange"
              label="Rotation"></rotation-input-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 py-0">
            <length-input-field
              v-model="position.x"
              :min="-100000"
              @input="positionChange"
              label="X (Sideways)"></length-input-field>
          </v-col>
          <v-col class="py-0">
            <length-input-field
              v-model="position.y"
              :min="-100000"
              :disabled="placementMode === 'Side by side'"
              @input="positionChange"
              label="Y (Depth)"></length-input-field>
          </v-col>
          <v-col class="pr-0 py-0">
            <length-input-field
              v-model="position.z"
              :min="-100000"
              @input="positionChange"
              label="Z (Up/Down)"></length-input-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click.stop="addContainers()" color="success" :disabled="!holdData">
          <v-icon>mdi-plus-circle</v-icon>
          <span class="ml-1">Add container</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Hold, HoldData, HoldDataPreview, HoldType, Point, Rotation } from '@/models/LoadlistModel';
import {
  AddContainersInfo,
  PlacementMode,
  SelectedContainerInfo,
  SetSlot,
  SetType,
} from '@/models/SetsModel';
import selectContainerDropdownComponent from '@/components/SetBuilder/SelectContainerDropdown.vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
export default Vue.extend({
  name: 'set_add_container',
  props: {
    set: Object as PropType<SetType>,
    prevContainerInfo: Object as PropType<SelectedContainerInfo>,
  },
  components: {
    selectContainerDropdownComponent,
  },
  data() {
    return {
      placementMode: 'Row' as PlacementMode,
      spacing: 0.1,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      rotation: [0, 0, 0, 'XYZ'] as Rotation,
      configIndex: 0,
      selectedSlot: undefined as SetSlot,
      holdData: null as HoldData,
      holdDataPreviews: [] as HoldDataPreview[],
      previouslySelectedHoldDatas: [] as HoldData[],
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    typeName(): string {
      return this.$typeNames(this.set.base_type);
    },
    allHolds(): Hold[] {
      return this.miscStore.holds;
    },
    holds(): Hold[] {
      return this.allHolds.filter((i) => i.base_type === this.set.base_type);
    },
  },
  mounted() {
    if (this.set.data.container_types.length === 0) {
      this.holdData = this.holds[0].data;
      this.rotation = [0, 0, 0, 'XYZ'] as Rotation;
      this.position = { x: 0, y: 0, z: 0 };
      this.containerDetailsChange();
    }
  },
  methods: {
    positionChange() {
      if (this.holdDataPreviews.length === 1) {
        this.holdDataPreviews.forEach((hd) => (hd.position = this.position));
      } else {
        // if multiple previews (side by side), keep Y position
        this.holdDataPreviews.forEach((hd) => {
          hd.position = { ...this.position, y: hd.position.y };
        });
      }
      this.$emit('previewUpdate', this.holdDataPreviews);
    },
    containerDetailsChange() {
      if (this.holdData) {
        const holdDatas: HoldDataPreview[] = [];
        const previewHoldData = this.createPreviewHoldData(
          this.rotation,
          this.calculatePosition(
            this.prevContainerInfo?.holdType,
            this.holdData,
            this.rotation,
            this.placementMode,
            this.spacing
          ),
          this.holdData
        );
        holdDatas.push(previewHoldData);
        this.position = previewHoldData.position;
        if (this.placementMode === 'Side by side') {
          // add another preview container next to the original
          const previewHoldDataMirrored = JSON.parse(JSON.stringify(previewHoldData));
          previewHoldDataMirrored.position.y = -Math.abs(previewHoldData.position.y);
          previewHoldDataMirrored.rotation[2] = -Math.abs(previewHoldData.rotation[2]);
          holdDatas.push(previewHoldDataMirrored);
        }
        this.holdDataPreviews = holdDatas;
        this.$emit('previewUpdate', this.holdDataPreviews);
      }
    },
    addContainers(): void {
      this.$emit('addContainers', {
        slotIndex: this.set.data.slots.indexOf(this.selectedSlot),
        holdDatas: this.holdDataPreviews,
      } as AddContainersInfo);
      this.containerDetailsChange();
    },
    onContainerTypeSelected(holdData: HoldData): void {
      this.holdData = holdData;
      this.containerDetailsChange();
    },
    calculatePosition(
      prev: HoldType,
      current: HoldData,
      rotation: Rotation,
      placementMode: PlacementMode,
      spacing: number
    ) {
      const l = this.calculateLengthOfContainer(current, rotation[2]);
      const w = this.calculateWidthOfContainer(current, rotation[2]);

      if (!prev) {
        if (placementMode === 'Side by side') {
          return {
            x: current.L / 2 - l / 2,
            y: 0 + w / 2,
            z: 0,
          };
        }
        return { x: 0, y: 0, z: 0 };
      }

      let direction = 'x';
      switch (placementMode) {
        case 'Row':
          direction = 'x';
          break;
        case 'Column':
          direction = 'y';
          break;
        case 'Above':
          direction = 'z+';
          break;
        case 'Below':
          direction = 'z-';
          break;
        case 'Side by side':
          direction = 'xy';
          break;
      }
      const prevContainer = this.containerOf(prev) || { L: 0, W: 0, H: 0 };
      const pl = this.calculateLengthOfContainer(prevContainer, prev.rotation[2]);
      const pw = this.calculateWidthOfContainer(prevContainer, prev.rotation[2]);
      switch (direction) {
        case 'x':
          return {
            x: prev.position.x + pl / 2 + prevContainer.L / 2 + spacing - (current.L / 2 - l / 2),
            y: prev.position.y,
            z: prev.position.z,
          };
        case 'y':
          return {
            x: prev.position.x,
            y: prev.position.y + pw / 2 + prevContainer.W / 2 + spacing - (current.W / 2 - w / 2),
            z: prev.position.z,
          };
        case 'z+':
          return {
            x: prev.position.x,
            y: prev.position.y,
            z: prev.position.z + Math.max(prevContainer.H, prevContainer.max_height) + spacing,
          };
        case 'z-':
          return {
            x: prev.position.x,
            y: prev.position.y,
            z: prev.position.z - (Math.max(prevContainer.H, prevContainer.max_height) + spacing),
          };
        case 'xy':
          return {
            x: prev.position.x + pl / 2 + prevContainer.L / 2 + spacing - (current.L / 2 - l / 2),
            y: 0 + w / 2,
            z: prev.position.z,
          };
      }
    },
    calculateLengthOfContainer(container: HoldData, rotationZ: number) {
      if (container) {
        return (
          Math.abs(Math.cos(rotationZ)) * container.L +
          Math.abs(Math.cos(Math.PI / 2 - rotationZ)) * container.W
        );
      }
    },
    calculateWidthOfContainer(container: HoldData, rotationZ: number) {
      if (container) {
        return (
          Math.abs(Math.cos(rotationZ)) * container.W +
          Math.abs(Math.cos(Math.PI / 2 - rotationZ)) * container.L
        );
      }
    },
    containerOf(ht: HoldType): HoldData {
      const hold = this.set.data.container_types.find((ct) => ct.id === ht.container_type_id);
      if (hold) {
        return {
          ...this.set.data.container_types.find((ct) => ct.id === ht.container_type_id),
          ...(ht.props
            ? Object.fromEntries(Object.entries(ht.props).filter(([_, v]) => v != null))
            : ht.props),
        };
      }
      return undefined;
    },
    createPreviewHoldData(
      rotation: Rotation,
      position: Point,
      holdData: HoldData
    ): HoldDataPreview {
      return {
        ...holdData,
        rotation,
        position,
        position_name: ' ',
        __indices: { start: 0, end: 0 },
        preview: true,
      };
    },
  },
  watch: {
    set: {
      handler: function (val: SetType): void {
        this.selectedSlot = this.selectedSlot ?? this.set.data.slots[0];
      },
      immediate: true,
    },
    prevContainerInfo: {
      handler: function (val: SelectedContainerInfo | null): void {
        if (val) {
          this.holdData = val.holdType
            ? this.set.data.container_types.find((c) => c.id === val.holdType.container_type_id)
            : undefined;
          this.rotation = val.holdType
            ? JSON.parse(JSON.stringify(val.holdType.rotation))
            : ([0, 0, 0, 'XYZ'] as Rotation);
          this.position = this.calculatePosition(
            this.prevContainerInfo.holdType,
            this.holdData,
            this.rotation,
            this.placementMode,
            this.spacing
          );
          this.selectedSlot = this.set.data.slots[val.slotIndex];
          this.containerDetailsChange();
        } else {
          this.holdData = null;
          this.rotation = [0, 0, 0, 'XYZ'] as Rotation;
          this.position = { x: 0, y: 0, z: 0 };
          this.containerDetailsChange();
        }
      },
      immediate: true,
    },
  },
});
</script>

<style scoped>
>>> .container-input input {
  cursor: pointer;
  text-overflow: ellipsis;
}
</style>
