<template>
  <div>
    <div v-if="isLoading" class="text-center">
      <v-progress-circular indeterminate v-bind:size="50" color="primary"></v-progress-circular>
    </div>
    <v-container v-else>
      <v-card v-for="article in news" :key="article.id" class="mb-3">
        <v-card-title primary-title>
          <div>
            <div class="text-h5 mb-0">{{ article.title }}</div>
            <span class="grey--text">{{ article.created_date }}</span>
          </div>
        </v-card-title>
        <v-card-text class="markdown-body">
          <div class="blockquote pl-0" v-html="parse(article.body)"></div>
        </v-card-text>
      </v-card>

      <v-pagination
        v-bind:length="newsPages"
        v-model="currentPage"
        :total-visible="7"
        @input="gotoPage"></v-pagination>
    </v-container>

    <v-dialog v-model="showWelcomeDialog" scrollable width="600">
      <v-card>
        <v-card-title class="text-h5">Welcome to Cargo-Planner</v-card-title>
        <v-card-text>
          <br />Please have a look at our documentation site
          <b><a href="https://cargo-planner.com/docs" target="_blank">here</a></b>
          <v-icon small @click="openLink('https://cargo-planner.com/docs')">mdi-open-in-new</v-icon>
          where you will find tutorials and guides on how to use the tool.
          <br />
          <br /><b
            >If you prefer to use imperial units - start off by updating your prefered units under
            <a href="/profile/general">Profile settings</a>.</b
          >
          <br />
          <br />Do you have any questions? Don't hesitate to send a mail to
          <b><a href="mailto:support@cargo-planner.com">support@cargo-planner.com</a></b>
          or use the chat function in the bottom right of the window.
          <br />
          <br />Happy planning!
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click.stop="
              showWelcomeDialog = false;
              $router.push('loadlists');
            "
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import API from '@/API';
import { Route } from 'vue-router';
import { News } from '@/models/NewsModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import Vue from 'vue';
import { marked } from 'marked';

export default Vue.extend({
  name: 'news',
  data() {
    return {
      total: 0 as number,
      currentPage: parseInt(this.$route.params.page) || 1,
      isLoading: false,
      perPage: 10,
      news: [] as News[],
      showWelcomeDialog: false,
    };
  },
  mounted() {
    this.fetchNews(this.currentPage);
    if (this.isAuthenticated && !localStorage.getItem('cplHasSeenIntro')) {
      this.showWelcomeDialog = true;
      localStorage.setItem('cplHasSeenIntro', 'true');
    }
  },
  beforeRouteUpdate(to: Route, from: Route, next: any) {
    this.fetchNews(Number(to.params.page));
    next();
  },
  computed: {
    ...mapStores(useMiscStore),
    newsPages(): number {
      return Math.ceil(this.total / 10);
    },
    isAuthenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
  },
  methods: {
    fetchNews(page: number): void {
      API.getNews({ page: page })
        .then((response) => {
          this.total = response.data.count;
          this.news = response.data.results;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('Error fetching news');
        });
    },
    gotoPage(page: number): void {
      this.$router.push({ name: 'news', params: { page: page.toString() } });
    },
    openLink(url: string): void {
      window.open(url, '_blank');
    },
    parse(html: string): string {
      return marked(
        html.replaceAll('(/media/markdownx/', `(${process.env.VUE_APP_API_HOST}/media/markdownx/`)
      );
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.markdown-body img {
  margin: 16px auto;
  display: block;
  max-width: 100%;
}
</style>
