<template>
  <v-card class="mt-1">
    <v-card-title class="d-flex align-baseline">
      <p>{{ part.type.toUpperCase() }}</p>

      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('delete', part.id)"><v-icon>mdi-delete</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="!(part.stick_to.includes('front') && part.stick_to.includes('rear'))">
          <length-input-field v-model="part.L" label="L"></length-input-field>
        </v-col>

        <v-col v-if="!(part.stick_to.includes('side1') && part.stick_to.includes('side2'))">
          <length-input-field v-model="part.W" label="W"></length-input-field>
        </v-col>
        <v-col v-if="!(part.stick_to.includes('floor') && part.stick_to.includes('roof'))">
          <length-input-field v-model="part.H" label="H"></length-input-field>
        </v-col>
      </v-row>

      <v-select
        v-model="part.stick_to"
        :items="['front', 'rear', 'floor', 'roof', 'side1', 'side2']"
        :menu-props="{ maxHeight: '400' }"
        label="Stick to"
        multiple></v-select>
      <v-row>
        <v-col v-if="!part.stick_to.includes('front') && !part.stick_to.includes('rear')">
          <length-input-field v-model="part.pos.x" label="X"></length-input-field
        ></v-col>
        <v-col v-if="!part.stick_to.includes('side1') && !part.stick_to.includes('side2')">
          <length-input-field v-model="part.pos.y" label="Y"></length-input-field
        ></v-col>
        <v-col v-if="!part.stick_to.includes('floor') && !part.stick_to.includes('roof')">
          <length-input-field v-model="part.pos.z" label="Z"></length-input-field> </v-col
      ></v-row>
      <v-checkbox
        v-if="!part.stick_to.includes('roof')"
        label="Not stackable"
        v-model="part.not_stackable"></v-checkbox>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { HoldPart } from '@/models/LoadlistModel';

export default Vue.extend({
  name: 'edit-part',
  data() {
    return {
      part: JSON.parse(JSON.stringify(this.value)) as HoldPart,
    };
  },
  props: {
    value: Object as PropType<HoldPart>,
    index: Number,
  },
  computed: {
    // title(): String {
    //   switch (this.part.type) {
    //     case "x_wall":
    //     case "y_wall":
    //       return "Wall";
    //     case "corner":
    //       return "Corner";
    //   }
    //   return "";
    // },
  },
  watch: {
    _part: {
      handler: function (a) {
        this.$emit('input', this.part);
      },
      immediate: true,
    },
  },
  created() {},
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
