<template>
  <div>
    <div class="rounded border d-flex width-1 mt-2 overflow-hidden">
      <div
        v-if="!isNaN(used)"
        :class="[color, 'text--white', 'px-2', 'text-center']"
        :style="{ flex: `${used} 1 0` }">
        <div :style="{ 'white-space': 'nowrap' }">
          <span v-if="weightFilter">{{ used | toWeight }}</span>
          <span v-else> {{ used | toVolume }}</span>
        </div>
      </div>
      <div
        v-if="!isNaN(available)"
        class="white px-2 text-center"
        :style="{
          flex: `${available} 1 0`,
        }">
        <div :style="{ 'white-space': 'nowrap' }">
          <span v-if="weightFilter">{{ available | toWeight }}</span>
          <span v-else> {{ available | toVolume }}</span>
        </div>
      </div>
    </div>

    <div class="text-center mt-1" v-if="!isNaN(used) && !isNaN(max)">
      <span :class="used / max > 1 ? ['error--text', 'font-weight-bold'] : []"
        >{{ (used / max) | percentage }}%</span
      >
      of
      <b>
        <span v-if="weightFilter">{{ max | toWeight }}</span>
        <span v-else>{{ max | toVolume }}</span>
      </b>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'availability-slider',
  props: {
    used: Number,
    max: Number,
    color: String,
    weightFilter: Boolean,
    noSliders: Boolean,
  },
  computed: {
    available(): number {
      return Math.max(0, this.max - this.used);
    },
  },
});
</script>
