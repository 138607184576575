<template>
  <div>
    <sheet-component
      :headers="getColumns"
      :value="flattenedObjects"
      :menu-items="getMenuItems"
      :disableAutocomplete="disableAutocomplete"
      :lengthDim="lengthDim"
      :weightDim="weightDim"
      :focusRow="focusRow"
      :warnings="warnings"
      :cargoes="cargoLibrary"
      :defaultRow="getDefaultRow"
      @firstCellClicked="showInputTableSettingsModal = true"
      :disableFirstCell="preferences.enforced"
      ref="sheet"
      :disabled="disabled"
      @selected-row="selectedRow = $event"
      @enableUndo="enableUndo"></sheet-component>
    <v-dialog v-model="showConvertDialog" scrollable persistent width="600">
      <v-card>
        <v-card-title class="text-h5">Convert dimensions for selected rows</v-card-title>

        <v-card-text>
          <v-alert :value="!weightDim || !lengthDim" type="warning" outlined>
            You need to select a main length and weight dim (further down) for this loadlist for the
            rows to be converted to
          </v-alert>
          <v-select
            v-model="convertLengthDimFrom"
            v-bind:items="convertLengthDimOptions"
            required
            label="From length"></v-select>

          <v-select
            v-model="convertWeightDimFrom"
            v-bind:items="convertWeightDimOptions"
            required
            label="From weight"></v-select>
        </v-card-text>
        <v-card-title v-if="weightDim && lengthDim"
          >...to {{ lengthDim }} and {{ weightDim }}</v-card-title
        >
        <v-card-actions>
          <v-btn text @click.stop="showConvertDialog = false" :ripple="false">Close</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!weightDim || !lengthDim || !showConvertDialog"
            @click.stop="
              showConvertDialog = false;
              convert();
            "
            :ripple="false"
            >Convert</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSwapDialog" scrollable persistent width="600">
      <v-card>
        <v-card-title class="text-h5">Reorder length, width and height</v-card-title>

        <v-card-text>
          Swap your data columns so the largest value is in the first column, etc.
          <v-select multiple label="Include in swap" :items="swapDimsOptions" v-model="swapDims" />
          Order by
          <div>
            <div
              v-for="(v, i) in swapDims"
              :key="v"
              :class="`swapOrder ${swapHover === i ? 'swapHover' : ''}`"
              draggable="true"
              @dragstart="swapDragStart($event, i)"
              @dragenter="swapDragEnter($event, i)"
              @dragleave="swapDragLeave($event, i)">
              <v-icon>mdi-drag</v-icon>{{ swapDimsOptions.find(({ value }) => value === v).text }}
            </div>
            <div
              :class="`${swapHover === swapDims.length ? 'swapHover' : ''}`"
              style="padding: 20px 0; border-top: 2px solid transparent"
              @dragenter="swapDragEnter($event, swapDims.length)"
              @dragleave="swapDragLeave($event, swapDims.length)"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showSwapDialog = false" :ripple="false">Close</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="swapDims.length < 2"
            @click.stop="
              showSwapDialog = false;
              executeSwap();
            "
            :ripple="false"
            >Swap Values</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDecimalSeparatorDialog" width="600">
      <v-card>
        <v-card-title class="text-h5">Change Decimal Separator</v-card-title>

        <v-card-text>
          <v-select
            :items="[
              { value: ',', text: 'Comma' },
              { value: '.', text: 'Point' },
            ]"
            v-model="decimalSeparator"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showDecimalSeparatorDialog = false" :ripple="false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <load-in-dialog
      v-if="showLibraryHoldsModal"
      :visible="showLibraryHoldsModal"
      :baseTypeForContainerID="baseTypeForContainerID"
      :containerIdsForItem="containerIdsForItem"
      @addLoadInId="addLoadInId"
      @removeLoadInId="removeLoadInId"
      @close="showLibraryHoldsModal = false" />

    <v-dialog v-model="showBundlingModal" scrollable max-width="1200" @input="saveBundles">
      <v-card>
        <v-card-title class="text-h5">Bundles</v-card-title>
        <v-card-text>
          <v-alert type="info" outlined>
            Goods that come pre-packaged in multiples (like packages of 12 or 100) can utilize
            bundles to get a more precise loadlist
          </v-alert>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on"
                ><v-checkbox label="Allow partial bundles" v-model="partialBundles"
              /></span>
            </template>
            <span>
              Allowing a partially full bundle keeps the dimensions of the bundle but reduces the
              weight
            </span>
          </v-tooltip>
          <sheet-component
            :headers="getBundleColumns"
            :value="bundlesForItem()"
            :menu-items="[]"
            :disableAutocomplete="true"
            :lengthDim="lengthDim"
            :weightDim="weightDim"
            :defaultRow="{ qty: undefined, orientations: 3 }"
            :maxRows="5"
            ref="bundleSheet"
            :disabled="disabled" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click.stop="
              saveBundles();
              showBundlingModal = false;
            "
            :ripple="false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMoveItemsToLoadlistDialog" scrollable>
      <v-card>
        <v-card-title class="text-h5">Move items</v-card-title>
        <v-card-text
          ><v-alert type="info" outlined>
            Select a list to move the currently selected rows to. If the selected rows are already
            loaded - they will also be unloaded from any container or pallet. </v-alert
          ><loadlists-component
            :readonly="true"
            @selectedLoadlist="
              moveItemsToLoadlist($event);
              showMoveItemsToLoadlistDialog = false;
            "></loadlists-component
        ></v-card-text>

        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showMoveItemsToLoadlistDialog = false" :ripple="false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showInputTableSettingsModal" scrollable width="1200">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="showInputTableSettingsModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Table settings</v-toolbar-title>
        </v-toolbar>
        <input-table-settings v-if="showInputTableSettingsModal"></input-table-settings>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import sheetComponent from './Sheet.vue';
import ItemProperties, {
  ItemProperty,
  CustomItemProperty,
  ItemPropertyValue,
} from '@/misc/itemProperties';
import { colorPalette, makeRandomColor } from '@/misc/colorUtils';
import { CompanySettings, UserPreferences } from '@/models/UserCompanyModel';
import { ListType, HoldInputItem, Cargo, Bundling } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import InputTableSettings from '@/components/Custom/InputTableSettings.vue';
import { Warning } from '@/misc/itemWarnings';
import LoadlistsComponent from '@/components/Loadlists/index.vue';
import LoadInDialog from '@/components/Modals/LoadIn.vue';
import { SegregationTable } from '@/models/CalculationModel';

export interface MenuItem {
  title: string;
  cb: () => void;
  disabled?: boolean;
  selectedRowRequired?: boolean;
}

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        sheet: InstanceType<typeof sheetComponent>;
        bundleSheet: InstanceType<typeof sheetComponent>;
      };
    }
  >
).extend({
  name: 'input-table',
  components: {
    sheetComponent,
    InputTableSettings,
    LoadlistsComponent,
    LoadInDialog,
  },
  data: function () {
    return {
      isLoading: false,
      showConvertDialog: false,
      showSwapDialog: false,
      showLibraryHoldsModal: false,
      showBundlingModal: false,
      partialBundles: false,
      showDecimalSeparatorDialog: false,
      showMoveItemsToLoadlistDialog: false,
      showInputTableSettingsModal: false,
      baseTypeForContainerID: this.mode,
      showDivideWeights: false,
      convertLengthDimOptions: [
        { text: 'MM', value: 'MM' },
        { text: 'CM', value: 'CM' },
        { text: 'DM', value: 'DM' },
        { text: 'M', value: 'M' },
        { text: 'IN', value: 'IN' },
        { text: 'FT', value: 'FT' },
      ],
      convertWeightDimOptions: [
        { text: 'KG', value: 'KG' },
        { text: 'MT', value: 'MT' },
        { text: 'LB', value: 'LB' },
      ],
      convertLengthDimFrom: '',
      convertWeightDimFrom: '',
      swapDimsOptions: [
        { text: 'Length', value: 'l' },
        { text: 'Width', value: 'w' },
        { text: 'Height', value: 'h' },
      ],
      swapDims: ['l', 'w', 'h'],
      swapHover: undefined,
      decimalSeparator: (0.1).toLocaleString().substr(1, 1),
      selectedRow: null,
    };
  },
  props: {
    objects: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    mode: {
      type: String as PropType<ListType>,
      default: 'SEA',
    },
    focusRow: {
      type: Number,
      default: null,
    },
    lengthDim: {
      type: String,
      default: null,
    },
    weightDim: {
      type: String,
      default: null,
    },
    extraHiddenColumns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    warnings: {
      type: Array as PropType<{ id: Warning; indexes: number[] }[]>,
      default: () => [] as { id: Warning; indexes: number[] }[],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    extraPreColumns: {
      type: Array as PropType<ItemProperty[]>,
      default: () => [] as ItemProperty[],
    },
    disableAutocomplete: Boolean,
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),

    lengthDimHeaderText(): string {
      return '[' + (this.lengthDim ? this.lengthDim.toLowerCase() : '-') + ']';
    },
    weightDimHeaderText(): string {
      return '[' + (this.weightDim ? this.weightDim.toLowerCase() : '-') + ']';
    },
    extraColumns(): CustomItemProperty[] {
      return this.miscStore?.company_settings?.extra_columns || [];
    },
    extraColumnsAsItemProperties(): ItemProperty[] {
      return this.extraColumns.map((i: CustomItemProperty) => {
        return {
          key: i.name,
          input: ['checkbox', 'select'].includes(i.type) ? i.type : 'text',
          type: ItemProperties.getPropTypeFromMetadata(i.type),
          values: i?.values?.map((j) => {
            return { key: j, text: j };
          }),
          text: i.name,
          desc: i.desc,
          width: 150,
        };
      });
    },
    hiddenColumns(): string[] {
      return (
        Array.isArray(this.preferences?.hidden_input_columns)
          ? this.preferences.hidden_input_columns
          : []
      ).concat(this.extraHiddenColumns);
    },
    getColumns(): ItemProperty[] {
      const loadplanSegregationTable = this.loadlistStore.loadplan?.settings?.segregation_table;
      const classIdValues = loadplanSegregationTable
        ? this.getClassIdValues(loadplanSegregationTable)
        : undefined;

      return [
        ...this.extraPreColumns,
        ...ItemProperties.props(classIdValues)
          .filter(
            (i) => (!i.mot || i.mot.includes(this.mode)) && !this.hiddenColumns.includes(i.key)
          )
          .filter((i) => !i.additional && !i.readOnly)
          .map((i) => {
            switch (i.dimension) {
              case 'length':
                i.text = `${i.text} ${this.lengthDimHeaderText}`;
                break;
              case 'weight':
                i.text = `${i.text} ${this.weightDimHeaderText}`;
                break;
            }
            switch (i.key) {
              case 'allowed_containers':
                i.cellAction = () => {
                  this.showLibraryHoldsModal = true;
                };
                break;
              case 'bundling':
                i.cellAction = () => {
                  this.showBundles();
                };
                break;
            }
            return i;
          }),
        ...this.extraColumnsAsItemProperties,
      ];
    },
    getMenuItems(): MenuItem[] {
      return [
        {
          title: 'Move to loadlist...',
          cb: () => {
            this.showMoveItemsToLoadlistDialog = true;
          },
          selectedRowRequired: true,
          disabled: !this.lengthDim || !this.weightDim,
        },
        {
          title: 'Convert dimensions...',
          cb: () => {
            this.showConvertDialog = true;
          },
          disabled: !this.lengthDim,
          selectedRowRequired: true,
        },
        {
          title: 'Swap Length/Width/Height...',
          cb: () => {
            this.showSwapDialog = true;
          },
          disabled: false,
          selectedRowRequired: true,
        },
        {
          title: 'Divide Weights by Quantities',
          cb: () => {
            const objects: HoldInputItem[] = this.$refs.sheet.getObjects();

            for (
              let i = this.$refs.sheet.selectedCells.start.row;
              i <= this.$refs.sheet.selectedCells.end.row;
              i++
            ) {
              if (!objects[i].wt || !objects[i].qty) continue;
              this.$refs.sheet.setValue(i, 'wt', Number(objects[i].wt) / Number(objects[i].qty));
            }
            this.showDivideWeights = true;
          },
          disabled: this.showDivideWeights,
          selectedRowRequired: true,
        },
        {
          title: `Change decimal separator`,
          cb: () => {
            this.showDecimalSeparatorDialog = true;
          },
        },
      ];
    },
    flattenedObjects(): HoldInputItem[] {
      if (this.extraColumns.length) {
        return this.objects.map((i) => {
          const tmp = {};
          if (i.metadata) {
            for (const [key, value] of Object.entries(i.metadata)) {
              (tmp as any)[key] = value;
            }
          }
          return {
            ...tmp,
            ...i,
          };
        });
      }
      return this.objects;
    },
    preferences(): CompanySettings {
      return this.miscStore.preferences;
    },
    autoColorByMetadataProp(): boolean {
      return (
        !!this.preferences.item_color_by_key &&
        !!this.extraColumns.find((i) => i.name === this.preferences.item_color_by_key)
      );
    },
    cargoLibrary(): Cargo[] {
      return this.miscStore.cargoes;
    },
    containerIdsForItem(): number[] {
      const row = this.selectedRow;
      return this.$refs.sheet?.getObjects()[row].allowed_containers || [];
    },
    getBundleColumns(): ItemProperty[] {
      return ItemProperties.bundleProps().map((i) => {
        switch (i.dimension) {
          case 'length':
            i.text = `${i.text} ${this.lengthDimHeaderText}`;
            break;
          case 'weight':
            i.text = `${i.text} ${this.weightDimHeaderText}`;
            break;
        }
        return i;
      });
    },
    selectedItem(): HoldInputItem {
      const row = this.selectedRow;
      return this.$refs.sheet?.getObjects()[row];
    },
    getDefaultRow(): HoldInputItem {
      return this.preferences.default_input_row
        ? JSON.parse(JSON.stringify(this.preferences.default_input_row))
        : { qty: 1, orientations: 3 };
    },
  },
  methods: {
    getClassIdValues(segregationTable: SegregationTable): ItemPropertyValue[] {
      const classIdkeys: string[] = Object.keys(segregationTable);
      return [{ key: undefined, text: '' }, ...classIdkeys.map((key) => ({ key, text: key }))];
    },
    getObjects(): HoldInputItem[] {
      return this.parseObjects(this.$refs.sheet.getObjects());
    },
    parseObjects(objects: HoldInputItem[], auto_fields: boolean = true): HoldInputItem[] {
      const palette = colorPalette(objects);

      const autocolors: Record<string, string> = {};

      let getColor = (item: HoldInputItem) => {
        if (this.preferences.item_color_by_key) {
          let value = this.autoColorByMetadataProp
            ? item.metadata[this.preferences.item_color_by_key]
            : item[this.preferences.item_color_by_key as keyof HoldInputItem];

          if (value) {
            if (!(value in autocolors)) {
              autocolors[value] = makeRandomColor(palette);
            }
            return autocolors[value];
          }
        }
        return makeRandomColor(palette);
      };

      const parseNumber =
        this.decimalSeparator === ',' ? parseNumberWithCommaDecimal : parseNumberWithDotDecimal;

      const existingSkus = new Set(objects.map((obj) => obj.sku));
      let lastId = 0;
      function generateUniqueSku() {
        let newSKU;
        do {
          newSKU = String(++lastId);
        } while (existingSkus.has(newSKU));
        existingSkus.add(newSKU);
        return newSKU;
      }
      const propLookup = new Map(ItemProperties.props().map((prop) => [prop.key, prop]));
      return objects
        .map((row: HoldInputItem, i: number) => {
          row.metadata = {};
          this.extraColumnsAsItemProperties.forEach((column) => {
            row.metadata[column.key] =
              (column.type === 'number'
                ? parseNumber((row as any)[column.key])
                : (row as any)[column.key]) || null;
            delete (row as any)[column.key];
          });

          const qty = parseInt(row.qty as any, 10);
          const sku = row.sku || (auto_fields ? generateUniqueSku() : undefined);
          const label = String(row.label || (auto_fields ? i + 1 : '')).trim();

          if (Object.keys(row.metadata).length === 0) row.metadata = null;
          const parsedRow: HoldInputItem = {
            ...row,
            sku: sku,
            l: parseNumber(row.l),
            w: parseNumber(row.w),
            h: parseNumber(row.h),
            wt: parseNumber(row.wt),
            label: label,
            qty: Number.isInteger(qty) ? qty : undefined,
            not_stackable: !!row.not_stackable,
            bottom_only: !!row.bottom_only,
            palletize: !!row.palletize,
            orientations: Number(row.orientations) || undefined,
            max_layers: Number(row.max_layers) || null,
            max_load: parseNumber(row.max_load) || null,
            free_space: row.free_space || null,
            shipment_id: row.shipment_id || null,
            class_id: row.class_id || null,
            color: row.color || (auto_fields ? getColor(row) : undefined),
            allowed_containers: row.allowed_containers || null,
            unit_qty: Number(row.unit_qty) || null,
            priority: Number(row.priority) || null,
            bundling: row.bundling
              ? {
                  ...row.bundling,
                  bundles: this.bundlesFromObjects(row.bundling?.bundles || [], row.label),
                }
              : undefined,
          };
          if (parsedRow.bundling && !parsedRow.bundling.bundles?.length) {
            parsedRow.bundling = undefined;
          }
          return parsedRow;
        })
        .filter((item: HoldInputItem) => item.l * item.w * item.h > 0)
        .map((item: HoldInputItem) => {
          /// remove null and undefined values
          return Object.fromEntries(
            Object.entries(item)
              .map(([k, v]) => {
                if (k == 'metadata' && !!v) {
                  let entries = Object.entries(v).filter(([_, v]) => v !== undefined && v !== null);
                  return [k, entries.length > 0 ? Object.fromEntries(entries) : null];
                }
                return [k, v];
              })
              .filter(([k, v]) => {
                let p = propLookup.get(k) || ({} as ItemProperty);
                return (
                  p.required ||
                  !(v === undefined || v === null || (p.type == 'bool' && v === false))
                );
              })
          ) as HoldInputItem;
        });
    },
    addLoadInId(id: number): void {
      this.$refs.sheet.setValue(this.$refs.sheet.primaryCell.row, 'allowed_containers', [
        ...this.containerIdsForItem,
        id,
      ]);
      this.$refs.sheet.resetSelectedCells();
    },
    removeLoadInId(id: number): void {
      this.$refs.sheet.setValue(
        this.$refs.sheet.primaryCell.row,
        'allowed_containers',
        [...this.containerIdsForItem].filter((i) => i !== id)
      );
      this.$refs.sheet.resetSelectedCells();
    },
    bundlesForItem(): HoldInputItem[] {
      if (this.selectedRow === undefined) return [];
      return (
        this.$refs.sheet
          ?.getObject(this.selectedRow)
          ?.bundling?.bundles?.map((b) => b as HoldInputItem) || []
      );
    },
    showBundles(): void {
      this.partialBundles =
        this.$refs.sheet?.getObject(this.selectedRow)?.bundling?.partial_bundles || false;
      this.showBundlingModal = true;
    },
    bundlesFromObjects(objects: HoldInputItem[], defaultLabel: string): HoldInputItem[] {
      return this.parseObjects(objects, false)
        .filter((i) => i.qty)
        .map((b) => {
          if (!b.label) {
            b.label = `${defaultLabel} (${b.qty} pcs)`;
          }
          delete b['sku'];
          return b;
        });
    },
    saveBundles(): void {
      let label = this.$refs.sheet?.getObject(this.selectedRow)?.label;
      const bundling = {
        partial_bundles: this.partialBundles,
        bundles: this.bundlesFromObjects(this.$refs.bundleSheet.getObjects(), label),
      } as Bundling;
      this.$refs.sheet.setValue(
        this.$refs.sheet.primaryCell.row,
        'bundling',
        bundling.bundles.length ? bundling : undefined
      );
      this.$refs.sheet.resetSelectedCells();
      this.showBundlingModal = false;
    },
    convert(): void {
      const objects: HoldInputItem[] = this.$refs.sheet.getObjects();
      const startRow: number = this.$refs.sheet.selectedCells.start.row;
      const endRow: number = this.$refs.sheet.selectedCells.end.row;
      const lengthFactor = this.$toSI(this.convertLengthDimFrom) / this.$toSI(this.lengthDim);
      const weightFactor = this.$toSI(this.convertWeightDimFrom) / this.$toSI(this.weightDim);

      for (let i = startRow; i <= endRow; i++) {
        this.$refs.sheet.setValue(
          i,
          'l',
          this.$options.filters.roundTwoDecimals(lengthFactor * Number(objects[i].l))
        );
        this.$refs.sheet.setValue(
          i,
          'w',
          this.$options.filters.roundTwoDecimals(lengthFactor * Number(objects[i].w))
        );
        this.$refs.sheet.setValue(
          i,
          'h',
          this.$options.filters.roundTwoDecimals(lengthFactor * Number(objects[i].h))
        );
        this.$refs.sheet.setValue(
          i,
          'wt',
          this.$options.filters.roundTwoDecimals(weightFactor * Number(objects[i].wt))
        );
      }
    },
    executeSwap(): void {
      const objects: HoldInputItem[] = this.$refs.sheet.getObjects();
      const startRow: number = this.$refs.sheet.selectedCells.start.row;
      const endRow: number = this.$refs.sheet.selectedCells.end.row;
      for (let i = startRow; i <= endRow; i++) {
        this.swapDims
          .map((key) => objects[i][key as keyof HoldInputItem])
          .sort((a, b) => b - a)
          .forEach((value, index) => {
            (objects[i][this.swapDims[index] as keyof HoldInputItem] as any) = value;
            this.$refs.sheet.setValue(i, this.swapDims[index], value);
          });
      }
    },
    swapDragStart(e: DragEvent, index: number): void {
      e.dataTransfer.setData('JSON', JSON.stringify(index));
    },
    swapDragEnter(e: DragEvent, index: number): void {
      this.swapHover = index;
    },
    swapDragLeave(e: DragEvent, index: number): void {
      if (this.swapHover === index) {
        if (!e.relatedTarget) {
          let fromIndex = JSON.parse(e.dataTransfer.getData('JSON'));
          if (fromIndex < this.swapHover) {
            this.swapDims.splice(this.swapHover, 0, this.swapDims[fromIndex]);
            this.swapDims.splice(fromIndex, 1);
          } else if (fromIndex > this.swapHover) {
            let from = this.swapDims.splice(fromIndex, 1)[0];
            this.swapDims.splice(this.swapHover, 0, from);
          }
          this.swapDims;
          e.stopImmediatePropagation();
        }
        this.swapHover = undefined;
      }
    },

    moveItemsToLoadlist(id: number): void {
      const startRow: number = this.$refs.sheet.selectedCells.start.row;
      const endRow: number = this.$refs.sheet.selectedCells.end.row;

      this.$emit('moveItemsToLoadlist', {
        startRow,
        endRow,
        id,
      });
    },
    undo(): void {
      this.$refs.sheet.undo();
    },
    enableUndo(undoEnabled: boolean): void {
      this.$emit('enableUndo', undoEnabled);
    },
  },
});

const regExp_OnlyNumbersAndDotAndComma = /[^0-9.,]/g;
const regExp_NumberWithDotDecimal = /[^0-9.]/g;
const regExp_MatchCommas = /\,/g;
const regExp_AllDots = /\.(?=.*\.)/g;

function parseNumberWithCommaDecimal(a: string | number): number {
  return parseFloat(
    String(a)
      .replace(regExp_OnlyNumbersAndDotAndComma, '')
      .replace(regExp_MatchCommas, '.')
      .replace(regExp_AllDots, '')
  );
}

function parseNumberWithDotDecimal(a: string | number): number {
  return parseFloat(String(a).replace(regExp_NumberWithDotDecimal, ''));
}
</script>

<style scoped>
.swapOrder {
  cursor: grab;
  border-top: 2px solid transparent;
  padding: 5px 0;
}
.swapOrder i {
  pointer-events: none;
}
.swapHover {
  border-top: 2px solid blue !important;
}
</style>
