<template>
  <v-row ref="plannerTable" class="fill-height">
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-for="hold in augmentedHolds"
      :key="hold.hold.uid"
      class="my-1">
      <v-card
        :loading="loadingContainers.includes(hold.__indices.start)"
        class="ma-1 fill-height plannerContainer">
        <planner-items-component
          :hideItems="$vuetify.breakpoint.xsOnly"
          :hold="hold.hold"
          @loadingContainers="loadingContainers = $event"
          max-list-height="300px">
          <template v-slot:top="{ container }">
            <!-- <div
              class="d-flex px-2 align-center
            "
            >
              <v-checkbox
                @change="checkRow(hold.__indices)"
                :input-value="checkedUnits.indexOf(hold.__indices) >= 0"
                :ripple="false"
                :label="
                  hold.__indices.end > hold.__indices.start
                    ? `${hold.__indices.start + 1}..${hold.__indices.end + 1}`
                    : `${hold.__indices.start + 1}`
                "
              ></v-checkbox>
              <span class="ml-4">{{ container.name }}</span>
            </div> -->

            <div class="d-flex flex-row ma-2">
              <v-simple-checkbox
                class="align-self-center"
                @input="checkRow(hold.__indices)"
                :value="checkedUnits.indexOf(hold.__indices) >= 0"
                :ripple="false"
                :label="
                  hold.__indices.end > hold.__indices.start
                    ? `${hold.__indices.start + 1} to ${hold.__indices.end + 1}`
                    : `${hold.__indices.start + 1}`
                "></v-simple-checkbox>
              <span class="align-self-center">{{
                hold.__indices.end > hold.__indices.start
                  ? `${hold.__indices.start + 1} to ${hold.__indices.end + 1}`
                  : `${hold.__indices.start + 1}`
              }}</span>

              <span class="ml-4 align-self-center text-subtitle-1">{{ container.hold.name }}</span>
              <v-spacer />

              <v-chip v-if="hold.hasCoordinates()" @click="$emit('showMapModal', hold.uuid)">
                <v-icon small>fa-map</v-icon>
              </v-chip>
            </div>
            <v-divider></v-divider>
            <div class="scene-container" @click="gotoInteractiveView(hold.__indices.start)">
              <scene-component
                class="my-1 d-flex justify-center"
                style="cursor: pointer"
                :hold-object="hold.hold"
                :key="hold.hold.uid"
                :canvas-width="400"
                :canvas-height="200"></scene-component>
            </div>
            <v-divider></v-divider>
            <!-- <div class="px-4 py-4">
              <v-icon v-if="container.weightUtilization > 0.9" color="red"
                >mdi-alert</v-icon
              >
              <div class="d-flex flex-column">
                <span
                  >Net: {{ container.weight | toWeight }} ({{
                    container.weightUtilization | percentage
                  }}%)</span
                >
                <span v-if="container.hold.tare > 0"
                  >Gross: {{ container.grossWeight | toWeight }}</span
                >
                <span v-if="settings && settings.shipping_factor">
                  Chargable:
                  {{
                    container.chargableWeight(settings.shipping_factor) |
                      toWeight
                  }}
                </span>
              </div> 
              <span v-if="container.oogValues.length">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on"
                      ><v-icon color="orange">mdi-information</v-icon> (OoG)
                    </span>
                  </template>
                  <span class="no-print">{{ container.oogSummary }}</span>
                </v-tooltip>
              </span>
              <span
                >{{ container.usedLength | toLength(false) }} x
                {{ container.usedWidth | toLength(false) }} x
                {{ container.usedHeight | toLength }}</span
              >
              <div>
                <span>{{ container.items_count }} pcs</span>
                <span class="ml-2">{{ container.volume | toVolume }}</span>
                <span v-if="container.volumeUtilization"
                  >({{ container.volumeUtilization | percentage }} %)</span
                >
              </div> 
            </div> -->
            <v-divider></v-divider>
          </template>
        </planner-items-component>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import plannerItemsComponent from '@/components/Custom/PlannerItems/index.vue';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import { HoldDataWithIndices, Indices } from '@/models/LoadlistModel';
import { CalculationSettings } from '@/models/CalculationModel';
import { AugmentedHold } from '@/models/augmented/hold';

export default Vue.extend({
  name: 'planner-table',
  props: {
    filteredHolds: Array as PropType<HoldDataWithIndices[]>,
    checkedUnits: Array as PropType<Indices[]>,
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: (): CalculationSettings => {
        return null;
      },
    },
  },
  components: {
    plannerItemsComponent,
    sceneComponent,
  },
  data() {
    return {
      loadingContainers: [] as number[],
    };
  },
  computed: {
    augmentedHolds(): AugmentedHold[] {
      return this.filteredHolds.map((h) => new AugmentedHold(h));
    },
  },
  methods: {
    gotoInteractiveView(index: number): void {
      this.$emit('sceneClick', index);
    },
    checkRow(checkedRow: Indices): void {
      this.$emit('toggleSelectedRow', checkedRow);
    },
  },
});
</script>

<style scoped>
.column-container {
  min-height: 500px;
  height: 100%;
}
.scene-container {
  height: 208px;
}
.scroll-x {
  overflow-x: auto;
}
.scroll-x::-webkit-scrollbar {
  height: 12px;
}
.scroll-x::-webkit-scrollbar-thumb {
  background: #4778b2;
  border-radius: 4px;
}
.scroll-x::-webkit-scrollbar-track {
  background: #f1f1f1;
}
</style>
