<template>
  <v-container>
    <v-row align="center">
      <v-col md="8">
        <v-select
          v-bind:items="$list_types"
          @input="changeListType"
          :value="currentType"
          hide-details
          outlined
          dense>
          <template #selection="{ item }">
            <v-icon> {{ `$vuetify.icons.${item.value}` }}</v-icon>
            &nbsp;
            {{ item.text }}
          </template>
        </v-select>
      </v-col>
      <v-col v-if="sets_enabled">
        <v-switch v-model="showSets" :label="'Show sets'"></v-switch>
      </v-col>
      <v-col>
        <v-tooltip bottom :disabled="user.is_editor">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                :disabled="!user.is_editor"
                @click="
                  $route.name === 'sets'
                    ? $router.push({
                        name: 'modify_set',
                        params: { type: currentType },
                      })
                    : $router.push({
                        name: 'modify_hold',
                        params: { type: currentType },
                      })
                "
                color="success"
                :ripple="false"
                block>
                <v-icon>mdi-plus-circle</v-icon>
                Create new
              </v-btn>
            </div>
          </template>
          <span>You need to be a Administrator to perform this action</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import containerLibraryComponent from '@/components/Custom/ContainerLibrary.vue';
import setsLibraryComponent from '@/components/Custom/SetsLibrary.vue';

import sceneComponent from '@/components/Custom/SceneComponent.vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { ListType } from '@/models/LoadlistModel';
import { User } from '@/models/UserCompanyModel';
import { UsageSettings } from '@/stores/usageSettings';

const usageSettings = UsageSettings.fetch();

export default Vue.extend({
  name: 'equipment',
  components: {
    containerLibraryComponent,
    setsLibraryComponent,
    sceneComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapStores(useMiscStore),
    user(): User {
      return this.miscStore.user;
    },
    sets_enabled(): boolean {
      return this.miscStore?.company?.sets_enabled;
    },
    currentType(): ListType {
      return (this.$route.params.type as ListType) || 'SEA';
    },
    showSets: {
      get(): boolean {
        return this.$route.name == 'sets';
      },
      set(value: boolean): void {
        this.$router.replace({
          name: value ? 'sets' : 'containers',
          params: { type: this.currentType },
        });
      },
    },
  },
  mounted() {
    if (!this.$route.params.type) {
      const base_type = (usageSettings.currentBaseType as ListType) || 'SEA';
      this.$router.replace({
        name: 'containers',
        params: { type: base_type },
      });
    }
  },
  methods: {
    changeListType(type: string) {
      this.$router.push({
        name: 'containers',
        params: { type: type },
      });
      usageSettings.currentBaseType = type as ListType;
    },
  },
  filters: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
