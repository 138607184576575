<template>
  <div>
    <v-alert outlined type="info">
      The segregation table enables you to tell which cargoes can or cannot be loaded together in
      the same equipment. The classes used in the table refer to the "Class" column when entering
      cargo data.
    </v-alert>
    <v-col>
      <div class="d-flex">
        <v-text-field
          hide-details
          label="New class"
          v-model="newClassId"
          @keydown.enter.prevent="addClassId">
        </v-text-field>
        <v-btn icon class="mt-5" color="green" @click="addClassId" :disabled="disableAddNewClassId">
          <v-icon> mdi-plus-circle </v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr class="">
            <th class="text-left text-subtitle-1 font-weight-bold">Classes</th>
            <th
              class="text-center text-subtitle-1 font-weight-bold"
              v-for="(_, key) in class_ids"
              :key="key">
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, key, index1) in class_ids" :key="key">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <td v-bind="attrs" v-on="on" class="text-subtitle-1 font-weight-bold primary--text">
                  {{ key }}
                </td>
              </template>
              <v-list>
                <v-list-item @click="deleteClassId(key)">
                  <v-list-item-title>Delete class</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <td class="text-center" v-for="(val, key2, index2) in class_ids" :key="key2">
              <span v-if="index1 === index2">-</span>

              <v-menu v-else offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" v-for="item in cellValues" :key="item.value">
                    <v-icon
                      :color="item.color"
                      v-if="(class_ids[key][key2] || class_ids[key2][key]) == item.value"
                      >{{ item.icon }}</v-icon
                    >
                  </span>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in cellValues"
                    :key="item.value"
                    @click="changeClassId(key, key2, item.value)">
                    <v-list-item-title
                      ><v-icon :color="item.color">{{ item.icon }}</v-icon> &nbsp;{{
                        item.text
                      }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn class="my-4" @click="$emit('update', class_ids)"
      ><v-icon>mdi-floppy</v-icon>Save table</v-btn
    >
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { SegregationTable } from '@/models/CalculationModel';

export default Vue.extend({
  props: {
    input: {
      type: Object as PropType<SegregationTable>,
      default: {} as SegregationTable,
    },
  },
  watch: {
    input: {
      handler: function (val) {
        this.class_ids = JSON.parse(JSON.stringify(val)) || {};
      },
      immediate: true,
    },
  },
  computed: {
    cellValues() {
      return [
        {
          text: 'Segregate',
          value: 'segregate',
          icon: 'mdi-cancel',
          color: 'red',
        },
        {
          text: 'Can be loaded together',
          value: undefined,
          icon: 'mdi-check',
          hide: true,
          color: 'green',
        },
      ];
    },
    disableAddNewClassId(): boolean {
      return this.class_ids && (!this.newClassId || this.newClassId in this.class_ids);
    },
  },
  data() {
    return {
      loading: false,
      class_ids: {} as SegregationTable,
      newClassId: '',
    };
  },
  methods: {
    addClassId(): void {
      if (this.disableAddNewClassId) return;
      if (!this.class_ids) this.class_ids = {};
      this.$set(this.class_ids, this.newClassId, {});
      this.newClassId = '';
    },
    changeClassId(a: string, b: string, val: string): void {
      this.$set(this.class_ids[a], b, val);
      this.$set(this.class_ids[b], a, val);
    },
    deleteClassId(key: string) {
      this.$delete(this.class_ids, key);
      for (const key2 in this.class_ids) {
        if (key in this.class_ids[key2]) this.$delete(this.class_ids[key2], key);
      }
      if (Object.keys(this.class_ids).length === 0) this.class_ids = null;
    },
  },
});
</script>

<style scoped>
table {
  border: none;
  border-collapse: collapse;
}

table td,
th {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

table td:first-child,
th:first-child {
  border-left: none;
}

table td:last-child,
th:last-child {
  border-right: none;
}
</style>
