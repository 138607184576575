<template>
  <div>
    <v-snackbar :timeout="5000" v-model="showSnackbar" bottom>
      {{ snackbarMessage }}
    </v-snackbar>
    <v-card flat>
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="item in tabs" :key="item.key">
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <v-card-text class="py-4">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabs" :key="item.key">
            <v-card v-if="item.key === 'visible_columns'">
              <v-alert color="info" icon="fa-lightbulb" outlined
                >If you do not want to show all columns / cargo properties you can click on each
                chip below to hide those columns.</v-alert
              >

              <v-tooltip bottom v-for="column in defaultColumns" :key="column.key">
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-on="on"
                    @click="toggleColumn(column.key)"
                    class="ma-2"
                    label
                    :color="hiddenColumns.includes(column.key) ? 'gray' : 'primary'">
                    {{ column.text }}
                  </v-chip>
                </template>
                <span>{{ column.desc }}</span>
              </v-tooltip>
            </v-card>
            <v-card flat v-else-if="item.key === 'custom_columns'" :disabled="!canEdit">
              <v-alert color="info" icon="fa-lightbulb" outlined>
                <ul>
                  <li>
                    Here you can add extra metadata fields which will also be shown in the load
                    plan, like item specific information, purchase orders etc
                  </li>
                  <li v-if="!isCompanySettings">
                    Due to the functionality of custom columns they are always shared within a
                    company
                  </li>
                </ul></v-alert
              >

              <v-alert type="error" v-if="!canEdit">
                Only users with Editor role can do modify custom columns
              </v-alert>

              <v-simple-table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Values</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in customColumns" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.desc }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ (item.values || []).join(', ') }}</td>
                    <td>
                      <v-btn icon color="red" :disabled="!canEdit" @click="removeColumn(index)"
                        ><v-icon>mdi-minus-circle</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-alert v-if="!customColumns.length" color="info" icon="mdi-information" outlined
                >No fields added
              </v-alert>
              <v-btn class="primary" @click="showAddNewColumnDialog = true"
                ><v-icon>mdi-plus</v-icon>Add column</v-btn
              >
            </v-card>
            <v-card flat v-else-if="item.key === 'data_defaults'">
              <v-alert type="info" outlined
                >Set default values for data input in 1. Data view</v-alert
              >
              <v-row>
                <v-col v-for="i in defaultsSelection" :key="i.key" cols="6">
                  <v-checkbox
                    v-if="i.input === 'checkbox'"
                    :label="i.text"
                    v-model="defaultInputItem[i.key]"></v-checkbox>
                  <v-select
                    v-else-if="i.input === 'select'"
                    :items="i.values"
                    item-value="key"
                    :label="i.text"
                    v-model="defaultInputItem[i.key]">
                  </v-select>
                  <orientation-picker
                    v-else-if="i.input === 'orientations_picker'"
                    :item="{}"
                    v-model="defaultInputItem[i.key]"></orientation-picker>
                  <v-text-field
                    v-else-if="i.type === 'string'"
                    :label="i.text"
                    type="text"
                    v-model="defaultInputItem[i.key]"></v-text-field>
                  <v-text-field
                    v-else
                    :label="i.text"
                    type="number"
                    v-model.number="defaultInputItem[i.key]"></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn v-if="tab === 2" class="primary" @click.stop="updateDefaultValues">Update</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showAddNewColumnDialog">
      <v-card>
        <v-card-title>Add new column</v-card-title>
        <v-card-text>
          <v-form ref="columnForm" v-model="validColumnForm" lazy-validation :disabled="!canEdit">
            <v-row align="center" justify="center">
              <v-col cols="2">
                <v-text-field
                  v-model="newColumn.name"
                  label="Column name"
                  :rules="columnNameRules"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="newColumn.desc" label="Description"></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-select v-model="newColumn.type" :items="customColumnTypes"> </v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :rules="columnValueRules"
                  v-if="newColumn.type === 'select'"
                  :value="newColumn.values.join(',')"
                  @input="newColumn.values = $event.split(',')"
                  label="Values (comma separated values)"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showAddNewColumnDialog = false">Close</v-btn>
          <v-spacer />
          <v-btn class="primary" :disabled="!canEdit" @click.stop="addColumn()"
            ><v-icon>mdi-plus</v-icon>Add column</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import ItemProperties, { CustomItemProperty, ItemProperty } from '@/misc/itemProperties';
import { CompanySerializer, CompanySettings, User } from '@/models/UserCompanyModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { getSerializerError } from '@/misc/errorUtils';
import { HoldInputItem } from '@/models/LoadlistModel';
import OrientationPicker from '@/components/Custom/OrientationPicker.vue';

export default Vue.extend({
  components: {
    OrientationPicker,
  },
  props: {
    visible: Boolean,
    isCompanySettings: {
      type: Boolean as PropType<Boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapStores(useMiscStore),
    tabs(): { key: string; text: string }[] {
      return [
        { key: 'visible_columns', text: 'Visible Columns' },
        { key: 'custom_columns', text: 'Custom columns' },
        { key: 'data_defaults', text: 'Default values' },
      ];
    },
    customColumnTypes(): { value: string; text: string }[] {
      return [
        { value: 'checkbox', text: 'Checkbox' },
        { value: 'text', text: 'Text' },
        { value: 'number', text: 'Number' },
        { value: 'select', text: 'Dropdown' },
      ];
    },
    defaultColumns(): ItemProperty[] {
      return ItemProperties.props().filter((i) => !i.required && !i.readOnly && !i.additional);
    },
    preferences(): CompanySettings {
      if (this.isCompanySettings) return this.miscStore.company_settings;
      return this.miscStore.preferences;
    },
    companySettings(): CompanySettings {
      return this.miscStore.company_settings;
    },
    company(): CompanySerializer {
      return this.miscStore.company;
    },
    canEdit(): boolean {
      return this.miscStore.user.is_editor || this.miscStore.user.is_admin;
    },
    user(): User {
      return this.miscStore.user;
    },

    defaultsSelection(): ItemProperty[] {
      return ItemProperties.props()
        .filter((i) => !i.additional && !i.readOnly)
        .filter(
          (i) =>
            ![
              'sku',
              'l',
              'w',
              'h',
              'wt',
              'color',
              'allowed_containers',
              'label',
              'destination',
              'class_id',
              'priority',
              'bundling',
            ].includes(i.key)
        )
        .sort((a, b) => {
          if (a.input < b.input) return -1;
          if (a.input > b.input) return 1;
          return 0;
        });
    },
  },
  watch: {
    preferences: {
      handler(val: CompanySettings): void {
        if (val?.hidden_input_columns)
          this.hiddenColumns = JSON.parse(JSON.stringify(val.hidden_input_columns));
        if (val?.default_input_row)
          this.$set(this, 'defaultInputItem', JSON.parse(JSON.stringify(val.default_input_row)));
      },
      immediate: true,
    },
    companySettings: {
      handler(val: CompanySettings): void {
        if (val?.extra_columns) this.customColumns = JSON.parse(JSON.stringify(val.extra_columns));
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      validColumnForm: false,
      showSnackbar: false,
      snackbarMessage: '',
      showAddNewColumnDialog: false,
      tab: null as number,
      customColumns: [] as CustomItemProperty[],
      hiddenColumns: [] as string[],
      newColumn: {
        name: '',
        type: 'text',
        desc: '',
        values: [],
      } as CustomItemProperty,
      defaultInputItem: { qty: 1 } as HoldInputItem,
      columnNameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => (v && v.length <= 20) || 'Name must be less than 20 characters',
        (v: string) =>
          (v && !this.$data.customColumns.map((i: CustomItemProperty) => i.name).includes(v)) ||
          'Name is already taken',
        (v: string) =>
          (v &&
            !ItemProperties.props()
              .map((i) => i.key)
              .includes(v)) ||
          'Reserved name',
      ],
      columnValueRules: [
        (v: string) => (v && v.split(',').length > 1) || 'At least one value is required',
        (v: string) =>
          (v && new Set(v.split(',')).size === v.split(',').length) ||
          'Can only have unique values',
      ],
    };
  },
  methods: {
    updateMethod(data: CompanySettings): Promise<undefined> {
      if (this.isCompanySettings) {
        return this.miscStore.updateCompanySettings(data);
      }
      return this.miscStore.updateMe({
        preferences: data,
      });
    },
    updateDefaultValues(): void {
      this.updateMethod({ default_input_row: this.defaultInputItem })
        .then((_) => {
          this.snackbarMessage = 'Settings updated';
          this.showSnackbar = true;
        })
        .catch((e) => {
          this.snackbarMessage = getSerializerError(e.response.data);
          this.showSnackbar = true;
        });
    },

    toggleColumn(key: string): void {
      const index = this.hiddenColumns.indexOf(key);
      if (index >= 0) {
        this.hiddenColumns.splice(index, 1);
      } else {
        this.hiddenColumns.push(key);
      }
      this.updateMethod({
        hidden_input_columns: this.hiddenColumns,
      })
        .then((_) => {
          this.showAddNewColumnDialog = false;
          this.snackbarMessage = 'Settings updated';
          this.showSnackbar = true;
        })
        .catch((e) => {
          this.snackbarMessage = getSerializerError(e.response.data);
          this.showSnackbar = true;
        });
    },
    updateCustomColumns(columns: CustomItemProperty[]): void {
      this.miscStore
        .updateCompanySettings({
          extra_columns: columns,
        })
        .then((_) => {
          this.snackbarMessage = 'Settings updated';
          this.showSnackbar = true;
          this.showAddNewColumnDialog = false;
          this.newColumn = {
            name: '',
            type: 'text',
            desc: '',
            values: [],
          };
        })
        .catch((e) => {
          this.snackbarMessage = getSerializerError(e.response.data);
          this.showSnackbar = true;
        });
    },
    addColumn(): void {
      if ((this.$refs.columnForm as any).validate()) {
        this.updateCustomColumns([...this.customColumns, this.newColumn]);
      }
    },
    removeColumn(index: number): void {
      this.customColumns.splice(index, 1);
      this.updateCustomColumns(this.customColumns);
    },
  },
});
</script>

<style></style>
