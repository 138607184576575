<template>
  <v-container>
    <settings-component></settings-component>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import SettingsComponent from '../Custom/Settings.vue';
export default Vue.extend({
  name: 'profile_general_settings',
  components: { SettingsComponent },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
