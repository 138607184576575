<template>
  <svg
    v-if="icon == 'airplane'"
    height="512"
    viewBox="0 0 64 64"
    width="512"
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg">
    <g id="outline">
      <path
        d="m40.65 44.66h-15a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-12a1 1 0 0 0 -1-1zm-1 12h-13v-10h5.5v1a1 1 0 0 0 2 0v-1h5.5z" />
      <path
        d="m58.65 44.66h-15a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-12a1 1 0 0 0 -1-1zm-1 12h-13v-10h5.5v1a1 1 0 0 0 2 0v-1h5.5z" />
      <path
        d="m58.65 29.66h-15a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-12a1 1 0 0 0 -1-1zm-1 12h-13v-10h5.5v1a1 1 0 0 0 2 0v-1h5.5z" />
      <path
        d="m43.52 27.63a1 1 0 0 0 .71-1.23l-.88-3.29 3-2.53.08-.07a19.15 19.15 0 0 0 4.37-7.4 18.94 18.94 0 0 0 .85-6.78 1 1 0 0 0 -.92-.94 18.42 18.42 0 0 0 -6.7.75 18.71 18.71 0 0 0 -7.57 4.54l-2.54 3-5.92-1.61a1 1 0 0 0 0-1.41 1 1 0 0 0 -1.42 0l-.7.7a1.53 1.53 0 0 0 -.11.16l-4.5-1.21.36-.36a1 1 0 0 0 0-1.42 1 1 0 0 0 -1.41 0l-1.22 1.16-8.61-2.3a1 1 0 0 0 -1.13.46l-2.33 4.15a1 1 0 0 0 -.11.76 1 1 0 0 0 .48.61l18.08 10.3-10 11.7-8.54-.91a1 1 0 0 0 -.93.43l-1.39 2.02a1 1 0 0 0 -.13.84 1 1 0 0 0 .55.64l7.5 3.35 2.83 2.83 3.35 7.5a1 1 0 0 0 .64.55.84.84 0 0 0 .27 0 1.06 1.06 0 0 0 .57-.17l2.07-1.41a1 1 0 0 0 .43-.94l-.91-8.52 9.63-8.23 2.06-1.76 5.4 9.48a1 1 0 0 0 1.37.37 1 1 0 0 0 .37-1.36l-5.59-9.78 6.73-5.75.64 2.37a1 1 0 0 0 1.22.71zm-34.36-15.55 1.49-2.56 21.8 5.82-5.75 6.74zm32.41 9.92-8.57 7.31-3 2.55-10 8.58a1 1 0 0 0 -.34.87l.9 8.45-.59.4-2.97-6.58a1 1 0 0 0 -.21-.3l-3.09-3.09a1 1 0 0 0 -.29-.2l-6.56-2.94.4-.59 8.46.9a1 1 0 0 0 .87-.34l18.42-21.61 2.86-3.34a16.5 16.5 0 0 1 6.69-4 16.12 16.12 0 0 1 5.07-.72 16.8 16.8 0 0 1 -.79 5.17 17 17 0 0 1 -3.83 6.57z" />
      <path d="m40.07 18.36-10.61 9.19a1 1 0 0 0 1.31 1.51l10.61-9.19a1 1 0 0 0 -1.31-1.51z" />
      <path
        d="m18.83 36.77-2 2a1 1 0 0 0 0 1.41 1 1 0 0 0 .7.3 1 1 0 0 0 .71-.3l2-2a1 1 0 0 0 -1.41-1.41z" />
    </g>
  </svg>

  <svg
    v-else-if="icon == 'container'"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 480 480"
    style="enable-background: new 0 0 480 480"
    xml:space="preserve"
    class="custom-icon">
    <g>
      <g>
        <path
          d="M121.08,96.108l-2.448-0.328l-2.184,1.088l-112,56L0,155.092v178.216l4.848,2.08L59.4,358.764l59.4,25.456L480,343.18
			V145.052L121.08,96.108z M48,336.476l-32-13.712V164.98l32-16V336.476z M112,363.9l-48-20.568V140.98l48-24V363.9z M464,328.892
			l-336,38.184v-253.88l336,45.824V328.892z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M32,208.036c-4.418,0-8,3.582-8,8v56c0,4.418,3.582,8,8,8s8-3.582,8-8v-56C40,211.618,36.418,208.036,32,208.036z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M88,208.036c-4.418,0-8,3.582-8,8v64c0,4.418,3.582,8,8,8s8-3.582,8-8v-64C96,211.618,92.418,208.036,88,208.036z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M440.88,168.084l-288-32c-4.391-0.486-8.345,2.68-8.831,7.071c-0.032,0.292-0.049,0.586-0.049,0.881v192
			c0,2.238,0.937,4.373,2.584,5.888c1.477,1.358,3.41,2.112,5.416,2.112c0.221,0.012,0.443,0.012,0.664,0l288-24
			c4.157-0.346,7.35-3.829,7.336-8v-136C448,171.958,444.933,168.533,440.88,168.084z M192,324.676l-32,2.664V152.972l32,3.56
			V324.676z M240,320.676l-32,2.664V158.308l32,3.552V320.676z M288,316.676l-32,2.664V163.644l32,3.552V316.676z M336,312.676
			l-32,2.664v-146.4l32,3.56V312.676z M384,308.676l-32,2.664V174.276l32,3.56V308.676z M432,304.676l-32,2.664V179.644l32,3.56
			V304.676z" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <svg
    v-else-if="icon == 'truck'"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    style="enable-background: new 0 0 511.999 511.999"
    xml:space="preserve"
    class="custom-icon">
    <g>
      <g>
        <path
          d="M95.758,344.692h-0.081c-4.142,0-7.459,3.358-7.459,7.5c0,4.142,3.398,7.5,7.54,7.5c4.142,0,7.5-3.358,7.5-7.5
			C103.258,348.05,99.9,344.692,95.758,344.692z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M159.887,344.692h-0.08c-4.142,0-7.46,3.358-7.46,7.5c0,4.142,3.398,7.5,7.54,7.5c4.142,0,7.5-3.358,7.5-7.5
			C167.387,348.05,164.029,344.692,159.887,344.692z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M424.419,344.692h-0.08c-4.142,0-7.46,3.358-7.46,7.5c0,4.142,3.398,7.5,7.54,7.5c4.142,0,7.5-3.358,7.5-7.5
			C431.919,348.05,428.561,344.692,424.419,344.692z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M505.107,254.016l-10.084-10.084l-20.315-60.946c3.031-0.963,5.228-3.798,5.228-7.147c0-4.142-3.358-7.5-7.5-7.5h-72.661
			v-32.578c0-8.556-6.96-15.516-15.516-15.516H15.516C6.96,120.245,0,127.206,0,135.761v176.354c0,8.556,6.96,15.516,15.516,15.516
			h8.532v16.544c0,8.556,6.96,15.516,15.516,15.516h17.271c3.516,18.24,19.591,32.064,38.842,32.064
			c13.182,0,24.871-6.486,32.064-16.428c7.194,9.942,18.883,16.428,32.065,16.428c19.251,0,35.327-13.824,38.842-32.064h186.848
			c3.516,18.24,19.591,32.064,38.842,32.064c21.816,0,39.564-17.749,39.564-39.564c0-0.172-0.011-0.341-0.013-0.512h32.594
			c8.556,0,15.516-6.96,15.516-15.516v-65.508C512,264.37,509.552,258.461,505.107,254.016z M56.835,344.692H39.564
			c-0.285,0-0.516-0.231-0.516-0.516v-16.544h25.645C60.813,332.518,58.06,338.334,56.835,344.692z M95.677,376.757
			c-13.545,0-24.564-11.02-24.564-24.564c0-13.493,10.937-24.477,24.411-24.561h0.308c13.474,0.083,24.411,11.067,24.411,24.561
			C120.242,365.737,109.222,376.757,95.677,376.757z M159.807,376.757c-13.545,0-24.565-11.02-24.565-24.564
			c0-13.493,10.937-24.477,24.411-24.561h0.308c13.474,0.083,24.411,11.067,24.411,24.561
			C184.371,365.737,173.352,376.757,159.807,376.757z M385.497,344.692H198.649c-1.225-6.358-3.978-12.175-7.859-17.061h145.371
			c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H15.516c-0.285,0-0.516-0.231-0.516-0.516V135.761
			c0-0.285,0.231-0.516,0.516-0.516h368.742c0.285,0,0.516,0.231,0.516,0.516v176.871h-16.545c-4.142,0-7.5,3.358-7.5,7.5
			c0,4.142,3.358,7.5,7.5,7.5h25.126C389.474,332.518,386.722,338.334,385.497,344.692z M480.734,248.499h-48.379
			c-0.285,0-0.516-0.231-0.516-0.516v-32.581h37.863L480.734,248.499z M424.339,376.757c-13.545,0-24.564-11.02-24.564-24.564
			c0-13.493,10.937-24.477,24.411-24.561h0.154c0.017,0,0.034-0.002,0.052-0.003c13.521,0.028,24.513,11.036,24.513,24.563
			C448.903,365.737,437.884,376.757,424.339,376.757z M497,336.165c0,0.285-0.231,0.516-0.516,0.516h-35.752
			c-6.045-14.127-20.08-24.052-36.393-24.052c-0.052,0-0.102,0.004-0.154,0.004h-24.41V183.858v-0.004
			c0-0.285,0.231-0.516,0.516-0.516h58.723l5.688,17.064h-40.363c-4.142,0-7.5,3.358-7.5,7.5v40.081
			c0,8.556,6.96,15.516,15.516,15.516h61.022l1.124,1.124c1.611,1.612,2.499,3.754,2.499,6.033v17.928h-8.532
			c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5H497V336.165z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M360.209,280.567H39.564c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h320.645c4.142,0,7.5-3.358,7.5-7.5
			C367.709,283.925,364.351,280.567,360.209,280.567z" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>

  <svg
    v-else-if="icon == 'pallet'"
    height="512"
    viewBox="0 0 64 64"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon">
    <g id="outline">
      <path
        d="m61.955 52.7-5-16a1 1 0 0 0 -.955-.7h-1v-23a.993.993 0 0 0 -.075-.37l-4-10a1 1 0 0 0 -.925-.63h-36a1 1 0 0 0 -.929.628l-4 10a.993.993 0 0 0 -.071.372v23h-1a1 1 0 0 0 -.955.7l-5 16a1.018 1.018 0 0 0 -.007.111.913.913 0 0 0 -.038.189v8a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3h18v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3h18v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-8a.913.913 0 0 0 -.038-.187 1.018 1.018 0 0 0 -.007-.113zm-57.955 1.3h56v2h-56zm5-10h-2.14l.625-2h1.515zm0 2v2h-3.39l.625-2zm43.523-34h-19.523v-3h18.323zm.477 20h-20v-18h20zm2 10h1.515l.625 2h-2.14zm0 4h2.765l.625 2h-3.39zm0 4h4.015l.625 2h-4.64zm-2 2h-20v-18h20zm-22-40h-19.523l1.2-3h18.323zm0 2v18h-20v-18zm0 38h-20v-18h20zm24.265-14 .625 2h-.89v-2zm-4.742-31h-17.523v-3h16.323zm-35.846-3h16.323v3h-17.523zm-5.677 34v2h-.89l.625-2zm-4 11.953v.047h4v2h-4.64zm3 10.047h-4v-2h4zm26 0h-4v-2h4zm22 0v-2h4v2z" />
      <path
        d="m14 23h6a1 1 0 0 0 1-1v-5a1 1 0 0 0 -1-1h-6a1 1 0 0 0 -1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4z" />
      <path
        d="m36 23h6a1 1 0 0 0 1-1v-5a1 1 0 0 0 -1-1h-6a1 1 0 0 0 -1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4z" />
      <path
        d="m14 43h6a1 1 0 0 0 1-1v-5a1 1 0 0 0 -1-1h-6a1 1 0 0 0 -1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4z" />
      <path
        d="m36 43h6a1 1 0 0 0 1-1v-5a1 1 0 0 0 -1-1h-6a1 1 0 0 0 -1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4z" />
      <path d="m25 25h4v2h-4z" />
      <path d="m25 28h4v2h-4z" />
      <path d="m25 45h4v2h-4z" />
      <path d="m25 48h4v2h-4z" />
      <path d="m47 45h4v2h-4z" />
      <path d="m47 48h4v2h-4z" />
      <path d="m47 25h4v2h-4z" />
      <path d="m47 28h4v2h-4z" />
    </g>
  </svg>

  <svg
    v-else-if="icon == 'cog'"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_2)">
      <circle cx="256" cy="256" r="240" stroke="black" stroke-width="32" class="stroke-icon" />
      <mask
        id="mask0_1_2"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="64"
        y="64"
        width="384"
        height="384">
        <circle cx="256" cy="256" r="192" fill="black" />
      </mask>
      <g mask="url(#mask0_1_2)">
        <rect x="256" width="256" height="256" class="custom-icon" fill="black" />
        <rect y="256" width="256" height="256" class="custom-icon" fill="black" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'icon',
  props: {
    icon: String,
  },
  mounted() {},
});
</script>

<style scoped>
.custom-icon {
  fill: currentColor;
}
.stroke-icon {
  stroke: currentColor;
}
</style>
