import { render, staticRenderFns } from "./Quotation.vue?vue&type=template&id=66f6432e&scoped=true"
import script from "./Quotation.vue?vue&type=script&lang=ts"
export * from "./Quotation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f6432e",
  null
  
)

export default component.exports