import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VContainer,{attrs:{"fluid":""}},[(!_vm.is_authenticated)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(" This "+_vm._s(_vm.typeName)+" is read-only ")]):_vm._e(),(!_vm.user.is_editor)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(" This "+_vm._s(_vm.typeName)+" is read-only. Only users with Editor role can do modifications ")]):(!_vm.isEditable)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(" This "+_vm._s(_vm.typeName)+" is read-only. To do any modifications - click on the \"Copy\" button below ")]):_vm._e(),_c(VToolbar,{staticClass:"mb-8",staticStyle:{"z-index":"1"},attrs:{"short":"","elevation":"1","rounded":""}},[_c(VToolbarItems,{staticStyle:{"width":"100%"}},[_c(VForm,{ref:"builderform",staticClass:"mt-2",staticStyle:{"width":"400px"},attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[(_vm.currentHold)?_c(VTextField,{attrs:{"label":"Name","dense":"","outlined":"","rules":_vm.nameRules,"required":"","disabled":!_vm.isEditable},model:{value:(_vm.currentHold.name),callback:function ($$v) {_vm.$set(_vm.currentHold, "name", $$v)},expression:"currentHold.name"}}):_vm._e()],1),_c(VSpacer),(_vm.isEditable)?_c(VBtn,{attrs:{"elevation":"","text":"","color":"green","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":_vm.onSubmit}},[_c(VIcon,[_vm._v("mdi-content-save")]),_vm._v("Save")],1):_vm._e(),(_vm.currentHold && _vm.currentHold.id)?_c(VBtn,{attrs:{"elevation":"","text":"","color":"primary","disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":function($event){_vm.copyName = `${_vm.currentHold.name} (copy)`;
            _vm.showCopyModal = true;}}},[_c(VIcon,[_vm._v("mdi-content-copy")]),_vm._v("Copy")],1):_vm._e(),(_vm.isEditable && _vm.currentHold && _vm.currentHold.id)?_c(VBtn,{attrs:{"elevation":"","text":"","color":"error","disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":function($event){_vm.showDeleteModal = true}}},[_c(VIcon,[_vm._v("mdi-delete")]),_vm._v("Delete")],1):_vm._e()],1)],1),_c('container-editor',{attrs:{"base_type":_vm.base_type},model:{value:(_vm.currentHold.data),callback:function ($$v) {_vm.$set(_vm.currentHold, "data", $$v)},expression:"currentHold.data"}})],1),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.showCopyModal),callback:function ($$v) {_vm.showCopyModal=$$v},expression:"showCopyModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Copy")]),_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"label":"New name:","required":"","rules":_vm.nameRules,"counter":100},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.copyHold()}},model:{value:(_vm.copyName),callback:function ($$v) {_vm.copyName=$$v},expression:"copyName"}})],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.showCopyModal = false}}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.copyHold();
            _vm.showCopyModal = false;}}},[_vm._v("Make copy")])],1)],1)],1),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.showDeleteModal),callback:function ($$v) {_vm.showDeleteModal=$$v},expression:"showDeleteModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Delete this?")]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.showDeleteModal = false}}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();_vm.deleteHold();
            _vm.showDeleteModal = false;}}},[_vm._v("Yes, I am sure")])],1)],1)],1),_c(VDialog,{model:{value:(_vm.showSupportModal),callback:function ($$v) {_vm.showSupportModal=$$v},expression:"showSupportModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Help")]),_c(VCardText,[_c('p',[_vm._v(" Creating or editing a "+_vm._s(_vm.typeName)+" can be a bit tricky. If you would like to add one not found in the Library and find it hard to create one - just drop an email to: "),_c('a',{attrs:{"href":`mailto:support@cargo-planner.com?subject=Please add this ${_vm.typeName} for us`}},[_vm._v("support@cargo-planner.com")]),_vm._v(" with the dimensions and specifications and we will try to create it for you. ")])]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.showSupportModal = false}}},[_vm._v("Okay")])],1)],1)],1),_c(VSnackbar,{attrs:{"timeout":5000,"top":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.error_message)+" "),_c(VBtn,{attrs:{"text":"","color":"primary"},nativeOn:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("Ok")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }