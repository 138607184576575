import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"loading":_vm.isLoading}},[_c(VCardTitle,{staticClass:"text-h5",attrs:{"primary-title":""}},[_vm._v("Copy loadlist")]),_c(VCardText,[_c(VForm,{model:{value:(_vm.newLoadlistValid),callback:function ($$v) {_vm.newLoadlistValid=$$v},expression:"newLoadlistValid"}},[_c(VTextField,{attrs:{"label":"Name of copy","rules":_vm.nameRules,"required":""},model:{value:(_vm.newLoadlist.name),callback:function ($$v) {_vm.$set(_vm.newLoadlist, "name", $$v)},expression:"newLoadlist.name"}}),_c(VSelect,{attrs:{"items":_vm.$list_types,"required":"","label":"Select type of loadlist for copy"},model:{value:(_vm.newLoadlist.list_type),callback:function ($$v) {_vm.$set(_vm.newLoadlist, "list_type", $$v)},expression:"newLoadlist.list_type"}}),_c(VTextarea,{attrs:{"label":"Notes","outlined":""},model:{value:(_vm.newLoadlist.notes),callback:function ($$v) {_vm.$set(_vm.newLoadlist, "notes", $$v)},expression:"newLoadlist.notes"}})],1),_c(VCheckbox,{attrs:{"label":"Also copy loadplan(s)","hide-details":""},model:{value:(_vm.newLoadlist.with_loadplan),callback:function ($$v) {_vm.$set(_vm.newLoadlist, "with_loadplan", $$v)},expression:"newLoadlist.with_loadplan"}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close', false)}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.newLoadlistValid},on:{"click":function($event){$event.stopPropagation();return _vm.copyList()}}},[_vm._v("Make copy")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }