import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VDialog,{attrs:{"width":"410"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Payment method")]),_c(VCardText,{staticClass:"pb-0"},[_c('p',[_vm._v("Please select your preferred payment method.")]),_c('div',[_c(VBtn,{staticClass:"primary",on:{"click":function($event){return _vm.$emit('useCredit')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-credit-card")]),_vm._v(" Credit card ")],1)],1),_c('div',[_c(VBtn,{staticClass:"primary display-block mt-4",on:{"click":function($event){_vm.showBankTransferModal = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-bank")]),_vm._v(" Bank transfer ")],1)],1)]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Close")])],1)],1)],1),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.showBankTransferModal),callback:function ($$v) {_vm.showBankTransferModal=$$v},expression:"showBankTransferModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Bank transfer")]),_c(VCardText,{staticClass:"pb-0"},[_c('p',[_vm._v(" If you want us to issue an invoice payable through bank-transfer - drop us an email with your company details to "),_c('a',{attrs:{"href":_vm.bankTransferEmailTemplate}},[_vm._v("support@cargo-planner.com")]),_vm._v(". ")]),_c('p',[_vm._v("Once the request has been sent, we will get back to you as soon as possible.")])]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.showBankTransferModal = false}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{staticClass:"primary ml-auto",attrs:{"href":_vm.bankTransferEmailTemplate}},[_vm._v(" Send Email ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }