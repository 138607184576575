<template>
  <div>
    <v-text-field v-if="editableName" v-model="modified_hold.name" label="Name"></v-text-field>
    <div>
      <v-row v-if="!hideQtyCost">
        <v-col cols="12" class="d-flex justify-center">
          <slot name="view" v-bind="{ renderKey }"> </slot>
          <!-- <scene-component
          :hold-object="hold"
          :canvas-width="200"
          :canvas-height="100"
          :key="renderKey"
        ></scene-component> -->
        </v-col>
        <v-col>
          <v-text-field
            label="Quantity:"
            type="number"
            :hint="`Number of ${typeName}s available`"
            min="0"
            :value="modified_hold.qty"
            @input="setQty($event)"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Cost [$, €, £, ...]:"
            type="number"
            :hint="`A number, relative to other ${typeName}s`"
            :value="modified_hold.cost"
            @change="$set(modified_hold, 'cost', parseFloat($event) || null)"></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!hideDimensions">
        <v-col cols="4">
          <length-input-field
            label="Length"
            :min="0"
            :value="modified_hold.L"
            @change="
              modified_hold.L = $event !== null ? $event : modified_hold.L
            "></length-input-field>
        </v-col>
        <v-col cols="4">
          <length-input-field
            label="Width"
            :min="0"
            :value="modified_hold.W"
            @change="
              modified_hold.W = $event === null ? modified_hold.W : $event
            "></length-input-field>
        </v-col>
        <v-col cols="4">
          <length-input-field
            label="Height"
            :disabled="
              modified_hold.no_side_walls && modified_hold.no_end_walls && modified_hold.no_roof
            "
            :min="0"
            v-model="modified_hold.H"
            @change="
              modified_hold.H = $event === null ? modified_hold.H : $event
            "></length-input-field>
        </v-col>
      </v-row>

      <div v-if="basetype === 'ROAD' && !hideAxles">
        <template
          v-if="
            modified_hold.axles &&
            !isNaN(modified_hold.axles.front_axle_x) &&
            !isNaN(modified_hold.axles.rear_axle_x)
          ">
          <p class="py-0 my-0 text-subtitle-1">Axle group weight limitations:</p>
          <v-row>
            <v-col>
              <weight-input-field
                :label="`Front ${modified_hold.tare ? '' : '(net)'}`"
                :rules="[
                  (v) =>
                    (modified_hold.tare || 0) <
                      (v || 0) + (modified_hold.axles.rear_axle_max_weight || 0) ||
                    'Axle weights is less than tare',
                ]"
                :min="0"
                placemodified_holder="Weight"
                :value="modified_hold.axles.front_axle_max_weight"
                @change="$set(modified_hold.axles, 'front_axle_max_weight', $event || null)"
                persistent-hint></weight-input-field>
            </v-col>
            <v-col>
              <weight-input-field
                :label="`Rear ${modified_hold.tare ? '' : ' (net)'}`"
                :min="0"
                :rules="[
                  (v) => {
                    return (
                      (modified_hold.tare || 0) <
                        (v || 0) + (modified_hold.axles.front_axle_max_weight || 0) ||
                      `Axle weights is less than tare`
                    );
                  },
                ]"
                placemodified_holder="Weight"
                :value="modified_hold.axles.rear_axle_max_weight"
                @change="$set(modified_hold.axles, 'rear_axle_max_weight', $event || null)"
                persistent-hint></weight-input-field> </v-col
          ></v-row>
        </template>
      </div>
      <v-row v-if="basetype === 'AIR' && !hideBottomSpacer"
        ><v-col cols="12">
          <length-input-field
            label="Auto bottom spacer height"
            :min="0"
            placemodified_holder="Height"
            :value="modified_hold.contours ? modified_hold.contours.auto_bottom_spacer_h : null"
            @change="
              $set(modified_hold, 'contours', modified_hold.contours || {});
              $set(modified_hold.contours, 'auto_bottom_spacer_h', $event || null);
            "
            hint="The tool will check if more cargoes can be loaded by raising the floor to specified height"></length-input-field> </v-col
      ></v-row>

      <div v-if="!hideMaxValues">
        <v-row>
          <v-col>
            <length-input-field
              label="Max Length"
              :min="0"
              :value="modified_hold.max_length"
              @change="$set(modified_hold, 'max_length', $event || null)"></length-input-field>
          </v-col>
          <v-col>
            <length-input-field
              label="Max Width"
              :min="0"
              :value="modified_hold.max_width"
              @change="$set(modified_hold, 'max_width', $event || null)"></length-input-field>
          </v-col>
          <v-col>
            <length-input-field
              label="Max Height"
              :min="0"
              :value="modified_hold.max_height"
              @change="$set(modified_hold, 'max_height', $event || null)"></length-input-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <weight-input-field
              label="Payload"
              :min="0"
              v-model="modified_hold.payload"></weight-input-field>
          </v-col>
          <v-col>
            <length-input-field
              label="Max Volume"
              :sup="3"
              :min="0"
              :value="modified_hold.max_volume"
              @change="
                $set(modified_hold, 'max_volume', parseFloat($event) || null)
              "></length-input-field>
          </v-col>
        </v-row>
      </div>

      <v-row v-if="basetype === 'ROAD'">
        <v-col>
          <weight-input-field
            label="Legal weight"
            :min="0"
            placemodified_holder="Weight"
            :value="(modified_hold.legal_limits || {}).weight"
            @change="
              $set(modified_hold, 'legal_limits', modified_hold.legal_limits || {});
              $set(modified_hold.legal_limits, 'weight', $event || null);
            "
            :hint="`If one cargo exceeds legal weight then no other items will be co loaded on the same ${typeName}`"></weight-input-field>
        </v-col>
        <v-col>
          <length-input-field
            label="Legal Height"
            :min="0"
            :value="(modified_hold.legal_limits || {}).height"
            @change="
              $set(modified_hold, 'legal_limits', modified_hold.legal_limits || {});
              $set(modified_hold.legal_limits, 'height', $event || null);
            "
            hint="Non-divisible loads can be loaded above the legal height, up to max height"></length-input-field>
        </v-col>
      </v-row>
      <v-row v-if="showSubHoldProps">
        <v-col v-for="prop in subHoldPropertiesList" :key="prop.key" cols="6">
          <v-checkbox
            v-if="prop.input == 'checkbox'"
            :label="prop.text"
            :input-value="modified_hold.properties ? !!modified_hold.properties[prop.key] : false"
            @change="setSubHoldProperty(prop.key, $event)"></v-checkbox>
          <span v-else-if="prop.input === 'orientations_picker'">
            <orientations-picker
              label="Orientations"
              :value="modified_hold.properties ? modified_hold.properties[prop.key] : 3"
              @input="setSubHoldProperty(prop.key, $event)"
              :item="{}"
              flat
              hide-details></orientations-picker>
          </span>
          <v-select
            v-else-if="prop.input === 'select'"
            type="number"
            :items="prop.values"
            item-text="text"
            item-value="key"
            :value="modified_hold.properties ? modified_hold.properties[prop.key] : null"
            @change="setSubHoldProperty(prop.key, $event || undefined)"
            max="100"
            label="Max number of layers"></v-select>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import createHold from '@/misc/containerUtils';
import { HoldData, ListType } from '@/models/LoadlistModel';
import ItemProperties, { ItemProperty } from '@/misc/itemProperties';
import OrientationsPicker from '@/components/Custom/OrientationPicker.vue';

export default Vue.extend({
  name: 'modify-hold',
  components: {
    OrientationsPicker,
  },
  data() {
    return {
      renderKey: 1,
      modified_hold: JSON.parse(JSON.stringify(this.value)) as HoldData,
    };
  },
  props: {
    value: Object as PropType<HoldData>,
    basetype: String as PropType<ListType>,
    editableName: Boolean,
    hideMaxValues: Boolean,
    hideDimensions: Boolean,
    hideAxles: Boolean,
    hideQtyCost: Boolean,
    hideBottomSpacer: Boolean,
    showSubHoldProps: Boolean,
  },
  computed: {
    typeName(): string {
      return this.$typeNames(this.basetype);
    },
    subHoldPropertiesList(): ItemProperty[] {
      return ItemProperties.props().filter((i) => {
        return ['not_stackable', 'bottom_only', 'orientations', 'max_layers'].includes(i.key);
      });
    },
  },
  watch: {
    modified_hold: {
      handler: function (val: HoldData): void {
        const modified_hold = createHold.assemble(JSON.parse(JSON.stringify(val)));
        this.$emit('input', modified_hold);
        this.renderKey++;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setQty($event: any): void {
      const qty = parseInt($event);
      const v = isNaN(qty) ? null : qty;
      this.$set(this.modified_hold, 'qty', v);
    },
    setSubHoldProperty(key: string, value: any) {
      if (!this.modified_hold.properties) {
        this.$set(this.modified_hold, 'properties', {});
      }
      this.$set(this.modified_hold.properties, key, value);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
