<template>
  <div class="align-center">
    <div class="pb-2 d-md-flex align-center justify-start">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-chip :color="matchCount > 0 ? 'primary' : ''" v-on="on">{{
            matchCount >= 0 ? `${matchCount} items selected` : 'Unknown'
          }}</v-chip>
        </template>
        <div v-if="matchCount === 0">No items matched</div>
        <div v-else-if="matchCount > 0">
          <b>Matched Items</b>
          <div v-for="(i, index) in matches" :key="index">
            {{ i.label }} ({{ i.l }} x {{ i.w }} x {{ i.h }} {{ lengthDim }})
          </div>
        </div>
        <div v-else>The number of selected items cannot be known before packing</div>
      </v-tooltip>
    </div>
    <div v-if="selectors.length === 0" class="subtitle-2 d-flex justify-center">
      <v-chip color="success">All Items selected</v-chip>
    </div>
    <div v-for="(selector, selectorIndex) in selectors" :key="selectorIndex">
      <div class="d-md-flex align-center">
        <v-select
          class="mr-md-2 flex-grow-0"
          v-model="selector.scope"
          :items="selector_type_options"
          label="Scope" />
        <v-select
          class="mr-md-2 flex-shrink-1"
          v-model="selector.property"
          :items="properties"
          item-value="key"
          :menu-props="{ maxHeight: '400' }"
          label="Items with"
          @change="(v) => propChange(v, selectorIndex)" />
        <v-select
          class="mr-md-2 flex-shrink-1"
          v-model="selector.comparison"
          :items="isOptionsField[selectorIndex] ? comparisonsShort : comparisons"
          :menu-props="{ maxHeight: '400' }"
          label="Condition" />
        <div class="mr-md-2 flex-grow-1 flex-shrink-1 justify-stretch align-center">
          <v-row>
            <v-col
              v-if="subSelector"
              :cols="!selector.is_dynamic || !isOptionsField[selectorIndex] ? '4' : '12'">
              <v-select
                label="Value Type"
                style="min-width: 50px"
                :items="[
                  { text: 'Fixed value', value: false },
                  isOptionsField[selectorIndex]
                    ? {
                        text: `${selectorProperties[selectorIndex].text} of selected item`,
                        value: true,
                      }
                    : {
                        text: `Percentage of selected item ${selectorProperties[selectorIndex].text}`,
                        value: true,
                      },
                ]"
                @change="
                  (e) => {
                    if (e) {
                      selector.value = 100;
                    }
                  }
                "
                v-model="selector.is_dynamic" />
            </v-col>
            <v-col
              :cols="subSelector ? '8' : '12'"
              v-if="!selector.is_dynamic || !isOptionsField[selectorIndex]">
              <v-slider
                class="t-6"
                style="position: relative; top: 32px"
                v-if="selector.is_dynamic"
                v-model="selector.value"
                thumb-label="always"
                :thumb-size="24"
                :thumb-color="selector.value !== 100 ? 'primary' : 'grey'"
                track-color="light-grey"
                :track-fill-color="selector.value !== 100 ? 'primary' : 'grey'"
                :max="200"
                :min="0"
                step="1"
                ticks="always"
                tick-size="1">
              </v-slider>
              <length-input-field
                v-else-if="selectorProperties[selectorIndex].dimension === 'length'"
                v-model="selector.value"
                :lengthDim="lengthDim"
                label="Value"
                :rules="[(v) => !!v || 'Required']"
                step="10" />
              <weight-input-field
                v-else-if="selectorProperties[selectorIndex].dimension === 'weight'"
                v-model="selector.value"
                :weightDim="weightDim"
                label="Value"
                :rules="[(v) => !!v || 'Required']"
                step="10" />
              <v-select
                v-else-if="isOptionsField[selectorIndex]"
                @change="optionChanged($event, selector)"
                :value="optionSelectorValue[selectorIndex]"
                :items="valueOptions[selectorIndex]"
                label="Value"
                :rules="[(v) => (!!v && !!v.length) || 'Select at least one']"
                multiple>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index < optionSelectorValue[selectorIndex].length - 2"
                    >{{ item.text || item }},&nbsp;</span
                  >
                  <span v-else-if="index === optionSelectorValue[selectorIndex].length - 2"
                    >{{ item.text || item }} or&nbsp;</span
                  >
                  <span v-else>{{ item.text || item }}</span>
                </template>
              </v-select>
              <v-checkbox
                v-else-if="selectorProperties[selectorIndex].type === 'bool'"
                label="True / false"
                v-model="selector.value"></v-checkbox>
              <v-text-field
                v-else-if="selectorProperties[selectorIndex].type === 'string'"
                v-model="selector.value"
                :label="selectorProperties[selectorIndex].text" />
              <v-text-field
                v-else
                v-model.number="selector.value"
                step="10"
                type="number"
                :label="selectorProperties[selectorIndex].text" />
              <!-- </div> -->
            </v-col>
          </v-row>
        </div>

        <div class="py-3 pl-md-2 d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="error"
                @click="
                  () => {
                    selectors.splice(selectorIndex, 1);
                  }
                ">
                <v-icon>mdi-minus-circle</v-icon></v-btn
              >
            </template>
            Remove selector
          </v-tooltip>
        </div>
      </div>
      <div v-if="selectors.length !== selectorIndex + 1" class="d-flex justify-center py-2">
        <v-chip>AND</v-chip>
      </div>
    </div>
    <div class="d-flex align-center justify-end">
      <div class="">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="success" @click="addBlankSelector()">
              <v-icon>mdi-plus-circle</v-icon></v-btn
            >
          </template>
          Add a selector
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import itemProperties, { CustomItemProperty } from '@/misc/itemProperties';
import { HoldInputItem, ItemSelector, LengthDim, Loadlist } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import Vue, { PropType } from 'vue';
import LengthInputField from '@/components/Custom/LengthInputField.vue';
import WeightInputField from '@/components/Custom/WeightInputField.vue';

interface ItemPropertyShort {
  key: string;
  type: string;
  dimension?: string;
  text: string;
  custom?: boolean;
  additional?: boolean;
}

const standardPropsAvailable = [
  'sku',
  'label',
  'l',
  'w',
  'h',
  'wt',
  'qty',
  'not_stackable',
  'bottom_only',
  'max_layers',
  'max_load',
  'geometry',
  'shipment_id',
  'class_id',
  'priority',
  'free_space',
  'destination',
];

export default Vue.extend({
  components: { LengthInputField, WeightInputField },
  name: 'item-selector',
  data: function () {
    return {
      selector_type_options: [
        {
          text: 'Cargo',
          value: 'cargo',
        },
        {
          text: 'Secondary equipment containing',
          value: 'in_secondary',
        },
      ],
      selectors: [
        {
          property: 'label',
          comparison: 'eq',
          value: [],
          is_dynamic: false,
          scope: 'cargo',
        } as ItemSelector,
      ],
    };
  },
  props: {
    items: Array as PropType<HoldInputItem[]>,
    value: [Array, Object] as PropType<ItemSelector | ItemSelector[]>, // string[] | ItemSelector
    class_ids: Array as PropType<string[]>,
    /**
     * Determines if we can have a dynamic value. Example "Can only support items with the same length"
     */
    subSelector: Boolean as PropType<boolean>,
  },
  watch: {
    selectors: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },
  computed: {
    ...mapStores(useLoadlistStore, useMiscStore),
    properties(): ItemPropertyShort[] {
      return [
        // Standard item props
        ...itemProperties
          .props()
          .filter((p) => standardPropsAvailable.includes(p.key))
          .map((p) => {
            return { key: p.key, type: p.type, dimension: p.dimension, text: p.text };
          }),
        // Custom used orientation prop
        {
          key: 'orientation',
          type: 'orientation',
          text: 'Used orientation',
          additional: true,
        },
        // Custom columns
        ...this.extraColumns.map((i) => {
          return {
            key: i.name,
            type: itemProperties.getPropTypeFromMetadata(i.type),
            text: i.name,
            custom: true,
          };
        }),
      ];
    },
    extraColumns(): CustomItemProperty[] {
      return this.miscStore?.company_settings?.extra_columns || [];
    },
    orientationOptions(): { text: string; value: number }[] {
      return [
        { text: 'LxWxH', value: 1 },
        { text: 'WxLxH', value: 2 },
        { text: 'LxHxW', value: 4 },
        { text: 'HxLxW', value: 8 },
        { text: 'HxWxL', value: 16 },
        { text: 'WxHxL', value: 32 },
      ];
    },
    comparisonsShort(): { text: string; value: string }[] {
      return [
        { text: 'Equals', value: 'eq' },
        { text: 'Not equals', value: 'ne' },
      ];
    },
    comparisons(): { text: string; value: string }[] {
      return [
        ...this.comparisonsShort,
        { text: 'Less than', value: 'lt' },
        { text: 'Greater than', value: 'gt' },
      ];
    },
    lengthDim(): string {
      return this.loadlist?.length_dim || this.miscStore.length_dim;
    },
    weightDim(): string {
      return this.loadlist?.weight_dim || this.miscStore.weight_dim;
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    matches(): HoldInputItem[] {
      return this.items.filter((i) =>
        this.selectors.every((selector, selectorIndex) => {
          const property = this.selectorProperties[selectorIndex];

          if (!property || property?.additional) {
            return false;
          }

          let value = null;

          if (property.custom) value = i?.metadata?.[selector.property];
          else {
            const v = i[selector.property as keyof HoldInputItem];
            const dim = property.dimension;
            const converter = dim
              ? (v: number) => v * this.$toSI(dim === 'length' ? this.lengthDim : this.weightDim)
              : (v: any) => v;
            value = converter(v);
          }

          let eq = property.type === 'bool' ? !!selector.value == !!value : selector.value == value;

          if (selector.value instanceof Array) {
            eq = selector.value.includes(value);
          }
          switch (selector.comparison) {
            case 'eq':
              return eq;
            case 'ne':
              return !eq;
            case 'gt':
              return value > selector.value;
            default:
              return value < selector.value;
          }
        })
      );
    },
    matchCount(): number {
      if (
        this.selectors.find((selector) => selector.scope === 'in_secondary' || selector.is_dynamic)
      )
        return -1;
      return this.matches.length;
    },
    valueOptions(): string[] | { text: string; value: number | string }[][] {
      return this.selectors.map((selector, selectorIndex) => {
        if (selector.property === 'orientation') {
          return this.orientationOptions;
        } else if (this.isOptionsField[selectorIndex]) {
          let additional: string[] = [];

          if (selector.property == 'class_id') {
            additional.push(...this.class_ids);
          }
          const is_custom_and_has_values = this.extraColumns.find(
            (i) => i.name === selector.property && i?.values?.length
          );
          if (is_custom_and_has_values) {
            additional.push(...is_custom_and_has_values.values);
          }
          // Return only unique values
          return [
            { value: '', text: 'Empty' },
            ...new Set([
              ...this.items
                .map((v) =>
                  this.selectorProperties[selectorIndex].custom
                    ? v?.metadata?.[selector.property]
                    : (v[selector.property as keyof HoldInputItem] as string)
                )
                .filter((v) => v)
                .sort()
                .filter((v, index, a) => index === 0 || a[index - 1] !== v)
                .map((value) => ({ value, text: value })),
              ...additional.map((value) => ({ value, text: value })),
            ]),
          ];
        }
        return [{ value: '', text: 'Empty' }];
      });
    },
    isOptionsField(): boolean[] {
      /// TODO: this should be based on if there are any options available
      return this.selectors.map((selector, selectorIndex) => {
        return (
          selector.property === 'orientation' ||
          this.selectorProperties[selectorIndex]?.type === 'string'
        );
      });
    },
    selectorProperties(): ItemPropertyShort[] {
      return this.selectors.map((selector) => {
        return this.properties.find((p) => p.key == selector.property);
      });
    },
    optionSelectorValue(): any[] {
      return this.selectors.map((selector) => {
        if (selector.property === 'orientation') {
          return this.orientationOptions
            .map(({ value }) => value)
            .filter((value) => (selector.value & value) > 0);
        }
        return selector.value;
      });
    },
  },
  mounted(): void {
    if (this.value) {
      this.selectors = Array.isArray(this.value) ? this.value : [this.value];
    }
  },
  methods: {
    addBlankSelector(): void {
      this.selectors.push({
        property: 'label',
        comparison: 'eq',
        value: [],
        is_dynamic: false,
        scope: 'cargo',
      });
    },

    optionChanged(val: string[] | number[], selector: ItemSelector): void {
      if (selector.property === 'orientation') {
        selector.value = val.map((v) => v as number).reduce((a, b) => a + b, 0);
      } else {
        selector.value = val;
      }
    },
    propChange(v: any, selectorIndex: number): void {
      if (this.isOptionsField[selectorIndex]) {
        this.selectors[selectorIndex].value = [];
      } else {
        this.selectors[selectorIndex].value = 0;
      }
    },
  },
});
</script>
