<template>
  <v-container>
    <v-alert outlined type="info">
      <p>
        API usage may or may not be included in your current plan. If you expect to do more than 20
        load plans per months, please get in touch with us at
        <a href="mailto:support@cargo-planner.com">support@cargo-planner.com</a>
      </p>
      <p>
        Please have a look at our developer documentation
        <a href="https://cargo-planner.com/docs/developer" target="_blank">here</a>
      </p>
    </v-alert>
    <calculation-stats v-if="showStats" :company="company.id"></calculation-stats>
    <v-expansion-panels v-model="tokenPanel" class="mt-3" v-if="!user.is_sso">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="text-h6">API Keys</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="token in tokens" :key="token.key">
            <v-text-field
              ref="tokenTextField"
              readonly
              outlined
              :value="token.key"
              :type="showToken ? 'text' : 'password'"
              :append-icon="showToken ? 'mdi-eye' : 'mdi-eye-off'"
              append-outer-icon="mdi-delete"
              :label="`API key (created ${token.created})`"
              @click:append="showToken = !showToken"
              @click:append-outer="deleteToken(token.key)">
            </v-text-field>
          </div>

          <v-btn v-if="!tokens.length" @click="createToken">Create token</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="text-h6">Webhooks</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table v-if="webhooks.length">
            <thead>
              <tr>
                <th>Event</th>
                <th>URL</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="webhook in webhooks" :key="webhook.key">
                <td>{{ webhook.event }}</td>
                <td>{{ webhook.url }}</td>
                <td>
                  <v-btn
                    icon
                    @click="
                      selectedWebhook = webhook;
                      showDeleteWebhookDialog = true;
                    "
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    @click="
                      selectedWebhook = webhook;
                      showEditWebhookDialog = true;
                    "
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-btn
            class="mt-2 success"
            :disabled="webhooks.length >= 10"
            @click="
              resetSelectedWebhook();
              showEditWebhookDialog = true;
            "
            ><v-icon>mdi-plus-circle-outline</v-icon>Add</v-btn
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      {{ snackbarText }}
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
    <v-dialog v-model="showEditWebhookDialog" width="600">
      <v-card>
        <v-card-title v-if="selectedWebhook.id" class="text-h5">Edit webhook</v-card-title>
        <v-card-title v-else class="text-h5">Create a webhook</v-card-title>

        <v-card-text>
          <v-select
            :items="eventTypes"
            v-model="selectedWebhook.event"
            label="Event"
            :error-messages="errors.event"></v-select>
          <v-text-field
            v-model="selectedWebhook.url"
            label="URL"
            :error-messages="errors.url"></v-text-field>
          <v-text-field
            v-if="selectedWebhook.id"
            v-model="selectedWebhook.secret"
            readonly
            disabled
            label="Secret"></v-text-field>

          <v-textarea v-model="selectedWebhook.description" label="Description"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="showEditWebhookDialog = false">Close</v-btn>
          <v-spacer />
          <v-btn color="primary" :disabled="loading" :loading="loading" @click.stop="createWebhook">
            Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteWebhookDialog" width="600">
      <v-card>
        <v-card-title class="text-h5">Delete webhook?</v-card-title>
        <v-card-actions>
          <v-btn text @click.stop="showDeleteWebhookDialog = false">Cancel</v-btn>
          <v-spacer />
          <v-btn color="error" :disabled="loading" :loading="loading" @click.stop="deleteWebhook">
            Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import API from '@/API';

import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { CompanyStoreTemplate, User, Webhook } from '@/models/UserCompanyModel';
import CalculationStats from '@/components/Custom/CalculationStats.vue';

interface Token {
  key: string;
  created: string;
}

export default Vue.extend({
  components: {
    CalculationStats,
  },
  data() {
    return {
      loading: false,
      errors: {},
      tokenPanel: null,
      tokens: [] as Token[],
      showToken: false,
      showEditWebhookDialog: false,
      showDeleteWebhookDialog: false,
      webhooks: [] as Webhook[],
      selectedWebhook: {
        description: '',
        event: 'loadlist_saved',
        url: '',
        secret: '',
      } as Webhook,
      showSnackbar: false,
      snackbarText: '',
    };
  },
  watch: {
    tokenPanel: function (val) {
      switch (val) {
        case 0:
          this.getTokens();
          return;
        case 1:
          this.getWebhooks();
          return;
      }
    },
  },
  mounted() {},
  computed: {
    ...mapStores(useMiscStore),
    company(): CompanyStoreTemplate {
      return this.miscStore.company;
    },
    user(): User {
      return this.miscStore.user;
    },
    eventTypes(): { text: string; value: string }[] {
      return [{ text: 'Loadlist saved', value: 'loadlist_saved' }];
    },
    showStats(): boolean {
      return !!this.company?.id;
    },
  },
  methods: {
    getTokens(): void {
      API.getAuthTokens()
        .then((response) => {
          this.tokens = response.data.results;
        })
        .catch((e) => {});
    },
    createToken(): void {
      API.createAuthToken()
        .then((response) => {
          this.getTokens();
        })
        .catch((e) => {});
    },
    deleteToken(key: string): void {
      API.deleteAuthToken(key)
        .then((response) => {
          this.getTokens();
        })
        .catch((e) => {});
    },
    getWebhooks(): void {
      API.getWebhooks()
        .then((response) => {
          this.webhooks = response.data.results;
        })
        .catch((e) => {});
    },
    createWebhook(): void {
      this.errors = {};

      if (this.selectedWebhook.id) {
        API.updateWebhook(this.selectedWebhook)
          .then((response) => {
            this.getWebhooks();
            this.showEditWebhookDialog = false;
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              this.errors = e.response.data;
            }
          });
      } else {
        API.createWebhook(this.selectedWebhook)
          .then((response) => {
            this.getWebhooks();
            this.selectedWebhook = response.data;
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              this.errors = e.response.data;
            }
          });
      }
    },
    deleteWebhook(): void {
      API.deleteWebhook(this.selectedWebhook.id)
        .then((response) => {
          this.getWebhooks();
          this.showDeleteWebhookDialog = false;
        })
        .catch((e) => {});
    },
    resetSelectedWebhook(): void {
      this.selectedWebhook = {
        description: '',
        event: 'loadlist_saved',
        url: '',
        secret: '',
      };
    },
    // copyToken(): void {
    //   this.$refs.tokenTextField.$refs.input.select();
    //   navigator.clipboard.writeText(this.token.key);
    // },
  },
});
</script>

<style scoped>
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
import CalculationStatsVue from './Custom/CalculationStats.vue';
