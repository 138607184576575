<template>
  <v-dialog v-model="show" scrollable persistent width="800">
    <v-card>
      <v-card-title class="text-h5">Privacy Policy</v-card-title>
      <v-card-text>
        <p class="pre">{{ policy }}</p>
      </v-card-text>
      <v-card-actions v-if="!user.accepted_policy">
        <v-checkbox
          label="Check here to indicate that you have read and agree to the terms of our Privacy Policy"
          v-model="hasAcceptedPolicy"></v-checkbox>
      </v-card-actions>
      <v-card-actions>
        <v-btn text :loading="loading" :disabled="loading" @click.stop="update()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { User } from '@/models/UserCompanyModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
export default Vue.extend({
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapStores(useMiscStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    user(): User {
      return this.miscStore.user;
    },
  },
  data() {
    return {
      hasAcceptedPolicy: false,
      policy: '',
      loading: false,
    };
  },
  mounted() {
    API.getPrivacyPolicy()
      .then((response) => {
        this.policy = response.data.data;
      })
      .catch((_) => {});
  },
  methods: {
    update() {
      if (this.hasAcceptedPolicy && !this.user.accepted_policy) {
        this.loading = true;
        API.acceptPrivacyPolicy(this.user).then((response) => {
          this.loading = false;
          this.updateMe();
        });
      }

      this.show = false;
    },
    updateMe(): void {
      this.miscStore.updateMe(null);
    },
  },
  filters: {
    date(timestamp: number) {
      return new Date(timestamp);
    },
  },
});
</script>

<style>
.pre {
  white-space: pre-wrap;
}
</style>
