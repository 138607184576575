import { Currency } from '@/models/LoadlistModel';

export const Currencies: Currency[] = [
  { name: 'Australian Dollar', code: 'AUD', symbol: '$' },
  { name: 'British Pound Sterling', code: 'GBP', symbol: '£' },
  { name: 'Canadian Dollar', code: 'CAD', symbol: '$' },
  { name: 'Chinese Yuan', code: 'CNY', symbol: '¥' },
  { name: 'Euro', code: 'EUR', symbol: '€' },
  { name: 'Hong Kong Dollar', code: 'HKD', symbol: '$' },
  { name: 'Japanese Yen', code: 'JPY', symbol: '¥' },
  { name: 'New Zealand Dollar', code: 'NZD', symbol: '$' },
  { name: 'Swiss Franc', code: 'CHF', symbol: 'CHf' },
  { name: 'US Dollar', code: 'USD', symbol: '$' },
];
