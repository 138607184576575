<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="4">
            <v-form ref="builderform" v-model="validForm" lazy-validation>
              <v-text-field
                class="mr-2"
                label="Name"
                v-model="set.data.name"
                :rules="nameRules"
                required></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="4"
            ><weight-input-field
              class="mr-2"
              v-model="set.data.payload"
              label="Payload"
              persistent-hint
              hint="(Max payload of the entire set)"></weight-input-field
          ></v-col>
          <v-col cols="4">
            <v-text-field
              label="Default Cost"
              type="number"
              persistent-hint
              hint="(A number, relative to other sets)"
              :value="set.data.cost"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-card id="equipmentSelectedHolds" width="100%" flat>
            <v-card-title>
              <span class="text-h6 font-weight-light"> Equipment used in this set</span>
            </v-card-title>

            <v-card-text>
              <v-row
                dense
                align="center"
                class="my-0"
                v-for="(holdDataWithCount, index) in usedContainers"
                :key="index">
                <v-col class="pt-1 py-0">
                  <v-card outlined>
                    <v-card-text class="px-4 py-2">
                      <v-row>
                        <v-col cols="6" class="d-flex align-center">
                          <div class="font-weight-bold">
                            {{ holdDataWithCount.count }}x -
                            {{ holdDataWithCount.holdData.name }}
                          </div>
                        </v-col>
                        <v-col class="d-flex align-center">
                          <span class="ml-4"
                            >{{ holdDataWithCount.holdData.L | toLength(false) }}
                            x
                            {{ holdDataWithCount.holdData.W | toLength(false) }}
                            x
                            {{
                              (holdDataWithCount.holdData.H ||
                                holdDataWithCount.holdData.max_height) | toLength(true)
                            }}</span
                          >
                        </v-col>
                        <v-col cols="1" class="d-flex align-center justify-end">
                          <v-btn
                            icon
                            @click="
                              editHoldIndex = index;
                              showContainerBuilderModal = true;
                            ">
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-btn
                v-if="set.data.container_types.length === 0"
                class="success"
                @click="goToBuilder()">
                <span class="ml-2">Start building</span>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
      <v-col>
        <scene-component
          v-if="isActive && !showContainerBuilderModal"
          ref="overview-scene"
          interactiveMode="camera_only"
          :key="renderKey"
          :set="renderedSet"
          :custom-view-settings="{
            hideCog: true,
            containerNumbers: true,
          }"></scene-component>
      </v-col>
    </v-row>

    <v-dialog
      v-if="showContainerBuilderModal"
      v-model="showContainerBuilderModal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
            icon
            @click="
              showContainerBuilderModal = false;
              rerender();
              editHoldIndex = undefined;
            ">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Local edit
            {{ editHoldIndex }}
            {{ set.data.container_types[editHoldIndex].name }}</v-toolbar-title
          >
        </v-toolbar>
        <container-builder-component
          :base_type="set.data.container_types[editHoldIndex].base_type"
          v-model="set.data.container_types[editHoldIndex]" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType, set } from 'vue';
import { SetType } from '@/models/SetsModel';
import { AugmentedSet } from '@/models/augmented/set';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import containerBuilderComponent from '../Custom/ContainerEditor/index.vue';
import { SceneManager } from '@/graphics/sceneManager';
import { HoldData } from '@/models/LoadlistModel';
export default Vue.extend({
  name: 'set_overview',
  props: {
    set: Object as PropType<SetType>,
    displayConfiguration: Array as PropType<number[]>,
    isActive: Boolean,
  },
  components: {
    sceneComponent,
    containerBuilderComponent,
  },
  data() {
    return {
      validForm: false,
      nameRules: [
        (v: string) => !!v || 'A name is required',
        (v: string) => (v && v.length <= 100) || 'Name must be less than 100 characters',
      ],
      renderedSet: null as AugmentedSet,
      viewSettings: undefined,
      renderKey: 0,
      showContainerBuilderModal: false,
      editHoldIndex: undefined as number,
    };
  },
  computed: {
    usedContainers(): { holdData: HoldData; count: number }[] {
      return this.set.data.container_types.flatMap((ct) => {
        return {
          holdData: ct,
          count: this.set.data.slots
            .flatMap((slot) => slot.configurations.flatMap((c) => c.group))
            .filter((ht) => ht.container_type_id === ct.id).length,
        };
      });
    },
  },
  methods: {
    rerender() {
      this.viewSettings = SceneManager.getViewSettings();
      if (this.viewSettings.pos[0]) {
        this.viewSettings.view = 'custom';
      }
      this.renderedSet = AugmentedSet.fromSetType(this.set.data, this.displayConfiguration);
      this.renderKey++;
    },
    goToBuilder() {
      this.$emit('goToBuilder');
    },
  },
  watch: {
    showContainerBuilderModal: {
      handler: function (val: boolean): void {
        if (!val) {
          this.rerender();
        }
      },
    },
    editHoldIndex: function () {
      this.renderKey++;
    },
    isActive: {
      handler: function (val: SetType): void {
        if (val) {
          setTimeout(() => {
            this.rerender();
          }, 200);
        }
      },
    },
    set: {
      handler: function (val: SetType): void {
        setTimeout(() => {
          this.rerender();
        }, 200);
      },
      deep: true,
      immediate: true,
    },
    validForm: {
      handler(val, old) {
        if (val !== old) {
          this.$emit('validForm', val);
        }
      },
    },
  },
});
</script>

<style scoped></style>
