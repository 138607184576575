<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mt-3">
          <v-card-title><p class="text-h6">Logo</p></v-card-title>
          <v-card-text>
            <img
              v-if="logo_url"
              id="logoPreview"
              :src="logo_url"
              style="max-height: 120px; max-width: 400px"
              alt="No logo added" />

            <v-file-input accept="image/*" label="File input" @change="logoChange"></v-file-input>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" block v-if="logo_file" @click="uploadLogo">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <settings-component class="mt-2" isCompanySettings></settings-component>

    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      Information updated
      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import API from '@/API';

import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { CompanyStoreTemplate, User } from '@/models/UserCompanyModel';
import SettingsComponent from '../Custom/Settings.vue';
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        companyForm: any;
      };
    }
  >
).extend({
  name: 'company_general_settings',
  components: {
    SettingsComponent,
  },
  data() {
    return {
      isLoading: false,
      showSnackbar: false,
      logo_file: null as File,
      logo_url: '',
    };
  },
  watch: {
    company() {
      this.getCompanyDetails();
    },
  },
  mounted() {
    this.getCompanyDetails();
  },
  computed: {
    ...mapStores(useMiscStore),
    company(): CompanyStoreTemplate {
      return this.miscStore.company;
    },
    user(): User {
      return this.miscStore.user;
    },
  },
  methods: {
    getCompanyDetails(): void {
      if (!this.company.id) return;
      API.getCompany(this.company.id).then((response) => {
        if (response.data.logo) {
          this.logo_url = response.data.logo;
          delete response.data['logo'];
        }
      });
    },
    logoChange(file: File): void {
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.logo_url = e.target.result as string;
        };
        reader.readAsDataURL(file);
        this.logo_file = file;
      } else {
        this.logo_file = null;
      }
    },
    uploadLogo(e: any): void {
      if (!this.logo_file) return;
      const fd = new FormData();
      fd.append('logo', this.logo_file);

      API.updateCompany(this.company.id, fd)
        .then((success) => {
          this.showSnackbar = true;
        })
        .catch((error) => {});
    },
  },
  filters: {
    uppercase(val: string): string {
      return val.toUpperCase();
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
