<template>
  <div>
    <v-card
      v-for="(subItem, index) in items"
      :key="index"
      outlined
      :disabled="depth == 0"
      class="py-1 px-1 mx-1 my-1">
      <v-card-text>
        <v-row>
          <v-icon v-bind:style="{ color: subItem.color }" v-if="!subItem.from_container"
            >fa-cube</v-icon
          >
          <span class="ml-2 font-weight-bold">{{ subItem.label }}</span>
          <v-spacer></v-spacer>
          <span>{{ subItem.qty }} pcs</span>
        </v-row>
        <v-row>
          <span
            >{{ subItem.L | toLength(false) }} x {{ subItem.W | toLength(false) }} x
            {{ subItem.H | toLength(true) }}, {{ subItem.WT | toWeight(true) }}</span
          >
          <v-spacer></v-spacer>
          <span class="item-indicator"
            >{{ subItem.not_stackable ? ' ≠ ' : '' }}
            {{ subItem.orientations <= 3 ? ' ⇈ ' : '' }}</span
          >
        </v-row>
      </v-card-text>
      <sub-items :item="subItem" :depth="depth + 1"></sub-items>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import { HoldItem } from '@/models/LoadlistModel';
import itemUtils from '@/misc/itemUtils';

export default Vue.extend({
  name: 'sub-items',

  props: {
    item: {
      default: null,
      type: Object as PropType<HoldItem>,
    },
    depth: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    items(): HoldItem[] {
      return itemUtils.bundledItems(this.item.from_container?.items || []);
    },
  },
});
</script>
