<template>
  <v-dialog v-model="show" scrollable width="1200">
    <v-card>
      <v-card-title class="text-h5">Quick guide</v-card-title>
      <v-card-text class="ma-3 text-body-1">
        <v-alert border="left" colored-border type="info" elevation="2">
          Read our
          <a href="https://cargo-planner.com/docs/tutorial/the-interactive-view" target="_blank"
            >tutorial</a
          >
          to learn more!
        </v-alert>
        <p>
          If you did not already know - you select a cargo by clicking on it with your mouse or
          <kbd>Enter</kbd> button while a cargo is hovered
        </p>
        <p>
          If clicking on a cargo while pressing the <kbd>Shift</kbd> button - you will also select
          all cargoes behind. This is useful if you want to select a stack at once
        </p>
        <p>
          You can select / deselect multiple cargoes by pressing the
          <kbd>Ctrl</kbd> button
        </p>
        <p>
          You can hover between different cargoes by pressing the
          <kbd>Tab</kbd> button
        </p>
        <p>
          Use the keyboard shortcuts <kbd>1</kbd>, <kbd>2</kbd>, <kbd>3</kbd>, <kbd>4</kbd> to
          quickly change the view from Top, Side, 3D and Front. Use the mouse to change the view,
          and scroll to zoom in and out -
          <b>however this only works in the 3D view</b>
        </p>

        <h3>Different ways to move cargo</h3>
        <ul>
          <li>Drag the cargo(s) with your <b>mouse</b></li>

          <li>
            Use the <b>arrow keys</b> <kbd>←</kbd>&nbsp;<kbd>↑</kbd>&nbsp; <kbd>→</kbd> &nbsp;<kbd
              >↓</kbd
            >
            on your keyboard to move a selected cargo in X-Y directions. Move the camera with your
            mouse and the up-down and right-left keys will move the cargoes accordingly. Use the
            <kbd>Space</kbd> button to move a cargo upwards
          </li>
        </ul>

        <p>
          Press
          <kbd>Del</kbd> or <kbd>Backspace</kbd> to unload selected cargo(s)
        </p>

        <p>
          Press
          <kbd>Ctrl + a</kbd> to select all cargoes
        </p>

        <p>
          Press
          <kbd>Ctrl + z</kbd> to undo last move
        </p>

        <p>Press <kbd>r</kbd> to rotate selected cargoes</p>

        <p>
          Press the
          <kbd>Esc</kbd> key or click outside the container to deselect a selected cargo
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click.stop="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
});
</script>

<style></style>
