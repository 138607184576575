import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{ref:"plannerTable",staticClass:"fill-height"},_vm._l((_vm.augmentedHolds),function(hold){return _c(VCol,{key:hold.hold.uid,staticClass:"my-1",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c(VCard,{staticClass:"ma-1 fill-height plannerContainer",attrs:{"loading":_vm.loadingContainers.includes(hold.__indices.start)}},[_c('planner-items-component',{attrs:{"hideItems":_vm.$vuetify.breakpoint.xsOnly,"hold":hold.hold,"max-list-height":"300px"},on:{"loadingContainers":function($event){_vm.loadingContainers = $event}},scopedSlots:_vm._u([{key:"top",fn:function({ container }){return [_c('div',{staticClass:"d-flex flex-row ma-2"},[_c(VSimpleCheckbox,{staticClass:"align-self-center",attrs:{"value":_vm.checkedUnits.indexOf(hold.__indices) >= 0,"ripple":false,"label":hold.__indices.end > hold.__indices.start
                  ? `${hold.__indices.start + 1} to ${hold.__indices.end + 1}`
                  : `${hold.__indices.start + 1}`},on:{"input":function($event){return _vm.checkRow(hold.__indices)}}}),_c('span',{staticClass:"align-self-center"},[_vm._v(_vm._s(hold.__indices.end > hold.__indices.start ? `${hold.__indices.start + 1} to ${hold.__indices.end + 1}` : `${hold.__indices.start + 1}`))]),_c('span',{staticClass:"ml-4 align-self-center text-subtitle-1"},[_vm._v(_vm._s(container.hold.name))]),_c(VSpacer),(hold.hasCoordinates())?_c(VChip,{on:{"click":function($event){return _vm.$emit('showMapModal', hold.uuid)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa-map")])],1):_vm._e()],1),_c(VDivider),_c('div',{staticClass:"scene-container",on:{"click":function($event){return _vm.gotoInteractiveView(hold.__indices.start)}}},[_c('scene-component',{key:hold.hold.uid,staticClass:"my-1 d-flex justify-center",staticStyle:{"cursor":"pointer"},attrs:{"hold-object":hold.hold,"canvas-width":400,"canvas-height":200}})],1),_c(VDivider),_c(VDivider)]}}],null,true)})],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }