<template>
  <v-dialog v-model="show" scrollable width="800">
    <v-card>
      <v-toolbar dark color="primary">
        <v-card-title class="text-h5">Multi factor authentication</v-card-title>
      </v-toolbar>
      <v-card-text>
        <div class="my-2">
          <v-alert v-if="devices.length" type="success" outlined>MFA is enabled </v-alert>
          <v-alert v-else type="warning" outlined
            >MFA id disabled. Setup to increase the security of your account.
          </v-alert>

          <!-- <v-alert type="info" outlined>Add </v-alert> -->
          <v-simple-table v-if="devices.length">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Created</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="device in devices" :key="device.id">
                  <td>{{ device.name }}</td>
                  <td>{{ new Date(device.created_at).toLocaleDateString() }}</td>
                  <td>
                    <v-btn
                      @click="
                        selectedDevice = device;
                        showRemoveDeviceModal = true;
                      "
                      icon
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-alert v-else type="info" outlined>You do not have and MFA devices. </v-alert>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn @click.stop="showAddDeviceModal = true" :ripple="false">Add device</v-btn>
        <v-spacer />
        <v-btn color="primary" @click.stop="show = false" :ripple="false">Ok</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showAddDeviceModal" scrollable width="800">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title class="text-h5">Add device</v-card-title>
        </v-toolbar>
        <v-card-text>
          <div class="my-2" v-if="!deviceCode">
            <v-text-field
              :rules="nameRules"
              v-model="newDeviceName"
              label="Name of device"></v-text-field>
            <v-btn
              color="success"
              :disabled="!newDeviceName"
              @click.stop="createDevice"
              :ripple="false"
              >Add</v-btn
            >
          </div>
          <v-alert class="my-2" type="success" outlined v-else>
            <p>Scan the code below in your authentiactor application.</p>
            <canvas ref="qrCanvas"></canvas>
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click.stop="
              deviceCode = null;
              showAddDeviceModal = false;
            "
            :ripple="false"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveDeviceModal" scrollable width="800">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title class="text-h5">Remove device</v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-alert class="my-2" type="warning" outlined>
            <p>Are you sure you want to remove this device?</p>
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn @click.stop="showRemoveDeviceModal = false" :ripple="false">Cancel</v-btn>
          <v-spacer />
          <v-btn color="error" @click.stop="deleteDevice" :ripple="false">Yes, delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapStores } from 'pinia';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { useMiscStore } from '@/stores/miscStore';
import API from '@/API';

export default Vue.extend({
  components: {},
  name: 'mfa',
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      devices: [] as { id: number; name: string; created_at: string }[],
      selectedDevice: null,
      showAddDeviceModal: false,
      showRemoveDeviceModal: false,
      newDeviceName: 'My phone',
      nameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => v.length < 50 || 'Max 50 characters',
      ],
      deviceCode: null,
      generatedQrCode: null,
    };
  },

  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  mounted() {
    this.getDevices();
  },

  methods: {
    getDevices() {
      API.getMfaDevices().then((data: any) => {
        this.devices = data.data;
      });
    },
    createDevice() {
      API.createMfaDevice(this.newDeviceName).then((data: any) => {
        this.getDevices();
        this.deviceCode = data.data.code;
        const QRCode = () => import('qrcode');
        QRCode().then((QR) => {
          console.log();
          QR.toCanvas(this.$refs.qrCanvas, data.data.code);
        });
      });
    },
    deleteDevice() {
      API.deleteMfaDevice(this.selectedDevice.id).then((data: any) => {
        this.getDevices();
        this.showRemoveDeviceModal = false;
        this.selectedDevice = null;
      });
    },
  },
});
</script>
<style scoped></style>
