<template>
  <v-card>
    <v-card-title>
      <p class="text-h6">Subscription</p>
    </v-card-title>
    <v-card-text>
      <div class="text-subtitle-1" v-if="valid_subscription"></div>
      <v-alert outlined color="info" :value="valid_subscription">
        <p v-if="company.plan === '1'">
          <v-icon color="primary">mdi-check-circle</v-icon>Trial version
        </p>
        <p v-else-if="company.plan === '2'">
          <v-icon color="primary">mdi-check-circle</v-icon> Lite version
        </p>
        <p v-else><v-icon color="primary">mdi-check-circle</v-icon> Standard version</p>
        <div v-if="company.has_ending_subscription">
          Subscription ends
          {{ new Date(company.subscription_end).toLocaleDateString() }}
        </div>

        <br />Any questions on your license, please contact
        <a href="mailto:support@cargo-planner.com">support@cargo-planner.com</a>
      </v-alert>
      <v-alert outlined type="error" :value="!valid_subscription">
        <span v-if="company.plan === '1'">Trial ended</span>
        <span v-else>Subscription ended</span>
        {{ new Date(company.subscription_end).toLocaleDateString() }}
      </v-alert>
    </v-card-text>
    <v-card-text v-if="showCompanyLink">
      <v-btn block :to="{ name: 'company_billing' }">Update subscription</v-btn>
    </v-card-text>
    <slot></slot>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { User, CompanyStoreTemplate } from '@/models/UserCompanyModel';
export default Vue.extend({
  name: 'length-input-field',
  data() {
    return {};
  },

  computed: {
    ...mapStores(useMiscStore),
    showCompanyLink(): boolean {
      return (
        this.$route.name !== 'company_billing' && this.company.plan === '1' && this.user.is_admin
      );
    },
    user(): User {
      return this.miscStore.user;
    },
    company(): CompanyStoreTemplate {
      return this.miscStore.company;
    },
    valid_subscription(): boolean {
      return this.company.valid_subscription;
    },
  },

  methods: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
